import React, {useState} from "react";
import styled from "styled-components";
import {VideoPlayer} from "../components/subtitles/VideoPlayer";
import {getSecondsFromTimestamp, secondsToTimestamp, Subtitle, Timestamp} from "../components/subtitles/subtitleUtils";
import {SubtitlesEditor} from "../components/subtitles/SubtitlesEditor";
import Breadcrumb from "../components/Breadcrumb";
import PageTitle from "../components/PageTitle";
import Validations, {Validation, ValidationItem} from "../components/validations/Validations";

const GridContainer = styled.div`
    display: grid;
    height: 100vh;
    grid-template-columns: auto 45%;
    grid-template-rows: 1fr 4fr 2fr;
    gap: 5px;
`

const SubtitlesContainer = styled.div`
    grid-column: 1/2;
    grid-row: 1/3;
`

const VideoPlayerContainer = styled.div`
    border-radius: 10px;
    border: 1px solid #aaa;
`;

const ValidationsContainer = styled.div`
    border-radius: 10px;
    border: 1px solid #aaa;
    overflow: hidden;
`;
export const SubtitlesCreator: React.FC = () => {
    const [editorSubtitles, setEditorSubtitles] = useState<Subtitle[]>([]);
    const [currentTimeSeconds, setCurrentTimeSeconds] = useState<number>(0);
    const [videoDurationSeconds, setVideoDurationSeconds] = useState<number>(0);
    const [focusTime, setFocusTime] = useState<number>(0);
    const [videoSubtitles, setVideoSubtitles] = useState<Subtitle[]>(editorSubtitles)
    const [validations, setValidations] = useState<Validation[]>([])


    const handleSubtitleFocus = (start: Timestamp): void => {
        setFocusTime(getSecondsFromTimestamp(start))
    }

    const handleReloadClick = (): void => {
        setVideoSubtitles(editorSubtitles);
        setFocusTime(0)
    }
    const handleValidationUpdate = (newValidations: Validation[]): void => {
        if (JSON.stringify(validations) !== JSON.stringify(newValidations)) {
            setValidations(newValidations);
        }
    };
    return (
        <>
            <Breadcrumb link="Subtitles Editor"/>
            <PageTitle title="Subtitles Editor"/>
            <GridContainer id="grid-container">
                <SubtitlesContainer id="subtitle-editor-container">
                    <SubtitlesEditor
                        subtitles={editorSubtitles}
                        updateSubtitles={setEditorSubtitles}
                        currentTime={secondsToTimestamp(currentTimeSeconds)}
                        videoDuration={secondsToTimestamp(videoDurationSeconds)}
                        onSubtitleFocus={handleSubtitleFocus}
                        onErrorsUpdate={handleValidationUpdate}
                    />
                </SubtitlesContainer>
                <ValidationsContainer>
                    <Validations>
                        {validations.map((validation, i) => {
                            return (
                                <ValidationItem key={`validation-${i}`} validation={validation} onValidationClick={() => {}}/>
                            )
                        })}
                    </Validations>
                </ValidationsContainer>
                <VideoPlayerContainer id="video-player-container">
                    <VideoPlayer
                        subtitles={videoSubtitles}
                        onUpdateCurrent={setCurrentTimeSeconds}
                        onUpdateDuration={setVideoDurationSeconds}
                        focusTime={focusTime}
                        onReloadSubtitles={handleReloadClick}
                    />
                </VideoPlayerContainer>
            </GridContainer>
        </>

    );
};