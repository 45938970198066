import {EditorButton} from "../translator/TargetEditorButtons";
import {TfiCommentAlt, TfiComment} from "react-icons/tfi";
import React, {JSX, useState} from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import {
    SegmentComment,
    useAddSegmentCommentMutation,
    useGetSegmentCommentsQuery
} from "../../services/segmentsApiSlice";
import {FaRegUser} from "react-icons/fa";
import {RxCalendar} from "react-icons/rx";
import {CiClock1} from "react-icons/ci";

const ModalContainer = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 10px;
    justify-content: space-between;
    overflow-y: auto;
`;

const CommentsSectionContainer = styled.div`
    width: 98%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const CommentItemContainer = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid gray;
`;

const CommentDetailsContainer = styled.div`
    padding: 5px 10px;
    border-radius: 4px;
    background-color: rgba(255, 193, 7, 0.5); //#ffc107
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 3;
`;

const CommentTextContainer = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: column;
    flex: 7;
    overflow-x: auto;
    font-size: 14px;
`;

const DetailContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

const AddCommentsContainer = styled.div`
    border-radius: 4px;
    min-height: 70px;
    width: 98%;
    display: flex;
`;

const NewCommentInputContainer = styled.div`
    flex: 7;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NewCommentInput = styled.textarea`
    border: 1px solid gray;
    border-radius: 4px;
    resize: none;
    width: 100%;
`;

const SaveCommentButtonContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
`;

const SaveCommentButton = styled.button`
    border-radius: 4px;
    border: 1px solid black;
    padding: 5px 10px;
    background-color: rgba(255, 193, 7, 0.5);
`;

export const AddSegmentCommentBtn = ({segmentId}: { segmentId: string }) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const {data: comments} = useGetSegmentCommentsQuery(segmentId);

    let modalStyles: ReactModal.Styles = {
        content: {
            border: '1px solid black',
            borderRadius: '4px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            maxHeight: '60%'
        }
    }

    const getIcon = (): JSX.Element => {
        if (comments && comments.length > 0)
            return <TfiCommentAlt fontSize={'14px'}/>
        else
            return <TfiComment fontSize={'14px'}/>
    }

    return (
        <>
            <EditorButton onMouseDown={() => setShowModal(prevState => !prevState)} title="Comments">
                {getIcon()}
            </EditorButton>
            <ReactModal isOpen={showModal} onRequestClose={() => setShowModal(false)} style={modalStyles}>
                <CommentsModal segId={segmentId} comments={comments}/>
            </ReactModal>
        </>
    );
};

const CommentsModal = ({segId, comments}: { segId: string, comments: SegmentComment[] | undefined }) => {
    return (
        <ModalContainer>
            <h2>Segment comments</h2>
            <CommentsSectionContainer>
                <CommentsSection comments={comments}/>
            </CommentsSectionContainer>
            <AddCommentsContainer>
                <AddCommentSection segId={segId}/>
            </AddCommentsContainer>
        </ModalContainer>
    )
}

const CommentsSection = ({comments}: { comments: SegmentComment[] | undefined }) => {
    return (
        <>
            {comments && comments.length > 0 && comments.map(c => <CommentItem comment={c}/>)}
            {!comments || comments.length === 0 && <h3>No comments in this segment</h3>}
        </>
    )
}

const CommentItem = ({comment}: { comment: SegmentComment }) => {
    const dateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    const hourOptions: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric'
    }

    return (<>
            <CommentItemContainer>
                <CommentDetailsContainer>
                    <DetailContainer><FaRegUser/>{`${comment.creator.firstName} ${comment.creator.lastName}`}
                    </DetailContainer>
                    <DetailContainer><RxCalendar/>{new Date(comment.creationDate).toLocaleString(undefined, dateOptions)}
                    </DetailContainer>
                    <DetailContainer><CiClock1/>{new Date(comment.creationDate).toLocaleString(undefined, hourOptions)}
                    </DetailContainer>
                </CommentDetailsContainer>
                <CommentTextContainer>
                    <div>
                        <strong>Status: </strong>{comment.relatedHistoryRecord ?
                            comment.relatedHistoryRecord.newStatus.charAt(0).toUpperCase() + comment.relatedHistoryRecord.newStatus.substring(1).toLowerCase() :
                            'No history record associated'}
                    </div>
                    <div>
                        <strong>Target: </strong>{comment.relatedHistoryRecord ? comment.relatedHistoryRecord.newTarget : 'No history record associated'}
                    </div>
                    <div>
                        <strong>Comment: </strong>{comment.text}
                    </div>
                </CommentTextContainer>
            </CommentItemContainer>
        </>
    )
}

const AddCommentSection = ({segId}: { segId: string }) => {
    const [text, setText] = useState('');
    const [addSegmentComment, {isLoading}] = useAddSegmentCommentMutation();

    const handleTextChange = (event: any) => {
        setText(event.target.value);
    };

    const handleSave = () => {
        if (text !== '') {
            addSegmentComment({id: segId, comment: text});
            setText('');
        }
    };

    return (
        <>
            <NewCommentInputContainer>
                <NewCommentInput
                    value={text}
                    onChange={handleTextChange}
                    placeholder="Insert new comment here..."
                />
            </NewCommentInputContainer>
            <SaveCommentButtonContainer>
                {!isLoading && <SaveCommentButton onClick={handleSave}>Save</SaveCommentButton>}
            </SaveCommentButtonContainer>
        </>
    );
}