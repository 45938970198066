import React, {useState, useEffect, ChangeEvent, useMemo, useRef} from "react";
import { skipToken } from '@reduxjs/toolkit/query/react';
import styled, { css } from "styled-components";
import Breadcrumb from "components/Breadcrumb";
import PageTitle from "components/PageTitle";
import FormLabel from "../components/ui/forms/action/ActionFormLabel";
import FormInput from "../components/ui/forms/action/ActionFormInput";
import {BiPlusMedical} from "react-icons/bi";
import {FaMinus} from "react-icons/fa";
import {RiEdit2Fill} from 'react-icons/ri';
import FullTable from "../components/tables/FullTable";
import {ImZoomIn} from "react-icons/im";
import {HiDotsVertical} from "react-icons/hi";
import Badge, {formatStatus} from "./../components/projects/Badge";
import {useNavigate} from "react-router-dom";
import CountryFlag from "./../components/projects/CountryFlag";
import {ProjectInfo, useGetProjectsQuery, useEditProjectMutation} from "../services/projectsApiSlice";
import SelectOption from '../components/ui/forms/action/SelectOption';
import {PAGE_SIZE_OPTIONS, SEARCH_COLUMN_OPTIONS, STATUS_COLUMN_OPTIONS, LANGUAGE_OPTIONS, FILTER_OPTIONS } from "../utils/constants";
import PaginationControl from "../components/ui/PaginationControl";
import SuccessPopup from "../components/ui/forms/SuccessPopup";
import {useGetWorkspacesQuery, useGetWorkspaceQuery, Workspace} from '../services/workspacesApiSlice';
import {TypeOfList} from "../services/usersApiSlice";
import ModalPopUp from "./../components/administration/ModalPopUp";
import {UserInfo} from "../store/userSlice";
import ChangeAssigneeForm from "../components/projects/ChangeAssigneeForm";
import {useAppSelector} from "../hooks/store-hooks";
import {PencilEditIcon} from "../components/icons/PencilEditIcon";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const TableContainer = styled.div`
  overflow-x: auto;
`;
const SecondaryContainer = styled.div`
  margin: 0 20px 30px 20px;
`;
const FiltersGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FilterGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3px;
`;
const PlusIconContainer = styled.div`
  position: relative;
  display: inline-block;
`;
const baseIconStyles = css`
  color: #2D292A;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
`;
const StyledPlusIcon = styled(BiPlusMedical)`
  ${baseIconStyles}
`;
const StyledMinusIcon = styled(FaMinus)`
  ${baseIconStyles}
`;
const Button = styled.button`
  width: 150px;
  height: 23px;
  cursor: pointer;
  border: 1px solid rgb(206, 212, 218);
  border-radius: 4px;
  color: #444;
  font-weight: 600;
  margin-right: 10px;
  &:hover {
    color: #1e1d1d;
    background-color: #e5e4e4;
  }
`;
const Tooltip = styled.div`
  visibility: hidden;
  background-color: transparent;
  color: #b0b0b0;
  padding: 10px 10px;
  font-size: 8px;
  position: absolute;
  bottom: 100%;
  left: 100%;
  transform: translateY(78%);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;

  ${PlusIconContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;
const FlagContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 65px);
  gap: 2px;
`;
interface Filter {
    columnOption: string;
    searchValue: string;
}
function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}
export interface AssigneeProps {
    username: string;
    icon: React.ReactNode;
}
export interface ProjectFilterProps {
    defaultFilter: string;
    icon: React.ReactNode;
    projectId?: number;
    jobId?: number;
    taskId?: number;
}
interface TableProjectProps {
    id: number;
    project_name: string;
    source: React.ReactNode;
    target_language: React.ReactNode;
    creator: string;
    workspace: number;
    assignee: AssigneeProps;
    filter: ProjectFilterProps;
    creation_date: string;
    due_date: string;
    total_tasks: number;
    wordcount: number;
    status: React.ReactNode;
    view_project: React.ReactNode;
}
const DefaultTableHeaders = [
    "id",
    "project_name",
    "source",
    "target_language",
    "creator",
    "workspace",
    "assignee",
    "filter",
    "creation_date",
    "due_date",
    "total_tasks",
    "wordcount",
    "status",
    "view_project",
];
export function formatHeader(header: string): string {
    const headerName = header.trim().split('_');
    const capitalizedHeaders = headerName.map(
        word => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedHeaders.join(' ');
}
export interface MemberOption {
    value: string;
    label: string;
}
const Projects: React.FC = () => {
    const [currentSearch, setCurrentSearch] = useState("");
    const [globalSearch, setGlobalSearch] = useState("");
    const [criteriaSearchInColumns, setCriteriaSearchInColumns] = useState("");
    const [filters, setFilters] = useState<Filter[]>([{ columnOption: "", searchValue: "" }]);
    const [allProjects, setAllProjects] = useState<TableProjectProps[]>([]);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sortPageDirection] = useState("asc");
    const [sortPageBy] = useState("id");
    const [, setShowPagination] = useState(false);
    const [totalPages, setTotalPages] = useState<number>(1);
    const shouldShowPagination = totalPages > 1;
    const [allOptionsSelected, setAllOptionsSelected] = useState(false);
    const initialFilterState = [{ columnOption: '', searchValue: '' }];
    const [showNoProjectsFound, setShowNoProjectsFound] = useState(false);
    const [allMembersFromWorkspace, setAllMembersFromWorkspace] = useState<MemberOption[]>([]);
    const [selectedWorkspace, setSelectedWorkspace] = useState<string>('0');
    const [selectedProject, setSelectedProject] = useState(0);
    const { data: workspaceUsers } = useGetWorkspaceQuery(selectedWorkspace !== '0' ? selectedWorkspace : skipToken);
    const [showMembersOptionsModal, setShowMembersOptionsModal] = useState(false);
    const { data: allWorkspaces } = useGetWorkspacesQuery({});
    const [editProject] = useEditProjectMutation({});
    const [newProjectFilter, setNewProjectFilter] = useState("");
    const [editingFilterProjectId, setEditingFilterProjectId] = useState<number | null>(null);
    const [showFilterConfirmationPopup, setShowFilterConfirmationPopup] = useState(false);
    const showFilterOptions = useRef<HTMLDivElement | null>(null);
    const [tempProjectId, setTempProjectId] = useState<number | null>(null);
    const userInfoFromState = useAppSelector(state => state.userInfo) as UserInfo;
    const [columnOrder, setColumnOrder] = useState<string[]>(DefaultTableHeaders);
    const [loadingColumnOrder, setLoadingColumnOrder] = useState(true);
    const [isFirstDataLoad, setIsFirstDataLoad] = useState(true);
    const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
    const [currentAssignee, setCurrentAssignee] = useState("");
    const { data, isLoading, isFetching, refetch } = useGetProjectsQuery({
        page: currentPage,
        size: pageSize,
        sortBy: sortPageBy,
        sortDir: sortPageDirection,
        filter: globalSearch,
        searchCriteria: criteriaSearchInColumns,
    });
    const workspacesById = useMemo(() => {
        if (allWorkspaces) {
            return allWorkspaces.reduce((acc: Record<string, Workspace>, workspace: Workspace) => {
                acc[workspace.id] = workspace;
                return acc;
            }, {} as Record<number, Workspace>);
        }
        return {};
    }, [allWorkspaces]);
    const addFilter = () => {
        setFilters(prevFilters => [...prevFilters, { columnOption: "", searchValue: "" }]);
    };
    const removeFilter = (index: number) => {
        setFilters(prevFilters => prevFilters.filter((_filter, i) => i !== index));
    };
    useEffect(() => {
        if (data) {
            setIsInitialDataLoaded(true);
        }
    }, [data]);
    useEffect(() => {
        if (isInitialDataLoaded) {
            if (userInfoFromState.views && userInfoFromState.views.length > 0) {
                const jobsListView = userInfoFromState.views.find(view => view.name === "PROJECTS_LIST");
                if (jobsListView) {
                    setColumnOrder(jobsListView.columns.split(';'));
                } else {
                    setColumnOrder(DefaultTableHeaders);
                }
            } else {
                setColumnOrder(DefaultTableHeaders);
            }
            setLoadingColumnOrder(false);
        }
    }, [userInfoFromState.views, isInitialDataLoaded]);

   useEffect(() => {
        if (data?.content) {
            const projects: TableProjectProps[] = data.content.map(getProjectTableInfo);
            setAllProjects(projects);
            setIsFirstDataLoad(false);
            setTotalPages(data.totalPages)
            setShowPagination(data.totalPages > 1);
        }
        if (!isFirstDataLoad  && data?.totalElements === 0) {
            setShowNoProjectsFound(true);
            setCriteriaSearchInColumns("");
            setFilters([...initialFilterState]);
        }
       if (data?.totalElements === 0) {
           setColumnOrder(DefaultTableHeaders);
           setLoadingColumnOrder(false)
       }
    }, [data]);

    useEffect(() => {
        if(filters.length >= SEARCH_COLUMN_OPTIONS.length){
            setAllOptionsSelected(true);
        } else {
            setAllOptionsSelected(false);
        }
    }, [filters]);

    useEffect(()=>{
        const timeout = setTimeout(() => {
            setGlobalSearch(currentSearch)
        },2000);
        return () =>  clearTimeout(timeout)
    },[currentSearch]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (showFilterOptions.current && !showFilterOptions.current.contains(event.target as Node)) {
                stopEditingFilter();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showFilterOptions]);
    const handleChangeColumnOption = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
        const value = String(event.target.value);
        setFilters(prevFilters => prevFilters.map((filter, i) => {
            if (i === index) {
                if (prevFilters.some((f, j) => f.columnOption === value && j !== i)) {
                    return filter;
                }
                return { ...filter, columnOption: value };
            }
            return filter;
        }));
        if(filters.length >= SEARCH_COLUMN_OPTIONS.length){
            setAllOptionsSelected(true);
        }
        if(filters.length < SEARCH_COLUMN_OPTIONS.length){
            setAllOptionsSelected(false);
        }
    };

    const handleSearchInColumns = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const value = event.target.value;
        setFilters(prevFilters => prevFilters.map((filter, i) => i === index ? { ...filter, searchValue: value } : filter));
    };

    const getAvailableOptions = (currentIndex: number) => {
        return SEARCH_COLUMN_OPTIONS.filter(option => {
            for (let i = 0; i < filters.length; i++) {
                if (i !== currentIndex && filters[i].columnOption === option.value) {
                    return false;
                }
            }
            return true;
        });
    };
    const canEditProject = (projectInfo: ProjectInfo) => {
        if(userInfoFromState.roles?.includes("ROLE_ADMIN") || userInfoFromState.roles?.includes("ROLE_OWNER")){
            return true;
        }
        if(projectInfo.creator.username === userInfoFromState.username){
            return true;
        }
        if(projectInfo.assignee.username === userInfoFromState.username){
            return true;
        }
        return false;
    };
    const getProjectTableInfo = (projectInfo: ProjectInfo): TableProjectProps => {
        const sourceLocaleFlag = (
            <CountryFlag id={`project_${projectInfo.id}_source_language`} languageCode={projectInfo.sourceLocale} fontSize="14px" />
        );
        const targetLocaleFlags = projectInfo.targetLocales.map(
            (locale: string) => (
                <CountryFlag id={`project_${projectInfo.id}_target_language`} key={locale} languageCode={locale} fontSize="14px" />
            )
        );
        const statusInfo = formatStatus(projectInfo.status);
        const statusBadge = (
            <Badge
                data={statusInfo.label}
                text={statusInfo.label}
                backgroundColor={statusInfo.color}
                textColor="#fff"
            />
        );
        const workspaceName = workspacesById[projectInfo.workspaceId]?.name || '';
        return {
            id: projectInfo.id,
            project_name: projectInfo.name,
            source: sourceLocaleFlag,
            target_language: targetLocaleFlags,
            creator: `${projectInfo.creator.firstName} ${projectInfo.creator.lastName}`,
            workspace: workspaceName,
            assignee: {
                username: `${projectInfo.assignee.firstName} ${projectInfo.assignee.lastName}`,
                icon: canEditProject(projectInfo) ?
                    <PencilEditIcon
                        id={`project_${projectInfo.id}_assignee_icon`}
                        onClick={() => handleAssigneeIconClick(projectInfo.id, projectInfo.workspaceId, projectInfo.assignee.username)}
                        style={{cursor: "pointer"}}
                    /> : null
            },
            filter:{
                defaultFilter: projectInfo.defaultFilter.charAt(0).toUpperCase() + projectInfo.defaultFilter.slice(1),
                icon: canEditProject(projectInfo) ?
                    <PencilEditIcon
                        id={`project_${projectInfo.id}_filter_icon`}
                        onClick={() => handleProjectFilterIconClick(projectInfo.id)}
                        style={{cursor: "pointer"}}
                    />: null,
                projectId: projectInfo.id
            },
            creation_date: formatDate(projectInfo.creationDate),
            due_date: formatDate(projectInfo.dueDate),
            total_tasks: projectInfo.jobs[0].tasks.length,
            wordcount: projectInfo.jobs[0].tasks
                .map((task: { wordCount: number }) => task.wordCount)
                .reduce((x: number, y: number) => x + y),
            status: statusBadge,
            view_project: (
                <ImZoomIn
                    id={`project_${projectInfo.id}_view_project_icon`}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleViewProject(projectInfo.id)}
                />
            ),
        };
    };
    const handleViewProject = (projectId: number) => {
        navigate(`/project/${projectId}`);
    };
    const renderCell = (id: number, key: string, value: any, rowIndex: number, cellIndex: number ): React.ReactNode => {
        if (key === "source" || key === "target_language") {
            if (Array.isArray(value)) {
                return (
                    <FlagContainer>
                        {value.map((flagComponent: React.ReactNode) => {
                            if (React.isValidElement(flagComponent)) {
                                const languageCode = (
                                    flagComponent as React.ReactElement
                                ).props.languageCode;
                                return (
                                    <CountryFlag
                                        key={languageCode}
                                        languageCode={languageCode}
                                        fontSize="14px"
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}
                    </FlagContainer>
                );
            } else if (React.isValidElement(value)) {
                const languageCode = (value as React.ReactElement).props.languageCode;
                return (
                    <CountryFlag
                        languageCode={languageCode}
                        fontSize="14px"
                    />
                );
            }
        } else if (key === "assignee") {
            if (typeof value === "object" && value !== null) {
                return (
                    <>
                        {value.username}
                        {value.icon}
                    </>
                );
            }
        } else if (key === "filter") {
            if (typeof value === "object" && value !== null) {
                if (value.projectId === editingFilterProjectId) {
                    return (
                        <div ref={showFilterOptions}>
                            <SelectOption
                                id={`project_${id}_filter_select`}
                                onChange={handleChangeProjectFilter}
                                value={newProjectFilter}
                                options={FILTER_OPTIONS.filter(option => option.value !== value.defaultFilter.toLowerCase())}
                                width={110}
                            />
                        </div>
                    );
                } else {
                    return (
                        <>
                            {value.defaultFilter}
                            {value.icon}
                        </>
                    );
                }
            }
        } else  {
            return value;
        }
        return null;
    };

    const renderHeader = (header: string, index: number): React.ReactNode => {
        const formattedHeader = formatHeader(header);
        return (
            <React.Fragment>
                <HiDotsVertical style={{ marginRight: "5px" }} />
                {formattedHeader}
            </React.Fragment>
        );
    };
    const handleSearchAllProjectsWithFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setCurrentSearch(value);
        setCurrentPage(0);
    };
    const handleSelectStatusChange = (e: ChangeEvent<HTMLSelectElement>, index: number) => {
        const updatedFilters = [...filters];
        updatedFilters[index].searchValue = e.target.value;
        setFilters(updatedFilters);
    }
    const handleSelectTargetChange = (e: ChangeEvent<HTMLSelectElement>, index: number) => {
        const updatedFilters = [...filters];
        updatedFilters[index].searchValue = e.target.value;
        setFilters(updatedFilters);
    }
    const handleSelectSourceChange = (e: ChangeEvent<HTMLSelectElement>, index: number) => {
        const updatedFilters = [...filters];
        updatedFilters[index].searchValue = e.target.value;
        setFilters(updatedFilters);
    }
    const handleSearchPerColumns = () => {
        const validFilters = filters.filter(filter => filter.columnOption && filter.searchValue);
        let searchCriteria = "";
        validFilters.forEach((filter, index) => {
            if(index === 0) {
                searchCriteria += `${filter.columnOption}=${encodeURIComponent(filter.searchValue)}`;
            } else {
                searchCriteria += `&${filter.columnOption}=${encodeURIComponent(filter.searchValue)}`;
            }
        });
        const urlParams = `${searchCriteria}`;
        setCriteriaSearchInColumns(urlParams);

    };
    const handleResetFilters = async () => {
        setCurrentSearch("");
        setCriteriaSearchInColumns("");
        setFilters([...initialFilterState]);
    }
    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };
    const handleChangePageSizeOption = (event: ChangeEvent<HTMLSelectElement>) => {
        setPageSize(Number(event.target.value));
        setCurrentPage(0);
    };
    const handleCloseNoProjectsPopup = () => {
        setShowNoProjectsFound(false)
    };
    const handleAssigneeIconClick = (projectId: number, workspaceId: string, assignee: string ) => {
        setSelectedWorkspace(workspaceId);
        setShowMembersOptionsModal(true);
        setSelectedProject(projectId)
        setCurrentAssignee(assignee);
    };
    const handleProjectFilterIconClick = (projectId: number) => {
        setEditingFilterProjectId(projectId);
        setTempProjectId(projectId);
    };
    const handleConfirmFilterChange = async () =>{
        if (newProjectFilter !== "") {
            try {
                await editProject({ projectId: tempProjectId, updates: { defaultFilter: newProjectFilter } });
                setEditingFilterProjectId(null);
                setTempProjectId(null);
                setNewProjectFilter("");
                refetch();
            } catch (err) {
                console.error("Changing filter error: ", err);
            }
        }
        setShowFilterConfirmationPopup(false);
        setEditingFilterProjectId(null)
        setTempProjectId(null);
        setNewProjectFilter("");
    }
    const handleCloseFilterConfirmationPopup = () =>{
        setShowFilterConfirmationPopup(false);
        setEditingFilterProjectId(null);
        setNewProjectFilter("");
    }
    const stopEditingFilter = () => {
        setEditingFilterProjectId(null);
    };
    const handleChangeProjectFilter = (event: ChangeEvent<HTMLSelectElement>) => {
        setNewProjectFilter(event.target.value);
        setShowFilterConfirmationPopup(true);
    };
    useEffect(() => {
        if (workspaceUsers) {
            let usersForDropdown = workspaceUsers.members
                .map((member:UserInfo) => {
                    return {
                        value: member.username,
                        label: `${member.firstName} ${member.lastName}`,
                    };
                })
                .filter((user:MemberOption) => user.value !== "Underline-Support" && user.value !== currentAssignee);
            setAllMembersFromWorkspace(usersForDropdown);
        }
    }, [selectedWorkspace, workspaceUsers, currentAssignee]);
    return (
        <>
            <MainContainer>
                <Breadcrumb link="Projects" />
                <PageTitle title="All Projects" />
                <SecondaryContainer>
                    <FiltersGroup>
                        <FilterGroup>
                            <FormLabel text="Search in all projects" marginLeft={0} />
                            <FormInput
                                id={"projects_global_filter_input"}
                                type="text"
                                value={currentSearch}
                                onChange={handleSearchAllProjectsWithFilter}
                                width={250}
                            />
                        </FilterGroup>
                        <FilterGroup>
                            <FormLabel text="Select items to show" />
                            <SelectOption
                                id={"projects_pagination_options"}
                                value={pageSize.toString()}
                                onChange={handleChangePageSizeOption}
                                options={PAGE_SIZE_OPTIONS}
                                width={150}
                            />
                        </FilterGroup>
                    </FiltersGroup>
                    <FiltersGroup>
                        <FilterGroup>
                            <FormLabel text="Search by Column" marginLeft={0} />
                            <FormLabel text="Search for" marginLeft={40}/>
                        </FilterGroup>
                    </FiltersGroup>

                    {filters.map((filter, index) => (
                        <FiltersGroup key={index}>
                            <FiltersGroup>
                                <FilterGroup>
                                    <SelectOption
                                        id={`projects_advance_filter_select_${index}`}
                                        value={filter.columnOption}
                                        onChange={(e) => handleChangeColumnOption(e, index)}
                                        options={getAvailableOptions(index)}
                                        width={150}
                                        height={25}
                                    />
                                    {filter.columnOption === 'status' ? (
                                        <SelectOption
                                            id={"projects_status_filter_select"}
                                            value={filter.searchValue}
                                            onChange={(e) => handleSelectStatusChange(e, index)}
                                            options={STATUS_COLUMN_OPTIONS}
                                            width={212}
                                            height={25}
                                            marginLeft={10}
                                        />
                                    ) : filter.columnOption === 'sourceLocale' ? (
                                        <SelectOption
                                            id={"projects_source_filter_select"}
                                            value={filter.searchValue}
                                            onChange={(e) => handleSelectSourceChange(e, index)}
                                            options={LANGUAGE_OPTIONS}
                                            width={212}
                                            height={25}
                                            marginLeft={10}
                                        />
                                    ) : filter.columnOption === 'targetLocale' ? (
                                        <SelectOption
                                            id={"projects_target_filter_select"}
                                            value={filter.searchValue}
                                            onChange={(e) => handleSelectTargetChange(e, index)}
                                            options={LANGUAGE_OPTIONS}
                                            width={212}
                                            height={25}
                                            marginLeft={10}
                                        />
                                    ): (
                                        <FormInput
                                            id={`projects_advance_filter_input_${index}`}
                                            type="text"
                                            value={filter.searchValue}
                                            onChange={(e) => handleSearchInColumns(e, index)}
                                            width={200}
                                            marginLeft={10}
                                            height={12}
                                        />
                                    )}
                                    {index === filters.length - 1 ? (
                                        <PlusIconContainer
                                            onClick={allOptionsSelected ? undefined : addFilter}
                                        >
                                            <StyledPlusIcon
                                                id={"add_another_criteria_icon"}
                                                style={{cursor: allOptionsSelected ? 'not-allowed' : 'pointer'}}
                                            />
                                            <Tooltip>Add another criteria</Tooltip>
                                        </PlusIconContainer>
                                    ) : (
                                        <PlusIconContainer
                                            onClick={() => removeFilter(index)}
                                        >
                                            <StyledMinusIcon  id={"remove_criteria_icon"}/>
                                            <Tooltip>Remove criteria</Tooltip>
                                        </PlusIconContainer>
                                    )}
                                </FilterGroup>
                            </FiltersGroup>
                        </FiltersGroup>
                    ))}
                    <Button id={"advance_filter_search"} onClick={handleSearchPerColumns}>Search</Button>
                    <Button id={"advance_filter_reset"} onClick={handleResetFilters}>Reset Filters</Button>
                </SecondaryContainer>
                <SecondaryContainer>
                    <TableContainer>
                        {!loadingColumnOrder && (
                            <FullTable
                                typeOfTable={"project"}
                                headers={columnOrder}
                                content={allProjects}
                                renderCell={renderCell}
                                renderHeader={renderHeader}
                                typeOfList={TypeOfList.PROJECTS_LIST}
                                isDraggable={true}
                            />
                        )}
                    </TableContainer>
                    {shouldShowPagination && (
                        <PaginationControl
                            currentPage={currentPage}
                            totalPages={data?.totalPages || 0}
                            onPageChange={handlePageChange}
                        />
                    )}
                    {showNoProjectsFound && <SuccessPopup
                        successMessage="No projects found with that criteria."
                        instructions="Please try again."
                        confirmationMessage="OK!"
                        handleClosePopup={handleCloseNoProjectsPopup}
                    />}
                    {showFilterConfirmationPopup &&
                        <SuccessPopup
                            successMessage="By changing this filter, you will also change the filters for all associated jobs and tasks, and you will lose the record of translated segments, history, and comments."
                            instructions="This action cannot be undone. Are you sure you want to proceed?"
                            confirmationMessage="Yes, proceed"
                            handleClosePopup={handleConfirmFilterChange}
                            handleCancel={handleCloseFilterConfirmationPopup}
                            cancelMessage="Cancel"
                        />

                    }
                </SecondaryContainer>
            </MainContainer>
            {showMembersOptionsModal  && (
                <ModalPopUp>
                    <ChangeAssigneeForm
                        handleClose={()=> setShowMembersOptionsModal(false)}
                        memberOptions={allMembersFromWorkspace}
                        id={selectedProject}
                        type={'project'}
                    />
                </ModalPopUp>
            )}
        </>
    );
};
export default Projects;