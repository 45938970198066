import React from "react";
import styled from 'styled-components';
import logo from "../assets/img/logo-gray-mustard-transparent.webp";
import { useNavigate } from 'react-router-dom';
import { SiInstagram } from "react-icons/si";
import { TfiLinkedin } from "react-icons/tfi";
import { SiYoutube } from "react-icons/si";
import {CookieConsentState} from "../store/cookieConsentSlice";
import {useAppSelector} from "../hooks/store-hooks";

interface StyledFooterProps {
    isSidebarCollapsed: boolean;
}
interface NavLinkProps {
    to: string;
    children: React.ReactNode;
    state?: object;
}

const FooterContainer = styled.footer<{ cookieAccepted: boolean}>`
  background-color: #2d292a;
  color: #fff;
  padding: ${(props) => (props.cookieAccepted ? "35px 0 20px 0" : "35px 0 120px 0")};
  font-size: 15px;
  line-height: 2.5;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
  margin-bottom: 10px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
`;

const LogoContainer = styled.div`
  text-align: left;
  @media (max-width: 390px) {
    margin-left: 30px;
  }
`;
const LogoImage = styled.img`
  width: 100px;
  height: auto;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  margin: 0 20px;
`;
const CopyRightText = styled.p`
  text-align: center;
  margin: 30px 0 0 0;
  font-size: 14px;

  @media (max-width: 490px) {
    margin-left: 70px;
  }
`;
const Links = styled.a`
  color: #fff;
  text-decoration: none;
  margin: 0;
  font-size: 15px;

  &:hover {
    color: #EAC113;
  }

  @media (max-width: 380px) {
    margin-left: 15px;
  }
`;
const SocialMediaSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: -15px 0 0 10px;

  @media (max-width: 480px) {
    margin: -15px 0 0 130px;
  }
  @media (max-width: 390px) {
    margin: -15px 0 0 155px;
  }
  @media (max-width: 380px) {
    margin: -15px 0 0 2px;
  }
`;
const IconsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;
const SocialMediaMessage = styled.div`
  color: #fff;
  font-size: 15px;
`;
const SocialIcon = styled.a`
  color: #fff;
  margin: 0 20px 0 0;
  font-size: 20px;

  &:hover {
    color: #EAC113;
  }
`;
const Exclamation = styled.span`
    color: #eac113;
    font-size: 20px;
    font-weight: 600;
    line-height: 60px;
`;

const NavLink: React.FC<NavLinkProps> = ({ to, children, state }) => {
    const navigate = useNavigate();

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        navigate(to, { state });
    };

    return (
        <Links href={to} onClick={handleClick}>
            {children}
        </Links>
    );
};
const ThisYear = new Date().getFullYear();
const Footer: React.FC<StyledFooterProps> = ({ isSidebarCollapsed }) => {
    const cookieState: CookieConsentState = useAppSelector((state) => state.cookieConsent);
    const cookieAccepted = cookieState.consent;

    return (
        <FooterContainer cookieAccepted={cookieAccepted}>

                <FooterContent>
                    <Column>
                        <LogoContainer>
                            <LogoImage src={logo} alt="Underline logo" title={"underline-logo"} />
                        </LogoContainer>
                    </Column>
                    <Column>
                        <NavLink to="/terms-and-conditions" state={{ from: 'previous-page' }}>Terms and Conditions</NavLink>
                        <NavLink to="/privacy-policy" state={{ from: 'previous-page' }}>Privacy Policy</NavLink>
                        <NavLink to="/contact-us">Contact Us</NavLink>
                    </Column>
                    <Column>
                        <SocialMediaSection>
                            <SocialMediaMessage>Follow us<Exclamation>!</Exclamation></SocialMediaMessage>
                            <IconsRow>
                                <SocialIcon href="https://www.instagram.com/thesinapiteam/" target="_blank"><SiInstagram /></SocialIcon>
                                <SocialIcon href="https://www.linkedin.com/company/sinapi-llc/mycompany/" target="_blank"><TfiLinkedin /></SocialIcon>
                                <SocialIcon href="https://www.youtube.com/@thesinapiteam" target="_blank"><SiYoutube /></SocialIcon>
                            </IconsRow>
                        </SocialMediaSection>
                    </Column>
                </FooterContent>

            <CopyRightText>
                &copy; Copyright {ThisYear} · This product is owned by <Links href="https://www.thesinapiteam.com/" target="_blank" rel="noopener noreferrer">Sinapi LLC.</Links> · All rights reserved
            </CopyRightText>
        </FooterContainer>
    );
}
export default Footer
