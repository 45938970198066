import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components';
import {MdDoubleArrow} from 'react-icons/md';
import SlimButton from "../ui/forms/action/SlimButton";
import SourceModal from "./SourceModal";
import {useAppSelector} from "../../hooks/store-hooks";
import {UserInfo} from "../../store/userSlice";
import {ProjectInfo, useEditProjectMutation} from "../../services/projectsApiSlice";
import {PencilEditIcon} from "../icons/PencilEditIcon";
import CloudSyncing from "../../assets/img/cloud-sync.gif";
import CountryFlag from "./CountryFlag";

const InfoCardContainer = styled.div`
    display: flex;
    align-items: flex-start;
`;

const BoxContainer = styled.div`
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background: #ffffff;
    display: flex;
    margin: 25px;
    min-height: 80px;
    width: 800px;

    @media (max-width: 1115px) {
        width: 400px;
    }
`;

const LoadingGif = styled.img`
    width: 20px;
`

const TitleContainer = styled.div`
    background-color: #ecebeb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;
    width: 33%;
`;
const Title = styled.h2`
    font-size: 20px;
    font-weight: 600;
    margin: 5px;
    word-wrap: break-word;
`;

const ProjectDetailsContainer = styled.div`
    padding: 5px 10px;
    width: 67%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1115px) {
        grid-template-columns: 1fr;
    }
`;

export const DetailWrapper = styled.div`
    padding: 3px 0;
    display: flex;
    align-items: center;
    font-size: 15px;
    flex-wrap: nowrap;

  @media screen and (min-resolution: 140dpi) {
    font-size: 12px;
  }
`;

const LargeDetailWrapper = styled.div`
    padding: 3px 0;
    display: flex;
    align-items: center;
    font-size: 15px;
    grid-column: span 2;
  
  @media screen and (min-resolution: 140dpi) {
    font-size: 12px;
  }
`;

const DetailInput = styled.input`
    margin-left: 2px;
    padding: 2px;
    border: none;
    width: ${props => props.width || '20px'};

  &:focus {
    border:  1px solid #aaa;
    border-radius: 4px ;
    outline: none;
  }

  &[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
    &::-ms-clear {
      display: none;
    }
  }
`

const DetailSpan = styled.span`
    cursor: pointer;
`

const DetailLabel = styled.span`
    font-weight: bold;
    margin-right: 2px;
`;

const SourceDetailContainer = styled.div`
    display: inline-block;
    margin: 20px;
`;

interface InfoCardProps {
    project: ProjectInfo | undefined;
    reloadProject: () => void;
}

const InfoCard: React.FC<InfoCardProps> = ({project, reloadProject}) => {
    const [showSourceListModal, setShowSourceListModal] = useState(false);
    const userInfoFromState = useAppSelector(state => state.userInfo) as UserInfo;
    const [canUpdateProject, setCanUpdateProject] = useState<boolean>(false);

    useEffect(() => {
        if (project) {
            setCanUpdateProject(verifyUpdateProjectPrivileges());
        }
    }, [project, userInfoFromState]);

    const verifyUpdateProjectPrivileges = (): boolean => {
        if (userInfoFromState.roles.includes("ROLE_ADMIN")) return true;
        if (userInfoFromState.roles.includes("ROLE_OWNER")) return true;
        if (project) {
            if (project.creator.username === userInfoFromState.username) return true;
            if (project.assignee.username === userInfoFromState.username) return true;
        }
        return false;
    }
    const handleOpenSourceList = () => {
        setShowSourceListModal(!showSourceListModal);
    };

    if (!project) {
        return <Title>Loading...</Title>;
    }

    const details: Detail[] = [
        {label: 'Name', value: project.name},
        {label: 'Creation Date', value: project.creationDate},
        {label: 'Due Date', value: project.dueDate},
        {label: 'Total Jobs', value: project.jobs.length},
        {
            label: 'Project Wordcount', value: project.jobs[0].tasks
                .map((task: { wordCount: number }) => task.wordCount)
                .reduce((x: number, y: number) => x + y)
        },
        {label: 'Max lines per subtitle', value: project.maxLines, modifiesField: "maxLines"},
        {
            label: 'Max characters per line',
            value: project.maxCharactersPerLine,
            modifiesField: "maxCharactersPerLine"
        },
        {label: 'Source Locale', value: project.sourceLocale},
        {label: 'Description', value: project.description},
    ];
    return (
        <InfoCardContainer>
            <BoxContainer>
                <TitleContainer>
                    <Title>Project ID: <span id={"project_id"}>{project.id}</span></Title>
                    <Title>Creator: <span
                        id={"project_creator"}>{`${project.creator.firstName} ${project.creator.lastName}`}</span></Title>
                </TitleContainer>
                <ProjectDetailsContainer>
                    {details.map((detail, index) => {
                        return (
                            <ProjectDetailItem key={`detail_item_${index}`} projectId={project.id} detailInfo={detail}
                                               canEdit={canUpdateProject}/>
                        );
                    })}
                </ProjectDetailsContainer>
            </BoxContainer>

            <SourceDetailContainer>
                <SlimButton
                    id={"view_sources_button"}
                    onClick={handleOpenSourceList}
                    type="button"
                    bgColor="#a9a9a9"
                    text={"View Sources"}
                    textColor="#2d292a"
                    bgGradient="linear-gradient(to right, #a9a9a9, #dcdcdc, #a9a9a9)"
                />
            </SourceDetailContainer>
            {showSourceListModal &&
                <SourceModal
                    showModal={showSourceListModal}
                    onClose={() => setShowSourceListModal(false)}
                    projectId={project.id.toString()}
                    updateEnabled={canUpdateProject}
                    reloadProject={reloadProject}
                />
            }

        </InfoCardContainer>
    )
}

interface Detail {
    label: string,
    value: string | number,
    modifiesField?: keyof ProjectInfo
}

const formatDate = (dateString: string | number): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}

const ProjectDetailItem: React.FC<{ projectId: number, detailInfo: Detail, canEdit: boolean }> = ({
                                                                                                      projectId,
                                                                                                      detailInfo,
                                                                                                      canEdit
                                                                                                  }) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<typeof detailInfo.value>(detailInfo.value);
    const [editProject, {isLoading}] = useEditProjectMutation();


    useEffect(() => {
        if (inputRef.current && isEditing) {
            inputRef.current.focus();
        }
    }, [isEditing]);

    let Wrapper = DetailWrapper;

    let largeContentLabels = ["Name", "Description"];

    if (largeContentLabels.includes(detailInfo.label)) {
        Wrapper = LargeDetailWrapper;
    }

    let displayValue: string | number = value;

    if (detailInfo.label === 'Creation Date' || detailInfo.label === 'Due Date' && typeof value === 'string') {
        displayValue = formatDate(value);
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (e.target.type === 'number') {
            setValue(e.target.valueAsNumber);
        } else {
            setValue(e.target.value);
        }
    }

    const handleInputBlur = () => {
        setIsEditing(false);

        if (detailInfo.modifiesField) {
            if (typeof value === 'number') {
                if (value < 0 || isNaN(value)) {
                    //Set the original value if the field is a number less than 0 or empty
                    setValue(detailInfo.value);
                } else {
                    const updates = {[detailInfo.modifiesField]: value};
                    editProject({projectId, updates});
                }
            } else if (value && value !== '') {
                const updates = {[detailInfo.modifiesField]: value};
                editProject({projectId, updates});
            } else {
                //Set the original value if the field is a string and  is empty
                setValue(detailInfo.value);
            }
        }
    }

    let valueElement =
        !isEditing ?
            <DetailSpan>{displayValue}</DetailSpan> :
            <DetailInput
                ref={inputRef}
                type={typeof value === 'string' ? 'text' : 'number'}
                value={value}
                onBlur={handleInputBlur}
                onChange={handleInputChange}
                width={`${value.toString().length * 30}px`}
            />

    if(detailInfo.label.includes("Locale") && typeof value === 'string'){
        valueElement = <CountryFlag languageCode={value} fontSize="13px"/>
    }
    const handleEditClick = () => {
        setIsEditing(true);
    }

    return (
        <Wrapper>
            <MdDoubleArrow/>
            <DetailLabel>{detailInfo.label}: </DetailLabel>
            {valueElement}
            {detailInfo.modifiesField && !isEditing && canEdit && !isLoading &&
                <PencilEditIcon
                    id={`project_detail_${detailInfo.modifiesField}_edit_icon`}
                    onClick={handleEditClick}
                    style={{cursor: "pointer"}}
                />
            }
            {isLoading && <LoadingGif src={CloudSyncing}/>}
        </Wrapper>

    );
}
export default InfoCard;