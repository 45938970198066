import React, {useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import logo from "../../assets/img/logo-gray-mustard-transparent.webp";
import {GiHamburgerMenu} from "react-icons/gi"
import {useLocation} from "react-router-dom";
import {DEFINED_ROUTES} from '../../utils/constants'

const NavbarBackground = styled.div<{noPadding: boolean}>`
  margin-top: ${props => props.noPadding ? '0' : '120px'};
  background-color: #2d292a;
`;
const NavbarBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background-color: #2d292a;
  z-index: 9998;
`;
const Navbar = styled.div<{ atTop: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 120px;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: ${({ atTop }) => (atTop ? "#2d292a" : "rgba(38, 38, 38, 0.7)")};

  @media (max-width: 1590px) {
    padding: 0 330px;
  }
  @media (max-width: 1392px) {
    padding: 0 220px;
  }
  @media (max-width: 1080px) {
    padding: 0 150px;
  }
  @media (max-width: 900px) {
    padding: 0 90px;
  }
  @media (max-width: 780px) {
    padding: 0 40px;
  }
`;
const LeftGroup = styled.div`
  margin-left: 30%;
  display: flex;
  align-items: center;

  @media (max-width: 1590px) {
    margin-left: 0;
  }
`;
const RightGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30%;

  @media (max-width: 1920px) {
    margin-right: 20%;
  }
  @media (max-width: 1590px) {
    margin-right: 35%;
  }
  @media (max-width: 767px) {
    margin-right: 15%;
  }
`;
const NavbarBorder = styled.div`
  width: 100%;
  height: 1.5px;
  background-color: transparent;
  position: fixed;
  top: 120px;
  left: 0;
  z-index: 9997;

`;
const NavbarProgress = styled.div<{ progress: number }>`
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  background-color: #F7C100;
`;
const LogoImage = styled.img`
  height:60px;
`;
const UnderlineText = styled.p`
  font-size: 26px;
  text-transform: lowercase;
  margin-left: 15px;
  color: #FFFFFF;
  
  @media (max-width: 290px) {
    visibility: hidden;
  }
`;
const DesktopLinks = styled.div`
  @media (max-width: 860px) {
    display: none;
  }
`;
const NavbarLink = styled.a<{ isMobile?: boolean }>`
  font-size: 20px;
  font-weight: 500;
  margin-right: 55px;
  text-decoration: none;
  color: #FFFFFF;
  cursor: pointer;
  scroll-behavior: smooth;

  &:hover {
    color: ${props => props.isMobile ? '#0c0c0c' : '#EAC113'};
  }
  @media (max-width: 1590px) {
    font-size: 20px;
    margin-right: 25px;
  }
  @media (max-width: 780px) {
    font-size: 16px;
    font-weight: 500;
  }
`;
const LoginButton = styled.button`
  border: 0.50px #EAC113 solid;
  border-radius: 7px;
  width: 135px;
  height: 39px;
  font-size: 18px;
  color: #FFFFFF;
  background-color: transparent;
  margin-left: 15px;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-color: #d7b00d;
  }
  @media (max-width: 1590px) {
    margin-left: 10px;
  }
  @media (max-width: 290px) {
    margin-left: -100px;
  }
`;
const HamburgerMenu = styled.div`
  display: none;

  @media (max-width: 860px) {
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
  }
`;
const StyledIcon = styled(GiHamburgerMenu)`
  font-size: 26px;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #EAC113;
  }
`;
const MobileLinks = styled.div<{ isOpen: boolean }>`
  display: none;
  
  @media (max-width: 860px) {
    display: ${props => (props.isOpen ? "flex" : "none")};
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 72px;
    right: 155px;
    background-color: #EAC113;
    width: 100px;
    z-index: 2;
    color: #302E2B;
    border-radius: 5px;
    padding: 10px;
  }
  @media (max-width: 730px) {
    right: 195px;
  }
  @media (max-width: 430px) {
    right: 105px;
  }
  @media (max-width: 385px) {
    right: 100px;
  }
  @media (max-width: 290px) {
    right: 85px;
  }
`;
const LandingNavbar: React.FC= () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const [scrollY, setScrollY] = useState<number>(0);
    const [scrollProgress, setScrollProgress] = useState<number>(0);
    const { pathname } = useLocation();
    const isRouteDefined = DEFINED_ROUTES.some(route =>pathname === route);
    const isPageNotFound = !isRouteDefined;
    const isHomePage = pathname === '/';

    useEffect(() => {
        let ticking = false;
        const handleScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    setScrollY(window.scrollY);
                    ticking = false;
                });
                ticking = true;
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const docHeight = document.documentElement.scrollHeight;
            const maxScrollableHeight = docHeight - windowHeight;
            const percentageScrolled = (window.scrollY / maxScrollableHeight) * 100;
            setScrollProgress(percentageScrolled);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const handleSmoothScroll = (selector: string) => {
        const element = document.querySelector(selector);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };
    const handleSmoothScrollAndCloseMenu = (event: React.MouseEvent, selector: string) => {
        event.preventDefault();
        handleSmoothScroll(selector);
        setMenuOpen(false);
    };
    const handleNavigateToLogin = (event: React.MouseEvent) => {
        event.preventDefault();
        window.location.pathname = "/login";
    };
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (menuRef.current !== null && !menuRef.current.contains(event.target as Node)) {
                setMenuOpen(false);
            }
        }
        if (isMenuOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isMenuOpen]);
    return(
        <NavbarBackground noPadding={isPageNotFound}>
            <NavbarBackdrop />
            <Navbar atTop={scrollY <= 10}>
                <LeftGroup>
                    <LogoImage src={logo} alt="Underline logo" title={"underline-logo"}/>
                    <UnderlineText>underline</UnderlineText>
                </LeftGroup>
                <RightGroup>
                    {isHomePage ? (
                        <HamburgerMenu onClick={() => { setMenuOpen(!isMenuOpen); }}>
                            <StyledIcon />
                        </HamburgerMenu>
                    ) : (
                        <LoginButton onClick={handleNavigateToLogin}>Log In</LoginButton>
                    )}
                    <MobileLinks ref={menuRef} isOpen={isMenuOpen}>
                        <NavbarLink
                            isMobile
                            href="#case-study"
                            onClick={(event) => handleSmoothScrollAndCloseMenu(event,"#case-study")}>
                            Case Study
                        </NavbarLink>
                        <NavbarLink
                            isMobile
                            href="#pricing"
                            onClick={(event) => handleSmoothScrollAndCloseMenu(event,"#pricing")}>
                            Pricing
                        </NavbarLink>
                        <NavbarLink
                            isMobile
                            onClick={(event) => {
                                event.preventDefault();
                                setMenuOpen(false);
                                handleNavigateToLogin(event);
                            }}
                        >Log In</NavbarLink>
                    </MobileLinks>

                    <DesktopLinks>
                        {isHomePage && (
                            <>
                                <NavbarLink onClick={() => handleSmoothScroll("#case-study")}>Case Study</NavbarLink>
                                <NavbarLink onClick={() => handleSmoothScroll("#pricing")}>Pricing</NavbarLink>
                                <LoginButton onClick={handleNavigateToLogin}>Log In</LoginButton>
                            </>
                        )}
                    </DesktopLinks>

                </RightGroup>
            </Navbar>
            <NavbarBorder>
                <NavbarProgress progress={scrollProgress} />
            </NavbarBorder>

        </NavbarBackground>
    );
}
export default LandingNavbar;