import React, {useEffect} from 'react';
import {Navigate} from "react-router-dom";
import {useAppDispatch} from "../hooks/store-hooks";
import {clearTokens} from "../store/tokenSlice";
import {clearUserInfo} from "../store/userSlice";
import {clearInstanceInfo} from "../store/instanceSlice";
import {underlineApi} from "../services/underlineApi";

const Logout = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(clearTokens());
        dispatch(clearUserInfo(null));
        dispatch(clearInstanceInfo(null));
        dispatch(underlineApi.util.resetApiState());
    }, []);

    return (
        <Navigate to={"/login"}/>
    );
};

export default Logout;