import React, {useState} from "react";
import styled from "styled-components";
import {Placeholder} from "../../services/segmentsApiSlice";
import {ArrowContainer, Popover, PopoverPosition, Rect} from "react-tiny-popover";
import {parseSubtitleTimestampString} from "../subtitles/subtitleUtils";

const TooltipContainer = styled.div`
    background: white;
    border: 1px solid black;
    border-radius: 4px;
    padding: 4px;
    font-size: 14px;
`

export const PlaceholderSpan = styled.span`
    background-color: #2a7bb2;
    color: white;
    border-radius: 4px;
`;

export interface PlaceholderProps {
    children: React.ReactNode;
    placeholderObj: Placeholder | undefined;
}

interface PopOverProps {
    position: PopoverPosition | undefined;
    childRect: Rect;
    popoverRect: Rect;
    content: string | undefined;
}

const PlaceholderBadge: React.FC<PlaceholderProps> = (props) => {
    const [showPopOver, setShowPopOver] = useState<boolean>(false)

    return (
        <Popover
            isOpen={showPopOver}
            padding={2}
            positions={['top']}
            containerStyle={{zIndex:'9999'}}
            content={({position, childRect, popoverRect}) => (
                <TimePopOver position={position} childRect={childRect}
                             popoverRect={popoverRect} content={props.placeholderObj?.content}/>
            )}
        >
            <PlaceholderSpan onMouseEnter={() => setShowPopOver(true)}
                             onMouseLeave={() => setShowPopOver(false)}>{props.children}</PlaceholderSpan>
        </Popover>
    );
};

const TimePopOver = ({position, popoverRect, childRect, content}: PopOverProps) => {
    let start: string = ''
    let end: string = ''

    if(content){
        let times = parseSubtitleTimestampString(content)
        start = times.start;
        end = times.end;
    }

    return (
        <>
            <ArrowContainer
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
                arrowColor={'black'}
                arrowSize={6}
            >
                <TooltipContainer>
                    <span><strong>Start: </strong>{start}</span><br/>
                    <span><strong>End: </strong>{end}</span>
                </TooltipContainer>
            </ArrowContainer>
        </>
    )
}
export default PlaceholderBadge;