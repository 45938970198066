import React from "react";
import styled from 'styled-components';

const ErrorContainer = styled.div`
    margin: 15px 15px 15px 22px;
`;
const ErrorMessageContainer = styled.div`
    color: red;
    list-style-type: none;

  @media screen and (min-resolution: 140dpi) {
    font-size: 10px;
  }
`;
interface InvalidFeedbackProps {
    errorMessage: string;
}

const InvalidFeedback: React.FC<InvalidFeedbackProps> = ({ errorMessage }) => {
    return (
        <ErrorContainer>
            <ErrorMessageContainer id="invalid-feedback-container">
            {errorMessage}
            </ErrorMessageContainer>
        </ErrorContainer>
    );
};

export default InvalidFeedback;
