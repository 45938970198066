import {underlineApi} from "./underlineApi";
export const jobsApiSlice = underlineApi.injectEndpoints({
    endpoints: (builder) => ({
        editJob: builder.mutation({
            query: ({ jobId, updates }) => {
                let body = JSON.stringify(updates);
                return {
                    url: `/jobs/${jobId}`,
                    method: 'PATCH',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            },
            invalidatesTags: ['AllProjects'],
        }),
    })
})

export const {
    useEditJobMutation
} = jobsApiSlice;