import React, {useState, useEffect, ChangeEvent, useRef} from "react";
import styled from "styled-components";
import {useNavigate, useParams} from "react-router-dom";
import Breadcrumb from "components/Breadcrumb";
import PageTitle from "components/PageTitle";
import FullTable from "../components/tables/FullTable";
import {FaAngleDoubleDown} from "react-icons/fa";
import Badge, {formatStatus} from "./../components/projects/Badge";
import CountryFlag from "./../components/projects/CountryFlag";
import InfoCard from "./../components/projects/InfoCard";
import Button from "../components/ui/forms/auth/AuthFormButton";
import TaskTable from "./../components/projects/TaskTable";
import {JobInfo, TaskInfo, useGetOneProjectQuery} from "../services/projectsApiSlice";
import {useEditJobMutation} from "../services/jobsApiSlice";
import {useGetWorkspaceQuery} from '../services/workspacesApiSlice';
import {AssigneeProps, MemberOption, ProjectFilterProps, formatHeader} from "./Projects";
import ModalPopUp from "../components/administration/ModalPopUp";
import ChangeAssigneeForm from "../components/projects/ChangeAssigneeForm";
import {UserInfo} from "../store/userSlice";
import {FILTER_OPTIONS} from "../utils/constants";
import SelectOption from "../components/ui/forms/action/SelectOption";
import SuccessPopup from "../components/ui/forms/SuccessPopup";
import {useAppSelector} from "../hooks/store-hooks";
import {TypeOfList} from "../services/usersApiSlice";
import {HiDotsVertical} from "react-icons/hi";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {PencilEditIcon} from "../components/icons/PencilEditIcon";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const SecondaryContainer = styled.div`
  margin: 0 20px 30px 20px;
`;
const TableContainer = styled.div`
  overflow-x: auto;
`;
const RotatingIcon = styled(FaAngleDoubleDown)`
    transition: transform 0.3s ease-in-out;
`;
function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}
export interface JobPropsForTable {
    id: number;
    target_language: string;
    workspace: string;
    assignee: AssigneeProps;
    projectCreator?: string
    filter: ProjectFilterProps;
    wordcount: number;
    last_date_modified: string;
    status: JSX.Element;
    view_tasks: React.ReactNode;
    tasks: TaskInfo[];
}
const jobTableHeaders = ["id", "target_language", "workspace", "assignee", "filter", "wordcount", "last_date_modified", "status", "view_tasks"];
const Project: React.FC = () => {
    const {projectId} = useParams<{ projectId: string }>();
    const [allJobs, setAllJobs] = useState<JobPropsForTable[]>([]);
    const [expandedRow, setExpandedRow] = useState<number | null>(null);
    const navigate = useNavigate();
    const [workspaceId, setWorkspaceId] = useState('0');
    const { data: workspaceData } = useGetWorkspaceQuery(workspaceId !== '0' ? workspaceId : skipToken);
    const { data: projectData, refetch } = useGetOneProjectQuery(projectId || "0");
    const [allMembersFromWorkspace, setAllMembersFromWorkspace] = useState<MemberOption[]>([]);
    const [selectedWorkspace, setSelectedWorkspace] = useState('0');
    const [selectedJob, setSelectedJob] = useState(0);
    const [showMembersOptionsModal, setShowMembersOptionsModal] = useState(false);
    const [editJob] = useEditJobMutation({});
    const [newJobFilter, setNewJobFilter] = useState("");
    const [editingFilterJobId, setEditingFilterJobId] = useState<number | null>(null);
    const [showFilterConfirmationPopup, setShowFilterConfirmationPopup] = useState(false);
    const showFilterOptions = useRef<HTMLDivElement | null>(null);
    const [tempJobId, setTempJobId] = useState<number | null>(null);
    const userInfoFromState = useAppSelector(state => state.userInfo) as UserInfo;
    const [columnOrder, setColumnOrder] = useState<string[]>(jobTableHeaders);
    const [loadingColumnOrder, setLoadingColumnOrder] = useState(true);
    const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
    const [currentAssignee, setCurrentAssignee] = useState("");

    useEffect(() => {
        if (projectData) {
            setWorkspaceId(projectData.workspaceId);
            setIsInitialDataLoaded(true);
        }
    }, [projectData]);

    useEffect(() => {
        if (isInitialDataLoaded) {
            if (userInfoFromState.views && userInfoFromState.views.length > 0) {
                const jobsListView = userInfoFromState.views.find(view => view.name === "JOBS_LIST");
                if (jobsListView) {
                    setColumnOrder(jobsListView.columns.split(';'));
                } else {
                    setColumnOrder(jobTableHeaders);
                }
            } else {
                setColumnOrder(jobTableHeaders);
            }
            setLoadingColumnOrder(false);
        }
    }, [userInfoFromState.views, isInitialDataLoaded]);

    useEffect(() => {
        if (workspaceData && projectData) {
            let allJobs: JobPropsForTable[] = projectData.jobs.map(
                (job: JobInfo) => getJobsProps(job, projectData.lastModifiedDate, workspaceData.name, projectData.creator.username));
            setAllJobs(allJobs);
        }
    }, [workspaceData, projectData, columnOrder]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (showFilterOptions.current && !showFilterOptions.current.contains(event.target as Node)) {
                stopEditingFilter();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showFilterOptions]);
    const canEditJob = (job: JobInfo, creator: string): boolean => {

        if(userInfoFromState.roles?.includes("ROLE_ADMIN") || userInfoFromState.roles?.includes("ROLE_OWNER")){
            return true;
        }
        if(creator === userInfoFromState.username){
            return true;
        }
        if(job.assignee.username === userInfoFromState.username){
            return true;
        }
        return false;
    }
    const getJobsProps = (job: JobInfo, lastModifiedDate: string, workspaceDataName:string, projectCreator:string): JobPropsForTable => {
        const statusInfo = formatStatus(job.status);
        const statusBadge = (
            <Badge
                id={`job_status_badge_${job.id}`}
                data={statusInfo.label}
                text={statusInfo.label}
                backgroundColor={statusInfo.color}
                textColor="#fff"
            />
        );
        return {
            id: job.id,
            target_language: job.targetLocale,
            workspace: workspaceDataName,
            projectCreator: projectCreator,
            assignee: {
                username: `${job.assignee.firstName} ${job.assignee.lastName}`,
                icon: canEditJob(job, projectCreator) ?
                    <PencilEditIcon
                        id={`job_assignee_icon_${job.id}`}
                        onClick={() => handleAssigneeIconClick(job.id, job.workspace, job.assignee.username)}
                        style={{cursor: "pointer"}}
                    /> : null
            },
            filter:{
                defaultFilter: job.defaultFilter.charAt(0).toUpperCase() + job.defaultFilter.slice(1),
                icon: canEditJob(job, projectCreator) ?
                    <PencilEditIcon
                        id={`job_filter_icon_${job.id}`}
                        onClick={() => handleJobFilterIconClick(job.id)}
                        style={{cursor: "pointer"}}
                    /> : null,
                jobId: job.id
            },
            wordcount: job.tasks
                .map((task: { wordCount: number }) => task.wordCount)
                .reduce((x: number, y: number) => x + y),
            last_date_modified: formatDate(lastModifiedDate),
            status: statusBadge,
            view_tasks: "",
            tasks: job.tasks,
        };
    }
    const handleJobFilterIconClick = (jobId: number) => {
        setEditingFilterJobId(jobId);
        setTempJobId(jobId);
    };
    const handleChangeJobFilter = async (event: ChangeEvent<HTMLSelectElement>) => {
        setNewJobFilter(event.target.value);
        setShowFilterConfirmationPopup(true);
    };
    const handleConfirmFilterChange = async () => {
        if (newJobFilter !== "") {
            try {
                await editJob({ jobId: tempJobId, updates: { defaultFilter: newJobFilter } });
                setEditingFilterJobId(null);
                setTempJobId(null);
                setNewJobFilter("")
                refetch();
            } catch (err) {
                console.error("Changing filter error: ", err);
            }
        }
        setShowFilterConfirmationPopup(false);
        setEditingFilterJobId(null);
        setNewJobFilter("");
        setTempJobId(null);
    };
    const handleCloseFilterConfirmationPopup = () =>{
        setShowFilterConfirmationPopup(false);
        setEditingFilterJobId(null);
        setNewJobFilter("");
    }
    const stopEditingFilter = () => {
        setEditingFilterJobId(null);
    };
    const handleAssigneeIconClick = (jobId: number, workspaceId: string, assignee: string) => {
        setSelectedWorkspace(workspaceId);
        setShowMembersOptionsModal(true);
        setSelectedJob(jobId);
        setCurrentAssignee(assignee)
    };
    useEffect(() => {
        if (workspaceData) {
            let usersForDropdown = workspaceData.members
                .map((member:UserInfo) => {
                    return {
                        value: member.username,
                        label: `${member.firstName} ${member.lastName}`,
                    };
                })
                .filter((user:MemberOption) => user.value !== "Underline-Support" && user.value !== currentAssignee);
            setAllMembersFromWorkspace(usersForDropdown);
        }
    }, [selectedWorkspace, workspaceData, currentAssignee]);
    const renderHeader = (header: string): React.ReactNode => {
        const formattedHeader = formatHeader(header);
        return (
            <React.Fragment>
                <HiDotsVertical style={{ marginRight: "5px" }} />
                {formattedHeader}
            </React.Fragment>
        );
    };
    const renderCell = (id: number, key: string, value: any, rowIndex: number): React.ReactNode => {
        if (key === "target_language") {
            return <CountryFlag id={`job_${id}_target_language`} languageCode={value}/>;
        } else if (key === "view_tasks") {
            return (
                <RotatingIcon
                    onClick={() => {
                        if (expandedRow === rowIndex) {
                            setExpandedRow(null);
                        } else {
                            setExpandedRow(rowIndex);
                        }
                    }}
                    id={`job_${id}_view_task_icon`}
                    className={expandedRow === rowIndex ? "expanded" : ""}
                    style={{cursor: "pointer"}}
                />
            );
        } else if (key === "assignee") {
            if (typeof value === "object" && value !== null) {
                return (
                    <>
                        {value.username}
                        {value.icon}
                    </>
                );
            }
        } else if(key === "filter")  {
            if (typeof value === "object" && value !== null) {
                if (value.jobId === editingFilterJobId) {
                    return (
                        <div ref={showFilterOptions}>
                            <SelectOption
                                id={`job_${id}_filter_select`}
                                onChange={handleChangeJobFilter}
                                value={newJobFilter}
                                options={FILTER_OPTIONS.filter(option => option.value !== value.defaultFilter.toLowerCase())}
                                width={110}
                            />
                        </div>
                    );
                } else {
                    return (
                        <>
                            {value.defaultFilter}
                            {value.icon}
                        </>
                    );
                }
            }
        } else {
            return value;
        }
    };
    const handleBackToProjects = () => {
        navigate(`/projects`);
    };
    return (
        <>
            <MainContainer>
                <Breadcrumb link="Project"/>
                <PageTitle title={`Project Details `}/>

                <SecondaryContainer>
                    <InfoCard
                        project={projectData}
                        reloadProject={refetch}
                    />
                    <TableContainer>
                        {!loadingColumnOrder && (
                            <FullTable
                                typeOfTable={"job"}
                                headers={columnOrder}
                                content={allJobs}
                                renderCell={renderCell}
                                renderHeader={renderHeader}
                                typeOfList={TypeOfList.JOBS_LIST}
                                expandableRows={true}
                                expandedRow={expandedRow}
                                workspaceId={workspaceId}
                                isDraggable={true}
                                renderSubTable={(rowIndex) => {
                                    if (projectId) {
                                        return (
                                            <TaskTable
                                                selectedJob={allJobs[rowIndex]}
                                                reloadProject={refetch}
                                                workspaceId={workspaceId}
                                            />
                                        );
                                    }
                                }}
                            />
                        )}
                    </TableContainer>
                    <Button
                        id={"back_to_projects_button"}
                        onClick={handleBackToProjects}
                        text="Back"
                    />
                </SecondaryContainer>
                {showMembersOptionsModal  && (
                    <ModalPopUp>
                        <ChangeAssigneeForm
                            handleClose={()=> setShowMembersOptionsModal(false)}
                            memberOptions={allMembersFromWorkspace}
                            id={selectedJob}
                            type={'job'}
                        />
                    </ModalPopUp>
                )}
                {showFilterConfirmationPopup &&
                    <SuccessPopup
                    successMessage="By changing this filter, you will also change the filters for all associated tasks, and you will lose the record of translated segments, history, and comments."
                    instructions="This action cannot be undone. Are you sure you want to proceed?"
                    confirmationMessage="Yes, proceed"
                    handleClosePopup={handleConfirmFilterChange}
                    handleCancel={handleCloseFilterConfirmationPopup}
                    cancelMessage="Cancel"
                    />
                }

            </MainContainer>
        </>
    );
};
export default Project;