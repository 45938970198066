import React, { useState } from 'react';
import styled from 'styled-components';
import SlimButton from '../ui/forms/action/SlimButton';
import { useCookies } from "react-cookie";
import {SESSION_TOKEN_COOKIE} from '../../utils/constants';
import InvalidFeedback from '../ui/forms/InvalidFeedback';
import SuccessPopup from '../ui/forms/SuccessPopup';
import { useUploadBilingualMutation } from "../../services/tasksApiSlice";
import {useParams} from "react-router-dom";
import {useGetOneProjectQuery} from "../../services/projectsApiSlice";

const PopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PopupContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
`;
const DisplayedName = styled.p`
  margin-top: 10px;
  margin-left: 2px;
`;
const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;
const FileInputLabel = styled.label`
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-weight: 600;
  transition: background-color 0.2s;
  &:hover {
    background-color: #e8e9ea;
  }
`;
const ErrorMessageContainer = styled.div`
  white-space: pre-wrap;
  word-wrap: break-word;
`;
interface FileUploadPopupProps {
  taskId: number;
  onClose: () => void;
}

const FileUploadPopup: React.FC<FileUploadPopupProps> = ({ onClose, taskId }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [sessionCookie] = useCookies([SESSION_TOKEN_COOKIE]);
  const tokenValue = sessionCookie[SESSION_TOKEN_COOKIE];
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false);
  const [uploadBilingual, uploadBilingualResult] = useUploadBilingualMutation();
  const {projectId} = useParams<{ projectId: string }>();
  const {refetch} = useGetOneProjectQuery(projectId || "0");
  const ShowSuccessPopup = () => {
    return (
        <SuccessPopup
            successMessage="Success!"
            instructions="Bilingual file has been successfully uploaded."
            confirmationMessage="Thanks!"
            handleClosePopup={() => closeAndRefresh()}
        />
    );
  }

  const isValidXliffFile = (file: File): boolean => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';  
    if (['xlf', 'xliff'].includes(fileExtension)) {
      return true;
    }
    return false;
  };
  const shortenFilename = (filename: string, maxLength: number = 30): string => {
    if (filename.length <= maxLength) {
      return filename;
    }
    const halfLength = Math.floor((maxLength - 3) / 2);
    return `${filename.slice(0, halfLength)}...${filename.slice(-halfLength)}`;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
  
      if (isValidXliffFile(file)) {
        setSelectedFile(file);
      } else {
        setErrorMessage("Invalid file format. Please select a .xlf file.");
      }
    }
  };

  const handleUpload = () => {
    if (!selectedFile || !isValidXliffFile(selectedFile)) {
      setErrorMessage('Please upload a valid XLIFF file.');
      return;
    } else if(selectedFile) {
      uploadBilingual({file: selectedFile, taskId})
          .then((response) => {
            if (
                'error' in response &&
                'data' in response.error &&
                response.error.data !== null &&
                typeof response.error.data === 'object' &&
                'error' in response.error.data &&
                typeof response.error.data.error === 'string'
            ) {
              console.log("Bilingüal error: ", response.error.data.error);
              setErrorMessage(response.error.data.error);
            } else  {
              setShowSuccessPopup(true);
            }
          })
          .catch((error) => {
            console.log("Bilingüal error: ", error);
            setErrorMessage(error);
          });


    } else {
      setErrorMessage('Please select a file.');
    }
  };
  const handelResetFiles = () => {
    setSelectedFile(null);
    onClose();
  };
  const closeAndRefresh = () => {
    onClose();
    refetch();
  };

  return (
    <PopupWrapper onClick={onClose}>
      <PopupContent onClick={(e) => e.stopPropagation()}>
        <h3>Select Bilingual File</h3>
        <StyledInput
          id="file-input"
          type="file"
          onChange={handleFileChange}
          accept=".xlf"
        />
        <FileInputLabel htmlFor="file-input">
          Choose a File
        </FileInputLabel>
        <DisplayedName>File selected: {selectedFile ? shortenFilename(selectedFile.name) : 'No file selected yet.'}</DisplayedName>
        <ErrorMessageContainer>
          {errorMessage && <InvalidFeedback errorMessage={errorMessage} />}
        </ErrorMessageContainer>

        {showSuccessPopup && ShowSuccessPopup()}
        <SlimButton
          type="reset"
          bgColor="#c71313"
          text="Cancel"
          textColor="#fff"
          onClick={handelResetFiles}
        />
        <SlimButton
          type="button"
          bgColor="#eac113"
          text="Upload file"
          textColor="#2d292a"
          onClick={handleUpload}
        />
      </PopupContent>
    </PopupWrapper>
  );
};
export default FileUploadPopup