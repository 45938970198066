import React, {useEffect, useState} from "react";
import { useDispatch } from 'react-redux';
import Breadcrumb from "components/Breadcrumb";
import PageTitle from "components/PageTitle";
import styled from "styled-components";
import {FaUserAlt, FaUserFriends, FaUserCheck, FaLock} from "react-icons/fa";
import {RiLock2Fill, RiLockPasswordFill} from "react-icons/ri";
import {MdAlternateEmail} from "react-icons/md";
import {BsFillPersonVcardFill} from "react-icons/bs";
import ActionFormTitle from "../components/ui/forms/action/ActionFormTitle";
import UserBasicInfo from "../components/ui/forms/users/UserBasicInfo";
import ActionFormLabel from "../components/ui/forms/action/ActionFormLabel";
import ActionFormInput from "../components/ui/forms/action/ActionFormInput";
import SlimButton from "../components/ui/forms/action/SlimButton";
import InvalidFeedback from "components/ui/forms/InvalidFeedback";
import SuccessPopup from "../components/ui/forms/SuccessPopup";
import {useAppSelector} from "../hooks/store-hooks";
import { UpdateUserInfoProps, useGetUserInfoQuery, useUpdateUserMutation } from "../services/usersApiSlice";
import Spinner from "../components/spinner/Spinner";
import {FORMAT_ROLE} from "../utils/constants";
import {useRefreshTokenMutation} from "../services/tokensApiSlice";
import {setTokens} from "../store/tokenSlice";
import {CookieConsentState} from "../store/cookieConsentSlice";

const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
`;
const ProfileFormContainer = styled.div`
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 470px;
  padding-top: 0;
  margin-top: 0;
  overflow-y: auto;
  margin-bottom: 100px;

  @media screen and (max-width: 750px) {
    width: 75%;
  }
`;
const FormBody = styled.form`
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  padding-top: 0;
  margin-top: 0;
`;
const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
`;
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;

  @media screen and (max-width: 750px) {
    margin-right: 0;
  }
`;
const ButtonsContainer = styled.div`
  margin-top: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 750px) {
    margin-right: 0;
    margin-left: 0;
  }
`;

interface FormField {
    labelIcon: any;
    labelText: string;
    inputId: string;
    inputType: string;
    inputName: string;
    inputValue: string;
    onChange: string;
}

const formFields = [
    {
        labelIcon: FaUserAlt,
        labelText: "First Name",
        inputId: "user-first-name",
        inputType: "text",
        inputName: "firstName",
        inputValue: "",
        onChange: "handleChangeFirstName",
    },
    {
        labelIcon: FaUserFriends,
        labelText: "Last Name",
        inputId: "user-last-name",
        inputType: "text",
        inputName: "lastName",
        inputValue: "",
        onChange: "handleChangeLastName",
    },
    {
        labelIcon: FaUserCheck,
        labelText: "Username",
        inputId: "user-username",
        inputType: "text",
        inputName: "username",
        inputValue: "",
        onChange: "handleChangeUsername",
    },
    {
        labelIcon: BsFillPersonVcardFill,
        labelText: "Company",
        inputId: "user-company",
        inputType: "text",
        inputName: "company",
        inputValue: "",
        onChange: "handleChangeCompany",
    },
    {
        labelIcon: MdAlternateEmail,
        labelText: "Email",
        inputId: "user-email",
        inputType: "email",
        inputName: "email",
        inputValue: "",
        onChange: "handleChangeEmail",
    },
    {
        labelIcon: FaLock,
        labelText: "Current Password",
        inputId: "user-current-password",
        inputType: "password",
        inputName: "currentPassword",
        inputValue: "",
        onChange: "handleChangeCurrentPassword",
    },
    {
        labelIcon: RiLock2Fill,
        labelText: "New Password",
        inputId: "user-new-password",
        inputType: "password",
        inputName: "newPassword",
        inputValue: "",
        onChange: "handleChangeNewPassword",
    },
    {
        labelIcon: RiLockPasswordFill,
        labelText: "Confirm Password",
        inputId: "user-new-password-repeated",
        inputType: "password",
        inputName: "repeatPassword",
        inputValue: "",
        onChange: "handleChangeRepeatPassword",
    },
];

function fieldsArray(array: FormField[], columns: number) {
    const results = [];
    while (array.length) {
        results.push(array.splice(0, columns));
    }
    return results;
}

const validateEmail = (email: string): boolean => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
};

const validatePassword = (newPassword: string, repeatPassword: string, currentPassword: string) => {
    if (newPassword) {
        if (newPassword && (!currentPassword)) {
            return {
                isValid: false,
                message: "Please enter your current password.",
            };
        }
        if (newPassword !== repeatPassword.trim()) {
            return {
                isValid: false,
                message: "New passwords do not match.",
            };
        }
        if (newPassword === currentPassword) {
            return {
                isValid: false,
                message: "New password must be different from the current password.",
            };
        }
        if (!isValidPassword(newPassword)) {
            return {
                isValid: false,
                message: "Password must be at least 9 characters long, contain at least one uppercase letter and at least one number or symbol.",
            };
        }

    }
    return {isValid: true, message: ""};
};
const isValidPassword = (password: string): boolean => {
    const lengthCheck = /.{9,}/.test(password);
    const upperCaseCheck = /[A-Z]/.test(password);
    const symbolOrNumberCheck = /[\d\W]/.test(password);

    return lengthCheck && upperCaseCheck && symbolOrNumberCheck;
}
interface FieldStateAndHandler {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface FieldStatesAndHandlers {
    [key: string]: FieldStateAndHandler;
}

const Profile: React.FC = () => {
    const initialUserInfo = useAppSelector(state => state.userInfo);
    const groupedFormFields = fieldsArray([...formFields], 2);
    const [errorMessage, setErrorMessage] = useState("");
    const [firstName, setFirstName] = useState(initialUserInfo.firstName);
    const [lastName, setLastName] = useState(initialUserInfo.lastName);
    const [username, setUsername] = useState(initialUserInfo.username);
    const [email, setEmail] = useState(initialUserInfo.email);
    const [company, setCompany] = useState(initialUserInfo.company);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [updateUser, {isLoading, isSuccess}] = useUpdateUserMutation();
    const {refetch: reloadUserInfo} = useGetUserInfoQuery();
    const [role, setRole] = useState('');
    const instanceId = useAppSelector((state) => state.instanceInfo.id);
    const dispatch = useDispatch();
    const [refreshToken] = useRefreshTokenMutation();
    const cookieState: CookieConsentState = useAppSelector(state => state.cookieConsent);

    useEffect(() => {
        if (isSuccess) {
            setShowSuccessPopup(true);
        }
    }, [isSuccess]);

    useEffect(() => {
        if (initialUserInfo) {
            setUsername(initialUserInfo.username || "");
            setEmail(initialUserInfo.email || "");
            setCompany(initialUserInfo.company || "");
            setFirstName(initialUserInfo.firstName || "");
            setLastName(initialUserInfo.lastName || "");
            setCurrentPassword("");
            setNewPassword("");
            setRepeatPassword("");
            if (initialUserInfo.roles && initialUserInfo.roles[0]) {
                setRole(FORMAT_ROLE(initialUserInfo.roles[0]));
            }
        }
    }, [initialUserInfo]);
    const handleChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target) {
            setter(event.target.value);
        }
    };

    const fieldStatesAndHandlers: FieldStatesAndHandlers = {
        firstName: {value: firstName, onChange: handleChange(setFirstName)},
        lastName: {value: lastName, onChange: handleChange(setLastName)},
        username: {value: username, onChange: handleChange(setUsername)},
        email: {value: email, onChange: handleChange(setEmail)},
        company: {value: company, onChange: handleChange(setCompany)},
        currentPassword: {value: currentPassword, onChange: handleChange(setCurrentPassword)},
        newPassword: {value: newPassword, onChange: handleChange(setNewPassword)},
        repeatPassword: {value: repeatPassword, onChange: handleChange(setRepeatPassword)},
    };

    const handleClosePopup = () => {
        setShowSuccessPopup(false);
    };
    const validateEmptyFields = () => {
        const fieldsToValidate = [
            {value: firstName, name: "First Name"},
            {value: lastName, name: "Last Name"},
            {value: username, name: "Username"},
            {value: email, name: "Email"},
            {value: company, name: "Company"},
        ];

        for (const field of fieldsToValidate) {
            if (!field.value.trim()) {
                return {isValid: false};
            }
        }
        return {isValid: true};
    };

    const userInfoUpdated = (): UpdateUserInfoProps => {
        let updatedData: UpdateUserInfoProps = {id: initialUserInfo.id};
        if (initialUserInfo.firstName !== firstName) {
            updatedData.firstName = firstName;
        }
        if (initialUserInfo.lastName !== lastName) {
            updatedData.lastName = lastName;
        }
        if (initialUserInfo.username !== username) {
            updatedData.username = username;
        }
        if (initialUserInfo.email !== email) {
            updatedData.email = email;
        }
        if (initialUserInfo.company !== company) {
            updatedData.company = company;
        }
        if (currentPassword) {
            updatedData.oldPassword = currentPassword;
        }
        if (newPassword) {
            updatedData.newPassword = newPassword;
        }
        return updatedData;
    };
    const resetInfo = () => {
        setErrorMessage("");
        setFirstName(initialUserInfo.firstName);
        setLastName(initialUserInfo.lastName);
        setUsername(initialUserInfo.username);
        setEmail(initialUserInfo.email);
        setCompany(initialUserInfo.company);
        setCurrentPassword("");
        setNewPassword("");
        setRepeatPassword("");
    };
    const handleEditProfile = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let isFormValid = true;
        const updatedData = userInfoUpdated();
        const validationResult = validateEmptyFields();
        const isValidPassword = validatePassword(newPassword, repeatPassword, currentPassword);
        const isValidEmail = validateEmail(email);

        if (!validationResult.isValid) {
            setErrorMessage("Fields cannot be empty");
            isFormValid = false;
        }
        if (!isValidPassword.isValid) {
            setErrorMessage(isValidPassword.message);
            isFormValid = false;
        }
        if (!isValidEmail) {
            setErrorMessage("Email format must be valid.");
            isFormValid = false;
        }

        if (isFormValid && Object.keys(updatedData).length > 1 && isValidPassword.isValid && isValidEmail) {
            try {
                await updateUser(updatedData).unwrap();
                const refreshedTokens = await refreshToken().unwrap();
                dispatch(setTokens(refreshedTokens));
                await reloadUserInfo();
                setErrorMessage("");
            } catch (error: any) {
                if (error.status === 400) {
                    console.log("Error 400: ", error)
                    let errorMessage: string = '';
                    for (const key in error.data) {
                        errorMessage += error.data[key] + ". ";
                    }
                    setErrorMessage(errorMessage);
                } else if (error.status === 401) {
                    console.log("Error 401", error)
                    setErrorMessage("Current password is not valid.")
                } else {
                    setErrorMessage("An unexpected error occurred.")
                }
            }

        }
    };
    return (
        <>
            {isLoading && <Spinner/>}
            <Breadcrumb link="Profile"/>
            <PageTitle title="User Profile"/>

            <ProfileContainer>
                <ProfileFormContainer>
                    <ActionFormTitle title="My Profile"/>
                    <FormBody onSubmit={handleEditProfile}>
                        <FormRow>
                            <UserBasicInfo
                                firstName={initialUserInfo.firstName}
                                lastName={initialUserInfo.lastName}
                                role={role}
                                id={initialUserInfo.id}
                                instanceId={instanceId}
                            />
                        </FormRow>
                        {groupedFormFields.map((group, groupIndex) => (
                            <FormRow key={groupIndex}>
                                {group.map((field, fieldIndex) => {
                                    const fieldStateAndHandler =
                                        fieldStatesAndHandlers[field.inputName];

                                    if (!fieldStateAndHandler) {
                                        return null;
                                    }

                                    return (
                                        <FormColumn key={`${groupIndex}-${fieldIndex}`}>
                                            <ActionFormLabel
                                                icon={field.labelIcon}
                                                text={field.labelText}
                                            />
                                            <ActionFormInput
                                                id={`user_${field.labelText.toLowerCase()}`}
                                                type={field.inputType}
                                                value={fieldStateAndHandler.value}
                                                onChange={fieldStateAndHandler.onChange}
                                                width={180}
                                            />
                                        </FormColumn>
                                    );
                                })}
                            </FormRow>
                        ))}
                        {errorMessage && <InvalidFeedback errorMessage={errorMessage}/>}
                        {showSuccessPopup && <SuccessPopup
                            successMessage="Success!"
                            instructions="Your profile has been updated"
                            confirmationMessage="THANKS!"
                            handleClosePopup={handleClosePopup}
                        />}
                        <ButtonsContainer>
                            <SlimButton
                                id={"edit_user_form_cancel_button"}
                                type="button"
                                bgColor="#c71313"
                                text="Cancel"
                                textColor="#ffff"
                                onClick={resetInfo}
                                bgGradient="linear-gradient(to right, #a11212, #c71313, #a11212)"
                            />
                            <SlimButton
                                id={"edit_user_form_submit_button"}
                                type="submit"
                                bgColor="#eac113"
                                text="Save"
                                textColor="#2d292a"
                                bgGradient="linear-gradient(to right, #eac113, #ffeb3b, #eac113)"
                            />
                        </ButtonsContainer>
                    </FormBody>
                </ProfileFormContainer>
            </ProfileContainer>
        </>
    );
};
export default Profile;