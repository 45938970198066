
import React from 'react'
import styled from 'styled-components';
import ActionFormTitle from './ActionFormTitle';
import SlimButton from './SlimButton';

const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 120px;
`;
const CardContainer = styled.div<{ width: number}>`
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    border: 1px solid #ccc;
    border-radius: 4px;
    width:  ${({ width }) => width}px;  
    overflow-y: auto;
    margin-bottom: 100px;
`;
const FormBody = styled.div<{ columns: number }>`
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    background-color: #ffffff;
    padding-left: ${({ columns }) => (columns === 1 ? '50px':'1.25rem')};
    border-radius: 4px;
`;
const ButtonsContainer = styled.div`
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
`;
interface ActionFormProps {
    title: string;
    children: React.ReactNode;
    width: number;
    cancelMessage?: string;
    acceptMessage?: string;
    cancelAction: (event: React.MouseEvent<HTMLButtonElement>) => void;
    acceptAction: (event: React.FormEvent) => void;
    columns?: number;
}
const ActionForm: React.FC<ActionFormProps> = ({title, children, width, cancelMessage='Cancel', acceptMessage='Save', cancelAction, acceptAction, columns= 1 }) => {
    return (
        <FormContainer>
            <CardContainer width={width}>
                <ActionFormTitle title={title} />
                <FormBody columns={columns}>
                    <form onSubmit={acceptAction}>
                        {children}
                        <ButtonsContainer>
                        <SlimButton
                            id={"form_cancel_button"}
                            type="reset"
                            bgColor="#c71313"
                            text={cancelMessage}
                            textColor="#fff"
                            onClick={cancelAction}
                            bgGradient="linear-gradient(to right, #a11212, #c71313, #a11212)"
                        />
                        <SlimButton
                            id={"form_submit_button"}
                            type="submit"
                            bgColor="#eac113"
                            text={acceptMessage}
                            textColor="#2d292a"
                            bgGradient="linear-gradient(to right, #eac113, #ffeb3b, #eac113)"
                        />
                        </ButtonsContainer>
                    </form>
                </FormBody>
            </CardContainer>
        </FormContainer>
    );
}
export default ActionForm;