import React, {SyntheticEvent} from 'react';
import styled from "styled-components";
import InsertSymbolBtn from "../editor/InsertSymbolBtn";
import CopySourceToTargetBtn from "../editor/CopySourceToTargetBtn";
import ChangeSegmentStatusBtn from "../editor/ChangeSegmentStatusBtn";
import {Segment, SegmentStatus} from "../../services/segmentsApiSlice";
import {SegmentHistoryBtn} from "../editor/SegmentHistoryBtn";
import {AddSegmentCommentBtn} from "../editor/AddSegmentCommentBtn";

export const EditorButton = styled.button`
    cursor: pointer;
    font-size: 16px;
    background: none;
    padding: 2px;
    border: 1px solid transparent;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #505254;
    
    &:hover {
        border: 1px solid #6e8093;
    }
`;
interface TargetEditorButtonsProps {
    addSymbolAndBlur: (symbol: string) => void,
    copySource: (event: SyntheticEvent) => void,
    changeSegmentStatus: (newStatus: SegmentStatus) => void,
    segment: Segment
}

const TargetEditorButtons = ({ addSymbolAndBlur, copySource, changeSegmentStatus, segment}: TargetEditorButtonsProps) => {
    return (
        <>
            <InsertSymbolBtn onAddSymbol={addSymbolAndBlur} />
            <CopySourceToTargetBtn onCopySourceAction={copySource}/>
            <ChangeSegmentStatusBtn onChangeStatusAction={changeSegmentStatus} currentStatus={segment.status}/>
            <SegmentHistoryBtn segmentId={segment.id}/>
            <AddSegmentCommentBtn segmentId={segment.id}/>
        </>
    );
};

export default TargetEditorButtons;