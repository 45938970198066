import React, { ReactNode } from 'react'
import styled from 'styled-components';

const BoxContainer = styled.div`
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background: #ffffff;
    display: flex;
    margin: 15px;
    min-height: 60px;
    padding: 10px;
    flex: 1;
    max-width: 90%;

  @media (min-width: 650px) {
    max-width: none;
  }
`;

const IconBox = styled.div<{ backgroundColor: string}>`
    border-radius: 4px;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    font-size: 22px;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    width: 60px;
    background-color:${({ backgroundColor }) => backgroundColor};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    color: #fff;
`;
const InfoBoxContent = styled.div`
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 120%;
    -ms-flex: 1;
    flex: 1;
    padding: 0 10px;
`;
const BoxText = styled.div`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const BoxValue = styled.div`
    display: block;
    margin-top: 4px;
    font-weight: 700;
`;

interface InfoBoxProps {
    icon: ReactNode;
    text: string;
    value: number;
    id: string;
    backgroundColor: string;
}
const InfoBox: React.FC<InfoBoxProps> = ({icon, text, value, id, backgroundColor}) => {
    return (
        <BoxContainer>
            <IconBox backgroundColor={backgroundColor}>{icon} </IconBox>
            <InfoBoxContent>
                <BoxText>{text}</BoxText>
                <BoxValue id={id}>{value}</BoxValue>
            </InfoBoxContent>
        </BoxContainer>
    )
}
export default InfoBox