import {EditorButton} from "../translator/TargetEditorButtons";
import {MdHistory} from "react-icons/md";
import {SegmentHistoryRecord, SegmentStatus, useSegmentHistoryQuery} from "../../services/segmentsApiSlice";
import React, {useState} from "react";
import styled from "styled-components";
import ReactModal from "react-modal";
import {getSegmentStatusIcon} from "../../pages/Translator";

const HistoryContainer = styled.div`
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: space-around;
    overflow-y: auto;
`;

const RecordContainer = styled.div`
    border: 1px solid gray;
    border-radius: 4px;
    padding-bottom: 10px;
    width: 97%;
    display: grid;
    row-gap: 10px;
    grid-template-areas: 
            "details details"
            "before after";
`

const RecordHeader = styled.div`
    padding: 5px;
    grid-area: details;
    display: flex;
    justify-content: space-between;
    background-color: #eac113;
`

const RecordColumnContainer = styled.div<{ area: string }>`
    padding: 5px;
    grid-area: ${({area}) => area};
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

interface Props {
    segmentId: string
}

export const SegmentHistoryBtn = ({segmentId}: Props) => {
    const [showHistory, setShowHistory] = useState<boolean>(false);

    let modalStyles: ReactModal.Styles = {
        content: {
            border: '1px solid black',
            borderRadius: '4px',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            maxHeight: '60%'
        }
    }
    return (
        <>
            <EditorButton onMouseDown={() => setShowHistory(prevState => !prevState)} title="Segment History">
                <MdHistory/>
            </EditorButton>
            <ReactModal isOpen={showHistory} onRequestClose={() => setShowHistory(false)} style={modalStyles}>
                <HistoryModal segmentId={segmentId}/>
            </ReactModal>
        </>
    );
};

const HistoryModal = ({segmentId}: { segmentId: string }) => {
    const {data: segmentHistory} = useSegmentHistoryQuery(segmentId);

    return (
        <HistoryContainer>
            <h2>Segment history</h2>
            {segmentHistory &&
                segmentHistory
                    .map(history => <HistoryRecord record={history}/>)}
            {segmentHistory?.length === 0 && <h3>No segment history</h3>}
        </HistoryContainer>
    );
}

enum RecordArea {
    BEFORE = 'before',
    AFTER = 'after'
}

const HistoryRecord = ({record}: { record: SegmentHistoryRecord }) => {
    return (
        <RecordContainer>
            <RecordHeader>
                <label><strong>Modified by: </strong>{`${record.modifiedBy.firstName} ${record.modifiedBy.lastName}`}
                </label>
                <label><strong>Updated at: </strong>{`${new Date(record.updatedAt).toLocaleString()}`}</label>
            </RecordHeader>
            <RecordColumn area={RecordArea.BEFORE} status={record.oldStatus} content={record.oldTarget}/>
            <RecordColumn area={RecordArea.AFTER} status={record.newStatus} content={record.newTarget}/>
        </RecordContainer>
    )
}

const RecordColumn = ({area, status, content}: { area: RecordArea, status: SegmentStatus, content: string }) => {
    const label = area.toString().charAt(0).toUpperCase() + area.toString().substring(1) + ':';
    const statusLabel = status.toString().charAt(0).toUpperCase() +
        status.toString().toLowerCase().substring(1).replace("_"," ");
    return (
        <RecordColumnContainer area={area.toString()}>
            <text><strong>{label}</strong></text>
            <text>Status: {statusLabel} {getSegmentStatusIcon(status)}</text>
            <textarea disabled={true} style={{resize: 'none'}}>{content}</textarea>
        </RecordColumnContainer>
    );
}