import React from 'react';
import profile from "../../assets/img/profile-pic.webp";
import styled from 'styled-components';
import {useAppSelector} from "../../hooks/store-hooks";
import {useNavigate} from "react-router-dom";

const UserPanel = styled.div`
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  border-bottom: 1px solid #dee2e6;
  align-items: center;
`;
const ProfileImage = styled.img`
  margin-left: 10px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
`;
const ProfileUsername = styled.a`
  display: block;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;  

  &:hover{
    color: #fff;
  }
`;

interface ProfileButtonProps {
    isCollapsed: boolean;
}

const ProfileButton: React.FC<ProfileButtonProps> = ({isCollapsed}) => {
    const {firstName, lastName} = useAppSelector(state => state.userInfo);
    const navigate = useNavigate();
    const handleProfileClick = () => {
        navigate("/profile");
    };

    return (
        <UserPanel>
            <ProfileImage
                onClick={handleProfileClick}
                src={profile}
                alt="Underline user avatar"
                title={"My Profile"}
            />
            <ProfileUsername
                onClick={handleProfileClick}
                id="profile-username"
                style={{display: isCollapsed ? "none" : "block"}}>
                {`${firstName} ${lastName}`}
            </ProfileUsername>
        </UserPanel>
    );
};

export default ProfileButton
