import React, {ChangeEvent, useState} from "react";
import {MdAlternateEmail} from "react-icons/md";
import {HiOfficeBuilding} from "react-icons/hi";
import AuthForm from '../components/ui/forms/auth/AuthForm';
import AuthFormInput from "../components/ui/forms/auth/AuthFormInput";
import InvalidFeedback from "../components/ui/forms/InvalidFeedback";
import AuthFormButton, {ButtonType} from "../components/ui/forms/auth/AuthFormButton";
import SuccessPopup from "../components/ui/forms/SuccessPopup";
import styled from 'styled-components';
import {useNavigate} from "react-router-dom";
import {useSendForgotPasswordEmailMutation} from "../services/usersApiSlice";
import Spinner from "../components/spinner/Spinner";


const ForgotPasswordMessage = styled.h5`
  color: #fff;
  margin: 15px 0 20px 20px;
  font-weight: 400;
  font-size: 14px;
`;

const footerLinks = [
    {
        text: "Don't have an account?  ",
        linkText: "Sign up here!",
        link: "/register",
    },
];
const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState("");
    const [instanceIdOrEmail, setInstanceIdOrEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const navigate = useNavigate();
    const [sendForgotPasswordEmail, {isLoading}] = useSendForgotPasswordEmailMutation();

    const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            setEmail(event.target.value);
        }
    };
    const handleChangeInstanceIdOrEmail = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            setInstanceIdOrEmail(event.target.value);
        }
    }

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailRegex.test(email);
    };

    const handleClosePopup = () => {
        setShowSuccessPopup(false);
        navigate("/login");
    };

    const handleResetPassword = async (event: React.FormEvent) => {
        event.preventDefault();
        if(email.trim() === "" ||instanceIdOrEmail.trim() === ""){
            setErrorMessage("Fields cannot be empty");
            return;
        }
        if (!isValidEmail(email)) {
            setErrorMessage("You must provide a valid email");
            return;
        }
        let request: any = {
            email
        }
        if(!isValidEmail(instanceIdOrEmail)){
            request.instanceId = instanceIdOrEmail;
            request.ownerEmail = null;
        }else{
            request.instanceId = null;
            request.ownerEmail = instanceIdOrEmail;
        }

        sendForgotPasswordEmail(request)
            .unwrap()
            .then(() => {
                setShowSuccessPopup(true);
            })
            .catch((error) => {
                if(error.status === 401){
                    setErrorMessage("The user does not exist in the Instance provided.");
                } else if(error.status === 404 && error.data.message){
                    setErrorMessage(error.data.message)
                } else if(error.status === 400){
                    setErrorMessage(`The value ${instanceIdOrEmail} is not valid.` );
                } else {
                    setErrorMessage("An unexpected error occurred. Please try again.");
                }
            });
    };

    return (
        <>
            {isLoading && <Spinner/>}
            <AuthForm title="Reset password" width={400} height={430} footerLinks={footerLinks}
                      hasError={!!errorMessage} submitHandler={handleResetPassword} >
                <ForgotPasswordMessage>
                    You can quickly modify it here.<br/>
                    Please give your email address as well as <br/>
                    the instance ID or owner's email address.
                </ForgotPasswordMessage>

                <AuthFormInput
                    prependIcon={<MdAlternateEmail/>}
                    placeholder="Email"
                    type="text"
                    value={email}
                    onChange={handleChangeEmail}
                />
                <AuthFormInput
                    prependIcon={<HiOfficeBuilding/>}
                    placeholder="Instance ID or Owner email"
                    type="text"
                    value={instanceIdOrEmail}
                    onChange={handleChangeInstanceIdOrEmail}
                />
                {errorMessage && <InvalidFeedback errorMessage={errorMessage}/>}
                <AuthFormButton
                    text="Request new password"
                    extraMargin="true" type={ButtonType.submit}
                />
                {showSuccessPopup &&
                    <SuccessPopup
                        successMessage="Success!"
                        instructions="An email has been sent with instructions."
                        confirmationMessage="THANKS!"
                        handleClosePopup={handleClosePopup}/>}
            </AuthForm>
        </>
    );
}

export default ForgotPassword;