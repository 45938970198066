import React from "react";
import styled from 'styled-components';

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  @media screen and (min-resolution: 140dpi) {
    width: 35px;
    height: 20px;
  }

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  & .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;

    @media screen and (min-resolution: 140dpi) {
      border-radius: 30px;
    }
  }
  & .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;

    @media screen and (min-resolution: 140dpi) {
      width: 17px;
      height: 17px;
      bottom: 1.5px;
      left: 1px;
    }
  }
  input:checked + .slider {
    background-color: #EAC113;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #EAC113;
  }
  input:checked + .slider:before {
    -ms-transform: translateX(26px);
    transform: translateX(26px);

    @media screen and (min-resolution: 140dpi) {
      -ms-transform: translateX(16px);
      transform: translateX(16px);      
    }
  }
`;
const SwitchText = styled.span`
  margin-left: 10px;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  height: 20px;
  font-size: 16px;
  font-weight: 600;

  @media screen and (min-resolution: 140dpi) {
    font-size: 12px;
    margin-bottom: 8px;
  }
`;

interface CookiesSwitchButtonProps {
    isOn: boolean;
    handleToggle: () => void;
    disabled?: boolean;
}
const CookiesSwitchButton: React.FC<CookiesSwitchButtonProps> = ({ isOn, handleToggle, disabled }) => {
    return (
        <>
            <Switch>
                <input
                    type="checkbox"
                    checked={isOn}
                    onChange={handleToggle}
                    disabled={disabled}
                />
                <span className="slider"></span>
            </Switch>
            <SwitchText>{isOn ? 'Enabled' : 'Disabled'}</SwitchText>
        </>
    );
};

export default CookiesSwitchButton