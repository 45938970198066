import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import ReactPlayer from "react-player";
import {convertToVtt, Subtitle} from "./subtitleUtils";
import styled from "styled-components";
import {BiRefresh} from "react-icons/bi";

const UploadedVideoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;

const FileContainer = styled.div`
    display: flex;
    align-items: center;
`;

const FileInputLabel = styled.label`
    display: inline-block;
    padding: 2px 12px;
    cursor: pointer;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    font-weight: 650;
    transition: background-color 0.2s;
    margin: 0 5px;

    &:hover {
        background-color: #e8e9ea;
    }
`;

const DisplayedName = styled.p`
    font-size: 12px;
`;

const StyledInput = styled.input`
    opacity: 0;
    position: absolute;
    z-index: -1;
`;

const RefreshButton = styled.button`
    display: flex;
    align-items: center;
    color: #2d292a;
    border: 1px solid #b5cef2;
    padding: 5px 20px;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    line-height: 1.5;
    border-radius: 4px;
    text-transform: uppercase;
    background: linear-gradient(to right, #eac113, #ffeb3b, #eac113);

    &:hover {
        background-color: #2d292a;
        border: 1px solid #b5cef2;
        color: #fff;
    }

    &:focus {
        outline: none;
    }
`;

const SectionContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;

interface VideoPlayerProps {
    subtitles: Subtitle[];
    onUpdateCurrent: React.Dispatch<React.SetStateAction<number>>;
    onUpdateDuration: React.Dispatch<React.SetStateAction<number>>;
    focusTime: number;
    onReloadSubtitles: Function;
    reloadText?: String;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
                                                            subtitles, onUpdateCurrent,
                                                            onUpdateDuration, focusTime,
                                                            onReloadSubtitles, reloadText
                                                        }) => {
    const [videoFile, setVideoFile] = useState<string>("");
    const [selectedFile, setSelectedFile] = useState<string>("");
    const [vttString, setVttString] = useState(convertToVtt(subtitles))
    const [vttSource, setVttSource] = useState(`${URL.createObjectURL(new Blob([vttString], {type: 'text/vtt'}))}`)
    const vttSourceRef = useRef(vttSource);
    const playerRef = useRef<ReactPlayer | null>(null)

    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.seekTo(focusTime)
        }
    }, [focusTime]);

    useEffect(() => {
        const newVttString = convertToVtt(subtitles)
        setVttString(newVttString);
        if (vttSourceRef.current) {
            URL.revokeObjectURL(vttSourceRef.current);
        }
        const newVttSource = `${URL.createObjectURL(new Blob([newVttString], {type: 'text/vtt'}))}`;
        setVttSource(newVttSource);
        vttSourceRef.current = newVttSource;
    }, [subtitles]);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        let file = event.target.files && event.target.files[0];
        if (file) {
            setVideoFile(URL.createObjectURL(file))
            setSelectedFile(file.name)
        }
    }

    const handleReloadClick = (): void => {
        onReloadSubtitles();
    }

    return (
        <SectionContainer>
            <FileContainer>
                <StyledInput
                    id="video-input"
                    type="file"
                    onChange={handleFileChange}
                    accept="video/*"
                />
                <FileInputLabel htmlFor="video-input">Select a Video File</FileInputLabel>
                <DisplayedName>{selectedFile}</DisplayedName>
            </FileContainer>

            <UploadedVideoContainer>
                <ReactPlayer
                    key={vttSource}
                    ref={playerRef}
                    url={videoFile}
                    controls={true}
                    width='100%'
                    height='100%'
                    onProgress={({playedSeconds}) => onUpdateCurrent(playedSeconds)}
                    onDuration={(duration) => onUpdateDuration(duration)}
                    config={{
                        file: {
                            attributes: {
                                crossOrigin: 'anonymous'
                            },
                            tracks: [
                                {kind: 'subtitles', src: vttSource, srcLang: 'en', label: 'English', default: true}
                            ]
                        }
                    }}
                ></ReactPlayer>
            </UploadedVideoContainer>
            {videoFile && (
                <RefreshButton id={"refresh-subtitles-btn"} onClick={handleReloadClick}>
                    <BiRefresh style={{fontSize: '25px'}}/>{reloadText ? reloadText : "Refresh"}
                </RefreshButton>
            )}
        </SectionContainer>
    );
};