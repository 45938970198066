import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface CookieConsentState {
    consent: boolean,
    expirationDate: number | null,
    necessaryCookies: boolean,
    thirdPartyCookies: boolean,
}

const initialState: CookieConsentState = {
    consent: false,
    expirationDate: null,
    necessaryCookies: false,
    thirdPartyCookies: false,
};

export const cookieConsentSlice = createSlice({
    name: 'cookieConsent',
    initialState,
    reducers: {
        setCookieConsent: (state, action: PayloadAction<CookieConsentState>) => {
            return action.payload;
        },
        clearCookieConsent: () => {
            return initialState;
        },
    },
});
export const { setCookieConsent, clearCookieConsent } = cookieConsentSlice.actions;
export default cookieConsentSlice.reducer;