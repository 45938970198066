import React from 'react';
import { Chart } from 'react-google-charts';
import { ChartTitle, TitleContainer } from './PieChart';

interface LineChartProps {
    data: (string | number)[][];
    title: string;
    options?: object;
}
const LineChart: React.FC<LineChartProps> = ({ data, title, options}) => {
    return (
        <div>
            <TitleContainer>
                <ChartTitle>{title}</ChartTitle>
            </TitleContainer>
            <Chart
                width={'100%'}
                height={'250px'}
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={data}
                options={{
                    ...options,
                    lineWidth: 2,
                    pointSize: 8,
                    curveType: 'function',
                    vAxis: {
                        viewWindow: {
                            min: 0,
                        },
                    }
                }}
                rootProps={{ 'data-testid': '1' }}

            />
        </div>
    );
};
export default LineChart;
