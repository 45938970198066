import React from "react";
import logo from "../assets/img/logo-white-transparent.webp";
import { GoThreeBars } from "react-icons/go";
import styled from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {DEFINED_ROUTES} from '../utils/constants'

const Line = styled.hr`
  height: 6px;
  background: #eac113;
  background: linear-gradient(to right,
      #eac113 19%,
      #f2e096 43%,
      #eac113 57%,
      #f2e096 81%);
  margin: 0;
  border: 0;
`;
const BannerContainer = styled.nav`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  background-color: #f8f9fa;
  -ms-flex-pack: start;
`;
const BannerList = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  margin-bottom: 10px;
  list-style: none;
`;
const ListItem = styled.li`
  display: flex;
  align-items: center;
`;
const TextLink = styled.a`
  cursor: pointer; 
  margin-left: 8px;
  color: #2d292a;
  font-size: 26px;
  font-weight: 700;
  vertical-align: middle;
  text-decoration: none;

  &:hover {
    color: #2d292a;
  }
`;
const IconLink = styled.a`
  cursor: pointer;
  margin-left: 10px;
  font-size: 20px;
  color: #2d292a;
  vertical-align: middle;

  &:hover {
    color: #2d292a;
  }
`;
const LogoImage = styled.img`
  margin-left: 10px;
  height:60px;
`;
interface BannerProps {
  toggleSidebar: () => void;
}
const Banner: React.FC<BannerProps> = ({ toggleSidebar }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const matchRoute = (path: string, route: string) => {
    const pathSegments = path.split('/').filter(p => p);
    const routeSegments = route.split('/').filter(r => r);

    if (pathSegments.length !== routeSegments.length) {
      return false;
    }

    return routeSegments.every((segment, index) => {
      return segment.startsWith(':') || segment === pathSegments[index];
    });
  };

  const isRouteDefined = DEFINED_ROUTES.some(route => matchRoute(pathname, route));

  const handleLogoClick = (event: React.MouseEvent) => {
    event.preventDefault();
    navigate("/home");
  };

  return (
      <>
        <BannerContainer>
          <BannerList>

            <ListItem>
              <IconLink
                  data-widget="pushmenu"
                  role="button"
                  onClick={toggleSidebar}
              >
                {isRouteDefined && <GoThreeBars />}
              </IconLink>

              <LogoImage src={logo} alt="Underline logo" title={"underline-logo"} onClick={handleLogoClick}/>
              <TextLink onClick={handleLogoClick}>
                underline
              </TextLink>
            </ListItem>
          </BannerList>
        </BannerContainer>
        <Line />
      </>
  );
}

export default Banner;