import React from 'react'
import ReactCountryFlag from "react-country-flag";
import styled from 'styled-components';

const FlagContainer = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 0 2px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f5f5f5;
    width: 60px;
    margin-right: 3px;
`;
const FlagText = styled.span<{ fontSize?: string }>`
    font-size: ${({ fontSize }) => fontSize || 'inherit'};
`;
interface CountryFlagsProps{
    id?: string;
    languageCode : string;
    fontSize?: string;
}
const CountryFlag: React.FC<CountryFlagsProps> = ({ id, languageCode, fontSize }) => {
    let countryCode = languageCode.split(/[-_]/)[1];

    if (languageCode === 'es-LA' || languageCode === 'es_LA') {
        countryCode = 'MX';
    }
    return (
        <FlagContainer id={id}>
            <ReactCountryFlag countryCode={countryCode} svg style={{ marginRight: "5px" }} />
            <FlagText fontSize={fontSize}>{languageCode}</FlagText>
        </FlagContainer>
    );
};
export default CountryFlag