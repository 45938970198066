import React from "react";
import styled from 'styled-components';
import landingImageCorner from "../../assets/img/landing-corner.svg";

const SmallCornerContainer = styled.img`
  position: absolute;
  z-index: 1;
  width: 400px;
  top: 1790px;
  left: 1400px;
  transform: rotate(60deg);
  
  @media (max-width: 4380px) {
    top: 1820px;
    left: 1100px;
  }
  @media (max-width: 3660px) {
    left: 800px;
  }
  @media (max-width: 3080px) {
    left: 400px;
  }
  @media (max-width: 2450px) {
    left: 300px;
  }
  @media (max-width: 2010px) {
    left: 120px;
  }
  @media (max-width: 1850px) {
    left: 20px;
  }
  @media (max-width: 1700px) {
    width: 300px;
    top: 1770px;
    left: 50px;
  }
  @media (max-width: 1530px) {
    top: 1550px;
    left: 40px;
  }
  @media (max-width: 1195px) {
    top: 1780px;
    width: 200px;
    left: 40px;
  }
  @media (max-width: 1050px) {
    top: 1600px;
  }
  @media (max-width: 860px) {
    width: 180px;
    left: -15px;
  }
  @media (max-width: 750px) {
    top: 1610px;
    width: 150px;
  }
  @media (max-width: 697px) {
    top: 1310px;
  }
  @media (max-width: 640px) {
    top: 1550px;
    width: 100px;
    left: -5px;
  }
  @media (max-width: 620px) {
    display: none;
  }
  @media (max-width: 580px) {
    display: block;
    top: 1500px;
  }
  @media (max-width: 565px) {
    top: 1650px;
  }
  @media (max-width: 475px) {
    top: 1620px;
  }
  @media (max-width: 360px) {
    top: 1680px;
  }
  @media (max-width: 310px) {
    display: none;
  }
`;
const BigCornerContainer = styled.img`
  position: absolute;
  z-index: 1;
  width: 750px;
  top: 1800px;
  left: 1050px;
  transform: rotate(60deg);

  @media (max-width: 4380px) {
    top: 1830px;
    left: 800px;
  }
  @media (max-width: 3660px) {
    left: 500px;
  }
  @media (max-width: 3080px) {
    left: 100px;
  }
  @media (max-width: 2450px) {
    left: 0;
  }
  @media (max-width: 2010px) {
    left: -150px;
  }
  @media (max-width: 1850px) {
    width: 600px;
  }
  @media (max-width: 1700px) {
    top: 1800px;
    left: -100px;
    width: 420px;
  }
  @media (max-width: 1530px) {
    top: 1600px;
    left: -100px;
  }
  @media (max-width: 1195px) {
    top: 1800px;
    left: -50px;
    width: 300px;
  }
  @media (max-width: 1050px) {
    top: 1600px;
    left: -30px;
    width: 280px;
  }
  @media (max-width: 860px) {
    left: -90px;
  }
  @media (max-width: 750px) {
    width: 250px;
    left: -80px;
  } 
  @media (max-width: 697px) {
    top: 1300px;  
  }
  @media (max-width: 640px) {
    width: 180px;
    top: 1550px;
    left: -50px;
  }
  @media (max-width: 620px) {    
    top: 1500px;
  }
  @media (max-width: 565px) {
    top: 1650px;
  }
  @media (max-width: 475px) {
    top: 1620px;
  }
  @media (max-width: 360px) {
    top: 1680px;
  }
  @media (max-width: 310px) {
    top: 1740px
  }
`;
const LandingCorners: React.FC= () => {
    return(
        <>
            <SmallCornerContainer src={landingImageCorner} alt={"Underline decoration lines"} title={"underline-decoration-lines"} />
            <BigCornerContainer src={landingImageCorner} alt={"Underline decoration lines"} title={"underline-decoration-lines"}/>
        </>
    )
}
export default LandingCorners