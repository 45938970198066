import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {
    compareTimestamps,
    formatTimestamp,
    parseTimestamp,
    Subtitle,
    Timestamp
} from "./subtitleUtils";
import styled from "styled-components";
import {RiStopLine} from "react-icons/ri";
import {VscDebugStart} from "react-icons/vsc"
import {FaTrashAlt} from 'react-icons/fa';
import {TfiSplitV} from "react-icons/tfi";
import InsertSymbolBtn from "../editor/InsertSymbolBtn";

const InputContainer = styled.div<{ hasOverlap?: boolean, exceedsVideoDuration?: boolean }>`
  padding: 10px;
  border-radius: 4px;
  border: ${(props) => (props.hasOverlap || props.exceedsVideoDuration) ? "2px solid red" : "1px solid #aaa"};
  display: flex;
  justify-content: space-between;
  margin: 5px;
  position: relative;

  &:focus-within {
    border: 3px solid #eac113;
  }
`;
const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;
const SecondColumn = styled.div`
  display: flex;
  flex: 4;
  align-items: center;
  justify-content: space-between
`;
const IdContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: -80px;
`;
const SubtitleIdDiv = styled.div`
  font-weight: 700;
  color: #2D292A;
  font-size: 11px;
  margin-left: 5px;
`;
const StyledSubtitleContentInput = styled.textarea`
  font-weight: 500;
  color: #6d7f92;
  padding: 5px 10px 5px 5px;
  border: none;
  width: 90%;
  font-size: 14px;
  background-color: #f3f6f4;

  &:focus {
    color: black;
    background-color: lightgray;
    outline: none;
    text-decoration: none;
  }
`;
const TimeInputContainer = styled.div`
  color: #6d7f92;
  align-items: center;
`;
const TimeColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;

const TimeColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const CPLContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #6d7f92;
  border: 1px solid #aaa;
  border-radius: 5px;
  padding: 2px 5px;
  align-items: center;
  margin: 0 8px 0 5px;
  height: auto;
  background-color: #f8fafc;
`;
const CPLInfo = styled.p`
  font-weight: 600;
  font-size: 14px;
  margin: 0;  
`;
const Tooltip = styled.div`
  position: absolute;
  bottom: 100%;
  left: 150%;
  transform: translateX(-50%);
  padding: 5px;
  background-color: #4a4a4a;
  color: white;
  white-space: nowrap;
  font-size: 11px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease;
`;
const TooltipContainer = styled.div`
  display: inline-block;
  position: relative;
  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`;
const StyledTimeInput = styled.input<{ isError?: boolean }>`
  color: #6d7f92;
  padding: 2px 3px;
  width: 90px;
  border-radius: 4px;
  border: ${(props) => props.isError ? "1px solid red" : "1px solid #aaa"};

  &:focus {
    outline: none;
    border-color: ${(props) => props.isError ? "red" : "#ccc"};
  }
`;
const ArrowIcon = styled.span`
  position: relative;
  top: 4px;
  margin-right: 12px;
  cursor: pointer;
  color: #505254;

  &:hover {
    color: #6e8093;
    border: 1px solid #6e8093;
    border-radius: 4px;
    padding: 3px 2px 0 2px;
    margin-right: 6px;
  }
`;
const IconButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  color: #505254;
  margin-left: 3px;

  &:hover {
    color: #6e8093;
    border: 1px solid #6e8093;
    border-radius: 4px;
    padding: 3px 5px 0 5px;
  }

  &:focus {
    border: none;
    outline: none;    
  }
`;
const StyledTfiSplitV = styled(TfiSplitV)`
  font-size: 15px;
`;

function SubtitleId(props: { subId: number }) {
    return <SubtitleIdDiv>ID: {props.subId}</SubtitleIdDiv>
}

function TimeInput(props: { time: Timestamp, updateTimestamp: Function, isError?: boolean }) {
    const [time, setTime] = useState<Timestamp>(props.time)
    const [value, setValue] = useState<string>(formatTimestamp(time))
    const [isValid, setIsValid] = useState<boolean>(true);
    const [showError, setShowError] = useState<boolean>(false);

    useEffect(() => {
        if (value) {
            let newTimestamp: Timestamp | null = parseTimestamp(value);
            if (newTimestamp) {
                setTime(newTimestamp)
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        }
    }, [value]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }

    const handleBlur = () => {
        if (isValid) {
            setShowError(false);
            props.updateTimestamp({...time, isValidInput: true});
        } else {
            setShowError(true);
            props.updateTimestamp({...time, isValidInput: false});
        }
    }

    return <>
        <StyledTimeInput type="text"
                         pattern="^(\d{2}:\d{2}:\d{2}.\d{3})$"
                         value={value}
                         isError={props.isError}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         style={showError ? {border: 'solid 1px red', backgroundColor: 'lightpink'} : {}}/>
    </>;
}

function ContentInput(props: { content: string, onEditorUpdate: Function, id: string, textDirection: string}) {
    const [content, setContent] = useState(props.content);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleBlur = (): void => {
        const cursorPosition = textAreaRef?.current?.selectionStart;
        props.onEditorUpdate(content, cursorPosition);
    }

    const addSymbol = (symbol: string): void => {
        setContent(prevContent => {
            let cursorPosition = prevContent.length-1;
            if(textAreaRef.current){
                cursorPosition = textAreaRef.current.selectionStart;
            }
            return prevContent.substring(0, cursorPosition) + symbol + prevContent.substring(cursorPosition);
        })
    }

    return (
        <>
            <TooltipContainer>
                <CPLContainer>
                    <CPLInfo>
                        CPL
                    </CPLInfo>
                    {content.split("\n").map((line,i) => <CPLInfo key={`sub-${props.id}-length-${i}`}>{line.length}</CPLInfo>)}
                </CPLContainer>
                <Tooltip>Characters per line</Tooltip>
            </TooltipContainer>
            <StyledSubtitleContentInput
                rows={4}
                dir={props.textDirection}
                ref={textAreaRef}
                id={props.id}
                value={content}
                onChange={(e => setContent(e.target.value))}
                onBlur={handleBlur}/>
            <InsertSymbolBtn onAddSymbol={addSymbol}/>
        </>
    );
}

interface SubtitleInputProps{
    subtitle: Subtitle;
    onSubtitleChange: Function;
    onDelete: Function;
    currentTime: Timestamp;
    exceedsVideoDuration: boolean;
    onSplit: Function;
    isOverlap: boolean;
    setContentAfterCursor: Function;
    setContentBeforeCursor: Function;
    textDirection: string;
}

export const SubtitleInput: React.FC<SubtitleInputProps> = ({
                                                                subtitle: initialSubtitle,
                                                                onSubtitleChange,
                                                                onDelete,
                                                                currentTime,
                                                                onSplit,
                                                                exceedsVideoDuration,
                                                                isOverlap,
                                                                setContentAfterCursor,
                                                                setContentBeforeCursor,
                                                                textDirection,
                                                            }) => {
    const [subtitle, setSubtitle] = useState<Subtitle>(initialSubtitle);
    const [isInvalid, setIsInvalid] = useState<boolean>(false);


    //If the subtitle gets re-rendered. The last valid input replaces the inputs in the timestamp.
    //The subtitle is rendered when the combination id+content+timestamps changes.
    useEffect(() => {
        setSubtitle(prevSub => {
            return {...prevSub, start: {...prevSub.start, isValidInput: true}, end: {...prevSub.end, isValidInput: true}}
        })
    }, [])

    useEffect(() => {
        onSubtitleChange(subtitle);
    }, [subtitle, onSubtitleChange]);

    useEffect(() => {
        setIsInvalid(compareTimestamps(subtitle.start, subtitle.end) >= 0);
    }, [subtitle.start, subtitle.end]);

    const updateStart = (start: Timestamp) => {
        setSubtitle((prevSub) => ({...prevSub, start}))
    }

    const updateEnd = (end: Timestamp) => {
        setSubtitle((prevSub) => ({...prevSub, end}))
    }

    const updateEditorContent = (newContent: string, cursorPosition: number) => {
        setSubtitle((prevSub) => {
            setContentBeforeCursor(newContent.slice(0, cursorPosition));
            setContentAfterCursor(newContent.slice(cursorPosition));
            return {...prevSub, content: newContent}
        })
    }

    const updateMerge = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSubtitle((prevSub) => ({...prevSub, canMerge: e.target.checked}))
    }

    const handleSetTime = (isStart: boolean) => {
        if (isStart) {
            updateStart(currentTime);
        } else {
            updateEnd(currentTime);
        }
    }

    return (
        <InputContainer hasOverlap={isOverlap} exceedsVideoDuration={exceedsVideoDuration}>

            <FirstColumn>
                <IdContainer>
                    <input
                        title="Select to merge this subtitle"
                        type="checkbox"
                        value={subtitle.subtitleId}
                        checked={subtitle.canMerge}
                        onChange={updateMerge}
                    />
                    <SubtitleId subId={subtitle.subtitleId}/>
                </IdContainer>

                <TimeColumnsContainer>
                    <TimeColumn>
                        <TimeInputContainer>
                            <ArrowIcon>
                                <VscDebugStart
                                    title="Insert current video timestamp"
                                    id={`sub-${subtitle.subtitleId}-start-time-icon`}
                                    onClick={() => handleSetTime(true)}
                                />
                            </ArrowIcon>
                            <TimeInput time={subtitle.start} updateTimestamp={updateStart} isError={isInvalid}/>
                        </TimeInputContainer>

                        <TimeInputContainer>
                            <ArrowIcon>
                                <RiStopLine
                                    title="Insert current video timestamp"
                                    id={`sub-${subtitle.subtitleId}-end-time-icon`}
                                    onClick={() => handleSetTime(false)}
                                />
                            </ArrowIcon>
                            <TimeInput time={subtitle.end} updateTimestamp={updateEnd} isError={isInvalid}/>
                        </TimeInputContainer>
                    </TimeColumn>
                </TimeColumnsContainer>
            </FirstColumn>
            <SecondColumn>
                <ContentInput
                    id={`sub-${subtitle.subtitleId}-content`}
                    key={`sub-${subtitle.subtitleId}-content`}
                    content={subtitle.content}
                    onEditorUpdate={updateEditorContent}
                    textDirection={textDirection}
                />
                <IconButton onClick={() => onSplit()} title="Split content">
                    <StyledTfiSplitV
                        title="Split content"
                    />
                </IconButton>
                <IconButton onClick={() => onDelete()} title="Delete subtitle">
                    <FaTrashAlt
                        title="Delete subtitle"
                    />
                </IconButton>
            </SecondColumn>
        </InputContainer>
    );
};