import React from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import {SUPPORT_EMAIL} from '../../../../utils/constants'

const Links = styled.div<{ hasError: boolean, extraMargin: string }>`
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0 solid rgba(0, 0, 0, 0.125);
  margin-top: ${({hasError, extraMargin}) => (hasError ? extraMargin : '110px')};
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  & a:hover {
    color: #eac113;
  }
`;
const LinkContainer = styled.div`
  display: inline-block;
`;
const TermsLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    color: #EAC113;
    text-decoration: underline;
  }
`;
const EmailLink = styled.a`
  text-decoration: none;
  color: #fff;
  &:hover {
    color: #EAC113;
    text-decoration: underline;
  }
`
interface FormFooterLinks {
    text: string;
    linkText: string;
    link: string;
}

interface FormFooterLinksProps {
    links: FormFooterLinks[];
    extraMargin?: string;
    hasError: boolean;
}

const AuthFormFooterLink: React.FC<FormFooterLinksProps> = ({links, hasError, extraMargin = '80px'}) => {
    return (
        <Links hasError={hasError} extraMargin={extraMargin}>
            {links.map((link, index) => (
                <LinkContainer key={index}>
                    <span>{link.text}</span>
                    <TermsLink to={link.link}>{link.linkText}</TermsLink>
                </LinkContainer>
            ))}
            <LinkContainer>
                <span>Do you have any questions? <EmailLink href={`mailto:${SUPPORT_EMAIL}`}>Contact Us</EmailLink></span><br/>
            </LinkContainer>
        </Links>
    );
};
export default AuthFormFooterLink