import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";


export interface TokenInfo {
    token: TokenPayload | null,
    refreshToken: TokenPayload | null
}

export interface TokenPayload {
    value: string,
    expiresAt: string
}

const emptyTokens: TokenInfo = {
    token: null,
    refreshToken: null
}

const tokenSlice = createSlice({
    initialState: emptyTokens,
    name: 'tokenInfo',
    reducers: {
        setTokens(state: TokenInfo, newTokensPayload: PayloadAction<TokenInfo>): TokenInfo {
            return newTokensPayload.payload;
        },
        clearTokens(): TokenInfo {
            return emptyTokens;
        }
    }
});
export const {setTokens, clearTokens} = tokenSlice.actions;
export default tokenSlice.reducer;
export const tokenInfoSelector = (state: RootState) => state.tokenInfo;