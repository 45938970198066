import React from 'react'
import Logo from './Logo'
import Navbar from './Navbar'
import ProfileButton from './ProfileButton'
import styled from 'styled-components';

const MainSidebar = styled.aside<SidebarProps>`
  height: 100vh;
  overflow-y: hidden;
  z-index: 1038;
  background-color: #3b3b3b;
  color: #fff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: ${({ isCollapsed }) => (isCollapsed ? "60px" : "250px")};
  transition: width 0.3s;
`;
const ItemsContainer = styled.div`
  color: #fff;
`;
interface SidebarProps {
  isCollapsed: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed }) => {
  return (
    <MainSidebar isCollapsed={isCollapsed}>
      <Logo isCollapsed={isCollapsed} />
      <ItemsContainer>
        <ProfileButton isCollapsed={isCollapsed} />
        <Navbar isCollapsed={isCollapsed} />
      </ItemsContainer>
    </MainSidebar>
  );
};

export default Sidebar