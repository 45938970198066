import React from 'react'
import styled from 'styled-components';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
`;
const ModalPopUpContent = styled.div`
    background-color: #fff;
    padding: 20px 60px;
    border-radius: 5px;
`;
const ConfirmationButton = styled.button`
    background-color: #34d639;
    color: #fff;
    padding: 5px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 700;
    &:focus {
        border:  1px solid #ccc;
        outline: none;
    }
`;
const CancelButton = styled.button`
    background-color: #f44336;
    color: #fff;
    padding: 5px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 700;
    margin-left: 20px;
    &:focus {
        border: 1px solid #ccc;
        outline: none;
    }
`;
interface ModalPopUpProps {
    confirmationMessage?: string;
    handleConfirmation?: () => void;
    handleCancel?: () => void;
    cancelMessage?: string;
    children?: React.ReactNode;
}
const ModalPopUp: React.FC<ModalPopUpProps> = ({
    confirmationMessage = 'Save',
    handleConfirmation,
    handleCancel,
    cancelMessage = 'Cancel',
    children
}) => {
    return (
        <Container>
            <ModalPopUpContent>
                {children}
                {handleConfirmation && (
                    <ConfirmationButton onClick={handleConfirmation}>
                        {confirmationMessage}
                    </ConfirmationButton>
                )}
                {handleCancel && (
                    <CancelButton onClick={handleCancel}>
                        {cancelMessage}
                    </CancelButton>
                )}
                
            </ModalPopUpContent>
        </Container>
    )
}
export default ModalPopUp