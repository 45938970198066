import React, {SyntheticEvent, useEffect, useRef, useState} from 'react';
import {
    CompositeDecorator,
    ContentBlock,
    ContentState,
    Editor,
    EditorState,
    Modifier,
    RichUtils,
    SelectionState,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import styled from "styled-components";
import {Placeholder, SegmentStatus, SegmentType, useUpdateSegmentMutation} from "../../services/segmentsApiSlice";
import PlaceholderBadge from "./PlaceholderBadge";
import TargetEditorButtons from "./TargetEditorButtons";
import {validateSubtitleLength} from "../subtitles/subtitleUtils";
import {Validation, ValidationType} from "../validations/Validations";
import {EditorSegment} from "../../pages/Translator";

const TargetEditorContainer = styled.div`
    display: flex;
    z-index: 0; //This prevents the text of the editor to appear above the modals
`;

const ButtonsContainer = styled.div`
    border: 1px solid #e8e8e8;
    flex: 1;
    border-radius: 4px;
    margin-left: 5px;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
`;

const TextEditorContainer = styled.div<{ isBlocked: boolean }>`
    flex: 5;
    font-weight: 500;
    color: #6d7f92;
    background-color: ${({ isBlocked }) => isBlocked ? '#f5f5f5' : '#fff'}; /* Conditionally set background color */
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    padding: 7px;
    font-size: 14px;
    overflow: auto;

    &:focus-within {
        color: #2f2f2f;
        background-color: #ece9e9;
        border: 1px solid #afafaf;
    }
`;

interface TargetEditorProps {
    segment: EditorSegment;
    onTextChange: (text: string, segmentId: string) => void;
    clearOnChange: number;
    setAreSegmentsSync: (value: boolean) => void;
    onUpdateError: (values: Validation[], segmentNumber: number) => void;
    maxLines: number;
    maxCharsPerLine: number;
    onChangeStatus: (newStatus: SegmentStatus, segmentId: string, newContent: string) => void;
    onUpdateFirstOccurrence: (taskSegmentId: number, previousText: string, newText: string) => void;
    focus: {id: number, time: Date} | null;
}

enum HandleResult {
    HANDLED = 'handled',
    NOT_HANDLED = 'not-handled'
}

enum EditorCommands {
    BACKSPACE = 'backspace',
    DELETE = 'delete'
}

const PH_REGEX = /\{\d+}/g;
const TargetEditor: React.FC<TargetEditorProps> = ({segment, onTextChange, clearOnChange, setAreSegmentsSync,
                                                       onUpdateError, maxLines, maxCharsPerLine, onChangeStatus,
                                                   onUpdateFirstOccurrence, focus}) => {
    const editorRef = useRef<Editor>(null);
    const [updateSegment] = useUpdateSegmentMutation();
    const [needsSync, setNeedsSync] = useState<boolean>(false);
    const [segmentValidations, setSegmentValidations] = useState<Validation[]>([]);
    const [originalContent, setOriginalContent] = useState("");
    const [isReadOnly, setIsReadOnly] = useState<boolean>(segment.status === SegmentStatus.LOCKED);

    useEffect(() => {
        if(focus && focus.id === segment.taskSegmentId){
            focusEditor();
        }
    }, [focus])

    useEffect(() => {
        setIsReadOnly(segment.status === SegmentStatus.LOCKED);
    }, [segment.status]);

    useEffect(() => {
        let criticalErrors = segmentValidations
            .find(validation => validation.type === ValidationType.ERROR);
        if(needsSync && !criticalErrors){
            synchronizeSegment(editorState.getCurrentContent().getPlainText())
                .finally(() => {
                    setNeedsSync(false);
                })
        }
    }, [needsSync, segmentValidations])

    useEffect(() => {
        onUpdateError(segmentValidations, segment.taskSegmentId);
    }, [segmentValidations]);

    async function synchronizeSegment(targetContent: string) {
        let genericError: Validation = {location: `Segment ${segment.taskSegmentId}`, type: ValidationType.ERROR, content: "An error occurred while saving the segment.", inputId: segment.taskSegmentId};
        try {
            setAreSegmentsSync(false);
            const updateInfo = {target: targetContent, status: segment.status};
            await updateSegment({id: segment.id, updateInfo}).unwrap();
        } catch (error: any) {
            if (typeof error === 'object' && error !== null && 'data' in error && typeof error.data === 'object' && 'error' in error.data) {
                setSegmentValidations([{...genericError, content: error.data.error}])
            } else {
                setSegmentValidations([genericError])
            }
        } finally {
            if(segment.repetitionData.isRepetition && segment.repetitionData.isFirstOccurrence){
                onUpdateFirstOccurrence(segment.taskSegmentId, originalContent, editorState.getCurrentContent().getPlainText());
            }
            setAreSegmentsSync(true);
        }
    }

    const handleFocus = () => {
        const currentContent = editorState.getCurrentContent().getPlainText();
        setOriginalContent(currentContent);
    };


    const handleBlur = async () => {
        const target = editorState.getCurrentContent().getPlainText();
        if (target !== originalContent) {
            return await synchronizeSegment(target);
        }
    };

    const phRegexStrategy = (block: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState): void => {
        const text = block.getText();
        let matchArr: RegExpExecArray | null;
        let start: number;

        while ((matchArr = PH_REGEX.exec(text)) !== null) {
            start = matchArr.index;
            callback(start, start + matchArr[0].length);
        }
    };

    const decorator = new CompositeDecorator([
        {
            strategy: phRegexStrategy,
            component: (props) => {
                let ph: Placeholder | undefined = segment.placeholders.find(ph => `{${ph.tagId}}` === props.decoratedText);
                return <PlaceholderBadge placeholderObj={ph} children={props.children} {...props}/>
            }
        },
    ]);

    const [editorState, setEditorState] = useState(() => {
        const content = ContentState.createFromText(segment.target);
        return EditorState.createWithContent(content, decorator);
    });

    useEffect(() => {
        validateSegment();
    }, [editorState]);

    useEffect(() => {
        if(!editorState.getSelection().getHasFocus()){
            setEditorState(prevState => {
                const content = ContentState.createFromText(segment.target);
                return EditorState.createWithContent(content, prevState.getDecorator());
            })
        }
    },[segment.target])

    const validateSegment = (): void => {
        let newValidations: Validation[] = [];
        let placeholdersValidation = validateTranslationPlaceholders();
        if(!placeholdersValidation){
            let lengthValidation = validateSegmentLength();
            if(lengthValidation){
                newValidations = [lengthValidation];
            }
        }else{
            newValidations = [placeholdersValidation];
        }
        setSegmentValidations(newValidations);
    }

    const validateTranslationPlaceholders = (): Validation | null => {
        if (segment.type === SegmentType.NON_TRANSLATABLE) return null;
        const originalCount = countPlaceholders(segment.source);
        const translatedCount = countPlaceholders(editorState.getCurrentContent().getPlainText());

        if (originalCount !== translatedCount) {
            return {location:`Segment ${segment.taskSegmentId}`, type:ValidationType.ERROR, content:`Invalid placeholder count: Expected ${originalCount}, found ${translatedCount}`, inputId: segment.taskSegmentId};
        }
        let sourcePhs: RegExpMatchArray | null = segment.source.match(/\{\d+\}/g);
        if(sourcePhs){
            let targetPhs: string[] | undefined = editorState.getCurrentContent().getPlainText().match(/\{\d+\}/g)?.map(ph => ph.toString());
            let sourcePhsNotFoundInTarget = sourcePhs.filter(sourcePh => !targetPhs?.includes(sourcePh));
            if(sourcePhsNotFoundInTarget.length > 0){
                return {location:`Segment ${segment.taskSegmentId}`, type:ValidationType.ERROR, content:`Placeholders missing in translation: ${sourcePhsNotFoundInTarget.join(',')}`, inputId: segment.taskSegmentId};
            }else{
                return null;
            }
        }
        return null;
    }

    function countPlaceholders(text: string) {
        if (!text) return 0;
        const matches = text.match(/\{\d+\}/g);
        return matches ? matches.length : 0;
    }

    const validateSegmentLength = (): Validation | null => {
        let content: string = editorState.getCurrentContent().getPlainText();
        let subtitlesContent: string[] = content.split(/{\d+}/).filter(s => s.trim() !== '');

        for(const subContent of subtitlesContent){
            if(!validateSubtitleLength(subContent, maxLines, maxCharsPerLine)){
                return{
                    location: `Segment ${segment.taskSegmentId}`,
                    type: ValidationType.WARNING,
                    content: `Invalid subtitle size. Max Lines: ${maxLines} - Max characters per line: ${maxCharsPerLine}`,
                    inputId: segment.taskSegmentId
                };
            }
        }
        return null;
    }

    // The parent component will change the value of this property when the target needs to be cleared.
    useEffect(() => {
        if(clearOnChange > 0){
            setEditorState(() => {
                let onlySourcePhs = segment.source.match(PH_REGEX);
                let cleanContent = onlySourcePhs ? onlySourcePhs.join('') : '';
                onTextChange(cleanContent, segment.id);
                return EditorState.createWithContent(ContentState.createFromText(cleanContent), decorator)
            })
            setNeedsSync(true);
        }
    }, [clearOnChange]);

    const focusEditor = () => {
        editorRef.current?.focus();
    };

    const isCursorInsideTag = (inputString: string, cursorPosition: number): boolean => {
        let tagRegex = /^\{\d+}$/g;
        let previousCurlyBrace: number = inputString.lastIndexOf("{",cursorPosition);
        let nextCurlyBrace: number = inputString.indexOf("}",cursorPosition);

        if(nextCurlyBrace < 0 || previousCurlyBrace < 0) return false;
        // The cursor is right before the open curly brace
        if(cursorPosition === previousCurlyBrace) return false;

        // Check if both indices form a tag
        let possibleTag = inputString.substring(previousCurlyBrace, nextCurlyBrace+1);
        return tagRegex.test(possibleTag)
    }
    const isCursorAfterTag = (inputString: string, cursorPosition: number): boolean => {
        let tagRegex = /.*?\{\d+}$/g;
        let textBeforeCursor = inputString.substring(0,cursorPosition);

        // Checks if text before cursor ends in {n}
        return tagRegex.test(textBeforeCursor);
    }

    const isCursorBeforeTag = (inputString: string, cursorPosition: number): boolean => {
        let tagRegex = /^\{\d+}.*?/g;
        let textAfterCursor = inputString.substring(cursorPosition);

        // Checks if a text after cursor starts in {n}
        return tagRegex.test(textAfterCursor);
    }

    const deletePlaceholder = (currentEditorText: string, tagStartIndex: number, tagEndIndex: number, currentState: EditorState): EditorState => {
        const currentContent = currentState.getCurrentContent();
        const selectionState = currentState.getSelection();

        // Crea una nueva selección que encierra el placeholder
        const targetRange = selectionState.merge({
            anchorOffset: tagStartIndex,
            focusOffset: tagEndIndex + 1,
        });
        // Deletes placeholder using Modifier.removeRange
        const newContent = Modifier.removeRange(currentContent, targetRange, 'backward');

        // Creates a new EditorState with new ContentState
        const newState = EditorState.push(currentState, newContent, 'remove-range');

        // Moves cursor at the initial position of the removed placeholder
        const newSelection = targetRange.merge({
            anchorOffset: tagStartIndex,
            focusOffset: tagStartIndex,
        });
        return EditorState.forceSelection(newState, newSelection);
    }

    const handleKeyCommand = (command: string, currentState: EditorState): HandleResult => {
        let blockKey: string = currentState.getSelection().getStartKey();

        if (command == EditorCommands.BACKSPACE) {
            let cursorPosition = currentState.getSelection().getAnchorOffset();
            let currentEditorText = currentState.getCurrentContent().getBlockForKey(blockKey).getText();
            if(isCursorAfterTag(currentEditorText, cursorPosition)){
                //Locate placeholder
                let tagEndIndex = currentEditorText.lastIndexOf("}",cursorPosition);
                let tagStartIndex = currentEditorText.lastIndexOf("{",tagEndIndex);

                let newState = deletePlaceholder(currentEditorText,tagStartIndex,tagEndIndex,currentState);

                setEditorState(newState);
                return HandleResult.HANDLED;
            }
            if (isCursorInsideTag(currentEditorText, cursorPosition)){
                return HandleResult.HANDLED;
            }
        }
        if(command === EditorCommands.DELETE){
            let cursorPosition = currentState.getSelection().getAnchorOffset();
            let currentEditorText = currentState.getCurrentContent().getBlockForKey(blockKey).getText();
            if(isCursorBeforeTag(currentEditorText, cursorPosition)){
                //Locate placeholder
                let tagStartIndex = currentEditorText.indexOf("{",cursorPosition);
                let tagEndIndex = currentEditorText.indexOf("}",tagStartIndex);

                let newState = deletePlaceholder(currentEditorText, tagStartIndex, tagEndIndex, currentState);

                setEditorState(newState);

                return HandleResult.HANDLED
            }
            if(isCursorInsideTag(currentEditorText, cursorPosition)){
                return HandleResult.HANDLED;
            }
        }
        const newState = RichUtils.handleKeyCommand(currentState, command);
        if (newState) {
            setEditorState(newState);
            return HandleResult.HANDLED;
        }
        return HandleResult.NOT_HANDLED;
    };

    const onEditorChange = (tempState: EditorState) => {
        let newState: EditorState;
        let cursorPosition = tempState.getSelection().getAnchorOffset();

        let blockKey: string = tempState.getSelection().getStartKey();
        // Get the block based on the key
        let currentBlockText = tempState.getCurrentContent().getBlockForKey(blockKey).getText();

        if(isCursorInsideTag(currentBlockText,cursorPosition)){
            // The cursor is right before the closing curly brace
            if(cursorPosition === currentBlockText.indexOf("}",cursorPosition)){
                newState = moveCursorBeforeTag(tempState, cursorPosition, blockKey);
            } else {
                newState = moveCursorAfterTag(tempState, cursorPosition, blockKey);
            }
        }else {
            newState = tempState;
        }
        setEditorState(newState);
        const plainText = newState.getCurrentContent().getPlainText();
        onTextChange(plainText, segment.id);
    };

    const moveCursorBeforeTag = (currentState: EditorState, currentCursor: number, blockKey: string): EditorState => {
        // Last open curly brace before cursor
        const newPosition = currentState.getCurrentContent().getPlainText().lastIndexOf("{",currentCursor);

        const selectionState = SelectionState.createEmpty(blockKey);
        const newSelection = selectionState.merge({anchorOffset: newPosition, focusOffset: newPosition})

        return EditorState.forceSelection(currentState, newSelection);
    }
    const moveCursorAfterTag = (currentState: EditorState, currentCursor: number, blockKey: string): EditorState => {
        // First closing curly brace after cursor
        const newPosition = currentState.getCurrentContent().getPlainText().indexOf("}",currentCursor)+1;

        const selectionState = SelectionState.createEmpty(blockKey);
        const newSelection = selectionState.merge({anchorOffset: newPosition, focusOffset: newPosition})

        return EditorState.forceSelection(currentState, newSelection);
    }

    const addSymbol = (symbol: string) => {
        if(isReadOnly) return;
        setEditorState(state => {
            let prevText = state.getCurrentContent().getPlainText();
            let decorator = state.getDecorator();
            let cursorPosition = state.getSelection().getAnchorOffset();

            //Add symbol
            let newText = prevText.substring(0, cursorPosition) + symbol + prevText.substring(cursorPosition);
            let newState = EditorState.createWithContent(ContentState.createFromText(newText), decorator);

            //Move cursor after symbol
            const selectionState = SelectionState.createEmpty(newState.getCurrentContent().getFirstBlock().getKey());
            const newSelection = selectionState.merge({anchorOffset: cursorPosition+1, focusOffset: cursorPosition+1})
            return EditorState.forceSelection(newState,newSelection);
        })
    }

    const copySource = async (e:SyntheticEvent) => {
        e.preventDefault();
        if(isReadOnly) return;
        const contentState = ContentState.createFromText(segment.source);
        const newEditorState = EditorState.createWithContent(contentState, decorator);
        setEditorState(newEditorState);
        return await synchronizeSegment(segment.source);
    }

    function changeStatus(newStatus: SegmentStatus, comment?: string) {
        if (newStatus === segment.status) return;
        setAreSegmentsSync(false);
        let updateInfo;
        if(comment){
            updateInfo = {status: newStatus, updateComment: comment};
        }else {
            updateInfo = {status: newStatus};
        }
        updateSegment({id: segment.id, updateInfo})
            .unwrap()
            .then(() => setAreSegmentsSync(true));

        onChangeStatus(newStatus, segment.id, segment.target);
    }

    return (
        <TargetEditorContainer>
            <TextEditorContainer isBlocked={isReadOnly} id={`target-input-${segment.taskSegmentId}`} onClick={focusEditor} >
                <Editor
                    ref={editorRef}
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    onChange={onEditorChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    readOnly={isReadOnly}
                />
            </TextEditorContainer>
            <ButtonsContainer>
                <TargetEditorButtons segment={segment} addSymbolAndBlur={addSymbol} copySource={copySource} changeSegmentStatus={changeStatus}/>
            </ButtonsContainer>
        </TargetEditorContainer>
    );
};
export default TargetEditor;