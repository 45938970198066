import React from "react";
import PolicyTemplate from './../components/policies/PolicyTemplate';
import {SUPPORT_EMAIL} from '../utils/constants'

const termsContent = [
  {
    heading: "Terms and Conditions",
    text: "By accessing or using our web tool, you agree to these Terms and Conditions. If you do not agree to these terms, please do not use Underline."
  },
  {
    heading: "Use of the Web Tool",
    text: "Underline is provided for personal or commercial use, subject to these Terms and Conditions. You agree to use the web tool only for lawful purposes and in accordance with these terms. You may not use Underline for any illegal or unauthorized purpose."
  },
  {
    heading: "Intellectual Property Rights",
    text: "The content and materials available on Underline, including but not limited to text, graphics, logos, images, and software, are owned or licensed by Underline and are protected by copyright and other intellectual property laws. You may not copy, reproduce, distribute, or create derivative works of the content or materials without our express written consent."
  },
  
  {
    heading: "User Conduct",
    text: "You agree not to use Underline to:",
    listItems: [
      "Harass, intimidate, or bully others",
      "Violate any applicable laws or regulations",
      "Post or transmit any content that is defamatory, obscene, or offensive",
      "Introduce viruses or other harmful code",
      "Attempt to gain unauthorized access to our servers or networks"
    ]
  },
  {
    heading: "Disclaimer of Warranties",
    text: 'Underline is provided on an "as-is" basis, without warranties of any kind, either express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not warrant that the web tool will meet your requirements or that it will be uninterrupted or error-free.'
  },
  {
    heading: "Indemnification",
    text: "You agree to indemnify and hold harmless Underline and its affiliates, officers, agents, and employees from any claims, damages, or liabilities arising out of your use of the web tool or your violation of these Terms and Conditions."
  },
  {
    heading: "Modification of Terms",
    text: "We reserve the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting on this page. Your continued use of Underline after any changes to these Terms and Conditions will indicate your acceptance of the changes."
  },
  {
    heading: "Termination",
    text: "We reserve the right to terminate your access to Underline at any time, with or without cause, and without prior notice."
  },
  {
    heading: "Governing Law",
    text: "These Terms and Conditions are governed by and construed in accordance with the laws of the Delaware, US jurisdiction. Any disputes arising out of or related to these terms will be resolved in the court Delaware, US."
  },
  {
    heading: "Contact Us",
    text: `If you have any questions or concerns about these Terms and Conditions or the use of our web tool, please contact us at ${SUPPORT_EMAIL}.`
  }
];

const TermsConditions: React.FC = () => {
  
  return (
    <>
      <PolicyTemplate policyContent={termsContent} />      
    </>
  );
}

export default TermsConditions;
