import React from "react";
import styled from 'styled-components';
import logo from "../../assets/img/logo-gray-mustard-transparent.webp";
import {DEFINED_ROUTES} from '../../utils/constants'
import {useLocation} from "react-router-dom";

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2d292a;
  margin-top: -12px;

  @media (max-width: 1920px) {
    margin-top: -1px;
  }
  @media (max-width: 1440px) {
    margin-top: -5.5px;
  }
  @media (max-width: 1024px) {
    margin-top: -8.5px;
  }
  @media (max-width: 1000px) {
    margin-top: -20px;
    padding-bottom: 50px;
  }
  @media (max-width: 767px) {
    padding-top: 20px;
  }
`
const Footer = styled.div<{noPadding: boolean}>`
  margin-top: ${props => props.noPadding ? '38px' : '60px'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 30px;
  
  @media (max-width: 400px) {
    padding: 0;
  }
`;
const FooterTextContainer = styled.div`
  text-align: right;
  width: 100%;

  @media (max-width: 1210px) {
    text-align: center;
  }
`;
const SinapiText = styled.p`
  font-size: 14px;
  margin-left: 8px;
  display: inline;
  color: #FFFFFF;

  @media (max-width: 1210px) {
    font-size: 10px;
    margin-left: 2px;
  }
  @media (max-width: 1152px) {
    display: block; 
    margin: 0 auto;
    font-size: 20px;
  }
  @media (max-width: 560px) {
    font-size: 10px;
  }
`;
const FooterText = styled.p`
  font-weight: 300;
  justify-content: flex-end;
  margin-right: 20px;

  @media (max-width: 1152px) {
    text-align: center;
  }
  @media (max-width: 400px) {
    margin-right: 5px;
  }
`;
const FooterLink = styled.a`
  margin-left: 8px;
  text-decoration: none;
  color: #FFFFFF;
  cursor: pointer;
  
  @media (max-width: 1210px) {
    margin-left: 2px;
    font-size: 10px;
  }
  @media (max-width: 1152px) {
    display: inline-block;
    font-size: 20px;
  }
  @media (max-width: 560px) {
    margin-left: 2px;
    font-size: 10px;
  }
  
  &:visited {
    color: #FFFFFF;
  }
  &:hover {
    color: #EAC113;
  }
`;
const FooterTopRow = styled.div`
  display: flex; 
  width: 100%;
  justify-content: space-between;
`;
const DividerLine = styled.div`
  width: 800px;
  height: 2px;
  border-top: 0.5px #EAC113 solid;
  margin-left: 20px;

  @media (max-width: 1570px) {
    width: 600px;
  }
  @media (max-width: 1180px) {
    width: 450px;
  }
  @media (max-width: 1080px) {
    width: 600px;
  }
  @media (max-width: 985px) {
    width: 500px;
  }
  @media (max-width: 800px) {
    width: 350px;
  }
  @media (max-width: 580px) {
    width: 150px;
  }
  @media (max-width: 380px) {
    width: 120px;
  }
`;
const LeftGroup = styled.div`
  display: flex;
  align-items: center;
`;
const RightGroup = styled.div`
  display: flex;
  align-items: center;
`;
const LogoImage = styled.img`
  height:60px;

  @media (max-width: 560px) {
    height:35px;
  }
`;
const UnderlineText = styled.p`
  font-size: 26px;
  text-transform: lowercase;
  margin-left: 15px;
  color: #fff;

  @media (max-width: 1152px) {
    font-size: 40px;
  }

  @media (max-width: 560px) {
    font-size: 16px;
  }
`;
const PipeSeparator = styled.span`
  margin-left: 8px;
  
  @media (max-width: 1210px) {
    font-size: 10px;
    margin-left: 2px;
  }
`;
const THIS_YEAR = new Date().getFullYear();
const LandingFooter: React.FC= () => {
    const { pathname } = useLocation();
    const isRouteDefined = DEFINED_ROUTES.some(route =>pathname === route);
    const isPageNotFound = !isRouteDefined;
    const handleNavigateToTerms = (event: React.MouseEvent) => {
        event.preventDefault();
        window.open("/terms-and-conditions", "_blank");
    };

    const handleNavigateToPrivacy = (event: React.MouseEvent) => {
        event.preventDefault();
        window.open("/privacy-policy", "_blank");
    };
    return(
        <FooterContainer>
            <Footer noPadding={isPageNotFound}>
                <FooterTopRow>
                    <LeftGroup>
                        <LogoImage src={logo} alt="Underline logo" title={"underline-logo"}/>
                        <UnderlineText>underline</UnderlineText>
                    </LeftGroup>
                    <RightGroup>
                        <DividerLine/>
                    </RightGroup>
                </FooterTopRow>

                <FooterTextContainer>
                    <FooterText>
                        <SinapiText>© Copyright {THIS_YEAR} · All rights reserved</SinapiText>
                        <PipeSeparator>|</PipeSeparator>
                        <SinapiText>This product is owned by</SinapiText>
                        <FooterLink href='https://www.thesinapiteam.com' target="_blank" rel="noopener noreferrer">SINAPI LLC</FooterLink>
                        <PipeSeparator>|</PipeSeparator>
                        <FooterLink href='https://www.thesinapiteam.com/iso-certifications/' target="_blank" rel="noopener noreferrer">ISO Certifications</FooterLink>
                        <PipeSeparator>|</PipeSeparator>
                        <FooterLink href='https://www.thesinapiteam.com/quality-policy/' target="_blank" rel="noopener noreferrer">Quality Policy</FooterLink>
                        <PipeSeparator>|</PipeSeparator>
                        <FooterLink onClick={handleNavigateToTerms}>Terms of Use</FooterLink>
                        <PipeSeparator>|</PipeSeparator>
                        <FooterLink onClick={handleNavigateToPrivacy}>Privacy Policy</FooterLink>
                    </FooterText>
                </FooterTextContainer>
            </Footer>
        </FooterContainer>
    )
}
export default LandingFooter