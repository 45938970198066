import {underlineApi} from "./underlineApi";
import {Segment} from "./segmentsApiSlice";

interface UploadBilingualRequest{
    file: File;
    taskId: number;
}

interface UpdateSegmentsRequest {
    taskId: number;
    segments: Array<{ id: string; target: string; }>;
}
interface TaskSegmentsResponse {
    segments: Segment[];
}

interface SubtitleResponse {
    id: number;
    start: string;
    end: string;
    content: string;
}

export const tasksApiSlice = underlineApi.injectEndpoints({
    endpoints: (builder) => ({
        downloadBilingual: builder.mutation<{ blob: Blob, status: number }, number>({
            query: (taskId) => ({
                url: `/tasks/${taskId}/download-bilingual`,
                method: 'GET',
                responseHandler: async (response) => {
                    const blob = await response.blob();
                    return { blob, status: response.status };
                },
            }),
            invalidatesTags: ['AllProjects'],
        }),
        downloadTarget: builder.mutation<Promise<Blob>, number>({
            query: (taskId) => ({
                url: `/tasks/${taskId}/download-target`,
                method: 'GET',
                responseHandler: (response) => response.blob()
            }),
            invalidatesTags: ['AllProjects'],
        }),
        uploadBilingual: builder.mutation<Promise<Blob>, UploadBilingualRequest>({
            query: ({taskId, file}) => {
                let formData= new FormData();
                const fileWithCorrectMimeType = new File([file], file.name, {
                    type: "application/x-xliff+xml",
                });
                formData.append("file ", fileWithCorrectMimeType);
                return {
                    url: `/tasks/${taskId}/update-bilingual`,
                    method: 'PUT',
                    formData: true,
                    body: formData,
                    responseHandler: (response) => response.json()
                }
            },
            invalidatesTags: ['Segment'],
        }),
        completeTask: builder.mutation<void, number>({
            query:(taskId)=>({
                url: `/tasks/${taskId}/complete-task`,
                method: 'PATCH',    
            }),
            invalidatesTags: ['Workspaces', 'AllProjects'],
        }),
        editTask: builder.mutation({
            query: ({ taskId, updates }) => {
                let body = JSON.stringify(updates);
                return {
                    url: `/tasks/${taskId}`,
                    method: 'PATCH',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            },
            invalidatesTags: ['AllProjects'],
        }),
        getTaskSegments: builder.query<TaskSegmentsResponse, number>({
            query: (taskId) => ({
                url: `/tasks/${taskId}/segments`,
                method: 'GET',
            }),
            providesTags: ['AllProjects']
        }),
        updateTaskSegments: builder.mutation<void, UpdateSegmentsRequest>({
            query: ({ taskId, segments }) => {
                const body = JSON.stringify(segments);
                return {
                    url: `/tasks/${taskId}/segments`,
                    method: 'PUT',
                    body,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
            },
            invalidatesTags: ['AllProjects', 'Segment'],
        }),
        previewTarget: builder.mutation<SubtitleResponse[], UpdateSegmentsRequest>({
            query: ({ taskId, segments }) => {
                const body = JSON.stringify(segments);
                return {
                    url: `/tasks/${taskId}/preview-target`,
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    responseHandler: async (response) => {
                        return await response.text();
                    },
                };
            },
        }),
    })
});
export const {
    useDownloadBilingualMutation,
    useDownloadTargetMutation,
    useUploadBilingualMutation,
    useCompleteTaskMutation,
    useEditTaskMutation,
    useGetTaskSegmentsQuery,
    useUpdateTaskSegmentsMutation,
    usePreviewTargetMutation,
} = tasksApiSlice;