import {getUrlWithPaginationProps, PageProps, PaginationParams, underlineApi} from "./underlineApi";
import * as url from "url";
import {UserOwnerInfo} from "./usersApiSlice";

export interface NewProjectData {
    name: string;
    description?: string;
    assignee?: string;
    filter: string;
    sourceLocale: string;
    targetLocales: string[];
    dueDate: string;
    file: File | null;
    workspace: string;
    maxLines: number | null;
    maxCharactersPerLine: number | null;
}

export interface ProjectInfo {
    id: number;
    name: string;
    description: string;
    location: string;
    creator: UserOwnerInfo;
    workspaceId: string;
    assignee: UserOwnerInfo;
    defaultFilter: string;
    sourceLocale: string;
    targetLocales: string[];
    jobs: JobInfo[];
    creationDate: string;
    lastModifiedDate: string;
    dueDate: string;
    status: string;
    maxLines: number;
    maxCharactersPerLine: number;
}

interface ProjectsResponse extends PageProps {
    content: ProjectInfo[];
}
interface FilteredProjectsParams extends PaginationParams {
    filter?: string;
}
interface FilteredProjectsByColumnParams extends PaginationParams {
    searchCriteria: string;
}
export interface JobInfo {
    id: number,
    targetLocale: string,
    status: string,
    workspace: string,
    assignee: UserOwnerInfo,
    defaultFilter: string,
    tasks: TaskInfo[],
}

export interface TaskInfo {
    id: number,
    wordCount: number,
}

interface ProjectsCount {
    IN_PROGRESS: number,
    TOTAL: number,
    COMPLETED: number,
    NEW: number
}
export interface SourceFile{
    id: string;
    name: string;
    lastModify: string;
    modifiedBy: {
        username: string;
    };
}
interface SourceFilesResponse {
    content: SourceFile[];
}
export const projectsApiSlice = underlineApi.injectEndpoints({
    endpoints: (builder) => ({
        createProject: builder.mutation<void, NewProjectData>({
            query: (projectData) => {
                let formData = new FormData();
                formData.append('name', projectData.name);
                formData.append('description', projectData.description ?? '');
                formData.append('assignee', projectData.assignee ?? '');
                formData.append('workspaceId', projectData.workspace);
                formData.append('filter', projectData.filter);
                formData.append('sourceLocale', projectData.sourceLocale);
                formData.append('targetLocales', projectData.targetLocales.join(','));
                formData.append('dueDate', projectData.dueDate);
                formData.append('file', projectData.file ?? '');
                if(projectData.maxLines){
                    formData.append('maxLines', projectData.maxLines.toString());
                }

                if(projectData.maxCharactersPerLine){
                    formData.append('maxCharactersPerLine', projectData.maxCharactersPerLine.toString());
                }

                return {
                    url: '/projects',
                    method: 'POST',
                    body: formData,
                    formData: true
                }
            },
            invalidatesTags: ['Instance', 'AllProjects'],
        }),
        getProjects: builder.query<ProjectsResponse, PaginationParams & Partial<FilteredProjectsParams & FilteredProjectsByColumnParams>>({
            query: ({ page, size, sortBy, sortDir, filter, searchCriteria }) => {
                let url = getUrlWithPaginationProps('/projects/all', { page, size, sortBy, sortDir });
                if (filter) {
                    url += `&filter=${filter}`;
                }
                if (searchCriteria) {
                    url += `&${searchCriteria}`;
                }
                return {
                    url: url,
                    method: 'GET'
                }
            },
            providesTags: ['AllProjects'],
        }),
        getOneProject: builder.query<ProjectInfo, string>({
            query: (projectId) => {
                return {
                    url: `/projects/${projectId}`,
                    method: 'GET'
                }
            },
            providesTags: ['AllProjects'],
        }),
        countProjects: builder.query<ProjectsCount, void>({
            query: () => ({
                url: '/projects/count',
                method: 'GET'
            }),
            providesTags: ['AllProjects'],
        }),

        editProject: builder.mutation({
            query: ({ projectId, updates }) => {
                let body = JSON.stringify(updates);
                return {
                    url: `/projects/${projectId}`,
                    method: 'PATCH',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            },
            invalidatesTags: ['AllProjects'],
        }),
        getSourceFiles: builder.query<SourceFilesResponse, string>({
            query: (projectId) => {
                return {
                    url: `/projects/${projectId}/source-files`,
                    method: 'GET'
                }
            },
            providesTags: ['AllProjects'],
        }),
        downloadSource: builder.mutation<Promise<Blob>, string>({
            query: (assetId) => ({
                url: `/assets/${assetId}/download`,
                method: 'GET',
                responseHandler: (response) => response.blob()
            }),
        }),
        updateSource: builder.mutation<void, { projectId: string, assetId: string, data: string }>({
            query: ({projectId, assetId, data }) => {
                return {
                    url: `/projects/${projectId}/update-source/${assetId}`,
                    method: 'PUT',
                    body: data,
                    headers: {
                        'Content-Type': 'text/plain'
                    },
                }
            },
            invalidatesTags: ['AllProjects'],
        }),
    }),

})

export const {
    useCreateProjectMutation,
    useGetProjectsQuery,
    useGetOneProjectQuery,
    useCountProjectsQuery,
    useEditProjectMutation,
    useGetSourceFilesQuery,
    useDownloadSourceMutation,
    useUpdateSourceMutation,
} = projectsApiSlice;