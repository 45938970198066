import React, { ChangeEvent } from 'react'
import styled from 'styled-components';

const FormInput = styled.input<{ width: number, marginLeft: number, height: number}>`
  width: ${({ width }) => width}px;
  margin-left: ${({ marginLeft }) => marginLeft}px;  
  padding: 5px 0 5px 10px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 15px;
  height: ${({ height }) => height}px;
  &:focus {
    border:  1px solid #aaa;
    outline: none;
  }
  &[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
    &::-ms-clear {
      display: none;
    }
  }
`;

interface UserFormInputProps{
  id?: string;
  type: string;
  value?: string;
  placeholder?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  width?: number;
  min?: string | number
  marginLeft?: number;
  height?: number;
}
const ActionFormInput: React.FC<UserFormInputProps> = ({id, type, value, placeholder, onChange, width=200, min, marginLeft=0, height=23}) => {
  return (
    <div>
      <FormInput id={id} type={type} value={value} placeholder={placeholder} onChange={onChange} width={width}  min={min} marginLeft={marginLeft} height={height}></FormInput>
    </div>
  )
}
export default ActionFormInput