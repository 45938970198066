import React, {useState, ChangeEvent} from "react";
import styled from "styled-components";
import ActionFormTitle from "../ui/forms/action/ActionFormTitle";
import FormLabel from "../ui/forms/action/ActionFormLabel";
import SlimButton from "../ui/forms/action/SlimButton";
import InvalidFeedback from "../ui/forms/InvalidFeedback";
import SelectOption from "../ui/forms/action/SelectOption";
import PopUp from "../ui/forms/SuccessPopup";
import {useEditProjectMutation} from "../../services/projectsApiSlice";
import {useEditJobMutation} from "../../services/jobsApiSlice";
import {useEditTaskMutation} from "../../services/tasksApiSlice";
import {BsPersonSquare} from "react-icons/bs";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;
const FormContainer = styled.div`
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 470px;
`;
const FormBody = styled.div`
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
`;
const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;
const ButtonsContainer = styled.div`
  margin-top: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
`;
interface ChangeAssigneeFormProps {
    handleClose: () => void;
    memberOptions: Array<{ value: string; label: string }>;
    id: number;
    type: string;
    onAssigneeChanged?: () => void;
}
const ChangeAssigneeForm: React.FC<ChangeAssigneeFormProps>= ({memberOptions, id, handleClose, type,onAssigneeChanged }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [newAssignee, setNewAssignee] = useState("");
    const [showNewAssigneeConfirmationPopup, setShowNewAssigneeConfirmationPopup] = useState(false);
    const [editProject] = useEditProjectMutation({});
    const [editJob] = useEditJobMutation({});
    const [editTask] = useEditTaskMutation({});

    const handleChangeAssignee = (event: ChangeEvent<HTMLSelectElement>) => {
        setNewAssignee(event.target.value);
    };
    const handleChangeNewAssignee = async () => {
        try {
            await executeChangeAssignee(id, newAssignee);
            setShowNewAssigneeConfirmationPopup(false);
            handleClose();
            if(onAssigneeChanged){
                onAssigneeChanged();
            }
        } catch (err) {
            setErrorMessage("Error");
            setShowNewAssigneeConfirmationPopup(false);

            console.error("Changing assignee error: ", err);
        }
    };
    async function executeChangeAssignee(id: number, assignee: string) {

        switch(type) {
            case "project":
                await editProject({ projectId: id, updates: { assignee: assignee } });
                break;
            case "job":
                await editJob({ jobId: id, updates: { assignee: assignee } });
                break;
            case "task":
                await editTask({ taskId: id, updates: { assignee: assignee } });
                break;
            default:
                console.error(`Invalid type: ${type}`);
        }
    }


    return (
        <>
            <Container>
                <FormContainer>
                    <ActionFormTitle title="Change Assignee"/>
                    <FormBody>
                        <FormRow>
                            <FormColumn>
                                <FormLabel
                                    icon={BsPersonSquare}
                                    text="Assignee"
                                />
                                <SelectOption
                                    id={`${type}_assignee_select`}
                                    value={newAssignee}
                                    onChange={handleChangeAssignee}
                                    options={memberOptions}
                                />
                            </FormColumn>

                        </FormRow>

                        {errorMessage && <InvalidFeedback errorMessage={errorMessage}/>}
                        <ButtonsContainer>
                            <SlimButton
                                id={`${type}_change_assignee_cancel_button`}
                                type="reset"
                                bgColor="#c71313"
                                text="Cancel"
                                textColor="#fff"
                                onClick={handleClose}
                                bgGradient="linear-gradient(to right, #a11212, #c71313, #a11212)"
                            />
                            <SlimButton
                                id={`${type}_change_assignee_submit_button`}
                                type="button"
                                bgColor="#eac113"
                                text="Save changes"
                                textColor="#2d292a"
                                onClick={()=> setShowNewAssigneeConfirmationPopup(true)}
                                bgGradient="linear-gradient(to right, #eac113, #ffeb3b, #eac113)"
                            />
                        </ButtonsContainer>
                    </FormBody>
                </FormContainer>
                {showNewAssigneeConfirmationPopup && (
                    <PopUp
                        successMessage={'You are about to change Assignee'}
                        instructions="Are you sure?"
                        confirmationMessage="Yes!"
                        handleConfirm={handleChangeNewAssignee}
                        handleClosePopup={handleChangeNewAssignee}
                        handleCancel={()=>setShowNewAssigneeConfirmationPopup(false)}
                        cancelMessage="No!"
                    />
                )}
            </Container>
        </>
    );
};
export default ChangeAssigneeForm;