export const FILTER_OPTIONS = [
    { value: 'sentence', label: 'Sentence'},
    { value: 'subtitle', label: 'Subtitle'},
];
export const LANGUAGE_OPTIONS = [
    { value: 'en-US', label: 'English' },
    { value: 'zh-CN', label: 'Chinese Simplified' },
    { value: 'fr-FR', label: 'French' },
    { value: 'de-DE', label: 'German' },
    { value: 'ja-JP', label: 'Japanese' },
    { value: 'pt-BR', label: 'Brazilian Portuguese' },
    { value: 'es-ES', label: 'Spanish (Castilian)' },
    { value: 'es-LA', label: 'Spanish (Latin America)' },
    { value: 'it-IT', label: 'Italian' },
    { value: 'ko-KR', label: 'Korean' },
    { value: 'ru-RU', label: 'Russian' },
    { value: 'zh-TW', label: 'Traditional Chinese' },
    { value: 'fr-CA', label: 'French (Canada)' },
    { value: 'cs-CZ', label: 'Czech' },
    { value: 'da-DK', label: 'Danish' },
    { value: 'fi-FI', label: 'Finnish' },
    { value: 'hu-HU', label: 'Hungarian' },
    { value: 'he-IL', label: 'Hebrew' },
    { value: 'nl-NL', label: 'Dutch' },
    { value: 'nb-NO', label: 'Norwegian' },
    { value: 'sv-SE', label: 'Swedish' },
    { value: 'tr-TR', label: 'Turkish' },
];
export const CONVERSION_OPTIONS = [
    { value: 'SRT to VTT', label: 'SRT to VTT'},
    { value: 'VTT to SRT', label: 'VTT to SRT'},
];
export const  PAGE_SIZE_OPTIONS = [
    { value: 5, label: '5'},
    { value: 10, label: '10'},
    { value: 25, label: '25'},
];
export const ROLE_OPTIONS = [
    {value: "ROLE_ADMIN", label: "Admin"},
    {value: "ROLE_OWNER", label: "Owner"},
    {value: "ROLE_USER", label: "User"},
];
export type USER_ROLE_TYPE = "ROLE_ADMIN" | "ROLE_OWNER" | "ROLE_USER";
export const ROLE_HIERARCHY: Record<USER_ROLE_TYPE, number> = {
    ROLE_ADMIN: 3,
    ROLE_OWNER: 2,
    ROLE_USER: 1,
};
export function FORMAT_ROLE_HIERARCHY (role: USER_ROLE_TYPE): string {
    return ROLE_OPTIONS.find(option => option.value === role)?.label || role;
}
export const FORMAT_ROLE = (role: USER_ROLE_TYPE) => {
    switch (role) {
        case "ROLE_ADMIN":
            return "Administrator";
        case "ROLE_OWNER":
            return "Owner";
        case "ROLE_USER":
            return "User";
        default:
            return "User";
    }
};
export const MONTHS = ['Jan.', 'Feb.', 'Mar.', 'Abr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'];
export const COLORS = ['#36a2eb', '#ff6384', '#0ef7f7', '#ffe608', '#9966ff', '#fa9b0d', '#15da43', '#412df7', '#ec1c49', '#d5f80e', '#d119d1', '#76fdb3'];
export const INSTANCE_HEADER = "INSTANCE_ID";
export const INSTANCE_QUERY_PARAM = "instanceId";
export const SESSION_TOKEN_COOKIE = 'UNDERLINE-TOKEN';
export const UNDERLINE_VERSION = '1.5';
export const MAX_UPLOAD_FILE_SIZE= 50 * 1024 * 1024; //50mb in bytes
export const SEARCH_COLUMN_OPTIONS = [
    { value: "id", label: "ID" },
    { value: "name", label: "Project Name" },
    { value: "sourceLocale", label: "Source" },
    { value: "targetLocale", label: "Target Language" },
    { value: "creator", label: "Creator" },
    { value: "assignee", label: "Assignee" },
    { value: "status", label: "Status" },
];
export const STATUS_COLUMN_OPTIONS = [
    { value: "NEW", label: "New" },
    { value: "IN_PROGRESS", label: "In Progress" },
    { value: "COMPLETED", label: "Completed" },
];

export const SUBSCRIPTION_PLANS = [
    {
        planName: 'Free',
        backgroundColor: '#0fcf7b, #0c9f30',
        features: [
            'Projects per Month: 3',
            'Targets per Project: 3',
            'Users per Instance: 2',
            'Number of Workspaces: 1',
        ],
        comingSoon: false,
    },
    {
        planName: 'Professional',
        backgroundColor: '#f7256e, #cc0c48',
        features: [
            'Projects per Month: 20',
            'Targets per Project: 5',
            'Users per Instance: 10',
            'Number of Workspaces: 2',
        ],
        comingSoon: true,
    },
    {
        planName: 'Premium',
        backgroundColor: '#f7ea1f, #f87d2c',
        features: [
            'Projects per Month: Unlimited',
            'Targets per Project: Unlimited',
            'Users per Instance: 50',
            'Number of Workspaces: 50',
        ],
        comingSoon: true,
    },
];
export const SUPPORT_EMAIL = 'udlsupport@udltool.com'
export const COOKIES_CONSENT_EXPIRATION_HOURS = 24;

export const DEFINED_ROUTES = [
    "/", "/login", "/register", "/forgot-password",
    "/terms-and-conditions", "/privacy-policy", "/account-confirmation",
    "/reset-password", "/invite/confirm", "/subtitles",
    "/home", "/about", "/administration", "/converter",
    "/subtitles-editor", "/source-editor/:projectId/:sourceId",
    "/help", "/new-project", "/profile", "/projects",
    "/project/:projectId", "/workspaces", "/subscription", "/logout",
    "/contact-us", "/translator/:id/:id",
];

export const ENDPOINTS_WITH_NO_AUTHENTICATION_NEEDED = [
    "login", "register", "changePassword", "accountConfirmation", "confirmInvitation", "sendForgotPasswordEmail"];