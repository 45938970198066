import { Middleware } from "redux";
import { clearTokens } from "./tokenSlice";
import {clearUserInfo} from "./userSlice";
import {clearCookieConsent} from "./cookieConsentSlice";
import {clearInstanceInfo} from "./instanceSlice";

const isTokenExpired = (expiresAt: string): boolean => {
    const expirationDate = new Date(expiresAt);
    return expirationDate < new Date();
};

export const tokenMiddleware: Middleware = ({ dispatch, getState }) => next => action => {
    if (action.type === clearTokens.type || action.type === clearUserInfo.type || action.type === clearCookieConsent.type || action.type === clearInstanceInfo.type) {
        return next(action);
    }

    const tokenInfo = getState().tokenInfo;
    if (tokenInfo.refreshToken && tokenInfo.refreshToken.expiresAt && isTokenExpired(tokenInfo.refreshToken.expiresAt)) {
        dispatch(clearTokens());
        dispatch(clearUserInfo(null));
        dispatch(clearCookieConsent());
        dispatch(clearInstanceInfo(null));
    }

    return next(action);
};



