import React from "react";
import styled from 'styled-components';
import {FaAngleDoubleRight, FaAngleDoubleDown} from 'react-icons/fa'
interface QuestionContainerProps {
    isActive?: boolean;
}
const QuestionContainer = styled.h4<QuestionContainerProps>`
    background: ${({ isActive }) => (isActive ? "#EAC113" : "#ddd")};
    border: 1px solid transparent;
    border-radius: 30px;
    display: block;
    padding: 12px 20px 12px 50px;
    position: relative;
    transition: all 0.3s ease 0s;
    text-decoration: none;
`;
interface QuestionIconContainerProps {
    isActive?: boolean;
}
const QuestionIconContainer = styled.div<QuestionIconContainerProps>`
    position: absolute;
    left: -20px;
    top: -5px;
    width: 55px;
    height: 55px;
    line-height: 55px;
    text-align: center;
    background: ${({ isActive }) => (isActive ? "#EAC113" : "#ddd")};
    border: 1px solid transparent;
    border-radius: 50%;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.58);
    transition: all 0.3s ease 0s;
`;

interface QuestionIconProps {
    isActive: boolean;
}
const QuestionIcon = styled.div<QuestionIconProps>`
    position: relative;
    top: 5px;
    left: 16px;
    width: 20px;
    height: 20px;
    color: #333;
    svg {
    width: 100%;
    height: 100%;
    }
`;
interface QuestionProps {
    isActive: boolean;
}
const Question = styled.a<QuestionProps>`
    color: ${({ isActive }) => (isActive ? "#fff" : "#333")};
    display: block;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    
    &:hover {
        color: ${({ isActive }) => (isActive ? "#fff" : "#333")};
    }
`;

interface FaqQuestionProps{
    questionNumber: string;
    collapseNumber: string;
    question: string;
    isActive: boolean;
    onToggle: () => void;
}

const FaqQuestion: React.FC<FaqQuestionProps> = ({questionNumber, collapseNumber, question, isActive, onToggle})=> {
    return (
        <QuestionContainer role="tab" id={questionNumber} isActive={isActive}>
            <Question
            role="button"
            data-toggle="collapse"
            data-parent="#accordion"
            href={collapseNumber}
            aria-expanded={isActive}
            aria-controls="collapseOne"
            onClick={onToggle}
            className={!isActive ? "collapsed" : ""}
            isActive={isActive}
            >
                <QuestionIconContainer isActive={isActive}>
                <QuestionIcon isActive={isActive}>{isActive ? <FaAngleDoubleDown /> : <FaAngleDoubleRight />}</QuestionIcon>
                </QuestionIconContainer>
                
                {question}
            </Question>
        </QuestionContainer>
    );
}
export default FaqQuestion