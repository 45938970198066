import React from "react";
import {HiChevronLeft, HiChevronRight} from "react-icons/hi";
import styled from "styled-components";
import {BiFirstPage, BiLastPage} from "react-icons/bi";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  vertical-align: center;
`;
const PaginationButton = styled.div`
  cursor: pointer;
  height: 22px;
  padding: 6px 10px;
  margin: 5px;
  font-weight: 600;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props: { current: boolean }) => props.current && `
        background-color: #2d4848;
        color: #ffffff;
    `}
`;

const Dots = styled.div`
  padding: 10px;
  margin: 5px;
`;
interface PaginationControlProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
}

const PaginationControl: React.FC<PaginationControlProps> = ({ currentPage, totalPages, onPageChange }) => {
    const handlePrevious = () => {
        if (currentPage > 0) onPageChange(currentPage - 1);
    };
    const handleNext = () => {
        if (currentPage < totalPages - 1) onPageChange(currentPage + 1);
    };
    const handleFirst = () => {
        onPageChange(0);
    };
    const handleLast = () => {
        onPageChange(totalPages - 1);
    };

    const isStart = (index: number) => currentPage <= 2 && index < 2;
    const isCurrent = (index: number) => Math.abs(currentPage - index) <= 1;
    const isEnd = (index: number) => index >= totalPages - 2;
    const isMiddle = (index: number) => index > 2 && index < totalPages - 2;

    let previousWasDots = false;

    return (
        <PaginationContainer>
            <PaginationButton onClick={handleFirst} current={false}><BiFirstPage /></PaginationButton>
            <PaginationButton onClick={handlePrevious} current={false}><HiChevronLeft /></PaginationButton>
            {Array(totalPages).fill(null).map((_, index) => {
                if (isStart(index) || isCurrent(index) || isEnd(index)) {
                    previousWasDots = false;
                    return (
                        <PaginationButton key={index} current={currentPage === index} onClick={() => onPageChange(index)}>
                            {index + 1}
                        </PaginationButton>
                    );
                } else if (isMiddle(index) && !previousWasDots && index === currentPage + 3) {
                    previousWasDots = true;
                    return <Dots key={index}>...</Dots>
                } else {
                    return null;
                }
            })}
            <PaginationButton onClick={handleNext} current={false}><HiChevronRight /></PaginationButton>
            <PaginationButton onClick={handleLast} current={false}><BiLastPage /></PaginationButton>
        </PaginationContainer>
    );
};
export default PaginationControl;