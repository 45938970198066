import { underlineApi } from "./underlineApi";
import {ProjectInfo} from "./projectsApiSlice";

export interface Member {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: string[];
    company: string;
    enabled: boolean;
    locked: boolean;
    lastLogin: string;
}

export interface Workspace {
    id: string;
    name: string;
    openProjects: number;
    closedProjects: number;
    members: Member[];
}
export const workspacesApiSlice = underlineApi.injectEndpoints({
    endpoints: (builder) => ({
        getWorkspaces: builder.query({
            query: () => {
                return {
                    url: `/workspaces`,
                    method: 'GET'
                }
            },
            providesTags: ['Workspaces']
        }),
        getWorkspace: builder.query<Workspace, string>({
            query: (id) => {
                return {
                    url: `/workspaces/${id}`,
                    method: 'GET'
                }
            },
            providesTags: ['Workspaces']
        }),
        editWorkspace: builder.mutation({
            query: ({id, newName}) => {
                let body: URLSearchParams = new URLSearchParams();
                body.append("newName",newName);
                return {
                    url: `/workspaces/${id}`,
                    method: 'PATCH',
                    body: body,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            },
            invalidatesTags: ['Workspaces']
        }),
        addUserToWorkspace: builder.mutation({
            query: ({workspaceId, userId}) => {
                let body: URLSearchParams = new URLSearchParams();
                body.append("userId",userId);
                return {
                    url: `/workspaces/${workspaceId}/users`,
                    method: 'PUT',
                    body: body
                }
            },
            invalidatesTags: ['Workspaces']
        }),

        removeUserFromWorkspace: builder.mutation({
            query: ({workspaceId, userId}) => {
                let body: URLSearchParams = new URLSearchParams();
                body.append("userId",userId);
                return {
                    url: `/workspaces/${workspaceId}/users`,
                    method: 'DELETE',
                    body: body
                }
            },
            invalidatesTags: ['Workspaces']
        })
    })
})
export const {
    useGetWorkspacesQuery,
    useGetWorkspaceQuery,
    useEditWorkspaceMutation,
    useAddUserToWorkspaceMutation,
    useRemoveUserFromWorkspaceMutation
} = workspacesApiSlice;