import React, { useState }  from 'react'
import styled from 'styled-components';
import {useAppDispatch} from '../../hooks/store-hooks';
import { setCookieConsent } from '../../store/cookieConsentSlice';
import SlimButton from '../ui/forms/action/SlimButton'
import SettingsModal from './CookieSettingModal';
import {COOKIES_CONSENT_EXPIRATION_HOURS} from "../../utils/constants";

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #202020;
  color: white;
  padding: 16px;
  z-index: 1000;
  border-top: 2px solid rgba(255, 255, 255, 0.2);

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: 1115px) {
    justify-content: center;
    text-align: center;
  }
`;
const Text = styled.p`
  font-size: 16px;
  margin-right: 16px;

  @media (max-width: 1115px) {
    width: 400px;
    margin-right: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column; 

  @media (min-width: 1300px) {
    flex-direction: row;
  }
`;
const MainContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SettingsLink = styled.span`
  color: #EAC113;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #656565;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  
  @media (max-width: 685px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 5px;
  }
`;
const StyledSlimButton = styled(SlimButton)`
  margin-top: 16px;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: 16px;
  }
`;
const CookieMessage: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const handleAcceptCookies = () => {
        let expirationDate: number = new Date().getTime() + COOKIES_CONSENT_EXPIRATION_HOURS * 60 * 60 * 1000;
        dispatch(setCookieConsent({consent: true, expirationDate, necessaryCookies: true, thirdPartyCookies: true}));
        setIsVisible(false);
        setIsModalOpen(false)
    };
    const handleDeclineCookies = () => {
        dispatch(setCookieConsent({ consent: false, expirationDate: null, necessaryCookies: true, thirdPartyCookies: false }));
        setIsModalOpen(false);
    };

    if (!isVisible) {
        return null;
    }
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Banner>
                <MainContentWrapper>
                    <ContentWrapper>
                        <Text>
                            We are using cookies to give you the best experience on our website. <br/>
                            You can find out more about which cookies we are using or switch them off in {' '}
                            <SettingsLink onClick={openModal}>settings</SettingsLink>
                        </Text>
                        <ButtonsContainer>
                            <StyledSlimButton
                                type="button"
                                bgColor="#EAC113"
                                text="Accept All"
                                textColor="#2D292A"
                                onClick={handleAcceptCookies}
                                marginLeft='25px'
                                bgGradient="linear-gradient(to right, #eac113, #ffeb3b, #eac113)"
                                marginTop={"5px"}
                            />
                            <StyledSlimButton
                                type="button"
                                bgColor="#c71313"
                                text="Reject"
                                textColor="#ffff"
                                onClick={handleDeclineCookies}
                                marginLeft='25px'
                                bgGradient="linear-gradient(to right, #a11212, #c71313, #a11212)"
                                marginTop={"5px"}
                            />
                        </ButtonsContainer>
                    </ContentWrapper>
                </MainContentWrapper>
            </Banner>
            {isModalOpen && (
                <SettingsModal
                    handleClose={closeModal}
                    handleAcceptCookies={handleAcceptCookies}
                    setIsModalOpen={setIsModalOpen}
                />
            )}

        </>
    );
};
export default CookieMessage