import React from 'react';
import styled, {keyframes} from 'styled-components';

const SpinnerContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(56, 56, 56, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const attractOrange = keyframes`
  to {
    transform: translateX(calc(var(--sz) + calc(var(--hm) / 4)));
  }
`;
const attractBlue = keyframes`
  to {
    transform: translateX(calc(var(--sz) * -1 - calc(var(--hm) / 4)));
  }
`;
const LoaderSpinner = styled.div`
  height: 10px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 45%;
  justify-content: center;
`;
const Block = styled.div`
  --sz: 8vmin;
  --tX: 0;
  --animation: 700ms cubic-bezier(0.3, 0.5, 0.4, 0.9) infinite alternate-reverse;
  --hm: 4.5vmin;
  width: 40px;
  height: 40px;
  background-image: var(--bg);
  border-radius: 50%;
  transform: translateX(var(--tX));
  mix-blend-mode: lighten;
`;
const Mustard = styled(Block)`
  --bg: linear-gradient(-50deg, #EAC113 0%, #fbab7e 100%);
  margin-right: var(--hm);
  animation: ${attractOrange} var(--animation);
`;
const Blue = styled(Block)`
  --bg: linear-gradient(50deg, #00bfd5 0%, #c5f5ff 100%);
  margin-left: var(--hm);
  animation: ${attractBlue} var(--animation);
`;

const Spinner: React.FC = () => {
    return (
        <SpinnerContainer>
            <LoaderSpinner>
                <Mustard/>
                <Blue/>
            </LoaderSpinner>
        </SpinnerContainer>
    );
};

export default Spinner;