import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Logo from '../../assets/img/logo-white-transparent.webp'
import { useNavigate } from 'react-router-dom';
import SlimButton from "../ui/forms/action/SlimButton";
import CookiesSwitchButton from "./CookieSwitchButton";
import {CookieConsentState, setCookieConsent} from "../../store/cookieConsentSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/store-hooks";
import {COOKIES_CONSENT_EXPIRATION_HOURS} from "../../utils/constants";
import InvalidFeedback from "../ui/forms/InvalidFeedback";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #494747;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;

  @media (max-width: 970px) {
    justify-content: end;
  }
`;

const ModalPopUpContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 60%;
  max-width: 800px;
  display: flex;
  margin: auto 10px; 
  
  @media (max-width: 1360px) {
    width: 590px;
    height: 600px;
    margin-right: 40px;
  }
  @media screen and (min-resolution: 140dpi) {
    height: 430px;
  }
`;
const WhiteSection = styled.div`
  background-color: #fff;
  width: 40%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;

`;
const GraySection = styled.div`
  background-color: #f0f0f0;
  width: 60%;
  height: 400px;
  padding: 20px;
  margin: 0;
  @media (max-width: 1360px) {    
    height: 560px;
  }
  @media screen and (min-resolution: 140dpi) {
    height: 390px;
    margin-left: 10px;
  }
`;
const LogoImage = styled.img`
  width: 130px;
  max-width: 200px;
  margin-bottom: 20px;

  @media screen and (min-resolution: 140dpi) {
    width: 90px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const LinkButtons = styled.button<{ active?: boolean }>`
  padding: 10px 60px 10px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #494747;
  margin: 0 15px 10px 0;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  text-align: left;
  width: 320px;
  text-transform: uppercase;

  @media (max-width: 1360px) {
    width: 220px;
    font-size: 13px;
  }
  @media screen and (min-resolution: 140dpi) {
    font-size: 9px;
    font-weight: 500;
  }

  &:hover {
    background-color: #656565;
    border-color: #656565;
    color: #f5f5f5;
  }

  &:focus {
    outline: none;
  }

  ${props =>
          props.active &&
          `
          background-color: #656565;
          font-weight: 600;
          color: #EAC113;
          border: 1px solid #656565
          `
  }
`;
const ContentTitle = styled.h2`
  font-size: 24px;
  
  @media screen and (min-resolution: 140dpi) {
    font-size: 16px;
  }
`;
const ContentInfo = styled.p`
  font-size: 16px;

  @media screen and (min-resolution: 140dpi) {
    font-size: 11px;
  }
`;
const Footer = styled.div`
  position: absolute;
  display: flex;
  bottom: 10px;
  width: 100%;
  justify-content: left;
  align-items: flex-start;
  padding-bottom: 20px;
  
  &:before{
    content: "";
    position: absolute;
    bottom: 60px;
    left: 1px;
    right: 550px;
    height: 1px;
    display: block;
    background-color: #c9c8c8;
    margin-bottom: 20px;
  }

  @media (max-width: 1360px) {
    flex-direction: column;
    align-items: flex-start;

    &:before{
      content: "";
      position: absolute;
      bottom: 60px;
      left: 1px;
      right: 400px;
      height: 1px;
      display: block;
      background-color: #c9c8c8;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 685px) {

    &:before{
      content: "";
      position: absolute;
      bottom: 80px;
      left: 1px;
      right: 400px;
      height: 1px;
      display: block;
      background-color: #c9c8c8;
      margin-bottom: 20px;
    }
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  
  @media (max-width: 685px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 5px;
  }
`;
const CloseButton = styled.button`
  position: absolute;
  right: -18px;
  top: -18px;
  width: 35px;
  height: 35px;
  background-color: #EAC113;
  border-radius: 50%;
  border: none;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  
  &:focus {
    outline: none;
  }
  &:hover {
    color: #fff;
  }
`;
const Links = styled.a`
  color: #EAC113;
  text-decoration: none;
  margin: 0 5px;
  font-weight: 600;

  &:hover {   
    color: #656565;
  }
`;
interface NavLinkProps {
    to: string;
    children: React.ReactNode;
    state?: object;
    handleClose?: () => void;
}
const NavLink: React.FC<NavLinkProps> = ({ to, children, state,handleClose }) => {
    const navigate = useNavigate();

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        navigate(to, { state });
        if(handleClose){
            handleClose()
        }

    };

    return (
        <Links target="_blank" href={to} onClick={handleClick}>
            {children}
        </Links>
    );
};
interface ModalPopUpProps {
    handleClose?: () => void;
    handleAcceptCookies?: () => void;
    setIsModalOpen: Function
}

const CookieSettingsModal: React.FC<ModalPopUpProps> = ({handleAcceptCookies,handleClose, setIsModalOpen}) => {
    const [activeContent, setActiveContent] = useState<'first' | 'second' | 'third' | 'fourth' >('first');
    const cookieState: CookieConsentState = useAppSelector((state) => state.cookieConsent);
    const dispatch = useAppDispatch();
    const [showWarningMessage, setShowWarningMessage] = useState(false);
    const [showThirdPartyMessage, setShowThirdPartyMessage] = useState(false);
    const [switchStateNecessaryCookies, setSwitchStateNecessaryCookies] = useState(cookieState.necessaryCookies);
    const [switchStateThirdPartyCookies, setSwitchStateThirdPartyCookies] = useState(cookieState.thirdPartyCookies);
    const toggleSwitchThird = () => setSwitchStateNecessaryCookies(!switchStateNecessaryCookies);
    const toggleSwitchFourth = () => {
        if ((switchStateNecessaryCookies && !switchStateThirdPartyCookies) || (switchStateThirdPartyCookies))  {
            setSwitchStateThirdPartyCookies(!switchStateThirdPartyCookies);
        }
        if(!switchStateNecessaryCookies){
            setShowThirdPartyMessage(true);
        }
    };

    useEffect(() => {
        if (!switchStateNecessaryCookies) {
            setSwitchStateThirdPartyCookies(false);
            setShowWarningMessage(true);
        } else {
            setShowWarningMessage(false);
            setShowThirdPartyMessage(false)
        }
    }, [switchStateNecessaryCookies]);

    useEffect(() => {
        if (switchStateThirdPartyCookies) {
            setShowThirdPartyMessage(false);
        }
    }, [switchStateThirdPartyCookies, switchStateNecessaryCookies]);

    const handleUpdateCookies = () => {
        if(!switchStateNecessaryCookies){
            dispatch(setCookieConsent({consent: false, expirationDate: null, necessaryCookies: false, thirdPartyCookies: false}));
        } else {
            let expirationDate: number = new Date().getTime() + COOKIES_CONSENT_EXPIRATION_HOURS * 60 * 60 * 1000;
            dispatch(setCookieConsent({consent: true, expirationDate, necessaryCookies: switchStateNecessaryCookies, thirdPartyCookies: switchStateThirdPartyCookies}));
        }

        setIsModalOpen(false)
    };
    return (
        <Container>
            <ModalPopUpContent>
                <CloseButton onClick={handleClose}>&times;</CloseButton>
                <WhiteSection>
                    <LogoImage src={Logo} alt="Underline logo" title={"underline-logo"} />
                    <ButtonWrapper>
                        <LinkButtons
                            active={activeContent === 'first'}
                            onClick={() => setActiveContent('first')}
                        >
                            Privacy Overview
                        </LinkButtons>
                        <LinkButtons
                            active={activeContent === 'second'}
                            onClick={() => setActiveContent('second')}
                        >
                            Cookie Policy
                        </LinkButtons>
                        <LinkButtons
                            active={activeContent === 'third'}
                            onClick={() => setActiveContent('third')}
                        >
                            Strictly Necessary Cookies
                        </LinkButtons>
                        <LinkButtons
                            active={activeContent === 'fourth'}
                            onClick={() => setActiveContent('fourth')}
                        >
                            3rd Party Cookies
                        </LinkButtons>
                    </ButtonWrapper>
                </WhiteSection>
                <GraySection>
                    {activeContent === 'first' ? (
                        <>
                            <ContentTitle>Privacy Overview</ContentTitle>
                            <ContentInfo>This website uses cookies so that we can provide you with the best user experience possible.</ContentInfo>
                            <ContentInfo>Cookie information is stored in your browser and performs functions such as recognising you when you return to our website
                                and helping our team to understand which sections of the website you find most interesting and useful.</ContentInfo>
                        </>
                    ) : activeContent === 'second' ? (
                        <>
                            <ContentTitle>Cookie Policy</ContentTitle>
                            <ContentInfo>More information about our
                                <NavLink to="/privacy-policy" state={{ from: 'previous-page' }} handleClose={handleClose}>
                                    Cookie Policy
                                </NavLink>
                            </ContentInfo>
                        </>
                    ) : activeContent === 'third' ? (
                        <>
                            <ContentTitle>Strictly Necessary Cookies</ContentTitle>
                            <ContentInfo>These cookies are essential for the website to function. Strictly Necessary Cookie should be enabled at all times
                                so that we can save your preferences for cookie settings.</ContentInfo>
                            <ContentInfo>These cookies do not store any personally identifiable information.</ContentInfo>
                            <CookiesSwitchButton isOn={switchStateNecessaryCookies} handleToggle={toggleSwitchThird} />
                            {showWarningMessage &&
                                <InvalidFeedback
                                    errorMessage={"If you disable this cookie, we will not be able to save your preferences. " +
                                        "This means that every time you visit this website you will need to enable or disable cookies again."}
                                />
                            }

                        </>
                    ): activeContent === 'fourth' ? (
                        <>
                            <ContentTitle>3rd Party Cookies</ContentTitle>
                            <ContentInfo>This website uses Google Analytics to collect anonymous information such as the number of visitors to the site, and the most popular pages.</ContentInfo>
                            <ContentInfo>Keeping this cookie enabled helps us to improve our website.</ContentInfo>
                            <CookiesSwitchButton
                                isOn={switchStateThirdPartyCookies}
                                handleToggle={toggleSwitchFourth}
                            />
                            {showThirdPartyMessage &&
                                <InvalidFeedback
                                    errorMessage={"Please enable Strictly Necessary Cookies first so that we can save your preferences. "}
                                />
                            }
                        </>
                    ) : null}
                    <Footer>
                        <ButtonsContainer>
                            <SlimButton
                                type="button"
                                bgColor="#EAC113"
                                text="Accept All"
                                textColor="#2D292A"
                                onClick={handleAcceptCookies}
                                bgGradient="linear-gradient(to right, #eac113, #ffeb3b, #eac113)"
                            />
                            <SlimButton
                                type="button"
                                bgColor="#EAC113"
                                text="Save Changes"
                                textColor="#2D292A"
                                onClick={handleUpdateCookies}
                                bgGradient="linear-gradient(to right, #eac113, #ffeb3b, #eac113)"
                            />
                        </ButtonsContainer>
                    </Footer>
                </GraySection>
            </ModalPopUpContent>
        </Container>
    );
};
export default CookieSettingsModal;

