import React, {useState, ChangeEvent} from 'react';
import styled from 'styled-components';
import Breadcrumb from "components/Breadcrumb";
import PageTitle from "components/PageTitle";
import Form from '../components/ui/forms/action/ActionForm';
import FormLabel from '../components/ui/forms/action/ActionFormLabel';
import SelectOption from '../components/ui/forms/action/SelectOption';
import UploadFileButton from '../components/ui/forms/action/UploadFileButton';
import SuccessPopup from '../components/ui/forms/SuccessPopup';
import InvalidFeedback from 'components/ui/forms/InvalidFeedback';
import {FaTrashAlt} from 'react-icons/fa'
import {CONVERSION_OPTIONS} from '../utils/constants';
import {useNavigate} from "react-router-dom";
import {useConvertMutation} from "../services/converterApiSlice";
import Spinner from "../components/spinner/Spinner";

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;
const FileList = styled.ul<FileListProps>`
  margin-top: 10px;
  list-style-type: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  width: 300px;
  height: ${({fileCount}) => (fileCount > 1 ? fileCount * 25 : 43)}px;
`;
const FileName = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 5px;
`;
const TrashButton = styled.button`
  border: none;
  color: black;
  background: none;
  cursor: pointer;
  font-size: 14px;

  &:focus {
    border: none;
    outline: none;
  }
`;
interface FileListProps {
    fileCount: number;
}

const shortenFileName = (fileName: string) => {
    const maxLength = 40;
    if (fileName.length > maxLength) {
        const start = fileName.slice(0, maxLength / 2);
        const end = fileName.slice(-maxLength / 2);
        return `${start}...${end}`;
    }
    return fileName;
};
const Converter: React.FC = () => {
    const [conversionMethod, setConversionMethod] = useState('');
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [resetFile, setResetFile] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [convert, {isLoading}] = useConvertMutation();

    const handleChangeConversionMethod = (event: ChangeEvent<HTMLSelectElement>) => {
        setConversionMethod(event.target.value);
    };
    const handleChangeFile = (uploadedFile: File | null, errorMessage?: string) => {
        if (errorMessage) {
            setErrorMessage(errorMessage);
            return;
        }
        setErrorMessage('');
        if (uploadedFile && !selectedFiles.some((file) => file.name === uploadedFile.name && file.size === uploadedFile.size) && uploadedFile instanceof File) {
            setSelectedFiles([...selectedFiles, uploadedFile]);
        }
    };

    const handleRemoveFile = (fileIndex: number, event: React.MouseEvent) => {
        event.preventDefault();
        setSelectedFiles((currentFiles) => currentFiles.filter((file, index) => index !== fileIndex));
    };

    const resetInfo = () => {
        setConversionMethod('');
        setResetFile(true);
        setSelectedFiles([]);
        setErrorMessage('');
    }

    const handleClosePopup = () => {
        setShowSuccessPopup(false);
        navigate('/projects');
    };

    async function handleConverter(event: React.FormEvent, conversionMethod: string) {
        event.preventDefault();
        if (conversionMethod === '') {
            setErrorMessage('Please select a conversion method before submitting.');
            return;
        }
        if (selectedFiles.length === 0) {
            setErrorMessage('Please select at least one file before submitting.');
            return;
        }

        let method: string = '';
        if(conversionMethod === "SRT to VTT"){
            method = "srt->vtt";
        }
        if (conversionMethod === "VTT to SRT") {
            method = "vtt->srt";
        }

        if(method === ''){
            setErrorMessage('Please select a valid conversion method before submitting.');
            return;
        }

        setErrorMessage('');
        convert({files: selectedFiles, conversionMethod: method})
            .unwrap()
            .then((blob) => {
                const element = document.createElement("a");
                element.href = URL.createObjectURL(blob);
                element.download = "converted-files.zip";
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                resetInfo();
            })
            .catch((error) => {
                error.data.text().then((text: string) => {
                    const errorResponse = JSON.parse(text);
                    setErrorMessage(errorResponse.error);
                    setResetFile(true);
                    setSelectedFiles([]);
                })
            });
    }

    return (
        <>
            {isLoading && <Spinner/>}
            <>
                <Breadcrumb link="Converter"/>
                <PageTitle title="Convert Subtitles"/>
                <Form
                    title="Subtitle Conversion"
                    width={400}
                    cancelMessage="Cancel"
                    acceptMessage="Convert File"
                    cancelAction={resetInfo}
                    acceptAction={(e) => handleConverter(e, conversionMethod)}
                    columns={1}
                >
                    <FormRow>
                        <FormColumn>
                            <FormLabel
                                text="Conversion Method"
                                marginLeft={0}
                            />
                        </FormColumn>
                    </FormRow>

                    <FormRow>
                        <FormColumn>
                            <SelectOption
                                id={"conversion_options_select"}
                                value={conversionMethod}
                                onChange={handleChangeConversionMethod}
                                options={CONVERSION_OPTIONS}
                                width={320}
                            />
                        </FormColumn>
                    </FormRow>

                    <FormRow>
                        <FormColumn>
                            <UploadFileButton
                                id={"converter_upload_button"}
                                onChange={handleChangeFile}
                                reset={resetFile}
                                validFiles=".srt, .vtt, .zip"
                                showName={false}
                            />
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn>
                            <SelectedFiles
                                id={"converter_selected_files"}
                                files={selectedFiles}
                                onRemoveFile={handleRemoveFile}
                            />
                        </FormColumn>
                    </FormRow>
                    {errorMessage && <InvalidFeedback errorMessage={errorMessage}/>}
                    {showSuccessPopup &&
                        <SuccessPopup
                            successMessage="Success!"
                            instructions="Your files have been converted."
                            confirmationMessage="Download"
                            handleClosePopup={handleClosePopup}
                        />
                    }
                </Form>
            </>
        </>
    )
}

interface SelectedFilesProps {
    id?: string;
    files: File[];
    onRemoveFile: (index: number, event: React.MouseEvent) => void;
}

const SelectedFiles: React.FC<SelectedFilesProps> = ({id, files, onRemoveFile}) => {
    return (
        <FileList fileCount={files.length}>
            {files.map((file, index) => (
                <FileName id={`file_${index}_name`} key={index}>
                    {shortenFileName(file.name)}
                    <TrashButton id={`file_${index}_delete_icon`} onClick={(e) => onRemoveFile(index, e)}>
                        <FaTrashAlt/>
                    </TrashButton>
                </FileName>
            ))}
        </FileList>
    );
};
export default Converter;