import {createSlice, PayloadAction, Slice} from "@reduxjs/toolkit";
export interface IntanceInfo {
    id: number,
    ownerFirstName: string,
    ownerLastName: string,
    ownerEmail: string,
    projectsPerMonth: number,
    currentMonthProjects: number,
    jobsPerProject: number,
    workspaces: number,
    users: number,
    activeUsers: number,
    subscriptionName: string,
    subscriptionId: number
}
const initialState: IntanceInfo = {
    id: 0,
    ownerFirstName: '',
    ownerLastName: '',
    ownerEmail: '',
    projectsPerMonth: 0,
    currentMonthProjects: 0,
    jobsPerProject: 0,
    workspaces: 0,
    users: 0,
    activeUsers: 0,
    subscriptionName: '',
    subscriptionId: 0
}

const instanceSlice: Slice = createSlice({
    name: 'instanceInfo',
    initialState,
    reducers: {
        setInstanceInfo(state: IntanceInfo, newInstancePayload: PayloadAction<IntanceInfo>): IntanceInfo {
            return newInstancePayload.payload;
        },
        clearInstanceInfo(): IntanceInfo{
            return initialState;
        }
    }
});
export const {setInstanceInfo, clearInstanceInfo} = instanceSlice.actions;
export default instanceSlice.reducer;