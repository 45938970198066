import React from "react";
import Select, { StylesConfig, components, MultiValueProps, OptionProps } from "react-select";
import styled from "styled-components";

const LanguageOption = styled.span`
    margin-left: 8px;
    &:active {
        border: 1px solid #aaa;
        outline: none;
    }
    &:focus-visible {
        border: 1px solid #aaa;
        outline: none;
    }
`;
export interface Option {
    value: string;
    label: string;
}
interface SelectOptionProps {
    id?: string;
    value: Option[];
    onChange: (selectedOptions: Option[]) => void;
    options: Option[];
    multiple?: boolean;
    selectedSource?: string;
}

const MultipleSelect: React.FC<SelectOptionProps> = ({id, value, onChange, options, multiple, selectedSource }) => {
    const customStyles: StylesConfig<Option, boolean> = {
        control: (provided, state) => ({
            ...provided,
            height: 35,
            minHeight: 35,
            paddingLeft: 10,
            width: 250,
            boxShadow: '0 0 0 1px #cc',
            borderColor: '#ccc',
            '&:focus-within': {
                borderColor: '#ccc'
            }
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: 35,
        }),
        clearIndicator: (provided) => ({
            ...provided,
            padding: '5px 8px',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#EAC113',
            padding: '5px 8px',
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 5,
            width: 250,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            textOverflow: 'ellipsis',
            maxWidth: 'calc(100% - 16px)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        }),
    };
    const CustomMultiValue: React.FC<MultiValueProps<Option> & { value: Option[] }> = (props) => {
        const { data, innerProps, removeProps, selectProps } = props;
        const [firstOption, secondOption, thirdOption] = value;
        
            if (data === firstOption) {
            return (
                <>
                {value.length > 3
                    ? `${value.length} selected`
                    : `${firstOption.label}${secondOption ? `, ${secondOption.label}` : ''}${
                        thirdOption ? `, ${thirdOption.label}` : ''
                    }`}
                </>
            );
            } else if (data === secondOption || data === thirdOption || value.length > 3) {
                return null;
            } else {
            return (
                <components.MultiValueRemove
                {...removeProps}
                data={data}
                innerProps={innerProps}
                selectProps={selectProps}
                />
            );
            }
    };
    const selectAllOption: Option = { value: "select_all", label: "Select all" };
    const handleChange = (
        selectedOptions: ReadonlyArray<Option> | Option | null
    ) => {
        if (Array.isArray(selectedOptions)) {
        if (
            selectedOptions.some(
            (option) => option.value === selectAllOption.value
            ) &&
            !value.includes(selectAllOption)
        ) {
            onChange([...options]);
        } else if (
            !selectedOptions.some(
            (option) => option.value === selectAllOption.value
            ) &&
            value.includes(selectAllOption)
        ) {
            onChange([]);
        } else {
            onChange([...selectedOptions]);
        }
        } else {
        onChange(selectedOptions ? [selectedOptions as Option] : []);
        }
    };
    const CustomOption: React.FC<OptionProps<Option, boolean>> = (props) => {
        const { data } = props;

        return (
        <components.Option {...props}>
            {multiple &&
            !value.some((selected) => selected.value === data.value) && (
                <input type="checkbox" />
            )}
            <LanguageOption>{data.label}</LanguageOption>
        </components.Option>
        );
    };
    const filteredOptions = options.filter(option => option.value !== selectedSource);

    return (
        <div id={id}>
            <Select
                value={value}
                onChange={handleChange}
                options={[selectAllOption, ...filteredOptions]}
                isMulti={multiple}
                closeMenuOnSelect={false}
                components={{
                    MultiValue: (props) => <CustomMultiValue {...props} value={value} />,
                    Option: CustomOption,
                }}
                placeholder={multiple ? "None selected" : null}
                styles={customStyles}
            />
        </div>
    );
};

export default MultipleSelect;