import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import styled from 'styled-components';
import backgroundImageDesktop from '../assets/img/error_background_desktop.webp';
import backgroundImageMobile from '../assets/img/error_background_mobile.webp';
import SlimButton from "../components/ui/forms/action/SlimButton";
import {useAppSelector} from "../hooks/store-hooks";

const PageContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BackgroundImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  transition: transform 0.3s ease;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: 'Pathway Gothic One', sans-serif;
  color: rgba(236, 231, 231, 0.5);
  font-weight: 700;
  z-index: 1;
  padding-bottom: 0;
`;
const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -150px;
  margin-top: -250px;

  @media (max-width: 800px) {
    flex-direction: column;
    margin-left: 0;
    margin-top: 0;
    align-items: flex-start;
  }
`;
const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 0;
  margin: 0 0 0 20px;
  
  @media (max-width: 800px) {   
   margin: 0;    
  }
`;
const ErrorNumber = styled.p`
  font-size: 200px;
  text-align: center;
  padding-top: 80px;

  @media (max-width: 1030px) {
    font-size: 140px;
  }
  @media (max-width: 800px) {
    font-size: 240px;
    padding-top: 0;
    margin: 0 0 -40px 50px;
  }
  @media (max-width: 377px) {
    font-size: 140px;
  }
`;
const Message = styled.p`  
  font-size: 80px;
  text-align: left;
  margin-bottom: 0;
  word-wrap: break-word;
  white-space: normal;

  @media (max-width: 1030px) {
    font-size: 60px;
  }
  @media (max-width: 800px) {
    font-size: 80px;
    margin-left: 50px;
    line-height: 1;
    margin-top: 0;
  }
  @media (max-width: 377px) {
    font-size: 60px;
  }
`;
const ButtonWrapper = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: -200px;
  
  
  @media (max-width: 800px) {
    margin-top: 40px;
  }
`;
const Page404: React.FC = () => {
    const navigate = useNavigate();
    const { token } = useAppSelector(state => state.tokenInfo);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const backgroundImage = windowWidth <= 800 ? backgroundImageMobile : backgroundImageDesktop;

    const handleBack = () => {
        if (!token ) {
            navigate("/");
        } else {
            navigate("/home");
        }
    };
    return(
        <>
            <BackgroundImg src={backgroundImage} alt="Underline background image" title="underline-background-image" />
            <PageContent>
                <ContentWrapper>
                    <TextWrapper>
                        <ErrorNumber>404</ErrorNumber>
                        <ErrorMessageWrapper>
                            <Message>OOPS!</Message>
                            <Message>LOOKS SOMETHING WENT WRONG</Message>
                        </ErrorMessageWrapper>
                    </TextWrapper>
                    <ButtonWrapper>
                        <SlimButton
                            type="button"
                            bgColor="#EAC113"
                            text="Keep Improving your Reach"
                            textColor="#23201C"
                            onClick={handleBack}
                            bgGradient="#EAC113"
                        />
                    </ButtonWrapper>
                </ContentWrapper>

            </PageContent>
        </>
    )
}
export default Page404