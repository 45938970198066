import React, {ChangeEvent, useState} from "react";
import {RiLock2Fill, RiLockPasswordFill} from 'react-icons/ri';
import AuthForm from '../components/ui/forms/auth/AuthForm';
import AuthFormInput from "../components/ui/forms/auth/AuthFormInput";
import InvalidFeedback from "../components/ui/forms/InvalidFeedback";
import AuthFormButton, {ButtonType} from "../components/ui/forms/auth/AuthFormButton";
import SuccessPopup from "../components/ui/forms/SuccessPopup";
import styled from 'styled-components';
import {useLocation, useNavigate} from "react-router-dom";
import {useChangePasswordMutation} from "../services/usersApiSlice";
import Spinner from "../components/spinner/Spinner";


const ForgotPasswordMessage = styled.h5`
  color: #fff;
  margin: 15px 0 20px 20px;
  font-size: 18px;
  font-weight: 500;
`;

const footerLinks = [
    {
        text: "Don't have an account?  ",
        linkText: "Sign up here!",
        link: "/register",
    },
];
const ResetPassword: React.FC = () => {
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [changePassword, {isLoading}] = useChangePasswordMutation ();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token") || "";
    const instanceId = searchParams.get("instanceId") || "";

    const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            setPassword(event.target.value);
        }
    };
    const handleChangeRepeatPassword = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            setRepeatPassword(event.target.value);
        }
    }

    const isValidPassword = (password: string): boolean => {
        const lengthCheck = /.{9,}/.test(password);
        const upperCaseCheck = /[A-Z]/.test(password);
        const symbolOrNumberCheck = /[\d\W]/.test(password);

        return lengthCheck && upperCaseCheck && symbolOrNumberCheck;
    }

    const handleClosePopup = () => {
        setShowSuccessPopup(false);
        if (token && instanceId) {
            navigate(`/login?token=${token}&instanceId=${instanceId}`);
        } else {
            navigate("/login");
        }
    };

    const handleResetPassword = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!isValidPassword(password)) {
            setErrorMessage("Password must be at least 9 characters long, contain at least one uppercase letter and at least one number or symbol.");
            return false;
        }
        if (password !== repeatPassword) {
            setErrorMessage("Passwords do not match.");
            return false;
        }

        const request = {
            token,
            instanceId,
            newPassword: password,
        };

        changePassword(request)
            .unwrap()
            .then(() => {
                setShowSuccessPopup(true);
            })
            .catch((error) => {
                console.log(error.status)
                if (error.status === 400) {
                    setErrorMessage("This link has expired. Please request a new one.");
                    setTimeout(() => {
                        navigate("/forgot-password");
                    }, 5000);
                } else {
                    console.error(error);
                }
            });
    };

    return (
        <>
            {isLoading && <Spinner/>}
            <AuthForm title="Reset password" width={400} height={430} footerLinks={footerLinks}
                      hasError={!!errorMessage} submitHandler={handleResetPassword}>
                <ForgotPasswordMessage>
                    Please choose a new password.
                </ForgotPasswordMessage>

                <AuthFormInput
                    prependIcon={<RiLock2Fill/>}
                    placeholder="New Password"
                    type="password"
                    value={password}
                    onChange={handleChangePassword}

                />
                <AuthFormInput
                    prependIcon={<RiLockPasswordFill/>}
                    placeholder="Repeat Password"
                    type="password"
                    value={repeatPassword}
                    onChange={handleChangeRepeatPassword}
                />
                {errorMessage && <InvalidFeedback errorMessage={errorMessage}/>}
                <AuthFormButton
                    text="Change password"
                    extraMargin="true" type={ButtonType.submit}
                />
                {showSuccessPopup &&
                    <SuccessPopup
                        successMessage="Success!"
                        instructions="Your password has been modified."
                        confirmationMessage="THANKS!"
                        handleClosePopup={handleClosePopup}/>}
            </AuthForm>
        </>
    );
}
export default ResetPassword;