import React from 'react';
import styled from 'styled-components';

const FormButton = styled.button<{ extraMargin?: string}>`
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 5px 12px;
  line-height: 1.5;
  border-radius: 4px;
  float: right;
  color: #2d292a;
  background: linear-gradient(to right, #eac113, #ffeb3b, #eac113);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  width: ${({extraMargin}) => (extraMargin ? "230px" : "100px")};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  margin: 15px 20px 5px 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background: linear-gradient(to right, #ffeb3b, #eac113, #ffeb3b);
    border: 1px solid #ffeb3b;
    color: #444444;
  }

  &:focus {
    outline: none;
  }
`;
interface AuthButtonProps {
    id?: string;
    onClick?: () => void;
    text: string;
    extraMargin?: string;
    type?: ButtonType;
}

export const enum ButtonType {
    submit = "submit",
    reset = "reset",
    button = "button"
}

const AuthFormButton: React.FC<AuthButtonProps> = ({ id, onClick, text, extraMargin,type = ButtonType.button}) => {
    return (
        <FormButton
            id={id}
            type={type}
            onClick={onClick}
            extraMargin={extraMargin}
        >
        {text}
        </FormButton>
    );
};
export default AuthFormButton