import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useAppSelector} from "../../hooks/store-hooks";

const CardContainer = styled.div<{ isOnLandingPage: boolean }>`
    width: 300px;
    height: 510px;
    border-radius: 14px;
    border: 0.50px #F3C300 solid;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;

    @media (max-width: 1534px) {
      width: 250px;
      height: 400px;
    }
    @media (max-width: 1180px) {
      width: 200px;
    }
    @media (max-width: 767px) {
      ${(props) => props.isOnLandingPage && `
          width: 170px;
          height: 95px;
          background-color: #EAC113;
          cursor: pointer;
          z-index: 100;
    `}
    }
`;
const TopPart = styled.div<{ isOnLandingPage: boolean }>`
  width: 100%;
  height: 140px;
  background: #EAC113;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 767px) {
    ${(props) => props.isOnLandingPage && `
        height: 95px;
     `}
  }
`;
const Title = styled.div<{ isOnLandingPage: boolean }>`
  width: 256px;
  height: 46px;
  text-align: center;
  color: #2D292A;
  font-size: 40px;
  font-weight: 600;
  word-wrap: break-word;

  @media (max-width: 1534px) {
    font-size: 36px;
  }
  @media (max-width: 767px) {
    ${(props) => props.isOnLandingPage && `
        font-size: 26px;
        height: 22px;
        margin-bottom: 5px;
    `}
  }
`;
const Subtitle = styled.div<{ isOnLandingPage: boolean }>`
  width: 256px;
  height: 46px;
  text-align: center;
  color: #2D292A;
  font-size: 22px;
  font-weight: 400;
  word-wrap: break-word;
  margin-top: 10px;

  @media (max-width: 767px) {
    ${(props) => props.isOnLandingPage && `
        font-size: 16px;
        margin-top: 5px;
    ` }
  }
`;
const ComingSoonRibbon = styled.div<{ isOnLandingPage: boolean }>`
  width: 309px;
  height: 40px;
  background: white;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  border: 0.50px white solid;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    ${(props) => props.isOnLandingPage && `
        width: 170px;
        height: 23px;
    `}
  }
`;
const ComingSoonRibbonText = styled.div<{ isOnLandingPage: boolean }>`
  text-align: center;
  color: #2D292A;
  font-size: 18px;
  font-weight: 600;
  word-wrap: break-word;
  text-transform: uppercase;

  @media (max-width: 767px) {
    ${(props) => props.isOnLandingPage && `
        font-size: 12px;
    `}
  }
`;
const BottomPart = styled.div`
  width: 100%;
  height: 450px;
  background: #2D292A;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;;
  align-items: center;  
  padding-top: 15px;
`;
const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 16px;
`;
const FeatureName = styled.div`
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 400;
  word-wrap: break-word;

  @media (max-width: 290px) {
    font-size: 16px;    
  }
`;
const FeatureSpec = styled.div`
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 600;
  word-wrap: break-word;
  margin-bottom: 5px;
`;
const Divider = styled.div`
  width: 198px;
  height: 0;
  border: 0.50px #EAC113 solid;
`;
const ActiveRibbon = styled.div`
  width: 130px;
  height: 130px;
  overflow: hidden;
  position: absolute;
  top: -10px;
  right: -10px;
`;
const ActiveRibbonText = styled.span`
  left: -15px;
  top: 25px;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  display: block;
  width: 190px;
  padding: 5px 0;
  background-color: white;
  color: #2D292A ;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
`;
const MobilePlanDetailContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 290px;
  background-color: #424040;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  @media (max-width: 290px) {
    width: 200px;
  }
`;
const MobilePlanDetailContent = styled.div`
  padding: 20px 60px;
  background-color: #424040;
  box-shadow: 0 4px 24px rgba(234, 193, 19, 0.10);
  border-radius: 14px;
  border: 1px #F3C300 solid;

  @media (max-width: 290px) {
    width: 130px;
  }
`;
const CloseButton = styled.button`
  position: absolute;
  right: -30px;
  top: -20px;
  padding: 5px 10px;
  background-color: #EAC113;
  border: none;
  border-radius: 50px;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;
interface SubscriptionCardProps{
    plan :{
        planName: string,
        backgroundColor: string,
        features: string[],
        comingSoon: boolean,
    };
    isOnLandingPage?: boolean;
}
const SubscriptionCard: React.FC<SubscriptionCardProps> = ({plan, isOnLandingPage}) => {
    const userSubscription = useAppSelector((state) => state.instanceInfo.subscriptionName);
    const [isCurrentPlan, setIsCurrentPlan] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const popupRef = useRef<HTMLDivElement>(null);

    const handleCardClick = () => {
        if (isOnLandingPage && window.innerWidth <= 767) {
            setShowPopup(true);
        }
    };
    const closePopup = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        setShowPopup(false);
    };
    const handleClickOutside = (event: MouseEvent) => {
        if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
            setShowPopup(false);
        }
    };

    useEffect(() => {
        if (plan.planName === userSubscription) {
            setIsCurrentPlan(true);
        }
    }, [plan.planName, userSubscription]);
    useEffect(() => {
        if (showPopup) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showPopup]);

    return (
        <>
            <CardContainer
                isOnLandingPage={isOnLandingPage || false}
                style={{ zIndex: showPopup ? 101 : 0 }}
                key={plan.planName}
                onClick={handleCardClick}
            >
                <TopPart isOnLandingPage={isOnLandingPage || false} >
                    {isCurrentPlan ? (
                        <ActiveRibbon>
                            <ActiveRibbonText>Active</ActiveRibbonText>
                        </ActiveRibbon>
                    ) : plan.comingSoon ? (
                        <ComingSoonRibbon isOnLandingPage={isOnLandingPage || false} >
                            <ComingSoonRibbonText isOnLandingPage={isOnLandingPage || false} >Coming Soon</ComingSoonRibbonText>
                        </ComingSoonRibbon>
                    ) : null}
                    <Title isOnLandingPage={isOnLandingPage || false} >{plan.planName}</Title>
                    <Subtitle isOnLandingPage={isOnLandingPage || false} >Subscription</Subtitle>
                </TopPart>
                <BottomPart>
                    {plan.features.map((feature, i) => {
                        const [text, number] = feature.split(': ');
                        return (
                            <InfoBlock key={i}>
                                <FeatureName>{text}</FeatureName>
                                <FeatureSpec>{number}</FeatureSpec>
                                {i < plan.features.length - 1 && <Divider />}
                            </InfoBlock>
                        );
                    })}
                </BottomPart>
                {showPopup && (
                    <MobilePlanDetailContainer ref={popupRef}>
                        <MobilePlanDetailContent>
                            <CloseButton onClick={(e) => closePopup(e)}>×</CloseButton>
                            {plan.features.map((feature, i) => {
                                const [text, number] = feature.split(': ');
                                return (
                                    <InfoBlock key={i}>
                                        <FeatureName>{text}</FeatureName>
                                        <FeatureSpec>{number}</FeatureSpec>
                                        {i < plan.features.length - 1 && <Divider />}
                                    </InfoBlock>
                                );
                            })}
                        </MobilePlanDetailContent>
                    </MobilePlanDetailContainer>
                )}
            </CardContainer>
        </>
    );
};
export default SubscriptionCard;
