import React from "react";
import styled from 'styled-components';
import background from '../../../../assets/img/background-grey.webp'

const TitleContainer = styled.div`
    position: relative;
    padding-top: 1px;
`;
const BackgroundImage = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 101;
`;
const TitleText = styled.h3`
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 24px;
    z-index: 102; 
    position: relative;

    &:after {
        content: "";
        background-color: #eac113;
        height: 2px;
        width: 80px;
        margin: 10px 0 0;
        display: block;
        clear: both;
    }
`;
interface FormTitleProps {
    title: string;
}

const ActionFormTitle: React.FC<FormTitleProps> = ({title}) => {
    return (
        <TitleContainer>
            <BackgroundImage src={background} alt="Underline background image" title="underline-background-image" />
            <TitleText>{title}</TitleText>
        </TitleContainer>
    );
};
export default ActionFormTitle;