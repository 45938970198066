import React from "react";
import styled from 'styled-components';

const MainTitle = styled.h1`
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1.5em;
    padding-bottom: 10px;
    position: relative;
    text-transform: uppercase;
    margin-left: 60px;
    margin-block-start: 0;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 5px;
        width: 55px;
        background-color: #eac113;
}
    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 2px;
        height: 1px;
        width: 95%;
        max-width: 255px;
        background-color: #eac113;
    }
`;

export const Dot = styled.span`
    color: #eac113;
    font-size: 60px;
    font-weight: 700;
    line-height: 60px;
`;

interface PageTitleProps {
    title: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
    return (
        <MainTitle>
            {title}
            <Dot>.</Dot>
        </MainTitle>
    );
};

export default PageTitle;