import React, { useEffect }  from "react";
import styled from 'styled-components';
import background from "../assets/img/phx-background.webp";
import {Dot} from '../components/PageTitle';
import {useAccountConfirmationQuery} from "../services/usersApiSlice";
import {useLocation, useNavigate} from "react-router-dom";
import Spinner from "../components/spinner/Spinner";
import Button from "../components/ui/forms/auth/AuthFormButton";
import UnderlineLogo from "../components/ui/forms/auth/AuthFormLogo";

const BodyContainer = styled.div`
  position: relative;
  background-color: #fbfbfb;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 0;
  margin: 0;
  font-size: 14px;
  overflow: hidden;
`;
const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const MessageContainer = styled.div`
  width: 680px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  height: 400px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;

  @media screen and (min-resolution: 140dpi) {
    margin-bottom: 50px;
  }
`;
const MessageWrapper = styled.div<{align?: string}>`
  display: flex;
  justify-content: ${({align}) => align || 'center'};
  width: 100%;
`;
const Message = styled.p<{ fontSize?: string, textAlign?: string, showLine?: boolean, fontWeight?: string}>`
  color: #fff;
  font-size: ${({fontSize}) => fontSize || '42px'};
  text-align: ${({textAlign}) => textAlign || 'center'};
  font-weight: ${({fontWeight}) => fontWeight || '700'};
  margin: 10px 20px;
  &:after {
    content: ${({showLine}) => (showLine ? '""' : 'none')};
    background-color: #eac113;
    height: 2px;
    width: 500px;
    display: block;
    clear: both;
    margin: 0px;
  }
`;
const AccountConfirmation: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const registrationToken = searchParams.get("token") || "";
    const instanceId = searchParams.get("instanceId") || "";
    const { data, error, isLoading, isError, isSuccess  } = useAccountConfirmationQuery({ registrationToken, instanceId }, {
        skip: !registrationToken || !instanceId,});

    useEffect(() => {
        if (isSuccess) {
            setTimeout(() => {
                if (registrationToken && instanceId) {
                    navigate(`/login?token=${registrationToken}&instanceId=${instanceId}`);
                } else {
                    navigate("/login");
                }
             }, 3000);
        }
    }, [isSuccess, navigate]);
    const handleBackToLogin = () => {
        if (registrationToken && instanceId) {
            navigate(`/login?token=${registrationToken}&instanceId=${instanceId}`);
        } else {
            navigate("/login");
        }
    };
    const renderMessage = () => {
        if (isSuccess) {
            return (
                <>
                    <MessageWrapper align="center">
                        <Message fontSize="42px" showLine={true}>
                            Welcome to Underline<Dot>.</Dot>
                        </Message>
                    </MessageWrapper>
                    <MessageWrapper align="center">
                        <Message fontSize="24px" fontWeight="500">
                            Thanks for signing up!
                        </Message>
                    </MessageWrapper>
                    <MessageWrapper align="center">
                        <Message fontSize="24px" fontWeight="500">
                            Your account has been activated successfully!
                        </Message>
                    </MessageWrapper>
                </>
            );
        }
        if (isError) {
            return (
                <>
                    <MessageWrapper align="center">
                        <Message fontSize="42px" showLine={true}>
                            Welcome to Underline<Dot>.</Dot>
                        </Message>
                    </MessageWrapper>
                    <MessageWrapper align="center">
                        <Message fontSize="24px" fontWeight="500">
                            Ooops! Something went wrong.
                        </Message>
                    </MessageWrapper>
                </>
            );

        }
    }
    return(
        <>
            {isLoading && <Spinner/>}
            <BodyContainer>
                <ImageContainer>
                    <StyledImage src={background} alt="Underline background image" title="underline-background-image" />
                </ImageContainer>
                <UnderlineLogo/>
                <MessageContainer>
                    {renderMessage()}
                    <Button
                        onClick={handleBackToLogin}
                        text="Back to Login"
                        extraMargin="true"
                    />
                </MessageContainer>
            </BodyContainer>
        </>
    );
}
export default AccountConfirmation;