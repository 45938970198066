import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    compareTimestamps,
    convertToSrt,
    convertToVtt,
    downloadFile,
    formatTimestamp,
    getMillisecondAfter,
    mergeSubtitles,
    parseSRT,
    parseVTT,
    splitSubtitle,
    Subtitle,
    Timestamp
} from "./subtitleUtils";
import {SubtitleInput} from "./SubtitleInput";
import SlimButton from "../ui/forms/action/SlimButton";
import styled from "styled-components";
import {TbDragDrop} from "react-icons/tb"
import {CgArrowsMergeAltV} from "react-icons/cg";
import SuccessPopup from "../ui/forms/SuccessPopup";
import RightToLeft from '../../assets/img/text-right-to-left.webp';
import LeftToRight from '../../assets/img/text-left-to-right.webp';
import {Validation, ValidationType} from "../validations/Validations";

const SubtitleContainer = styled.div`
    padding: 15px 5px;
    height: 80%;
    overflow-y: auto;
`;

const EditorContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const SubtitleFileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #b5cef2;
  border-radius: 10px;
  height: 100px;
  margin: 20px;
`;
const FileMessage = styled.div`
  display: flex;
  align-items: center;
  color: #75a0e0;

`;
const ButtonsContainer = styled.div`
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`;

const ChangeTextDirectionButton = styled.button`
    margin-left: 5px;
    margin-top: 5px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1px 30px;
    background: linear-gradient(to right, #eac113, #ffeb3b, #eac113);

    &:hover {
        background: linear-gradient(to right, #ffeb3b, #eac113, #ffeb3b);
    }
    
    @media (max-width: 750px) {
        font-size: 14px;
    }
    @media (max-width: 285px) {
        width: 180px;
    }
`;

const StyledImage = styled.img`
  width: 25px;
  height: 25px;
`;
const MergeButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 20px;`

const MergeButton = styled.button`
  display: flex;
  color: #2d292a;
  border: 1px solid #b5cef2;
  padding: 3px 5px;
  font-size: 25px;
  cursor: pointer;
  border-radius: 4px;
  background: linear-gradient(to right, #eac113, #ffeb3b, #eac113);

  &:hover {
    background-color: #2d292a;
    border: 1px solid #b5cef2;
    color: #fff;
  }

  &:focus {
    outline: none;
  }
`;

interface SubtitlesEditorProps {
    subtitles: Subtitle[],
    updateSubtitles: React.Dispatch<React.SetStateAction<Subtitle[]>>,
    currentTime: Timestamp,
    videoDuration: Timestamp,
    onSubtitleFocus: Function,
    onUpdate?: () => void,
    showUpdateBtn?: boolean,
    onErrorsUpdate?: (validations: Validation[]) => void
}

export const SubtitlesEditor: React.FC<SubtitlesEditorProps> = ({
                                                                    subtitles,
                                                                    updateSubtitles,
                                                                    currentTime,
                                                                    videoDuration,
                                                                    onUpdate,
                                                                    onSubtitleFocus,
                                                                    showUpdateBtn = false,
                                                                    onErrorsUpdate
                                                                }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [overlapSubs, setOverlapSubs] = useState<number[]>([]);
    const [showWarning, setShowWarning] = useState(false);
    const [contentBeforeCursor, setContentBeforeCursor] = useState("");
    const [contentAfterCursor, setContentAfterCursor] = useState("");
    const [direction, setDirection] = useState('ltr');
    const [errors, setErrors] = useState<Validation[]>([]);

    const changeTextDirectionTextDirection = () => {
        if (direction === 'ltr') {
            setDirection('rtl');
        } else {
            setDirection('ltr');
        }
    }

    useEffect(() => {
        if(onErrorsUpdate)
            onErrorsUpdate(errors);
    }, [errors]);

    useEffect(() => {
        validateSubtitlesForm();
    }, [subtitles, videoDuration]);


    const validateSubtitlesForm = () => {
        let formattingErrors: Validation[] = findTimeFormattingErrors();
        let overlappingErrors: Validation[] = findTimeOverlappingErrors();
        let videoDurationErrors: Validation[] = findVideoDurationErrors();
        let internalErrors: Validation[] = findInternalSubErrors();
        setErrors(formattingErrors.concat(overlappingErrors, videoDurationErrors, internalErrors));
    }

    const findInternalSubErrors = () => {
        let validations: Validation[] = []
        for(let i=0; i< subtitles.length; i++){
            let currentSub = subtitles[i];
            let comparator = compareTimestamps(currentSub.start, currentSub.end)
            if(comparator >= 0) {
                validations.push({location: `Subtitle ${currentSub.subtitleId}`, type: ValidationType.ERROR, content: "End time must be after start time", inputId: currentSub.subtitleId})
            }
        }
        return validations;
    }

    const findTimeFormattingErrors = (): Validation[] => {
        let newValidations: Validation[] = [];
        let subsWithErrors: Subtitle[] = subtitles.filter(s => ((s.start.isValidInput !== undefined && !s.start.isValidInput) || (s.end.isValidInput !== undefined && !s.end.isValidInput)));

        subsWithErrors.forEach(sub => newValidations.push({type: ValidationType.ERROR, location: `Subtitle ${sub.subtitleId}`, content: "Incorrect timestamp format: HH:MM:SS.mmm", inputId: sub.subtitleId}))

        return newValidations;
    }

    const findTimeOverlappingErrors = (): Validation[] => {
        if (subtitles.length <= 1) {
            setOverlapSubs([])
            return [];
        }
        let overlappingIds: number[] = [];
        for (let i: number = 0; i < subtitles.length; i++) {
            let currentSub: Subtitle = subtitles[i];
            let previousEnd: Timestamp | null = i > 0 ? subtitles[i - 1].end : null;

            if (previousEnd === null) continue;

            let comparator: number = compareTimestamps(previousEnd, currentSub.start)
            if (comparator > 0) {
                overlappingIds.push(subtitles[i - 1].subtitleId, currentSub.subtitleId);
            }
        }
        setOverlapSubs(overlappingIds);

        return overlappingIds.map(id => ({type: ValidationType.ERROR, location:`Subtitle ${id}`, content: 'Subtitle overlap', inputId: id}));
    }

    const findVideoDurationErrors = (): Validation[] => {
        if (videoDuration.hours === 0 &&
            videoDuration.minutes === 0 &&
            videoDuration.seconds === 0 &&
            videoDuration.millis === 0) {
            return [];
        }
        let subtitleExceedsDuration = subtitles
            .findIndex(s => (compareTimestamps(s.start, videoDuration) > 0) || (compareTimestamps(s.end, videoDuration) > 0));
        if(subtitleExceedsDuration < 0) return [];
        return [{type: ValidationType.WARNING, location: `Subtitle ${subtitles[subtitleExceedsDuration].subtitleId}`, content: "Video duration exceeded", inputId: subtitles[subtitleExceedsDuration].subtitleId}];
    }

    const handleSubtitleUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        updateSubtitles([]);
        if (files) {
            readSubtitlesFile(files);
        }

        if (e.target) {
            e.target.value = '';
        }
    };

    const processFileContent = (extension: string, content: string): Subtitle[] => {
        if (extension === 'srt') {
            return parseSRT(content);
        } else if (extension === 'vtt') {
            return parseVTT(content);
        }
        return [];
    };

    function readSubtitlesFile(files: FileList) {
        if (files && files.length > 0) {
            const file = files[0];

            const reader = new FileReader();
            reader.onload = (event) => {
                if (event.target && event.target.result) {
                    const content = event.target.result as string;
                    const newSubtitles = processFileContent(file.name.split('.').pop() || '', content);
                    updateSubtitles(newSubtitles);
                }
            };
            reader.readAsText(file);
        }
    }

    const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        readSubtitlesFile(files);
    };
    const handleClearAll = () => {
        updateSubtitles([]);
    }

    const handleSubtitleChange = useCallback((newSubtitle: Subtitle) => {
        updateSubtitles((prevSubs) => {
            return prevSubs.map(s => {
                if (s.subtitleId === newSubtitle.subtitleId) {
                    return newSubtitle;
                } else {
                    return s;
                }
            });
        });
    }, [updateSubtitles]);

    const deleteSubtitle = (id: number) => {
        updateSubtitles(prevSubs => {
                return prevSubs
                    .filter(s => s.subtitleId !== id)
                    .map((s, i) => ({...s, subtitleId: i + 1}));
            }
        )
    }
    const addSubtitle = () => {
        updateSubtitles((prevSubs) => {
            if (prevSubs.length === 0) {
                return [{
                    subtitleId: 1,
                    start: {hours: 0, minutes: 0, seconds: 0, millis: 0},
                    end: {hours: 0, minutes: 0, seconds: 0, millis: 999},
                    content: ""
                }]
            }
            let newSubs: Subtitle[] = [...prevSubs]
            let lastSubtitle: Subtitle = prevSubs[prevSubs.length - 1]

            let newStartTime: Timestamp = getMillisecondAfter(lastSubtitle.end);
            let newSubtitle: Subtitle = {
                subtitleId: lastSubtitle.subtitleId + 1,
                start: getMillisecondAfter(lastSubtitle.end),
                end: {...newStartTime, seconds: newStartTime.seconds + 1},
                content: ""
            }

            newSubs.push(newSubtitle);
            return newSubs;
        })
    }
    const isValidVttContent = (vttContent: string) => {
        if (!vttContent.startsWith('WEBVTT')) {
            return false;
        }

        const blocks = vttContent.split(/\n\s*\n/).slice(1);
        for (let block of blocks) {
            const lines = block.split('\n');
            if (lines.length < 3) {
                return false;
            }
            if (!lines[2].trim()) {
                return false;
            }
        }
        return true;
    };

    const downloadVtt = () => {
        let vttContent: string = convertToVtt(subtitles);
        if (vttContent.length > 7 && isValidVttContent(vttContent)) {
            downloadFile(vttContent, `UDL-${new Date().toISOString().replaceAll(":", "-")}.vtt`, "text/vtt");
        } else {
            setShowWarning(true);
        }
    }

    const isValidSrtContent = (srtContent: string) => {
        const blocks = srtContent.split(/\n\s*\n/);
        for (let block of blocks) {
            const lines = block.split('\n');
            if (lines.length < 3 || !lines[2].trim()) {
                return false;
            }
        }
        return true;
    };
    const downloadSrt = () => {
        let srtContent: string = convertToSrt(subtitles);
        if(!isValidSrtContent(srtContent)){
            setShowWarning(true)
        } else {
            setShowWarning(false)
            downloadFile(srtContent, `UDL-${new Date().toISOString().replaceAll(":", "-")}.srt`, "text/srt")
        }
    }

    function handleSplitSubtitle(subtitleId: number) {
        let subToSplitIndex: number = subtitles.findIndex(s => s.subtitleId === subtitleId);
        if (subToSplitIndex >= 0) {
            let subtitleToSplit: Subtitle = subtitles[subToSplitIndex];
            let splits: Subtitle[] = splitSubtitle(subtitleToSplit, contentBeforeCursor?.trim(), contentAfterCursor?.trim())

            updateSubtitles((prevSubs: Subtitle[]): Subtitle[] => {
                let newSubs: Subtitle[] = [];
                for (let i = 0; i < prevSubs.length; i++) {
                    if (i === subToSplitIndex) {
                        newSubs.push(splits[0]);
                        newSubs.push(splits[1]);
                    } else {
                        newSubs.push(prevSubs[i]);
                    }
                }
                return newSubs.map((s, i) => ({...s, subtitleId: i + 1}));
            })
        }
        setContentBeforeCursor("");
        setContentAfterCursor("");
    }
    const checkMerge = (subtitleId: number): boolean => {
        let subsToMerge: Subtitle[] = subtitles.filter(s => s.subtitleId === subtitleId || s.subtitleId === subtitleId + 1);
        if (subsToMerge.length !== 2) {
            return false;
        }
        let sub1: Subtitle = subsToMerge[0];
        let sub2: Subtitle = subsToMerge[1];

        if (sub1.canMerge) {
            if (sub2.canMerge) {
                return true;
            }
        }
        return false;
    }

    const handleMergeWithNext = (subId: number): void => {
        let replacementIndex: number = subtitles.findIndex(s => s.subtitleId === subId);
        let firstSub: Subtitle | undefined = subtitles.find(s => s.subtitleId === subId);
        let secondSub: Subtitle | undefined = subtitles.find(s => s.subtitleId === subId + 1);

        if (firstSub !== undefined && secondSub !== undefined && replacementIndex >= 0) {
            let newSub: Subtitle = mergeSubtitles(firstSub, secondSub);

            updateSubtitles(prevSubs => {
                let newSubs = prevSubs.filter(s => (s.subtitleId !== subId) && (s.subtitleId !== subId + 1));
                newSubs.splice(replacementIndex, 0, newSub);

                return newSubs.map((s, i) => ({...s, subtitleId: i + 1}))
            })
        }
    }

    function checkTimeExceedsVideoDuration(end: Timestamp): boolean {
        if (videoDuration.hours === 0 &&
            videoDuration.minutes === 0 &&
            videoDuration.seconds === 0 &&
            videoDuration.millis === 0) {
            return false;
        }

        return compareTimestamps(end, videoDuration) > 0;
    }

    const handleSubtitleFocus = (subtitle: Subtitle) => {
        onSubtitleFocus(subtitle.start);
    }

    return (
        <EditorContainer>
            <SubtitleContainer>
                {
                    subtitles.length === 0 ?
                        <SubtitleFileContainer
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={handleFileDrop}
                        >
                            <FileMessage>
                                <TbDragDrop style={{marginTop: '1px'}} size={20}/>
                                You Can Drop Your Subtitle File Here
                            </FileMessage>

                        </SubtitleFileContainer>
                        :
                        subtitles.map((sub) =>
                            <React.Fragment key={`subtitle-input-${sub.subtitleId}`}>
                                <div id={`subtitle-input-${sub.subtitleId}`} onFocus={() => handleSubtitleFocus(sub)}>
                                    <SubtitleInput
                                        //The complex key value ensures that this list gets re-rendered each time a subtitle changes.
                                        key={`${sub.subtitleId}-${sub.content}-${formatTimestamp(sub.start)}-${formatTimestamp(sub.end)}`}
                                        subtitle={sub}
                                        onSubtitleChange={handleSubtitleChange}
                                        onDelete={() => deleteSubtitle(sub.subtitleId)}
                                        currentTime={currentTime}
                                        isOverlap={overlapSubs.includes(sub.subtitleId)}
                                        exceedsVideoDuration={checkTimeExceedsVideoDuration(sub.end)}
                                        onSplit={() => handleSplitSubtitle(sub.subtitleId)}
                                        setContentAfterCursor={setContentAfterCursor}
                                        setContentBeforeCursor={setContentBeforeCursor}
                                        textDirection={direction}
                                    />
                                </div>
                                {checkMerge(sub.subtitleId) && <MergeButtonContainer>
                                    <MergeButton
                                        title="Merge selected subtitles"
                                        id={`merge-${sub.subtitleId}`}
                                        onClick={() => handleMergeWithNext(sub.subtitleId)}>
                                        <CgArrowsMergeAltV/>
                                    </MergeButton>
                                </MergeButtonContainer>}
                            </React.Fragment>)
                }
            </SubtitleContainer>
            <ButtonsContainer>
                <ChangeTextDirectionButton
                    onClick={() => changeTextDirectionTextDirection()}
                    title={direction === 'ltr' ? "Change Right-to-Left Direction" : "Change Left-to-Right Direction"}
                >
                    {direction === 'ltr'
                        ? <StyledImage src={LeftToRight} alt="Underline change to right-to-left direction" title="underline-change-to-right-to-left-direction" />
                        : <StyledImage src={RightToLeft} alt="Underline change to left-to-right direction" title="underline-change-to-left-to-right-direction" />
                    }
                </ChangeTextDirectionButton>
                <input type="file" style={{display: 'none'}} accept=".srt, .vtt" ref={fileInputRef}
                       onChange={handleFileChange}/>
                {showUpdateBtn && <SlimButton text={"Update Source"} onClick={onUpdate} marginTop={'5px'} textColor="#fff"
                                         bgColor="#b5cef2"
                                         bgGradient="linear-gradient(to right, #2980b9, #34495e, #2980b9)"/>}
                <SlimButton id={"upload-file-button"} text={"Upload Subtitle"} onClick={handleSubtitleUpload}
                            marginTop={'5px'} textColor="#2d292a" bgColor="#b5cef2"
                            bgGradient="linear-gradient(to right, #eac113, #ffeb3b, #eac113)"/>
                <SlimButton id={"add-subtitle-button"} text={"Add Subtitle"} onClick={addSubtitle} marginTop={'5px'}
                            textColor="#2d292a" bgColor="#eac113"
                            bgGradient="linear-gradient(to right, #eac113, #ffeb3b, #eac113)"/>
                <SlimButton id={"download-srt-button"} text={"Download SRT"} onClick={downloadSrt} marginTop={'5px'}
                            textColor="#2d292a" bgColor="#eac113"
                            bgGradient="linear-gradient(to right, #eac113, #ffeb3b, #eac113)"/>
                <SlimButton id={"download-vtt-button"} text={"Download VTT"} onClick={downloadVtt} marginTop={'5px'}
                            textColor="#2d292a" bgColor="#eac113"
                            bgGradient="linear-gradient(to right, #eac113, #ffeb3b, #eac113)"/>
                <SlimButton id={"clear-all-button"} text={"Clear All"} onClick={handleClearAll} marginTop={'5px'}
                            textColor="#fff" bgColor="#c71313"
                            bgGradient="linear-gradient(to right, #a11212, #c71313, #a11212)"/>
            </ButtonsContainer>

            {showWarning && <SuccessPopup
                successMessage="Make sure all entries have content."
                instructions="Please review and correct before continuing."
                confirmationMessage="Understood!"
                handleClosePopup={()=>setShowWarning(false)}
            />}
        </EditorContainer>
    );
};