import React from "react";
import styled from 'styled-components';
import SlimButton from "../ui/forms/action/SlimButton";

const TitleAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Title = styled.h3`
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 0;

  @media (max-width: 1852px) {
    font-size: 35px;
  }
  @media (max-width: 1590px) {
    padding-top: 50px;
    font-size: 22px;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 30px;
`;
const LandingTitle: React.FC= () => {
    const handleNavigateToLogin = (event: React.MouseEvent) => {
        event.preventDefault();
        window.location.pathname= "/login";
    };
    return(
        <TitleAndButtonContainer>
            <Title>
                Prepare your subtitling <br />
                and captioning files <br />
                for translation with ease.
            </Title>
            <ButtonContainer>
                <SlimButton
                    type="button"
                    bgColor="#EAC113"
                    text="Start Now"
                    textColor="#23201C"
                    onClick={handleNavigateToLogin}
                    bgGradient="#EAC113"
                />
            </ButtonContainer>
        </TitleAndButtonContainer>
    )
}
export default LandingTitle