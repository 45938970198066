import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError
} from "@reduxjs/toolkit/query/react";
import {RootState} from "../store/store";
import {clearTokens, setTokens, TokenInfo} from "../store/tokenSlice";
import {clearUserInfo} from "../store/userSlice";
import {clearCookieConsent} from "../store/cookieConsentSlice";
import {clearInstanceInfo} from "../store/instanceSlice";
import {ENDPOINTS_WITH_NO_AUTHENTICATION_NEEDED} from "../utils/constants";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export interface PageProps {
    page: number;
    size: number;
    totalElements: number;
    totalPages: number;
    lastPage: boolean;
}

export interface PaginationParams {
    page?: number;
    size?: number;
    sortBy?: string;
    sortDir?: string;
}

export const getUrlWithPaginationProps = (url: string, paginationParams: PaginationParams) => {
    url += '?';
    if (paginationParams) {
        if (paginationParams.page) {
            url += `page=${paginationParams.page}&`;
        }
        if (paginationParams.size) {
            url += `size=${paginationParams.size}&`;
        }
        if (paginationParams.sortBy) {
            url += `sortBy=${paginationParams.sortBy}&`;
        }
        if (paginationParams.sortDir) {
            url += `sortDir=${paginationParams.sortDir}&`;
        }
    }

    return url;
}
const REFRESH_TOKEN_ENDPOINT = '/auth/refresh';

const baseQuery = fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers, {getState, endpoint}) => {
        const state = getState() as RootState;
        const authToken = state.tokenInfo.token?.value;

        if (authToken && needsAuthentication(endpoint)) {
            headers.set('Authorization', `Bearer ${authToken}`)
        }
        return headers;
    }
})
const refreshTokenQuery = fetchBaseQuery({
    baseUrl: API_BASE_URL,
    method: 'POST',
    prepareHeaders: (headers, {getState, endpoint}) => {
        const state = getState() as RootState;
        const refreshToken = state.tokenInfo.refreshToken?.value;

        if (refreshToken && needsAuthentication(endpoint)) {
            headers.set('Authorization', `Bearer ${refreshToken}`)
        }
        return headers;
    }
})

const baseQueryWithRefresh: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =
    async (args, api, extraOptions) => {
        let result = await baseQuery(args, api, extraOptions);

        if (result?.error?.status === 403) {
            const refreshResult = await refreshTokenQuery(REFRESH_TOKEN_ENDPOINT, api, {
                ...extraOptions,
            });
            //If the request for the refresh token is successful
            if (refreshResult?.data && !(refreshResult?.error?.status === 403)) {
                //store new token
                api.dispatch(setTokens(refreshResult.data as TokenInfo))
                //retry the initial query
                result = await baseQuery(args, api, extraOptions);
            } else {
                //If something goes wrong, clear the tokens. The user will have to re-login in order to use the app
                api.dispatch(clearTokens());
                api.dispatch(clearUserInfo(null));
                api.dispatch(clearCookieConsent());
                api.dispatch(clearInstanceInfo(null));
            }
        } else if ( result.meta && result.meta.response && result.meta.response.status >= 200 && result.meta.response.status < 300 && typeof result.data === 'string') {
            try {
                result.data = JSON.parse(result.data);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }
        return result;
    }
    const needsAuthentication = (endpoint: string): boolean => {
        return !ENDPOINTS_WITH_NO_AUTHENTICATION_NEEDED.includes(endpoint);
    }

export const underlineApi = createApi({
    baseQuery: baseQueryWithRefresh,
    endpoints: (builder) => ({}),
    tagTypes: ['Instance', 'AllProjects', 'Workspaces','Users','Views','Segment'],
})