import React, { useState } from 'react'
import Breadcrumb from "components/Breadcrumb";
import PageTitle from "components/PageTitle";
import styled from 'styled-components';
import FaqQuestion from './../components/help/FaqQuestion';
import FaqVideo from './../components/help/FaqVideo';

const FaqContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  overflow: auto;
  box-sizing: border-box;
`;

const AllQuestions = styled.div`
  box-sizing: border-box;
  background: #fff none repeat scroll 0 0;
  border-radius: 5px;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.04);
  padding: 30px;
  margin-top: 60px;
  width: 100%;
  max-width: 800px;
`;
const QuestionAnswer = styled.div`
  border: medium none;
  border-radius: 0;
  box-shadow: none;
  margin: 0 0 15px 10px;
  padding-bottom: 10px;

  @media (max-width: 1000px) {
    max-width: 95%;
    padding: 20px;
  }
`;
const faqData = [
  {
    question: "How to create a new project?",
    videoLink: "qlJKBw32AqY",
  },
  {
    question: "How to create a new user in your workspace to collaborate with?",
    videoLink: "T0wE4PVQFWA",
  },
  {
    question: "How to add a user to your workspace?",
    videoLink: "KjdTCaJzT1s",
  },
  {
    question: "How to assign a user to a task?",
    videoLink: "C-8dyfJBEFg",
  },
  {
    question: "How to download a bilingual file?",
    videoLink: "y98nRxR_mW8",
  },
  {
    question: "How to upload the translated bilingual file?",
    videoLink: "emusUhWYyZk",
  },
  {
    question: "How to set a task as completed?",
    videoLink: "kqhRvHaGreY",
  },
  {
    question: "How to download the translated SRT file?",
    videoLink: "GH_k3ZTdy-4",
  },
  {
    question: "How to use the SRT and VTT converter?",
    videoLink: "TLCa3_bJNr8",
  },
];
const Help: React.FC = () => {
  const [activePanel, setActivePanel] = useState<number | null>(0);
  const handleTogglePanel = (index: number) => {
    if (activePanel === index) {
      setActivePanel(null);
    } else {
      setActivePanel(index);
    }
  };

  return (
    <>
      <Breadcrumb link="Help" />
      <PageTitle title="Frequently Asked Questions"/>

      <FaqContainer>
        <AllQuestions>
          {faqData.map((item, index) => (
              <QuestionAnswer key={index} id={`question${index + 1}`}>
                <FaqQuestion
                    questionNumber={String(index + 1)}
                    collapseNumber={`#collapse${index + 1}`}
                    question={item.question}
                    isActive={activePanel === index + 1}
                    onToggle={() => handleTogglePanel(index + 1)}
                />
                <FaqVideo
                    questionNumber={String(index + 1)}
                    collapseNumber={`#collapse${index + 1}`}
                    videoLink={item.videoLink}
                    isActive={activePanel === index + 1}
                />
              </QuestionAnswer>
          ))}
        </AllQuestions>
      </FaqContainer>
    </>
  )
}
export default Help