import React from "react";
import Breadcrumb from "components/Breadcrumb";
import PageTitle from "components/PageTitle";
import styled from 'styled-components';
import backgroundImage from '../assets/img/background-grey.webp';
import {UNDERLINE_VERSION} from '../utils/constants'
import {SUPPORT_EMAIL} from '../utils/constants'

const ThisYear = new Date().getFullYear();
const CenteredContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;    
    width: 100%;
`;
const AboutBox = styled.div`
  position: relative;
  color: #fff;
  box-shadow: 0 2px 15px 0 rgba(0,0,0,0.5);
  border-radius: 4px;
  padding: 20px 30px;
  margin: 2%;
  width: 650px;
  text-align: center;
`;
const BackgroundImage = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
    z-index: -1;
`;
const StyledHr = styled.hr`
    border: 0;
    border-top: 2px solid #ffffff;
    margin-bottom:  10px;
`;
const Title = styled.h5`
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 0;
`;
const Info = styled.p`
    font-size: 18px;
`;
const Link = styled.a`
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    &:hover {
      color: #EAC113;
    }   
`;
const About: React.FC = () => {
  return (
    <>
      <Breadcrumb link="About" />
      <PageTitle title="About Underline"/>
      
      <CenteredContainer>
          <AboutBox>
              <BackgroundImage src={backgroundImage} alt="Underline background image" title="underline-background-image" />
              <Title>Version:</Title>
              <Info>{UNDERLINE_VERSION}</Info>
              <StyledHr />

              <Title>Customer Support:</Title>
              <Info>Our support team is here to assist you with any questions or concerns. <br />
                  Please feel free to contact us at <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>
                  <br />
                  We strive to respond as promptly as possible.
              </Info>
              <StyledHr />

              <Title>Copyright and Usage:</Title>
              <Info>Copyright © 2015-{ThisYear}, Sinapi Inc. All rights reserved.<br />
                  Use of this computer program is subject to the terms of the applicable license.<br />
                  Any reproduction, distribution, or use of this program or any portion of it<br />
                  other than as authorized by its owner is strictly prohibited <br />
                  and may result in civil and criminal penalties.
              </Info>
          </AboutBox>
      </CenteredContainer>
    </>
  );
}

export default About;
