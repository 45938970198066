export interface Timestamp {
    hours: number,
    minutes: number,
    seconds: number,
    millis: number,
    isValidInput?: boolean
}
export interface Subtitle{
    subtitleId: number,
    start: Timestamp,
    end: Timestamp
    content: string,
    canMerge?: boolean
}

// Function to convert subtitle data to VTT format
export function convertToVtt(subtitles: Subtitle[]) {
    let vttContent = 'WEBVTT\n\n'; // VTT header

    subtitles.forEach((subtitle, index) => {
        vttContent += `${index + 1}\n`; // Subtitle identifier
        vttContent += `${formatVttTimestamp(subtitle.start)} --> ${formatVttTimestamp(subtitle.end)}\n`; // Time range
        vttContent += `${subtitle.content}\n\n`; // Subtitle content
    });
    if(vttContent.endsWith("\n\n")){
        vttContent = vttContent.substring(0,vttContent.length-1);
    }
    return vttContent;
}

// Function to convert subtitle data to SRT format
export function convertToSrt(subtitles: Subtitle[]) {
    let srtContent = '';
    subtitles.forEach((subtitle, index) => {
        srtContent += `${index + 1}\n`; // Subtitle identifier
        srtContent += `${formatSrtTimestamp(subtitle.start)} --> ${formatSrtTimestamp(subtitle.end)}\n`; // Time range
        srtContent += `${subtitle.content}\n\n`; // Subtitle content
    });

    if(srtContent.endsWith("\n\n")){
        srtContent = srtContent.substring(0,srtContent.length-1);
    }
    return srtContent;
}


export function formatVttTimestamp(timestamp: Timestamp): string{
    const {hours, minutes, seconds, millis} = timestamp;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    const formattedMillis = millis.toString().padStart(3, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}.${formattedMillis}`
}

function formatSrtTimestamp(timestamp: Timestamp): string{
    const {hours, minutes, seconds, millis} = timestamp;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
    const formattedMillis = millis.toString().padStart(3, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds},${formattedMillis}`
}

export function formatTimestamp(timestamp: Timestamp): string {
    return `${timestamp.hours.toString().padStart(2, '0')}:${timestamp.minutes.toString().padStart(2, '0')}:${timestamp.seconds.toString().padStart(2, '0')}.${timestamp.millis.toString().padStart(3, '0')}`
}

export function parseTimestamp(timestampStr: string): Timestamp | null {
    const regex = /^(\d{2}):(\d{2}):(\d{2}).(\d{3})$/;
    const match = timestampStr.match(regex);

    if (match) {
        const [, hoursStr, minutesStr, secondsStr, millisStr] = match;
        const hours = parseInt(hoursStr, 10);
        const minutes = parseInt(minutesStr, 10);
        const seconds = parseInt(secondsStr, 10);
        const millis = parseInt(millisStr, 10);

        if (
            hours >= 0 && hours <= 23 &&
            minutes >= 0 && minutes <= 59 &&
            seconds >= 0 && seconds <= 59 &&
            millis >= 0 && millis <= 999
        ) {
            return {
                hours,
                minutes,
                seconds,
                millis,
            };
        }
    }

    return null; // Return null for invalid input.
}
export const secondsToTimestampStr = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    const millis = Math.floor((seconds % 1) * 1000);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}.${millis.toString().padStart(3, '0')}`;
}
export function downloadFile(content: string, filename: string, contentType: string) {
    const blob = new Blob([content], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}

export const parseSRT = (srtContent: string): Subtitle[] => {
    const normalizedContent = srtContent.replace(/\r\n|\r|\n/g, '\n');
    const regex = /(\d+)\s+(\d{2}):(\d{2}):(\d{2}),(\d{3})\s+-->\s+(\d{2}):(\d{2}):(\d{2}),(\d{3})\s+([\s\S]+?)(?=\r?\n\r?\n|\r?\n\d+\r?\n|\s*$)/g;

    let match;
    const subtitles: Subtitle[] = [];

    while ((match = regex.exec(normalizedContent)) !== null) {
        const subtitleId = parseInt(match[1], 10);
        const start = {
            hours: parseInt(match[2], 10),
            minutes: parseInt(match[3], 10),
            seconds: parseInt(match[4], 10),
            millis: parseInt(match[5], 10)
        };
        const end = {
            hours: parseInt(match[6], 10),
            minutes: parseInt(match[7], 10),
            seconds: parseInt(match[8], 10),
            millis: parseInt(match[9], 10)
        };
        const content = match[10].trim();

        subtitles.push({
            subtitleId,
            start,
            end,
            content
        });
    }
    return subtitles;
};

export const parseVTT = (vttContent: string): Subtitle[] => {
    const normalizedContent = vttContent.replace(/\r\n|\r|\n/g, '\n');
    const regex = /(\d+)\s+(\d{2}):(\d{2}):(\d{2})\.(\d{3})\s+-->\s+(\d{2}):(\d{2}):(\d{2})\.(\d{3})\s+([\s\S]+?)(?=\r?\n\r?\n|\r?\n\d+\r?\n|\s*$)/g;
    let match;
    const subtitles: Subtitle[] = [];

    while ((match = regex.exec(normalizedContent)) !== null) {
        const subtitleId = parseInt(match[1], 10);
        const start = {
            hours: parseInt(match[2], 10),
            minutes: parseInt(match[3], 10),
            seconds: parseInt(match[4], 10),
            millis: parseInt(match[5], 10)
        };
        const end = {
            hours: parseInt(match[6], 10),
            minutes: parseInt(match[7], 10),
            seconds: parseInt(match[8], 10),
            millis: parseInt(match[9], 10)
        };
        const content = match[10].trim();

        subtitles.push({
            subtitleId,
            start,
            end,
            content
        });
    }

    return subtitles;
};

export function getMillisecondAfter({hours, minutes, seconds, millis}: Timestamp): Timestamp {

    if (millis === 999){
        millis = 0;
        seconds+=1;
    } else {
        millis +=1;
        return {hours, minutes, seconds, millis};
    }

    if(seconds === 60){
        seconds = 0;
        minutes += 1;
    }

    if(minutes === 60){
        minutes = 0;
        hours +=1;
    }

    return {hours, minutes, seconds, millis};
}
export function getMillisecondBefore({ hours, minutes, seconds, millis }: Timestamp): Timestamp {
    if (hours < 0 || minutes < 0 || seconds < 0 || millis < 0) {
        throw new Error("Timestamp components cannot be negative");
    }

    const totalMillis = hours * 3600000 + minutes * 60000 + seconds * 1000 + millis;
    const millisBefore = totalMillis - 1; // Calculate one millisecond before

    // Calculate new components
    const newHours = Math.floor(millisBefore / 3600000);
    const newMinutes = Math.floor((millisBefore % 3600000) / 60000);
    const newSeconds = Math.floor((millisBefore % 60000) / 1000);
    const newMillis = millisBefore % 1000;

    return {
        hours: newHours,
        minutes: newMinutes,
        seconds: newSeconds,
        millis: newMillis,
    };
}

/*
* Returns
* -1 if t1 is before t2
* 0 if they are equal
* +1 if t1 is after t2*/
export function compareTimestamps(t1: Timestamp, t2: Timestamp): number {
    if (t1.hours < t2.hours) return -1;
    if (t1.hours > t2.hours) return 1;

    if (t1.minutes < t2.minutes) return -1;
    if (t1.minutes > t2.minutes) return 1;

    if (t1.seconds < t2.seconds) return -1;
    if (t1.seconds > t2.seconds) return 1;

    if (t1.millis < t2.millis) return -1;
    if (t1.millis > t2.millis) return 1;

    return 0; // timestamps are equal
}

export function splitSubtitle(subtitle: Subtitle, contentBeforeCursor: string, contentAfterCursor: string): Subtitle[] {
    let splitTimestamp: Timestamp = getTimestampSplit(subtitle.start, subtitle.end);
    let sub1: Subtitle;
    let sub2: Subtitle;
    if(contentBeforeCursor.length < 1 && contentAfterCursor.length < 1){
        sub1 = {subtitleId: subtitle.subtitleId, start: subtitle.start, end: splitTimestamp, content: subtitle.content};
        sub2 = {subtitleId: subtitle.subtitleId+1, start: getMillisecondAfter(splitTimestamp), end: subtitle.end, content: ''};
    } else {
        sub1 = {subtitleId: subtitle.subtitleId, start: subtitle.start, end: splitTimestamp, content: contentBeforeCursor};
        sub2 = {subtitleId: subtitle.subtitleId+1, start: getMillisecondAfter(splitTimestamp), end: subtitle.end, content: contentAfterCursor};
    }
    return [sub1, sub2];
}

function getTimestampSplit(timestamp1: Timestamp, timestamp2: Timestamp): Timestamp {
    const totalMillis1 =
        timestamp1.hours * 3600000 +
        timestamp1.minutes * 60000 +
        timestamp1.seconds * 1000 +
        timestamp1.millis;

    const totalMillis2 =
        timestamp2.hours * 3600000 +
        timestamp2.minutes * 60000 +
        timestamp2.seconds * 1000 +
        timestamp2.millis;

    const middleMillis = (totalMillis1 + totalMillis2) / 2;

    return {
        hours: Math.floor(middleMillis / 3600000),
        minutes: Math.floor((middleMillis % 3600000) / 60000),
        seconds: Math.floor((middleMillis % 60000) / 1000),
        millis: Math.floor(middleMillis % 1000),
    };
}

export const mergeSubtitles = (sub1: Subtitle, sub2: Subtitle) => {
    let content1: string = sub1.content ? sub1.content.trim() : '';
    let content2: string = sub2.content ? sub2.content.trim() : '';

    let newContent: string = `${content1} ${content2}`;

    return {subtitleId: sub1.subtitleId, start: sub1.start, end: sub2.end, content: newContent}
}

export function secondsToTimestamp(totalSeconds: number): Timestamp {
    if (totalSeconds < 0) {
        return {
            hours: 0,
            minutes: 0,
            seconds: 0,
            millis: 0
        };
    }

    const hours = Math.floor(totalSeconds / 3600);
    const remainingSeconds = totalSeconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = Math.floor(remainingSeconds % 60);
    const millis = Math.round((remainingSeconds % 60 - seconds) * 1000);

    return {
        hours,
        minutes,
        seconds,
        millis
    };
}

export function getSecondsFromTimestamp(timestamp: Timestamp): number {
    return timestamp.hours * 3600 + timestamp.minutes * 60 + timestamp.seconds + timestamp.millis / 1000;
}

export function areSubtitlesEquivalent (s1: Subtitle, s2: Subtitle): boolean {
    if(s1.content !== s2.content) return false;
    if(compareTimestamps(s1.start,s2.start) !== 0) return false;
    if(compareTimestamps(s1.end, s2.end) !== 0) return false;
    return true;
}

export function parseSubtitleTimestampString(content: string): {id: number, start: string, end: string}{
    let start = '', end = '';
    let lines: string[] = content.split(/\r?\n/);
    let id: number = 0;
    if(lines.length > 1){
        let timesLine: string | undefined = lines.find(str => str.match('.*?\\s-->.*?'));
        if(timesLine){
            let times: string[] = timesLine.split(' --> ');
            if(times.length === 2){
                start = times[0];
                end = times[1];
            }
        }
        id = parseInt(lines[0].trim())
    }
    return {id, start, end};
}

export function validateSubtitleLength(content: string, maxLines: number, maxCharsPerLine: number): boolean {
    // Split the content into lines
    const lines = content.split(/\r?\n/);

    if(maxLines && maxLines > 0){
        // Check if the number of lines exceeds the maximum allowed
        if (lines.length > maxLines) {
            return false;
        }
    }

    if(maxCharsPerLine && maxCharsPerLine > 0){
        // Check if any line exceeds the maximum number of characters
        for (const line of lines) {
            if (line.length > maxCharsPerLine) {
                return false;
            }
        }
    }
    // All checks passed, the content is valid
    return true;
}