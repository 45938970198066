import React, {useEffect} from "react";
import {clearCookieConsent, CookieConsentState} from "../../store/cookieConsentSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/store-hooks";
import {Outlet} from "react-router-dom";
import CookieMessage from "./CookieMessage";
import CookieGear from "./CookieGear";

interface CookieConsentProps {
    isSidebarCollapsed: boolean;
}
export const CookieConsent: React.FC<CookieConsentProps> = ({ isSidebarCollapsed }) => {
    const cookieState: CookieConsentState = useAppSelector((state) => state.cookieConsent);
    const shouldShowCookieGear = (!cookieState.thirdPartyCookies && cookieState.necessaryCookies) || (cookieState.consent && cookieState.necessaryCookies && cookieState.expirationDate);

    const dispatch = useAppDispatch();
    useEffect(() => {
        if(cookieState.expirationDate && new Date().getTime() > cookieState.expirationDate){
            dispatch(clearCookieConsent())
        }
    }, [dispatch, cookieState.expirationDate]);
    return (
        <>
            {shouldShowCookieGear ? <CookieGear isSidebarCollapsed={isSidebarCollapsed} /> : <CookieMessage />}
            <Outlet/>
        </>
    );
};