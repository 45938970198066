import React from "react";
import Breadcrumb from "components/Breadcrumb";
import PageTitle from "components/PageTitle";
import styled from 'styled-components';
import RequireRole, {UserRole} from "../components/RequireRole";
import {SUBSCRIPTION_PLANS} from "../utils/constants";
import SubscriptionCard from "../components/subscription/SubscriptionCard";

const SubscriptionContainer = styled.div`
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;
const Subscription: React.FC = () => {
    return(
        <>
            <RequireRole allowedRoles={[UserRole.ROLE_ADMIN, UserRole.ROLE_OWNER]}>
                <Breadcrumb link="Subscriptions" />
                <PageTitle title="Subscriptions"/>
                <SubscriptionContainer>
                    {SUBSCRIPTION_PLANS.map((plan, index) => (
                        <SubscriptionCard plan={plan}/>
                    ))}
                </SubscriptionContainer>
            </RequireRole>
        </>
    );
}
export default Subscription;