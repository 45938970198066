import React, {useEffect, useRef, useState, useLayoutEffect} from "react";
import {FaUserAlt, FaUserCheck, FaUserFriends} from 'react-icons/fa';
import {RiLock2Fill, RiLockPasswordFill} from 'react-icons/ri';
import {MdAlternateEmail} from 'react-icons/md';
import {BsFillPersonVcardFill} from 'react-icons/bs';
import AuthForm from '../components/ui/forms/auth/AuthForm';
import AuthFormInput from "../components/ui/forms/auth/AuthFormInput";
import InvalidFeedback from '../components/ui/forms/InvalidFeedback';
import AuthFormButton, {ButtonType} from '../components/ui/forms/auth/AuthFormButton';
import SuccessPopup from '../components/ui/forms/SuccessPopup';
import styled from 'styled-components';
import {Link, useNavigate} from 'react-router-dom';
import {RegistrationInfo, useRegisterMutation} from "../services/usersApiSlice";
import Spinner from "../components/spinner/Spinner";
import ReCAPTCHA from "react-google-recaptcha";

const InputGroup = styled.div<{ width?: string }>`
  margin-bottom: -20px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: ${(props) => props.width || 'auto'};

  @media (max-width: 680px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;
const AcceptTermsContainer = styled.div`
  margin-left: 20px;
  margin-top: 20px;
  color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Checkbox = styled.input`
  margin-right: 5px;
  margin-top: 5px;
`;
const TermsLabel = styled.label`
  font-weight: 500;
  user-select: none;
`;
const TermsLink = styled(Link)`
  color: inherit;
  text-decoration: underline;

  &:hover {
    color: #EAC113;
    text-decoration: underline;
  }
`;
const CaptchaContainer = styled.div`
  margin: 25px 20px -30px 20px;

  @media screen and (min-resolution: 140dpi) {
    transform: scale(0.77);
    transform-origin: 0 0;
  }  
`;

const footerLinks = [
    {
        text: 'Already have an account? ',
        linkText: 'Click here',
        link: '/login',
    },
];
function useMediaQuery(query: string) {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useLayoutEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        media.addEventListener('change', listener);
        return () => media.removeEventListener('change', listener);
    }, [matches, query]);

    return matches;
}
const Register: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const navigate = useNavigate();
    const [register, {isLoading, isError, error, isSuccess}] = useRegisterMutation();
    const InputWidth = '230px';
    const captchaRef = useRef(null);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width: 680px)');

    useEffect(() => {
        // @ts-ignore
        captchaRef.current?.reset();
    }, []);
    const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTermsAccepted(e.target.checked);
    };
    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailRegex.test(email);
    };
    const isValidPassword = (password: string): boolean => {
        const lengthCheck = /.{9,}/.test(password);
        const upperCaseCheck = /[A-Z]/.test(password);
        const symbolOrNumberCheck = /[\d\W]/.test(password);
        return lengthCheck && upperCaseCheck && symbolOrNumberCheck;
    }

    const [fields, setFields] = useState<RegistrationInfo>({
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        password: '',
        repeatPassword: '',
        company: '',
    });

    const handleClosePopup = () => {
        setShowSuccessPopup(false);
        navigate("/login");
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFields({...fields, [name]: value});
    };

    function hasEmptyFields() {
        let hasEmptyFields = false;

        for (const fieldName in fields) {
            if (Object.prototype.hasOwnProperty.call(fields, fieldName)) {
                const fieldValue = fields[fieldName as keyof RegistrationInfo];
                if (fieldValue === undefined || (typeof fieldValue === 'string' && fieldValue.trim() === "")) {
                    hasEmptyFields = true;
                }
            }
        }
        return hasEmptyFields;
    }
    const validateForm = (): boolean => {
        if (hasEmptyFields()) {
            setErrorMessage("All fields are required.");
            return false;
        }
        if (!isValidEmail(fields.email)) {
            setErrorMessage("Invalid email format.");
            return false;
        }
        if (!isValidPassword(fields.password)) {
            setErrorMessage("Password must be at least 9 characters long, contain at least one uppercase letter and at least one number or symbol.");
            return false;
        }
        if (fields.password !== fields.repeatPassword) {
            setErrorMessage("Passwords do not match.");
            return false;
        }
        if (!termsAccepted) {
            setErrorMessage("You must accept the terms and conditions.");
            return false;
        }
        if (!isCaptchaValid) {
            setErrorMessage("Captcha verification failed. Please try again.");
            return false;
        }
        return true;
    };

    const handleRegistration = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        setErrorMessage('');
        register(fields).unwrap()
            .then((response) => {
                setShowSuccessPopup(true);
            })
            .catch((error) => {
                if (error.status === 400) {
                    console.log("Error 400: ", error)
                    let errorMessage: string = '';
                    for (const key in error.data) {
                        errorMessage += error.data[key] + ". ";
                    }
                    setErrorMessage(errorMessage);
                } else {
                    setErrorMessage("An unexpected error occurred.")
                }
            })
    };
    function verifyCaptcha(value: any) {
        if (value) {
            setIsCaptchaValid(true);
        } else {
            setErrorMessage("You must prove you are a human.");
            setIsCaptchaValid(false);
        }
    }
    function handleCaptchaExpiration() {
        setErrorMessage("Captcha verification expired. Please try again.");
        setIsCaptchaValid(false);
        // @ts-ignore
        captchaRef.current.reset();
    }
    return (
        <>
            {isLoading && <Spinner/>}
            <AuthForm title="Create an Account" width={isSmallScreen ? 335 : 655} height={isSmallScreen ? 720 :520} footerLinks={footerLinks}
                      hasError={!!errorMessage} submitHandler={handleRegistration} extraMargin={'50px'}>
                <InputGroup>
                    <AuthFormInput
                        name="firstName"
                        prependIcon={<FaUserAlt/>}
                        placeholder="First Name"
                        type="text"
                        value={fields.firstName}
                        onChange={handleInputChange}
                        inputWidth={InputWidth}
                    />

                    <AuthFormInput
                        name="lastName"
                        prependIcon={<FaUserFriends/>}
                        placeholder="Last Name"
                        type="text"
                        value={fields.lastName}
                        onChange={handleInputChange}
                        inputWidth={InputWidth}
                    />
                </InputGroup>

                <InputGroup>
                    <AuthFormInput
                        name="username"
                        prependIcon={<FaUserCheck/>}
                        placeholder="Username"
                        type="text"
                        value={fields.username}
                        onChange={handleInputChange}
                        inputWidth={InputWidth}
                    />

                    <AuthFormInput
                        name="email"
                        prependIcon={<MdAlternateEmail/>}
                        placeholder="Email"
                        type="email"
                        value={fields.email}
                        onChange={handleInputChange}
                        inputWidth={InputWidth}
                    />
                </InputGroup>

                <InputGroup>
                    <AuthFormInput
                        name="password"
                        prependIcon={<RiLock2Fill/>}
                        placeholder="Password"
                        type="password"
                        value={fields.password}
                        onChange={handleInputChange}
                        inputWidth={InputWidth}
                    />

                    <AuthFormInput
                        name="repeatPassword"
                        prependIcon={<RiLockPasswordFill/>}
                        placeholder="Repeat Password"
                        type="password"
                        value={fields.repeatPassword}
                        onChange={handleInputChange}
                        inputWidth={InputWidth}
                    />
                </InputGroup>

                <InputGroup>
                    <AuthFormInput
                        name="company"
                        prependIcon={<BsFillPersonVcardFill/>}
                        placeholder="Company"
                        type="text"
                        value={fields.company}
                        onChange={handleInputChange}
                        inputWidth={InputWidth}
                    />
                </InputGroup>
                <AcceptTermsContainer>
                    <Checkbox
                        type="checkbox"
                        checked={termsAccepted}
                        onChange={handleTermsChange}
                    />
                    <TermsLabel htmlFor="accept-terms">
                        Accept{" "}
                        <TermsLink to="/terms-and-conditions">Terms and Conditions</TermsLink>
                    </TermsLabel>
                </AcceptTermsContainer>
                <AuthFormButton type={ButtonType.submit} text='Register'/>
                <InputGroup>
                    {errorMessage && <InvalidFeedback errorMessage={errorMessage}/>}
                </InputGroup>
                <CaptchaContainer>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        ref={captchaRef}
                        onChange={verifyCaptcha}
                        onExpired={handleCaptchaExpiration}
                        theme="dark"
                        size="normal"
                    />
                </CaptchaContainer>
                {showSuccessPopup &&
                    <SuccessPopup
                        successMessage="Registration Success!"
                        instructions="Please confirm the email we sent."
                        confirmationMessage="SURE!"
                        handleClosePopup={handleClosePopup}
                    />}
            </AuthForm>
        </>
    );
};
export default Register;