import React from 'react';
import styled from "styled-components";
import {MdErrorOutline} from "react-icons/md";
import {IoWarningOutline} from "react-icons/io5";

const ListContainer = styled.div`
    max-height: 100%; /* Set the maximum height to the height of ValidationsContainer's row */
    overflow-y: auto;
    padding: 0;
    margin: 0;
`;

const StyledItem = styled.div`
    padding: 0 10px;
    border: 1px solid #aaa;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: #f0f0f0;
    }
`
const Title = styled.h6`
    font-size: 15px;
    margin: 0;
    padding: 1px 5px;
    background-color: #eac113;
`

const EmptyValidation = styled.div`
    height: 100%;
    text-align: center;
`

export enum ValidationType {
    ERROR,
    WARNING
}

export interface Validation {
    type: ValidationType,
    location: string,
    content: string,
    inputId: number
}

const Validations = ({children}: {children: React.ReactNode[]}) => {
    let content;

    if(children.length > 0){
        content = children;
    }else {
        content = <EmptyValidation><p>Nothing to show</p></EmptyValidation>
    }

    return (
        <>
            <Title>Validations</Title>
            <ListContainer>
                {content}
            </ListContainer>
        </>
    );
};

export default Validations;

export const ValidationItem = ({validation: {type, location, content, inputId}, onValidationClick}: {validation: Validation, onValidationClick: ((params: {id: number, time: Date}) => void)}) => {
    let icon;
    switch (type) {
        case ValidationType.ERROR:
            icon = <MdErrorOutline color={"red"}/>;
            break;
        case ValidationType.WARNING:
            icon = <IoWarningOutline color={"darkgoldenrod"}/>;
            break;
    }

    const handleClick = () => {
        onValidationClick({id: inputId, time: new Date()});
    }
    return (
        <StyledItem onClick={handleClick}>
            <div>
                <strong>{`${location}: `}</strong>{content}
            </div>
            {icon}
        </StyledItem>
    )
}