import React, {useState, useEffect, useCallback, ChangeEvent} from "react";
import styled from "styled-components";
import Breadcrumb from "components/Breadcrumb";
import PageTitle from "components/PageTitle";
import InvalidFeedback from "components/ui/forms/InvalidFeedback";
import PopUp from "../components/ui/forms/SuccessPopup";
import {HiDotsVertical, HiUserGroup} from "react-icons/hi";
import {BsFillDiagram3Fill, BsPersonWorkspace} from "react-icons/bs";
import {AiFillFolderOpen} from "react-icons/ai";
import {ImCheckmark} from "react-icons/im";
import {RiEdit2Fill} from "react-icons/ri";
import {FaUserAlt, FaUserFriends, FaUserCheck, FaUserAstronaut} from "react-icons/fa";
import {MdAlternateEmail} from "react-icons/md";
import {BsFillPersonVcardFill} from "react-icons/bs";
import InfoBox from "./../components/administration/InfoBox";
import FullTable from "../components/tables/FullTable";
import SwitchButton from "../components/buttons/SwitchButton";
import FormTitle from "../components/ui/forms/action/ActionFormTitle";
import FormLabel from "../components/ui/forms/action/ActionFormLabel";
import FormInput from "../components/ui/forms/action/ActionFormInput";
import SlimButton from "../components/ui/forms/action/SlimButton";
import SelectOption from "../components/ui/forms/action/SelectOption";
import ModalPopUp from "./../components/administration/ModalPopUp";
import EditUserForm from "./../components/administration/EditUserForm";
import {
    useActivateUserMutation,
    useCountUsersQuery,
    useDisableUserMutation,
    useGetUsersQuery,
    useInviteUserMutation,
    TypeOfList,
    View,
    NewUserInfo
} from "../services/usersApiSlice";
import {formatHeader} from "./Projects";
import {useCountProjectsQuery} from "../services/projectsApiSlice";
import {useAppSelector} from "../hooks/store-hooks";
import {UserInfo} from "../store/userSlice";
import Spinner from "../components/spinner/Spinner";
import PaginationControl from "../components/ui/PaginationControl";
import {
    PAGE_SIZE_OPTIONS,
    ROLE_OPTIONS,
    ROLE_HIERARCHY,
    USER_ROLE_TYPE,
    FORMAT_ROLE_HIERARCHY,
} from "../utils/constants";
import {useGetWorkspacesQuery, Workspace} from '../services/workspacesApiSlice';
import RequireRole, {UserRole} from "../components/RequireRole";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const InfoBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: -7.5px;
  margin-left: -7.5px;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;
const Tabs = styled.div`
  display: flex;
  width: 250px;
  margin-left: 10px;
`;
const Tab = styled.div<{ active: boolean }>`
  flex: 1;
  padding: 10px;
  background-color: ${({active}) => (active ? "#fff" : "#fcfcfc")};
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-weight: bold;
  border-top: 3px solid #EAC113;
  color: ${({active}) => (active ? "#000" : "#888")};
  border-top: ${({active}) => (active ? "3px solid #EAC113" : "1.5px solid #EAC113")};
  border-bottom: none;

  &:hover {
    background-color: #f5f5f5;
    color: #000;
  }
`;
const TabContent = styled.div<{ active: boolean }>`
  display: ${({active}) => (active ? "block" : "none")};
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 20px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 15px
`;
const TableContainer = styled.div`
  overflow-x: auto;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;
const NewUserFormContainer = styled.div`
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 470px;
`;
const FormBody = styled.div`
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
`;
const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  @media (max-width: 780px) {
    flex-direction: column;
  }
`;
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;

  @media (max-width: 780px) {
    margin-right: 0;
  }
`;
const ButtonsContainer = styled.div`
  margin-top: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
`;
const FilterGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: flex-end;
`;
interface FormField {
    labelIcon: any;
    labelText: string;
    inputId: string;
    inputType: string;
    inputName: string;
    inputValue: string;
    onChange: string;
}
const validateEmail = (email: string): boolean => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
};
interface User {
    id: number;
    first_name: string;
    last_name: string;
    username: string;
    email: string;
    company: string;
    role: string;
    edit: React.ReactNode;
    status: React.ReactNode;
    last_login: string;
}
interface FieldStateAndHandler {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
interface FieldStatesAndHandlers {
    [key: string]: FieldStateAndHandler;
}
interface ShowSuccessPopupProps {
    successMessage?: string;
    instructions: string;
    show: boolean;
}
interface UserTableProps {
    id: number,
    first_name: string,
    last_name: string,
    username: string,
    email: string,
    company: string,
    role: string,
    edit: React.ReactNode,
    status: React.ReactNode,
    last_login: string
}
interface WorkspaceOption{
    value: number;
    label: string;
}
interface ProjectCount {
    name: string;
    count: number;
}
const TableHeader = ["id", "first_name", "last_name", "username", "email", "company", "role", "edit", "status", "last_login"];
const Administration: React.FC = () => {
    const userInfo = useAppSelector(state => state.userInfo);
    const [currentUserRole, setCurrentUserRole] = useState<USER_ROLE_TYPE | "">(userInfo.roles[0]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalProjects, setTotalProjects] = useState(0);
    const [activeProjects, setActiveProjects] = useState(0);
    const [closedProjects, setClosedProjects] = useState(0);
    const [activeTab, setActiveTab] = useState("tab1");
    const [allUsers, setAllUsers] = useState<User[]>([]);
    const [showSuccessPopup, setShowSuccessPopup] = useState<{
        show: boolean;
        successMessage?: string;
        message?: string;
    }>({show: false, message: ""});
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [selectedUserStatus, setSelectedUserStatus] = useState<boolean | null>(null);
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
    const [confirmationResolver, setConfirmationResolver] = useState<{
        resolve: () => void;
        reject: () => void;
    } | null>(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [newUserFirstName, setNewUserFirstName] = useState("");
    const [newUserLastName, setNewUserLastName] = useState("");
    const [newUserUsername, setNewUserUsername] = useState("");
    const [newUserEmail, setNewUserEmail] = useState("");
    const [newUserCompany, setNewUserCompany] = useState("");
    const [newUserRole, setNewUserRole] = useState("");
    const [showEditUserPopup, setShowEditUserPopup] = useState(false);
    const [selectedUserForEdit, setSelectedUserForEdit] = useState<number | null>(null);
    const countUsersQuery = useCountUsersQuery();
    const countProjectsQuery = useCountProjectsQuery();
    const [pageSize, setPageSize] = useState(10);
    const [sortPageDirection, setSortPageDirection] = useState("asc");
    const [sortPageBy, setPageSortBy] = useState('id')
    const [currentPage, setCurrentPage] = useState(0);
    const getUsersQuery = useGetUsersQuery({page: currentPage, size: pageSize, sortBy: sortPageBy, sortDir:sortPageDirection});
    const [activateUser, userActivationResult] = useActivateUserMutation();
    const [disableUser, disableUserResult] = useDisableUserMutation();
    const [inviteUser, userInviteResult] = useInviteUserMutation();
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [filteredRoleOptions, setFilteredRoleOptions] = useState<{ value: string; label: string }[]>([]);
    const usersLimit = useAppSelector((state) => state.instanceInfo.users);
    const [showUsersLimitPopup, setShowUsersLimitPopup] = useState(false);
    const totalActiveUser =useAppSelector((state) => state.instanceInfo.activeUsers);
    const [newUserWorkspace, setNewUserWorkspace] =  useState<WorkspaceOption | null>(null);
    const [workspaces, setWorkspaces] = useState<WorkspaceOption[]>([]);
    const { data: workspacesInfo, error } = useGetWorkspacesQuery({});
    const userInfoFromState = useAppSelector(state => state.userInfo.views) as View[];
    const [columnOrder, setColumnOrder] = useState<string[]>(TableHeader);
    const [loadingColumnOrder, setLoadingColumnOrder] = useState(true);
    const [showModalSuccessPopup, setShowModalSuccessPopup] = useState(false);
    const [errorModalMessage, setErrorModalMessage] = useState("");
    const onSuccessfulUpdate = () => {
        setShowModalSuccessPopup(true);
    };
    const closeSuccessPopup = () => {
        setShowModalSuccessPopup(false);
    };
    const onError = (message: string) => {
        setErrorModalMessage(message);
    };
    useEffect(() => {
        if (userInfo) {
            getUsersQuery.refetch();
            countUsersQuery.refetch();
        }
    }, [userInfo, totalUsers, activeTab]);

    useEffect(() => {
        const result = getFilteredRoleOptions();
        setFilteredRoleOptions(result);
    }, []);

    useEffect(() => {
        if (workspacesInfo && workspacesInfo) {
            let workspacesForDropdown: WorkspaceOption[] = workspacesInfo
                .map(getWorkspacesForDropdown)
            setWorkspaces(workspacesForDropdown);
        }
    }, [workspacesInfo]);
    useEffect(() => {
        if (!showEditUserPopup) {
            setErrorModalMessage('');
        }
    }, [showEditUserPopup]);
    const getWorkspacesForDropdown = (workspace:Workspace): WorkspaceOption => {
        return {
            value: Number(workspace.id),
            label: workspace.name,
        };
    };
    const handleChangeWorkspace = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedWorkspaceId = Number(event.target.value);
        const foundWorkspace = workspaces.find(workspace => workspace.value === selectedWorkspaceId);
        setNewUserWorkspace(foundWorkspace || null);
    };
    function getFilteredRoleOptions() {
        if (currentUserRole && ROLE_HIERARCHY[currentUserRole as USER_ROLE_TYPE]) {
            const currentRoleValue = ROLE_HIERARCHY[currentUserRole as USER_ROLE_TYPE];
            return ROLE_OPTIONS.filter((option) => ROLE_HIERARCHY[option.value as USER_ROLE_TYPE] < currentRoleValue);
        }
        return [];
    }
    useEffect(() => {
        if (userInviteResult) {
            if (userInviteResult.isLoading) {
                setIsPageLoading(true);
                return;
            }else{
                setIsPageLoading(false)
            }
            if (userInviteResult.isSuccess) {
                setShowSuccessPopup({
                    show: true,
                    message: "User successfully registered. An email has been sent to the new team member with instructions.",
                });
            }
        }

    }, [userInviteResult]);
    useEffect(() => {
        if (countUsersQuery.data) {
            setTotalUsers(countUsersQuery.data.ENABLED);
        }
    }, [countUsersQuery.data, activeTab]);

    useEffect(() => {
        let totalProjects = 0;
        let activeProjects = 0;
        let closedProjects = 0;

        if (Array.isArray(countProjectsQuery.data)) {
            const data: ProjectCount[] = countProjectsQuery.data || [];
            countProjectsQuery.data.forEach((item) => {
                totalProjects += item.count;

                if (item.name === 'NEW' || item.name === 'IN_PROGRESS') {
                    activeProjects += item.count;
                }

                if (item.name === 'COMPLETED') {
                    closedProjects += item.count;
                }
            });
        }

        setTotalProjects(totalProjects);
        setActiveProjects(activeProjects);
        setClosedProjects(closedProjects);
    }, [countProjectsQuery.data]);

    useEffect(() => {
        if (getUsersQuery.data) {
            let usersForTable: UserTableProps[] = getUsersQuery.data.content.map(getUserTableProps);
            setAllUsers(usersForTable);
        }
    }, [getUsersQuery.data, columnOrder]);

    useEffect(() => {
        if (userInfoFromState && userInfoFromState.length > 0) {
            const usersListView = userInfoFromState.find(view => view.name === "USERS_LIST");
            if (usersListView) {
                setColumnOrder(usersListView.columns.split(';'));
                setLoadingColumnOrder(false);
            } else {
                setColumnOrder(TableHeader);
                setLoadingColumnOrder(false);
            }
        } else {
            setColumnOrder(TableHeader);
            setLoadingColumnOrder(false);
        }
    }, [userInfoFromState]);

    useEffect(() => {
        if (userActivationResult) {
            if (userActivationResult.isLoading) {
                setIsPageLoading(true);
                return;
            }else{
                setIsPageLoading(false)
            }
            if (userActivationResult.isSuccess) {
                setShowConfirmationPopup(false);
                setShowSuccessPopup({
                    show: true,
                    message: "User has been enabled."
                });
                getUsersQuery.refetch();
                countUsersQuery.refetch();
            }else if (userActivationResult.isError) {
                console.log("ERROR: ", userActivationResult.error);
                if(userActivationResult.status === 'rejected'){
                    setShowUsersLimitPopup(true);
                    setShowConfirmationPopup(false);
                }

            }
        }
    }, [userActivationResult]);

    useEffect(() => {
        if (disableUserResult) {
            if (disableUserResult.isLoading) {
                setIsPageLoading(true);
                return;
            }else{
                setIsPageLoading(false)
            }
            if (disableUserResult.isSuccess) {
                setShowConfirmationPopup(false);
                setShowSuccessPopup({
                    show: true,
                    message: "User has been disabled."
                });
                getUsersQuery.refetch();
                countUsersQuery.refetch();
            }
        }
    }, [disableUserResult]);
    const handleCloseUsersLimitPopup = () => {
        setShowUsersLimitPopup(false);
        setActiveTab("tab1");
    };
    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };
    const handleChangePageSizeOption = (event: ChangeEvent<HTMLSelectElement>) => {
        setPageSize(Number(event.target.value));
        setCurrentPage(0);
    };
    const BoxItems = [
        {
            icon: <HiUserGroup/>,
            text: "Active Users",
            value: totalUsers,
            id: "company-active-users",
            backgroundColor: "#1787ff"
        },
        {
            icon: <BsFillDiagram3Fill/>,
            text: "Projects Created",
            value: totalProjects,
            id: "projects-created-card-value",
            backgroundColor: "#ffc107"
        },
        {
            icon: <AiFillFolderOpen/>,
            text: "Active Projects",
            value: activeProjects,
            id: "active-projects-card-value",
            backgroundColor: "#28a745"
        },
        {
            icon: <ImCheckmark/>,
            text: "Closed Projects",
            value: closedProjects,
            id: "closed-projects-card-value",
            backgroundColor: "#bd2130"
        },
    ];
    const renderHeader = (header: string, index: number): React.ReactNode => {
        const formattedHeader = formatHeader(header);
        return (
            <React.Fragment>
                <HiDotsVertical style={{ marginRight: "5px" }} />
                {formattedHeader}
            </React.Fragment>
        );
    };
    const renderCell = (id: number, key: string, value: any, rowIndex: number, cellIndex: number ): React.ReactNode => {
        return value;
    };

    const getUserTableProps = (user: UserInfo): UserTableProps => {
        return {
            id: user.id,
            first_name: user.firstName,
            last_name: user.lastName,
            username: user.username,
            email: user.email,
            company: user.company,
            role: FORMAT_ROLE_HIERARCHY(user.roles[0] as USER_ROLE_TYPE),
            edit: (FORMAT_ROLE_HIERARCHY(user.roles[0] as USER_ROLE_TYPE) != "Admin") ?
                <RiEdit2Fill
                    id={`user_${user.id}_edit_user_icon`}
                    onClick={() => handleEditUserClick(user.id)}
                    style={{cursor: "pointer"}}
                /> : null,
            status: user.roles.includes('ROLE_OWNER') ? (
                ''
            ) : (
                <SwitchButton
                    checked={user.enabled}
                    onChange={(checked: boolean) => handleSwitchChange(user.id, checked)}
                    id={user.id + ""}
                    confirmation={() => handleSwitchChange(user.id, !user.enabled)}
                />
            ),
            last_login: user.lastLogin
                ? new Date(user.lastLogin).toLocaleString(navigator.language, {hour12: false})
                : "Never logged in"
        };
    };
    async function changeUserStatus(userId: number, newStatus: boolean) {
        let action = newStatus ? activateUser : disableUser;
        await action(userId);
    }
    const ShowSuccessPopup: React.FC<ShowSuccessPopupProps> = ({successMessage = "Success!", instructions, show}) => {
        if (!show) {
            return null;
        }
        return (
            <PopUp
                successMessage={successMessage}
                instructions={instructions}
                confirmationMessage="Thanks!"
                handleClosePopup={handleCloseSuccessNewUserPopUp}
            />
        );
    };
    const handleCloseSuccessNewUserPopUp = () => {
        setShowSuccessPopup({
            show: false,
        });
        resetInfo();
    };
    const handleCloseStatusConfirmationPopup = () => {
        setShowConfirmationPopup(false);
        if (confirmationResolver) {
            confirmationResolver.reject();
            setShowConfirmationPopup(false);
        }
        setSelectedUserId(null);
        setSelectedUserStatus(null);
    };

    const handleConfirmUserStatusChange = () => {
        if (selectedUserId !== null && selectedUserStatus !== null) {
            changeUserStatus(selectedUserId, selectedUserStatus);
        }
        if (confirmationResolver) {
            confirmationResolver.resolve();
        }
        handleCloseStatusConfirmationPopup();
    };
    const handleChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setter(event.target.value);
    };
    const handleChangeRole = (event: ChangeEvent<HTMLSelectElement>) => {
        setNewUserRole(event.target.value);
    };
    const resetInfo = () => {
        setErrorMessage("");
        setNewUserFirstName("");
        setNewUserLastName("");
        setNewUserUsername("");
        setNewUserEmail("");
        setNewUserCompany("");
        setNewUserRole("");
        setNewUserWorkspace(null);
    };

    const handleTabClick = useCallback((tabKey: string) => {
        if (activeTab === "tab2" && totalActiveUser >= usersLimit) {
            setShowUsersLimitPopup(true);
            setActiveTab("tab1");
        }
        setActiveTab(tabKey);
    }, []);
    const handleSwitchChange = (userId: number, checked: boolean) => {
        return new Promise<void>((resolve, reject) => {
            setSelectedUserId(userId);
            setSelectedUserStatus(checked);
            setShowConfirmationPopup(true);
            setConfirmationResolver({resolve, reject});
        });
    };
    const validateEmptyFields = () => {
        const fieldsToValidate = [
            {value: newUserFirstName, name: "First Name"},
            {value: newUserLastName, name: "Last Name"},
            {value: newUserUsername, name: "Username"},
            {value: newUserEmail, name: "Email"},
            {value: newUserCompany, name: "Company"},
            {value: newUserRole, name: "Role"},
            {value: newUserWorkspace ? newUserWorkspace.value : "", name: "Workspace"},
        ];
        for (const field of fieldsToValidate) {
            if (field.name === "Workspace") {
                if (field.value === null) {
                    return {isValid: false};
                }
            } else {
                if (!field.value || !String(field.value).trim()) {
                    return {isValid: false};
                }
            }
        }
        return {isValid: true};
    };

    const handleRegisterNewUser = async () => {
        if(totalUsers >= usersLimit){
            setShowUsersLimitPopup(true);
            return;
        }
        const validationResult = validateEmptyFields();
        const isValidEmail = validateEmail(newUserEmail);

        if (!validationResult.isValid) {
            setErrorMessage("Fields cannot be empty");
            return;
        } else if (!isValidEmail) {
            setErrorMessage("Email format must be valid.");
            return;
        }

        let userRole = [];
        let userWorkspace= [];
        userRole.push(newUserRole);
        if (newUserWorkspace) {
            userWorkspace.push(newUserWorkspace.value);
        }
        const reqBody : NewUserInfo = {
            username: newUserUsername,
            firstName: newUserFirstName,
            lastName: newUserLastName,
            email: newUserEmail,
            company: newUserCompany,
            roles: userRole,
            workspaces: userWorkspace
        };

        await inviteUser(reqBody).unwrap()
            .then((response) => {
                setErrorMessage('')
                setShowSuccessPopup(
                    {
                        show: true,
                        message: `User successfully registered. An email has been sent to the new team member with instructions.`,
                    }
                );
            })
            .catch((error) => {
                const errorData = error.data;
                let errorMessage = "";

                if (errorData.email) {
                    errorMessage += errorData.email + ". ";
                }
                if (errorData.username) {
                    errorMessage += errorData.username + ". ";
                }
                setErrorMessage(errorMessage);
            });
    };

    const handleEditUserClick = (userId: number) => {
        setSelectedUserForEdit(userId);
        setShowEditUserPopup(true);
    };

    interface EditUserModalProps {
        id: number;
        handleClose: () => void;
    }
    const EditUserModal: React.FC<EditUserModalProps> = ({id, handleClose}) => {
        return (
            <ModalPopUp>
                <EditUserForm
                    id={id}
                    handleClose={handleClose}
                    roleOptions={getFilteredRoleOptions()}
                    setShowEditUserPopup={setShowEditUserPopup}
                    onSuccessfulUpdate={onSuccessfulUpdate}
                    closeSuccessPopup={closeSuccessPopup}
                    onError={onError}
                    showModalSuccessPopup={showModalSuccessPopup}
                    errorModalMessage={errorModalMessage}
                />
            </ModalPopUp>

        );
    };
    return (
        <RequireRole allowedRoles={[UserRole.ROLE_ADMIN, UserRole.ROLE_OWNER]}>
            {isPageLoading && <Spinner/>}
            <MainContainer>
                <Breadcrumb link="Administration"/>
                <PageTitle title="Administration Panel"/>
                <InfoBoxContainer>
                    {BoxItems.map((item) => (
                        <InfoBox
                            key={item.id}
                            icon={item.icon}
                            text={item.text}
                            value={item.value}
                            id={item.id}
                            backgroundColor={item.backgroundColor}
                        />
                    ))}
                </InfoBoxContainer>
                <Tabs>
                    <Tab id={"all_users_tab"} active={activeTab === "tab1"} onClick={() => handleTabClick("tab1")}>
                        All Users
                    </Tab>
                    <Tab id={"new_user_tab"} active={activeTab === "tab2"} onClick={() => handleTabClick("tab2")}>
                        New User
                    </Tab>
                </Tabs>

                <TabContent active={activeTab === "tab1"}>
                    <FilterGroup>
                        <FormLabel text="Select items to show" />
                        <SelectOption
                            id={"administration_pagination_option"}
                            value={pageSize.toString()}
                            onChange={handleChangePageSizeOption}
                            options={PAGE_SIZE_OPTIONS}
                            width={150}
                        />
                    </FilterGroup>
                    {!loadingColumnOrder && (
                        <TableContainer>
                            <FullTable
                                typeOfTable={"user"}
                                headers={columnOrder}
                                renderHeader={renderHeader}
                                renderCell={renderCell}
                                content={allUsers}
                                typeOfList={TypeOfList.USERS_LIST}
                                isDraggable={true}
                            />
                        </TableContainer>
                    )}
                    <PaginationControl currentPage={currentPage} totalPages={getUsersQuery.data?.totalPages || 0} onPageChange={handlePageChange} />
                </TabContent>

                <TabContent active={activeTab === "tab2"}>
                    <Container>
                        <NewUserFormContainer>
                            <FormTitle title="Register New User"/>
                            <FormBody>
                                <FormRow>
                                    <FormColumn>
                                        <FormLabel
                                            icon={FaUserAlt}
                                            text="First Name"
                                        />
                                        <FormInput
                                            id={"new_member_first_name"}
                                            value={newUserFirstName}
                                            onChange={handleChange(setNewUserFirstName)}
                                            type="text"
                                        />
                                    </FormColumn>
                                    <FormColumn>
                                        <FormLabel
                                            icon={FaUserFriends}
                                            text="Last Name"
                                        />
                                        <FormInput
                                            id={"new_member_last_name"}
                                            value={newUserLastName}
                                            onChange={handleChange(setNewUserLastName)}
                                            type="text"
                                        />
                                    </FormColumn>
                                </FormRow>
                                <FormRow>
                                    <FormColumn>
                                        <FormLabel
                                            icon={FaUserCheck}
                                            text="Username"
                                        />
                                        <FormInput
                                            id={"new_member_username"}
                                            value={newUserUsername}
                                            onChange={handleChange(setNewUserUsername)}
                                            type="text"
                                        />
                                    </FormColumn>
                                    <FormColumn>
                                        <FormLabel
                                            icon={MdAlternateEmail}
                                            text="Email"
                                        />
                                        <FormInput
                                            id={"new_member_email"}
                                            value={newUserEmail}
                                            onChange={handleChange(setNewUserEmail)}
                                            type="text"
                                        />
                                    </FormColumn>
                                </FormRow>
                                <FormRow>
                                    <FormColumn>
                                        <FormLabel
                                            icon={FaUserAstronaut}
                                            text="Role"
                                        />
                                        <SelectOption
                                            id={"new_member_role_select"}
                                            value={newUserRole}
                                            onChange={handleChangeRole}
                                            options={filteredRoleOptions}
                                            width={200}
                                        />
                                    </FormColumn>
                                    <FormColumn>
                                        <FormLabel
                                            icon={BsPersonWorkspace}
                                            text="Workspace"
                                        />
                                        <SelectOption
                                            id={"new_member_workspace_select"}
                                            value={newUserWorkspace ? newUserWorkspace.value.toString() : ''}
                                            onChange={handleChangeWorkspace}
                                            options={workspaces}
                                            width={200}
                                        />
                                    </FormColumn>
                                </FormRow>
                                <FormRow>
                                    <FormColumn>
                                        <FormLabel
                                            icon={BsFillPersonVcardFill}
                                            text="Company"
                                        />
                                        <FormInput
                                            id={"new_member_company"}
                                            value={newUserCompany}
                                            onChange={handleChange(setNewUserCompany)}
                                            type="text"
                                        />
                                    </FormColumn>
                                </FormRow>
                                {errorMessage && <InvalidFeedback errorMessage={errorMessage}/>}
                                <ButtonsContainer>
                                    <SlimButton
                                        id={"new_member_form_cancel_button"}
                                        type="reset"
                                        bgColor="#c71313"
                                        text="Cancel"
                                        textColor="#fff"
                                        onClick={resetInfo}
                                    />
                                    <SlimButton
                                        id={"new_member_form_submit_button"}
                                        type="button"
                                        bgColor="#eac113"
                                        text="Save changes"
                                        textColor="#2d292a"
                                        onClick={handleRegisterNewUser}
                                    />
                                </ButtonsContainer>

                            </FormBody>
                        </NewUserFormContainer>
                    </Container>
                </TabContent>
                {showSuccessPopup.show && (
                    <ShowSuccessPopup
                        show={showSuccessPopup.show}
                        successMessage={showSuccessPopup.successMessage || ""}
                        instructions={showSuccessPopup.message || ""}/>)}
                {showConfirmationPopup && (
                    <PopUp
                        successMessage={`User will be ${
                            selectedUserStatus ? "enabled" : "disabled"
                        }.`}
                        instructions="Are you sure?"
                        confirmationMessage="Yes!"
                        handleClosePopup={handleConfirmUserStatusChange}
                        handleCancel={handleCloseStatusConfirmationPopup}
                        cancelMessage="No!"
                    />)}
                {showEditUserPopup && (
                    <EditUserModal
                        id={selectedUserForEdit || -1}
                        handleClose={() => setShowEditUserPopup(false)}
                    />
                )}
                {showUsersLimitPopup && (
                    <PopUp
                        successMessage="You have reach your limit!"
                        instructions={`Cannot have more than ${usersLimit} active users.`}
                        confirmationMessage="Understood!"
                        handleClosePopup={handleCloseUsersLimitPopup}
                    />)}
            </MainContainer>
        </RequireRole>
    );
};export default Administration;