import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import JSZip from 'jszip';
import InvalidFeedback from 'components/ui/forms/InvalidFeedback';
import {FaFileUpload} from 'react-icons/fa'
import {MAX_UPLOAD_FILE_SIZE} from "../../../../utils/constants";

const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 300px; 
`;
const UploadButton = styled.button`
    font-weight: 700;
    margin-top: 20px;
    border-radius: 4px;
    border:  1px solid #aaa;
    text-transform: uppercase;
    padding: 5px 10px;
    width: 180px;
    &:focus {
        border:  1px solid #aaa;
        outline: none;
    }
`;
const FileName = styled.p`
    margin-top: 10px;
`;

const maxLength = 20;
const shortenFileName = (fileName: string) => {
    if (fileName.length > maxLength) {
        const start = fileName.slice(0, maxLength / 2);
        const end = fileName.slice(-maxLength / 2);
        return `${start}...${end}`;
    }
    return fileName;
};

interface FileUploadProps {
    id?: string;
    onChange: (file: File | null, errorMessage?: string) => void;
    reset: boolean;
    validFiles: string;
    showName?: boolean;

    fileErrorMessage?: string;
}

const UploadFileButton: React.FC<FileUploadProps> = ({ id, onChange, reset, validFiles, showName=true, fileErrorMessage }) => {
    const [file, setFile] = useState<File | null>(null);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const errorFeedback = fileErrorMessage ? <InvalidFeedback errorMessage={fileErrorMessage} /> : null;

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];
        setErrorMessage('');
        setFile(selectedFile || null);
        setIsValid(false);



        if (selectedFile) {
            const validFileExtensions = validFiles.split(',').map((ext) => ext.trim());
            let isValidFile = validFileExtensions.some((ext) => selectedFile.name.endsWith(ext));

            let largeFile: boolean = false
            if(selectedFile?.size  && selectedFile.size > MAX_UPLOAD_FILE_SIZE){
                largeFile = true;
                isValidFile = false;
            }

            if(selectedFile.size)
            if (isValidFile) {
                if (selectedFile.name.endsWith('.zip')) {
                    const zip = new JSZip();
                    const zipFile = await zip.loadAsync(selectedFile);
                    let containsFolders = false;
    
                    const hasValidFiles = Object.keys(zipFile.files).some((filename) => {
                        if (filename.endsWith('/')) {
                            containsFolders = true;
                            return false;
                        }
                        return validFileExtensions.some((ext) => filename.endsWith(ext));
                    });
    
                    if (!containsFolders && hasValidFiles) {
                        setIsValid(true);
                        setErrorMessage('');
                        onChange(selectedFile);
                    } else {
                        setIsValid(false);
                        const errorMessage = containsFolders
                            ? 'ZIP files containing folders are not allowed. Please select a valid ZIP file.'
                            : `Not a valid zip file. Files must be one of the following types: ${validFiles}`;
                        onChange(null, errorMessage);
                    }
                } else {
                    setIsValid(true);
                    setErrorMessage('');
                    onChange(selectedFile);
                }
            } else {
                if(largeFile){
                    onChange(null,`Please upload a valid file.  Max file size ${MAX_UPLOAD_FILE_SIZE} bytes`);
                }else{
                    onChange(null,`Please upload a valid file. Accepted file types: ${validFiles}.`);
                }
            }
        }
    };
    useEffect(() => {
        if (reset) {
            setFile(null);
            setIsValid(false);
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        }
    }, [reset]);

    const handleButtonClick = (e:  React.MouseEvent) => {
        e.preventDefault();
        if (inputRef.current) {
            inputRef.current.click();
        }
    };
    const fileName = file && isValid ? `Selected file: ${shortenFileName(file.name)}` : '';
    return (
        <UploadContainer>
            <UploadButton id={id} onClick={handleButtonClick}><FaFileUpload size={18} />Choose File</UploadButton>
            <input
                type="file"
                accept= {validFiles}
                style={{ display: 'none' }}
                ref={inputRef}
                onChange={handleFileChange}/>
            {showName && <FileName id={"file_name"}>{fileName}</FileName>}
            {errorFeedback}
        </UploadContainer>
    );
};

export default UploadFileButton;