import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import arrow from "../../assets/img/back-to-top.webp";

const ToTop = styled.button<{ show: boolean }>`
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: ${props => props.show ? 'block' : 'none'};
  background-color: transparent;
  border: none;
`;
const ToTopImage = styled.img`
  height: 40px;
  cursor: pointer;
`;

const LandingBackToTop: React.FC = () => {
    const [showButton, setShowButton] = useState(false);

    const checkScrollPosition = () => {
        if (window.scrollY > 300) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", checkScrollPosition);
        return () => {
            window.removeEventListener("scroll", checkScrollPosition);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <ToTop show={showButton} onClick={scrollToTop}>
            <ToTopImage src={arrow} alt="Underline back to top" title={"underline-back-to-top"}></ToTopImage>
        </ToTop>
    );
};

export default LandingBackToTop;
