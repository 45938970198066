import React from 'react';
import { Chart } from 'react-google-charts';
import { ChartTitle, TitleContainer } from './PieChart';

interface BarChartProps {
    data: (string | number)[][];
    title: string;
    options?: object;
}

const BarChart: React.FC<BarChartProps> = ({ data, title, options }) => {

    return (
        <div>
            <TitleContainer>
                <ChartTitle>{title}</ChartTitle>
            </TitleContainer>
            <Chart
                width={'100%'}
                height={'250px'}
                chartType="ColumnChart"
                loader={<div>Loading Chart</div>}
                data={data}
                options={{
                    ...options,
                    legend: { position: 'none' },
                    vAxis: { format: '0' },
                }}
                rootProps={{ 'data-testid': '1' }}
            />
        </div>
    );
};

export default BarChart;
