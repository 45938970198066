import React from 'react';
import {useAppSelector} from "../hooks/store-hooks";
import {Navigate, Outlet, useLocation} from "react-router-dom";

const RequireAuth = () => {
    const {token} = useAppSelector(state => state.tokenInfo);
    const location = useLocation();
    return (
        token
            ? <Outlet/>
            : <Navigate to={"/login"} state={{from: location}} replace/>
    );
};

export default RequireAuth;