import React, {useState} from 'react';
import {HiOutlineAtSymbol} from "react-icons/hi";
import {EditorButton} from "../translator/TargetEditorButtons";
import {ArrowContainer, Popover, PopoverPosition, Rect} from "react-tiny-popover";
import styled from "styled-components";

const SymbolsPopOverContainer = styled.div`
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    display: grid;
    grid-template-rows: repeat(11, 30px);
    grid-auto-columns: 30px;
    grid-auto-flow: column;
`

const SymbolDescription = styled.div`
    background-color: #f3f6f4;
    font-size: 14px;
    display: inline-block;
    z-index: 9999;
    border-radius: 4px;
    padding: 2px;
`

interface InsertSymbolProps {
    onAddSymbol: (symbol: string) => void;
}

interface PopOverProps extends InsertSymbolProps {
    position: PopoverPosition|undefined;
    childRect: Rect;
    popoverRect: Rect;
    closePopOver: () => void;
}

const symbols = [
    {symbol: '\u00A9', description: "Copyright symbol"},
    {symbol: '\u00AE', description: "Registered trademark symbol"},
    {symbol: '\u2122', description: "Trademark symbol"},
    {symbol: '\u2120', description: "Service mark symbol"},
    {symbol: '\u2126', description: "Ohm symbol"},
    {symbol: '\u00B5', description: "Micro symbol"},
    {symbol: '\u00A7', description: "Section symbol"},
    {symbol: '\u00B6', description: "Paragraph symbol"},
    {symbol: '\u2020', description: "Dagger symbol"},
    {symbol: '\u2021', description: "Double dagger symbol"},
    {symbol: '\u203B', description: "Reference mark symbol"},
    {symbol: '\u266A', description: "Eighth note symbol"},
    {symbol: '\u266B', description: "Musical note symbol"},
    {symbol: '\u266F', description: "Sharp symbol"},
    {symbol: '\u266D', description: "Flat symbol"},
    {symbol: '\u262F', description: "Yin and yang symbol"},
    {symbol: '\u262E', description: "Peace symbol"},
    {symbol: '\u2622', description: "Radioactive symbol"},
    {symbol: '\u2623', description: "Biohazard symbol"},
    {symbol: '\u263A', description: "Smiley face symbol"},
    {symbol: '\u2639', description: "Frowning face symbol"},
    {symbol: '\u028C', description: "Turned v symbol ʌ"},
    {symbol: '\u0251', description: "Open back unrounded vowel symbol ɑ"},
    {symbol:'a', description:"low central (or front) unrounded vowel"},
    {symbol:'ä', description:"central vowel ranging between [ɛ] and [ə]"},
    {symbol:'ɑ', description:"low back unrounded vowel; often written [a]"},
    {symbol:'ɒ', description:"low back rounded vowel"},
    {symbol:'æ', description:"low front unrounded vowel"},
    {symbol:'b', description:"voiced bilabial stop"},
    {symbol:'ḇ', description:"spirantized [b]; historically [β], modern [v]"},
    {symbol:'β', description:"voiced bilabial fricative"},
    {symbol:'c', description:"voiceless alveolar affricate; IPA [ʦ] or [ts]"},
    {symbol:'č', description:"voiceless palatoalveolar affricate; IPA [ʧ] or [tʃ]"},
    {symbol:'ɔ', description:"lax mid back rounded vowel"},
    {symbol:'ɕ', description:"voiceless alveolopalatal fricative"},
    {symbol:'ç', description:"voiceless palatal fricative"},
    {symbol:'d', description:"voiced alveolar stop"},
    {symbol:'ḏ', description:"palatalized [dʸ]; can be pronounced [ǰ]', description:\"or spirantized [d], same as [ð]"},
    {symbol:'ḍ', description:"voiced retroflex stop; IPA [ɖ] or emphatic, i.e. pharyngealized [dˁ]"},
    {symbol:'ð', description:"voiced dental fricative"},
    {symbol:'e', description:"tense mid front unrounded vowel"},
    {symbol:'ə', description:"lax mid central vowel (unstressed in English); \"schwa\""},
    {symbol:'ɚ', description:"rhotacized schwa, essentially [ər]"},
    {symbol:'ɛ', description:"lax mid front unrounded vowel"},
    {symbol:'ɝ', description:"stressed [ɚ] in English; often transcribed the same way"},
    {symbol:'f', description:"voiceless labiodental fricative"},
    {symbol:'g', description:"voiced velar stop"},
    {symbol:'ḡ', description:"spirantized [g]; same as [ɣ]"},
    {symbol:'h', description:"voiceless glottal fricative"},
    {symbol:'ʰ', description:"aspiration of preceding sound"},
    {symbol:'ḥ', description:"voiceless pharyngeal fricative; IPA [ħ]"},
    {symbol:'ḫ', description:"voiceless uvular fricative; same as [χ]"},
    {symbol:'ẖ', description:"voiceless fricative; probably palatal [ç]"},
    {symbol:'i', description:"tense high front unrounded vowel"},
    {symbol:'ɪ', description:"lax high front unrounded vowel"},
    {symbol:'ỉ', description:"special transcriptional symbol; also [j]"},
    {symbol:'ɨ', description:"high central unrounded vowel"},
    {symbol:'j', description:"voiced palatal glide; same as [y] in other systems or alternate transliteration for [ỉ]"},
    {symbol:'ʲ', description:"palatalization of preceding sound; also [ʸ]"},
    {symbol:'ǰ', description:"voiced palatoalveolar affricate; IPA [ʤ] or [dʒ]"},
    {symbol:'k', description:"voiceless velar stop"},
    {symbol:'ḳ', description:"voiceless uvular stop; same as [q]"},
    {symbol:'ḵ', description:"spirantized [k]; same as [x]"},
    {symbol:'l', description:"voiced alveolar lateral liquid"},
    {symbol:'ḷ', description:"voiced retroflex lateral liquid; IPA [ɭ]"},
    {symbol:'ɬ', description:"voiceless alveolar lateral fricative"},
    {symbol:'ɫ', description:"velarized voiced alveolar lateral liquid"},
    {symbol:'m', description:"voiced bilabial nasal"},
    {symbol:'n', description:"voiced alveolar nasal"},
    {symbol:'ŋ', description:"voiced velar nasal; don't confuse with sequence [ŋg]"},
    {symbol:'ṇ', description:"voiced retroflex nasal; IPA [ɳ]"},
    {symbol:'ɲ', description:"voiced palatal nasal"},
    {symbol:'ɴ', description:"voiced uvular nasal"},
    {symbol:'o', description:"tense mid back rounded vowel"},
    {symbol:'ŏ', description:"mid central unrounded vowel, similar to [ə]"},
    {symbol:'ɸ', description:"voiceless bilabial fricative"},
    {symbol:'θ', description:"voiceless dental fricative"},
    {symbol:'p', description:"voiceless bilabial stop"},
    {symbol:'p̅', description:"spirantized [p]; historically [ɸ], modern [f]"},
    {symbol:'þ', description:"runic letter equivalent to [θ] or runic letter that can be read as either [θ] or [ð]"},
    {symbol:'q', description:"voiceless uvular stop"},
    {symbol:'r', description:"voiced alveolar trill (often used for other types of \"r\")"},
    {symbol:'ɹ', description:"voiced (post)alveolar liquid, the English \"r\"; often just written [r]"},
    {symbol:'ɾ', description:"voiced alveolar tap; sometimes written [ᴅ]"},
    {symbol:'ʀ', description:"voiced uvular trill"},
    {symbol:'ʁ', description:"voiced uvular fricative"},
    {symbol:'ṛ', description:"voiced retroflex flap; IPA [ɽ]"},
    {symbol:'s', description:"voiceless alveolar fricative"},
    {symbol:'š', description:"voiceless postalveolar fricative; IPA [ʃ]"},
    {symbol:'ś', description:"voiceless alveolopalatal fricative; IPA [ɕ] or voiceless alveolar fricative; historically distinct from [z]', description:\"or voiceless fricative; historically distinct from [s]"},
    {symbol:'ṣ', description:"voiceless retroflex fricative; IPA [ʂ] or emphatic, i.e. pharyngealized [sˁ]"},
    {symbol:'ʃ', description:"voiceless postalveolar fricative; same as [š]"},
    {symbol:'t', description:"voiceless alveolar stop"},
    {symbol:'ṭ', description:"voiceless retroflex stop; IPA [ʈ] or emphatic, i.e. pharyngealized [tˁ]"},
    {symbol:'ṯ', description:"palatalized [tʸ]; can be pronounced [č]', description:\"or spirantized [t], same as [θ]"},
    {symbol:'ʨ', description:"voiceless alveolopalatal affricate"},
    {symbol:'tʂ', description:"voiceless retroflex affricate"},
    {symbol:'u', description:"tense high back rounded vowel"},
    {symbol:'ʊ', description:"lax high back rounded vowel"},
    {symbol:'ŭ', description:"high central unrounded vowel, similar to [ɨ]"},
    {symbol:'ü', description:"tense high front rounded vowel"},
    {symbol:'v', description:"voiced labiodental fricative"},
    {symbol:'ʌ', description:"mid central unrounded vowel; stressed in English"},
    {symbol:'ɣ', description:"voiced velar fricative"},
    {symbol:'w', description:"voiced labial-velar glide"},
    {symbol:'ʍ', description:"voiceless labial-velar fricative"},
    {symbol:'x', description:"voiceless velar fricative"},
    {symbol:'χ', description:"voiceless uvular fricative"},
    {symbol:'y', description:"voiced palatal glide (in many transcription systems); IPA [j] high front rounded vowel (in IPA)"},
    {symbol:'ʸ', description:"palatalization of preceding sound; IPA [ʲ]"},
    {symbol:'ʎ', description:"voiced palatal lateral"},
    {symbol:'z', description:"voiced alveolar fricative"},
    {symbol:'ẓ', description:"voiced retroflex fricative; IPA [ʐ] or emphatic, i.e. pharyngealized [zˁ] or [ðˁ]"},
    {symbol:'ž', description:"voiced palatoalveolar fricative; IPA [ʒ]"},
    {symbol:'ʒ', description:"voiced palatoalveolar fricative; same as [ž]"},
    {symbol:'’', description:"glottalization of preceding sound (ejective)"},
    {symbol:'‘', description:"aspiration of preceding sound; same as [ʰ]"},
    {symbol:'ʔ', description:"glottal stop; also written ’ or ʾ"},
    {symbol:'ʕ', description:"voiced pharyngeal fricative; also written ‘ or ʿ"},
    {symbol:'ā', description:"high level tone|or long vowel"},
    {symbol:'ē', description:"high level tone|or long vowel"},
    {symbol:'ī', description:"high level tone|or long vowel"},
    {symbol:'ō', description:"high level tone|long vowel"},
    {symbol:'ū', description:"high level tone|long vowel"},
    {symbol:'ǖ', description:"high level tone|long vowel"},
    {symbol:'á', description:"rising tone|primary stress|\"acute accent\"|equivalent to subscript 2 for distinguishing homophones"},
    {symbol:'é', description:"rising tone|primary stress|\"acute accent\"|equivalent to subscript 2 for distinguishing homophones"},
    {symbol:'í', description:"rising tone|primary stress|\"acute accent\"|equivalent to subscript 2 for distinguishing homophones"},
    {symbol:'ó', description:"rising tone|primary stress|\"acute accent\"|equivalent to subscript 2 for distinguishing homophones"},
    {symbol:'ú', description:"rising tone|primary stress|\"acute accent\"|equivalent to subscript 2 for distinguishing homophones"},
    {symbol:'ǘ', description:"rising tone|primary stress|\"acute accent\"|equivalent to subscript 2 for distinguishing homophones"},
    {symbol:'ǎ', description:"falling-rising tone"},
    {symbol:'ě', description:"falling-rising tone"},
    {symbol:'ǐ', description:"falling-rising tone"},
    {symbol:'ǒ', description:"falling-rising tone"},
    {symbol:'ǔ', description:"falling-rising tone"},
    {symbol:'ǚ', description:"falling-rising tone"},
    {symbol:'à', description:"falling tone|secondary stress|\"grave accent\"|word-final stress|equivalent to subscript 3 for distinguishing homophones"},
    {symbol:'è', description:"falling tone|secondary stress|\"grave accent\"|word-final stress|equivalent to subscript 3 for distinguishing homophones"},
    {symbol:'ì', description:"falling tone|secondary stress|\"grave accent\"|word-final stress|equivalent to subscript 3 for distinguishing homophones"},
    {symbol:'ò', description:"falling tone|secondary stress|\"grave accent\"|word-final stress|equivalent to subscript 3 for distinguishing homophones"},
    {symbol:'ù', description:"falling tone|secondary stress|\"grave accent\"|word-final stress|equivalent to subscript 3 for distinguishing homophones"},
    {symbol:'ǜ', description:"falling tone|secondary stress|\"grave accent\"|word-final stress|equivalent to subscript 3 for distinguishing homophones"},
    {symbol:'â', description:"long vowel that results from two short vowels|or any long vowel|\"circumflex accent\""},
    {symbol:'ê', description:"long vowel that results from two short vowels|or any long vowel|\"circumflex accent\""},
    {symbol:'î', description:"long vowel that results from two short vowels|or any long vowel|\"circumflex accent\""},
    {symbol:'ô', description:"long vowel that results from two short vowels|or any long vowel|\"circumflex accent\""},
    {symbol:'û', description:"long vowel that results from two short vowels|or any long vowel|\"circumflex accent\""},
]
const InsertSymbolBtn = ({onAddSymbol}: InsertSymbolProps) => {
    const [showPopOver, setShowPopOver] = useState<boolean>(false);

    const handleClick = (event: any): void => {
        event.preventDefault();
        setShowPopOver(prevState => !prevState);
    }

    return (
        <Popover
            containerStyle={{zIndex: '9999'}}
            isOpen={showPopOver}
            padding={2}
            onClickOutside={() => setShowPopOver(false)}
            positions={['top']}
            content={({position, childRect, popoverRect}) => (
                <SymbolsPopOver onAddSymbol={onAddSymbol} position={position} childRect={childRect}
                                popoverRect={popoverRect} closePopOver={() => setShowPopOver(false)}/>
            )}
        >
            {/*onMouseDown prevents losing focus on the editor. This would not happen with onClick()*/}
            <EditorButton onMouseDown={(e) => handleClick(e)} title="Add Symbol">
                <HiOutlineAtSymbol title="Add Symbol"/>
            </EditorButton>
        </Popover>
    );
};

const SymbolsPopOver = ({onAddSymbol, position, popoverRect, childRect, closePopOver}: PopOverProps) => {
    return (
        <>
            <ArrowContainer
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
                arrowColor={'black'}
                arrowSize={8}
            >
                <SymbolsPopOverContainer>
                    {symbols.map((s, i) =>
                        <SymbolButton key={i}
                                      symbol={s.symbol}
                                      description={s.description}
                                      onAddSymbol={onAddSymbol}
                                      closePopover={closePopOver}></SymbolButton>)}
                </SymbolsPopOverContainer>
            </ArrowContainer>
        </>
    )
}
export default InsertSymbolBtn;

const SymbolButton = (props: {
    symbol: string,
    description: string,
    closePopover: Function,
    onAddSymbol: Function
}) => {
    const [showDescription, setShowDescription] = useState<boolean>(false);
    const handleClick = (event: any, symbol: string): void => {
        event.preventDefault()
        props.onAddSymbol(symbol);
        props.closePopover();
    }

    return (
        // onMouseDown prevents losing focus on the editor. This would not happen with onClick()
        <Popover
            containerStyle={{zIndex: '9999'}}
            isOpen={showDescription}
            padding={2}
            onClickOutside={() => setShowDescription(false)}
            positions={['top']}
            content={() => (
                <SymbolDescription>
                    {props.description}
                </SymbolDescription>
            )}
        >
            <EditorButton
                onMouseEnter={() => setShowDescription(true)}
                onMouseLeave={() => setShowDescription(false)}
                onMouseDown={(e) => handleClick(e, props.symbol)}>
                {props.symbol}
            </EditorButton>
        </Popover>
    );
}