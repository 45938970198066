import React from "react";
import logo from "../../assets/img/logo-gray-mustard-transparent.webp";
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const LogoLink = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.8125rem 0.5rem;
  transition: width 0.3s ease-in-out;
  white-space: nowrap;
  border-bottom: 1px solid #dee2e6;
  cursor: pointer;
`;
const LogoImage = styled.img`
  padding-left: 5px;
  height: 40px;
  margin-right: 10px
`;
const LogoText = styled.span`
  font-weight: 600;
  color: #ffff;
  font-size: 20px;
  letter-spacing: 1px;
  vertical-align: middle;
  text-decoration: none;
`;
interface LogoProps {
    isCollapsed: boolean;
}

const Logo: React.FC<LogoProps> = ({ isCollapsed }) => {
    const navigate = useNavigate();

    return (
        <LogoLink onClick={() => navigate("/home")}>
            <LogoImage src={logo} alt="Underline logo" title={"underline-logo"} />
            <LogoText style={{ display: isCollapsed ? "none" : "inline" }}>
                underline
            </LogoText>
        </LogoLink>
    );
};

export default Logo;
