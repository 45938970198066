import {underlineApi} from "./underlineApi";
export const contactApiSlice = underlineApi.injectEndpoints({
    endpoints: (builder) => ({
        sendMessage: builder.mutation({
            query:({name, email, subject, content} : {name:string, email: string, subject: string, content: string})=>{
                let body = JSON.stringify({
                    name: name,
                    email: email,
                    subject: subject,
                    content: content
                });
                return{
                    url: `/contact`,
                    method: 'POST',
                    body: body,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            }
        })
    })
})
export const {
    useSendMessageMutation
} = contactApiSlice;