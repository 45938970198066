import React from 'react'
import styled from 'styled-components';
import {Dot} from '../PageTitle';
import Button from '../ui/forms/auth/AuthFormButton';
import { useNavigate, useLocation } from 'react-router-dom';

const MainContainer = styled.div`
  background-color: #fbfbfb;
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 0;
  font-size: 14px;
`;
const TitleContainer = styled.div`
  background-image: linear-gradient(to bottom, #6b6b6b 0%, #4b4b4b 100%);
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
`;
const PolicyContainer = styled.div`
  width: 1200px;
  margin: 20px;
  border-radius:4px;
  padding: 25px;

  @media (max-width: 1265px) {
    width: 90%;
    padding: 20px;
  }
`;
const  MainTitle= styled.h2`
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0;
`;
const Subtitle = styled.h3`
  text-align: center;
  margin-bottom: 30px;
  font-weight: 600;
`;
const  ParagraphHeading= styled.p`
  font-size: 35px;
  font-weight: 700;  
  margin-bottom: 0;
  margin-top: 10px;
  line-height: 1.3;
`;
const Text = styled.p`
  font-size: 22px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
`;
const CustomList = styled.ul`
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;

    & > li {
        position: relative;
        padding-left: 15px;
    }

    & > li::before {
        content: '•';
        position: absolute;
        left: 0;
        color: #EAC113;
        font-size: 25px;
    }
`;
interface PolicyTemplateProps {
    policyContent: {
        heading: string;
        text: string;
        listItems?: string[];
    }[];
}

const PolicyTemplate: React.FC<PolicyTemplateProps> = ({ policyContent }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleBack = () => {
        if (location.state?.from) {
            navigate(-1);
        } else {
            navigate("/register");
        }
    };
    return (
        <>
            <TitleContainer>
                <MainTitle>
                Welcome to Underline<Dot>.</Dot>
                </MainTitle>
                <Subtitle>
                The web tool for preparing SRT files
                for translation and creating localization projects.
                </Subtitle>
            </TitleContainer>

            <MainContainer>
                <PolicyContainer>
                {policyContent.map((item, index) => (
                    <React.Fragment key={index}>
                    <ParagraphHeading>
                        {item.heading}
                        <Dot>.</Dot>
                    </ParagraphHeading>
                    <Text>
                        {item.text}
                        {item.listItems && (
                        <CustomList>
                            {item.listItems.map((listItem, listItemIndex) => (
                            <li key={listItemIndex}>{listItem}</li>
                            ))}
                        </CustomList>
                        )}
                    </Text>
                    </React.Fragment>
                ))}
                <Button onClick={handleBack} text="Back"/>
                </PolicyContainer>
            </MainContainer>
        </>
    )
}
export default PolicyTemplate