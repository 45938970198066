import React, {useRef} from "react";
import styled from 'styled-components';

const AnswerBody = styled.div<{ isActive: boolean }>`
  background: transparent none repeat scroll 0 0;
  border-top: medium none;
  padding: 20px 25px 10px 9px;
  position: relative;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;
const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  overflow: hidden;
`;
const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

interface FaqVideoProps{
    questionNumber: string;
    collapseNumber: string;
    videoLink: string;
    isActive: boolean;
}

const FaqVideo: React.FC<FaqVideoProps> = ({questionNumber, collapseNumber, videoLink, isActive }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    return (
        <div
            id={collapseNumber}
            className={`collapse${isActive ? " show" : ""}`}
            role="tabpanel"
            aria-labelledby={questionNumber.toString()}
        >
            <AnswerBody isActive={isActive}>
                <VideoContainer>
                    <StyledIframe
                        ref={iframeRef}
                        src={`https://www.youtube.com/embed/${videoLink}?enablejsapi=1&rel=0`}
                        title="Underline Tutorial"
                        id="youtube-player"
                    />
                </VideoContainer>
            </AnswerBody>
        </div>
    );
};
export default FaqVideo;