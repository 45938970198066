import React, {useState, useEffect, useCallback, ChangeEvent} from 'react';
import styled from "styled-components";
import Breadcrumb from "../components/Breadcrumb";
import PageTitle from "../components/PageTitle";
import {RiEdit2Fill} from 'react-icons/ri';
import {FaAngleDoubleDown} from 'react-icons/fa'
import {COLORS} from "../utils/constants";
import {useGetWorkspacesQuery, useEditWorkspaceMutation, useAddUserToWorkspaceMutation,useRemoveUserFromWorkspaceMutation} from '../services/workspacesApiSlice';
import Spinner from "../components/spinner/Spinner";
import PopUp from "../components/ui/forms/SuccessPopup";
import {useGetUsersQuery} from "../services/usersApiSlice";
import SelectOption from '../components/ui/forms/action/SelectOption';
import {UserInfo} from '../services/usersApiSlice'
import RequireRole, {UserRole} from "../components/RequireRole";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 80px;
`;
const ExpandedContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const EditIcon = styled(RiEdit2Fill)`
  font-size: 18px;
  margin-left: 8px;
  cursor: pointer;
`;
const Card = styled.div<{borderColor: string}>`
  margin: 5px;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #f9f9f9;
  border-left: 8px solid ${props => props.borderColor};
  height: auto;
  width: 1000px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 1060px) {
    width: 100%;
  }
`;
const Members = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  overflow-x: auto;
`;
const LeftSection = styled.div`
  flex: 1.2;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  margin: 0;
  width: 100%;

  @media (min-width: 790px) {
    width: auto;
  }
`;
const MiddleSection = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;
const WorkspaceName = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 20px;
  width: 100%;

  @media (min-width: 790px) {
    width: 450px;
  }
`;
const NameInput = styled.input`
  border: none;
  font-size: 16px;
  color: #333;
  font-weight: 600;
  background-color: #f9f9f9;
  flex-grow: 1;
  &:focus {
    outline: none;
  }
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #ccc;

  @media (max-width: 1150px) {
    border-left: none;
  }
`;
const InfoItem = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #6c6c6c;
`;

const Tabs = styled.div`
  display: flex;
  width: 380px;
  margin-left: 60px;
`;
const Tab = styled.div<{ active: boolean }>`
  flex: 1;
  padding: 10px;
  background-color: ${({active}) => (active ? "#fff" : "#fcfcfc")};
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-weight: bold;
  border-top: 3px solid #EAC113;
  color: ${({active}) => (active ? "#000" : "#888")};
  border-top: ${({active}) => (active ? "3px solid #EAC113" : "1.5px solid #EAC113")};
  border-bottom: none;

  &:hover {
    background-color: #f5f5f5;
    color: #000;
  }
`;
const TabContent = styled.div<{ active: boolean }>`
  display: ${({active}) => (active ? "block" : "none")};
  border: 1px solid #ccc;
  margin-left: 60px;
  margin-right: 20px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 5px;
  margin-bottom: 20px;
`;
const ExpandIcon = styled(FaAngleDoubleDown)<{ isExpanded: boolean }>`
  cursor: pointer;
  transform: ${props => props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s ease-in-out;
  margin-right: 20px;
`;
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;
const StyledTh = styled.th`
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #f2f2f2;
  font-size: 14px;
`;
const StyledTd = styled.td`
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 12px;
`;
const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const Button = styled.button`
  padding: 4px 10px;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  margin-left: 5px;

  &:hover {
    background-color: #2980b9;
  }
`;
let currentColorIndex = 0;
const getNextColor = () => {
    const color = COLORS[currentColorIndex];
    currentColorIndex = (currentColorIndex + 1) % COLORS.length;
    return color;
};
interface MemberOption {
    value: string;
    label: string;
}
const Workspaces2: React.FC = () => {
    const [editingWorkspace, setEditingWorkspace] = useState(-1);
    const [editingWorkspaceName, setEditingWorkspaceName] = useState('');
    const [activeTab, setActiveTab] = useState("tab1");
    const [expandedCard, setExpandedCard] = useState<number | null>(null);
    const [ editWorkspace ] = useEditWorkspaceMutation();
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [allMembersForDropdown, setAllMembersDropdown] = useState<MemberOption[]>([]);
    const [allMembersFullInfo, setAllMembersFullInfo] = useState<UserInfo[]>([]);
    const getUsersQuery = useGetUsersQuery({page: 0, size: 100, sortBy: 'firstName', sortDir:'ASC'});
    const [editingRow, setEditingRow] = useState<number | null>(null);
    const [selectedUser, setSelectedUser] = useState('');
    const { data: workspacesInfo, isLoading } = useGetWorkspacesQuery({});
    const [memberToRemove, setMemberToRemove] = useState<{workspaceId: number, memberId: number} | null>(null);
    const [removeUserFromWorkspace] = useRemoveUserFromWorkspaceMutation();
    const [addUserToWorkspace] = useAddUserToWorkspaceMutation();
    const [showUpgradePlanPopup, setShowUpgradePlanPopup] = useState(false);
    const [showNameChangeConfirmationPopup, setShowNameChangeConfirmationPopup] = useState(false);
    const [selectedMemberId, setSelectedMemberId] = useState(null);
    const [showNoChangesPopup, setShowNoChangesPopup] = useState(false);

    const [confirmationResolver, setConfirmationResolver] = useState<{
        resolve: () => void;
        reject: () => void;
    } | null>(null);

    const handleTabClick = useCallback((tabKey: string) => {
        setActiveTab(tabKey);

        if (tabKey === "tab2") {
            setShowUpgradePlanPopup(true);
        } else {
            setShowUpgradePlanPopup(false);
        }
    }, []);

    useEffect(() => {
        if (getUsersQuery.data && editingRow !== null && workspacesInfo[editingRow]) {
            let existingUsersInWorkspace = workspacesInfo[editingRow].members.map((member:UserInfo) => member.username);
            let usersForDropdown: MemberOption[] = getUsersQuery.data.content
                .filter((user) => user.enabled === true && !existingUsersInWorkspace.includes(user.username) && user.username !== "Underline-Support")
                .map((member:UserInfo) => {
                    return {
                        value: member.username,
                        label: `${member.firstName} ${member.lastName}`,
                    };
                })
            setAllMembersDropdown(usersForDropdown);
            setAllMembersFullInfo(getUsersQuery.data.content);
        }
    }, [getUsersQuery.data, workspacesInfo, editingRow, ]);

    const getFullUserInfo = (username: string) => {
        return allMembersFullInfo.find(user => user.username === username);
    }
    const handleCloseConfirmationPopup = () => {
        setShowConfirmationPopup(false);
        setShowNameChangeConfirmationPopup(false);
        setEditingWorkspace(-1);
    };
    const handleCloseUpgradePlanPopup = () => {
        setShowUpgradePlanPopup(false);
        setActiveTab('tab1');
    };
    const handleDeleteMember = (event: React.MouseEvent, memberId: number, workspaceId: number) => {
        event.stopPropagation();
        setMemberToRemove({ workspaceId: workspaceId, memberId: memberId });
        setShowConfirmationPopup(true);
    };
    const handleCloseRemoveConfirmationPopup = () => {
        setShowConfirmationPopup(false);
        if (confirmationResolver) {
            confirmationResolver.reject();
        }
        setSelectedMemberId(null);
    };
    const handleConfirmRemoveMember = async () => {
        if (memberToRemove === null) {
            setShowNoChangesPopup(true);
            return;
        }

        try {
            await removeUserFromWorkspace({
                workspaceId: memberToRemove.workspaceId,
                userId: memberToRemove.memberId,
            });

            handleCloseConfirmationPopup();
        } catch (err) {
            console.error("Removing user error: ", err);
        }
    };
    const handleConfirmChangeName = async () => {
        try {
            await editWorkspace({ id: workspacesInfo[editingWorkspace].id, newName: editingWorkspaceName });
            setEditingWorkspace(-1);
            setShowNameChangeConfirmationPopup(false);
        } catch (err) {
            console.error("Editing workspace name error: ", err);
        }
    };

    const startEditingName = (index: number, event: React.MouseEvent) => {
        event.stopPropagation();
        if (editingWorkspace !== -1 && editingWorkspaceName !== workspacesInfo[editingWorkspace].name) {
            setShowNameChangeConfirmationPopup(true);
        } else {
            setEditingWorkspace(index);
            setEditingWorkspaceName(workspacesInfo[index].name);
        }
    };
    const stopEditingName = () => {
        if (editingWorkspace === -1 || editingWorkspaceName.trim() === '' || workspacesInfo[editingWorkspace].name === editingWorkspaceName) {
            setEditingWorkspace(-1);
        } else {
            setShowNameChangeConfirmationPopup(true);
        }
    };
    useEffect(() => {
        const handleClickOutside = () => {
            stopEditingName();
        };
        if (editingWorkspace !== -1) {
            window.addEventListener('click', handleClickOutside);
        }
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [editingWorkspace, editingWorkspaceName]);

    const ShowNameChangeConfirmationPopup = () => {
        return (
            <PopUp
                successMessage={'You are about to change the name of your workspace.'}
                instructions="Are you sure?"
                confirmationMessage="Yes!"
                handleConfirm={handleConfirmChangeName}
                handleClosePopup={handleConfirmChangeName}
                handleCancel={handleCloseConfirmationPopup}
                cancelMessage="No!"
            />
        );
    };

    const handleAddMember = (event: React.MouseEvent, workspaceIndex: number) => {
        event.stopPropagation();

        if (editingRow !== null) return;
        setEditingRow(workspaceIndex);
    };
    const handleUserSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedUser(event.target.value);
    };

    const confirmAddMember = async () => {
        if (!selectedUser) return;

        const fullUserInfo = getFullUserInfo(selectedUser);
        if (!fullUserInfo) return;

        if (editingRow === null) return;
        const workspaceId = workspacesInfo[editingRow].id;

        try {
            await addUserToWorkspace({
                workspaceId: workspaceId,
                userId: fullUserInfo.id
            });

            workspacesInfo.map((workspace: any, index: number) => {
                if (index === editingRow) {
                    return {
                        ...workspace,
                        members: [...workspace.members, fullUserInfo],
                    };
                } else {
                    return workspace;
                }
            });
            setSelectedUser('');
            setEditingRow(null);
        } catch (err) {
            console.error(err);
        }
    }
    const ShowConfirmationPopup = () => {
        return (
            <PopUp
                successMessage={`Member will be removed.`}
                instructions="Are you sure?"
                confirmationMessage="Yes!"
                handleClosePopup={handleConfirmRemoveMember}
                handleCancel={handleCloseRemoveConfirmationPopup}
                cancelMessage="No!"
            />
        );
    };
    return (
        <RequireRole allowedRoles={[UserRole.ROLE_ADMIN, UserRole.ROLE_OWNER]}>
            {isLoading && <Spinner />}
            <Breadcrumb link="Workspaces" />
            <PageTitle title="My Workspaces" />

            <Tabs>
                <Tab id={"workspaces_tab"} active={activeTab === "tab1"} onClick={() => handleTabClick("tab1")}>
                    My Workspaces
                </Tab>
                <Tab id={"add_new_workspace_tab"} active={activeTab === "tab2"}  onClick={() => handleTabClick("tab2")}>
                    Add New workspace
                </Tab>
            </Tabs>

            <TabContent active={activeTab === "tab1"}>
                <Container>
                    {workspacesInfo && workspacesInfo.map((workspace: any, index:number) => (
                        <Card  id={`workspace_${index}_card`} key={index}
                              borderColor={COLORS[index % COLORS.length]}
                        >
                            <TopRow id={`workspace_${index}_top_row`} onClick={() => setExpandedCard(expandedCard === index ? null : index)}>
                                <WorkspaceName id={`workspace_${index}_name`} >
                                    <ExpandIcon id={`workspace_${index}_expand_icon`} isExpanded={expandedCard === index} onClick={() => setExpandedCard(expandedCard === index ? null : index)} />
                                    <>
                                        {editingWorkspace === index ? (
                                            <NameInput
                                                value={editingWorkspaceName}
                                                onChange={(e) => setEditingWorkspaceName(e.target.value)}
                                                onBlur={stopEditingName}
                                                autoFocus
                                            />
                                        ) : (
                                            <>
                                                {workspace.name}
                                                <EditIcon id={`workspace_${index}_edit_icon`} onClick={(event) => startEditingName(index, event)} />
                                            </>
                                        )}
                                    </>
                                </WorkspaceName>
                                <LeftSection>
                                    <Info>
                                        <InfoItem id={`workspace_${index}_open_projects`}><span>Open Projects: </span> {workspace.openProjects}</InfoItem>
                                        <InfoItem id={`workspace_${index}_closed_projects`}>Closed Projects: {workspace.closedProjects}</InfoItem>
                                    </Info>
                                </LeftSection>
                            </TopRow>
                            {expandedCard === index && (
                                <ExpandedContainer>
                                    <MiddleSection>
                                        <Members>
                                            <StyledTable id={"members_table"}>
                                                <thead>
                                                <tr>
                                                    <StyledTh>First Name</StyledTh>
                                                    <StyledTh>Last Name</StyledTh>
                                                    <StyledTh>Email</StyledTh>
                                                    <StyledTh></StyledTh>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {workspace.members.map((member: any, i: number) => (
                                                    member.username !== "Underline-Support" &&
                                                    <tr key={i}>
                                                        <StyledTd id={`member_${i}_first_name`}>{member.firstName}</StyledTd>
                                                        <StyledTd id={`member_${i}_last_name`}>{member.lastName}</StyledTd>
                                                        <StyledTd id={`member_${i}_email`}>{member.email}</StyledTd>
                                                        <StyledTd>
                                                            <Button id={`member_${i}_delete_button`} onClick={(event) => handleDeleteMember(event, member.id, workspace.id)}>Delete</Button>
                                                        </StyledTd>
                                                    </tr>
                                                ))}
                                                {editingRow === index && (
                                                    <tr>
                                                        <StyledTd>
                                                            <SelectOption
                                                                id={"all_members_select"}
                                                                value={selectedUser}
                                                                options={allMembersForDropdown}
                                                                onChange={handleUserSelect}
                                                                width={150}
                                                                height={30}
                                                            />
                                                        </StyledTd>
                                                        <StyledTd></StyledTd>
                                                        <StyledTd></StyledTd>
                                                        <StyledTd>
                                                            <Button id={"add_member_confirm_button"} onClick={confirmAddMember}>Confirm</Button>
                                                            <Button id={"add_member_cancel_button"} onClick={() => setEditingRow(null)}>Cancel</Button>
                                                        </StyledTd>
                                                    </tr>
                                                )}
                                                </tbody>
                                                <Button id={"add_member_button"} type="submit" onClick={(e) => handleAddMember(e, index)}>Add Member</Button>
                                            </StyledTable>
                                        </Members>
                                    </MiddleSection>
                                </ExpandedContainer>
                            )}
                        </Card>

                    ))}
                </Container>
            </TabContent>
            {showConfirmationPopup && <ShowConfirmationPopup/>}
            {showNameChangeConfirmationPopup  && <ShowNameChangeConfirmationPopup/>}
            <TabContent active={activeTab === "tab2"}>
                {showUpgradePlanPopup && <PopUp
                    successMessage='You cannot create a new workspace.'
                    instructions="Please upgrade your plan"
                    confirmationMessage="Understood!"
                    handleConfirm={handleCloseUpgradePlanPopup}
                    handleClosePopup={handleCloseUpgradePlanPopup}
                    workspacesMode={true}
                />}
            </TabContent>
        </RequireRole>
    );
}
export default Workspaces2;