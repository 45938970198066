import React, {useEffect, useRef, useState, useLayoutEffect} from 'react'
import Breadcrumb from "components/Breadcrumb";
import PageTitle from "components/PageTitle";
import styled from 'styled-components';
import FormTitle from "../components/ui/forms/action/ActionFormTitle";
import FormLabel from "../components/ui/forms/action/ActionFormLabel";
import FormInput from "../components/ui/forms/action/ActionFormInput";
import SlimButton from "../components/ui/forms/action/SlimButton";
import {FaUserAlt, FaTag} from "react-icons/fa";
import {BsChatTextFill, BsFillEnvelopeAtFill} from "react-icons/bs";
import InvalidFeedback from "../components/ui/forms/InvalidFeedback";
import PopUp from "../components/ui/forms/SuccessPopup";
import ReCAPTCHA from "react-google-recaptcha";
import {useSendMessageMutation} from "../services/contactApiSlice";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 100px;

  @media (max-width: 1110px) {
    flex-direction: column;
  }
`;
const Intro = styled.p`
  color: #212529;
  font-size: 16px;
  margin-right: 60px;
  padding-bottom: 160px;

  @media (max-width: 1100px) {
    margin-right: 0;
    margin-left: 20px;
    padding-bottom: 10px;
  }
`;
const LetsTalk = styled.p`
  font-size: 42px;
  font-weight: 600;
  color: #212529;
  margin: 0;
`;
const YellowSign = styled.span`
  color: #eac113;
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
`;
const ContactUsFormContainer = styled.div`
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 470px;
  max-height: 100%;
  margin-bottom: 100px;

  @media (max-width: 740px) {
    width: 300px;
  }

`;
const FormBody = styled.div`
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
`;
const StyledTextArea = styled.textarea`
  font-family: "Arial", sans-serif;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 410px;
  font-size: 15px;
  color: #63506d;

  &:focus {
    border: 1px solid #aaa;
    outline: none;
  }
  @media (max-width: 740px) {
    width: 220px;
  }
`;
const ButtonsContainer = styled.div`
  margin-top: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
`;
const CaptchaContainer = styled.div`
  margin: 25px 20px 0 0;

  @media (max-width: 740px) {
    transform: scale(0.77);
  }
`;
function useMediaQuery(query: string) {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useLayoutEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        media.addEventListener('change', listener);
        return () => media.removeEventListener('change', listener);
    }, [matches, query]);

    return matches;
}
const ContactForm: React.FC = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const captchaRef = useRef(null);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const [sendMessage, { data, isError }] = useSendMessageMutation();
    const isSmallScreen = useMediaQuery('(max-width: 740px)');

    useEffect(() => {
        // @ts-ignore
        captchaRef.current?.reset();
    }, []);

    const handleSendMessage = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!name.trim() || !email.trim() || !message.trim()) {
            setErrorMessage("All mandatory fields must be filled in.");
            return;
        }
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        if (!emailRegex.test(email)) {
            setErrorMessage("Please enter a valid email address.");
            return;
        }
        const finalSubject = subject.trim() ? subject : email;

        if (!isCaptchaValid) {
            setErrorMessage("Captcha verification failed. Please try again.");
            return false;
        }

        try {
            const response = await sendMessage({ name, email, subject: finalSubject, content: message });
            if (isError) {
                console.error("Error sending message: ", response);
                setErrorMessage("An error occurred while sending your message. Please try again.");
                return;
            } else {
                setShowConfirmationPopup(true);
                resetInfo();
            }
        } catch (error) {
            console.error('Error: ', error);
            setErrorMessage("An error occurred while sending your message. Please try again.");
        }
    };
    const resetInfo = () => {
        setErrorMessage("");
        setName("");
        setEmail("");
        setMessage("");
        setSubject("");
        setIsCaptchaValid(false);
        // @ts-ignore
        captchaRef.current.reset();
    };
    function verifyCaptcha(value: any) {
        if (value) {
            setIsCaptchaValid(true);
        } else {
            setErrorMessage("You must prove you are a human.");
            setIsCaptchaValid(false);
        }
    }
    function handleCaptchaExpiration() {
        setErrorMessage("Captcha verification expired. Please try again.");
        setIsCaptchaValid(false);
        // @ts-ignore
        captchaRef.current.reset();
    }
    const ShowConfirmationPopup = () => {
        return (
            <PopUp
                successMessage="We've successfully received your message!"
                instructions="One of our specialists will be getting in touch with you shortly."
                confirmationMessage="Thanks!"
                handleClosePopup={()=>setShowConfirmationPopup(false)}
            />
        );
    };

    return(
        <>
            <Breadcrumb link="Contact Us" />
            <PageTitle title="Contact Us"/>

            <Container>
                <Intro>
                    <LetsTalk>
                        Let's talk<YellowSign>!</YellowSign>
                    </LetsTalk>
                    Thank you for using our application! <br/>
                    Your feedback is very valuable to us. <br/>
                    If you have any questions, suggestions, or concerns, <br/>
                    please do not hesitate to let us know.<br/>
                    We are committed to providing you <br/>
                    with the best experience possible <br/>
                    and are eager to assist and hear your thoughts.
                </Intro>
                <ContactUsFormContainer>
                    <FormTitle title="Contact our Team"/>
                    <FormBody>
                        <FormLabel
                            icon={FaUserAlt}
                            text="Name"
                            isRequired
                        />
                        <FormInput
                            id={"contact_form_name"}
                            value={name}
                            placeholder="Name"
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            width={isSmallScreen ? 220 : 420}
                        />
                        <FormLabel
                            icon={BsFillEnvelopeAtFill}
                            text="Email"
                            isRequired
                        />
                        <FormInput
                            id={"contact_form_email"}
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            width={isSmallScreen ? 220 : 420}
                        />
                        <FormLabel
                            icon={FaTag}
                            text="Subject"
                        />
                        <FormInput
                            id={"contact_form_subject"}
                            type="text"
                            placeholder="Optional"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            width={isSmallScreen ? 220 : 420}
                        />
                        <FormLabel
                            icon={BsChatTextFill}
                            text="Message"
                            isRequired
                        />
                        <StyledTextArea
                            id={"contact_form_message"}
                            placeholder="Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                            rows={5}
                        />
                        <CaptchaContainer>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                ref={captchaRef}
                                onChange={verifyCaptcha}
                                onExpired={handleCaptchaExpiration}
                                size="normal"
                            />
                        </CaptchaContainer>

                        {errorMessage && <InvalidFeedback errorMessage={errorMessage}/>}
                        {showConfirmationPopup && <ShowConfirmationPopup/>}
                        <ButtonsContainer>
                            <SlimButton
                                id={"contact_form_cancel_button"}
                                type="reset"
                                bgColor="#c71313"
                                text="Cancel"
                                textColor="#fff"
                                onClick={resetInfo}
                            />
                            <SlimButton
                                id={"contact_form_submit_button"}
                                type="button"
                                bgColor="#eac113"
                                text="Send message"
                                textColor="#2d292a"
                                onClick={handleSendMessage}
                            />
                        </ButtonsContainer>

                    </FormBody>
                </ContactUsFormContainer>
            </Container>
        </>
    )
}
export default ContactForm