import React from "react";
import styled from 'styled-components';
import landingImageCorner from "../../assets/img/landing-corner.svg";

const LeftCornerContainer = styled.img`
  position: absolute;
  z-index: 1;
  width: 750px;
  transform: rotate(-59.89deg);
  top: 800px;
  left: 3000px;

  @media (max-width: 4900px) {
    left: 2700px;
  }
  @media (max-width: 4300px) {
    left: 2400px;
  }
  @media (max-width: 3900px) {
    top: 800px;
    left: 2400px;
  }
  @media (max-width: 3400px) {
    left: 1900px;
  }
  @media (max-width: 2650px) {
    left: 1600px;
  }
  @media (max-width: 2250px) {
  left: 1400px;
}
  @media (max-width: 2060px) {
    top: 885px;
    left: 1220px;
  }
  @media (max-width: 1870px) {
    left: 1080px;
  }
  @media (max-width: 1730px) {
    width: 600px;
    top: 880px;
  }
  @media (max-width: 1590px) {
    width: 490px;
    top: 600px;
    left: 950px;
  }
  @media (max-width: 1360px) {
    top: 600px;
    left: 780px;
  }
  @media (max-width: 1230px) {
    width: 350px;
    top: 680px;
    left: 680px;
  }
  @media (max-width: 1005px) {
    width: 290px;
    left: 600px;
  }
  @media (max-width: 870px) {
    left: 520px;
  }
  @media (max-width: 790px) {
    width: 250px;
  }
  @media (max-width: 755px) {
    width: 235px;
    left: 480px;
  }
  @media (max-width: 705px) {
    width: 200px;
  }
  @media (max-width: 670px) {
    left: 425px;
  }
  @media (max-width: 640px) {
    width: 170px;
    top: 930px;
    left: 430px;
  }
  @media (max-width: 590px) {
    width: 140px;
    top: 870px;
    left: 410px;
  }
  @media (max-width: 549px) {
    display: none;
  }
`;
const LandingLeftCorner: React.FC= () => {
    return(
        <LeftCornerContainer src={landingImageCorner} alt={"Underline decoration lines"} title={"underline-decoration-lines"}/>
    )
}
export default LandingLeftCorner