import React from "react";
import styled from 'styled-components';

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 120px;
`;
const YellowLine = styled.div`
  width: 600px;
  height: 2px;
  border-top: 0.5px #EAC113 solid;
  margin-right: 20px;

  @media (max-width: 1530px) {
    width: 450px;
  }
  @media (max-width: 1380px) {
    width: 380px;
  }
  @media (max-width: 1310px) {
    width: 280px;
  }
  @media (max-width: 1148px) {
    width: 180px;
  }
  @media (max-width: 470px) {
    width: 50px;
  }
`;
const DividerText = styled.div`
  font-weight: 500;
  font-size: 36px;
  margin: 0 0 0 20px;

  @media (max-width: 1616px) {
    font-size: 28px;
  }
  @media (max-width: 1310px) {
  font-size: 22px;
  }
  @media (max-width: 340px) {
    font-size: 18px;
  }
`;
interface LandingSectionTitleProps {
    id: string;
    title: string;
}
const LandingSectionTitle: React.FC<LandingSectionTitleProps> = ({ id, title }) => {
    return(
        <DividerContainer id={id}>
            <YellowLine />
            <DividerText>{title}</DividerText>
        </DividerContainer>
    )
}
export default LandingSectionTitle