import React from "react";
import styled from "styled-components";
import background from "../../../../assets/img/phx-background.webp";
import AuthFormLogo from "./AuthFormLogo";
import AuthFormTitle from "./AuthFormTitle";
import AuthFormFooterLink from "./AuthFormFooterLink";

const FormContainer = styled.div`
  position: relative;
  background-color: #656565;
  max-height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 0;
  margin: 0;
  font-size: 14px;

  @media (max-width: 1100px) {
    background-position: center;
  }
  @media screen and (min-resolution: 140dpi) {
    height: 150%;
    padding-bottom: 50px;
  }
`;
const BackgroundImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;

  @media screen and (min-resolution: 140dpi) {
    
  }
`;
const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
`;
const Card = styled.div<{ width: number; height: number }>`
  margin-top: auto;
  margin-bottom: auto;
  width: ${({width}) => width}px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  height: ${({height}) => height}px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  position: relative;

  @media (max-width: 395px) {
    width: 270px;
  }
`;

interface AuthFormContainerProps {
    title: string;
    width: number;
    height: number;
    footerLinks: Array<{ text: string; linkText: string; link: string }>;
    hasError: boolean;
    children: React.ReactNode;
    submitHandler: (event: React.FormEvent<HTMLFormElement>) => void;
    extraMargin?: string;
}

const AuthForm: React.FC<AuthFormContainerProps> = ({
                                                        title,
                                                        width,
                                                        height,
                                                        footerLinks,
                                                        hasError,
                                                        children,
                                                        submitHandler,
                                                        extraMargin
                                                    }) => {
    return (
        <FormContainer>
            <BackgroundImage src={background} alt="Underline background image" title="underline-background-image" />
            <AuthFormLogo/>
            <CardContainer>
                <Card width={width} height={height}>
                    <AuthFormTitle title={title}/>
                    <form onSubmit={submitHandler}>
                        {children}
                        <AuthFormFooterLink links={footerLinks} hasError={hasError} extraMargin={extraMargin}/>
                    </form>

                </Card>
            </CardContainer>
        </FormContainer>
    );
};
export default AuthForm;