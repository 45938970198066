import React, {useState, useEffect} from "react";
import styled from "styled-components";
import Breadcrumb from "components/Breadcrumb";
import PageTitle from "components/PageTitle";
import BarChart from "../components/dashboards/BarChart";
import LineChart from "../components/dashboards/LineChart";
import PieChart from "../components/dashboards/PieChart";
import {MONTHS, COLORS} from "../utils/constants";
import {useGetInstanceStatisticsQuery} from "../services/usersApiSlice";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const AllChartsContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  padding: 20px 60px;
  justify-content: center;
`;
const ChartContainer = styled.div`
      width: 600px;
      height: 350px;
      margin: 0 60px 40px;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 30px 40px -20px #a3a5ae;
      border-top: 6px solid ${props => props.color || "#6b6b6b"};     
      @media (max-width: 915px) {
        width: 400px;
      }
`;
const ChartContainerFlex = styled(ChartContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const EmptyMessage = styled.p`
  font-size: 25px;
  font-weight: 600;  
`;
let currentColorIndex = 0;
const getNextColor = () => {
    const color = COLORS[currentColorIndex];
    currentColorIndex = (currentColorIndex + 1) % COLORS.length;
    return color;
};
interface ProjectProps {
    projectId: number;
    name: string;
    sourceLocale: string;
    targetLocales: string[];
    creator: string;
    assignee: string;
    creationDate: Date;
    dueDate: Date;
    totalTasks: number;
    sourceWordCount: number;
    status: string;
    defaultFilter: string;
}
interface ChartProps {
    totalProjects: number;
    data: any;
    title: string;
    type: 'Pie' | 'Bar' | 'Line';
    options?: any;
    color: string;
}
function removeEmptyYearsAtStart(mapData: Map<number, number>) {
    let years = Array.from(mapData.keys());
    let firstNonEmptyYearIndex = years.findIndex(year => mapData.get(year) !== 0);

    if (firstNonEmptyYearIndex === -1) {
        return new Map();
    }

    let newMapData = new Map<number, number>();
    for (let i = firstNonEmptyYearIndex; i < years.length; i++) {
        newMapData.set(years[i], mapData.get(years[i]) as number);
    }
    return newMapData;
}
type StatsCount = {
    name: string;
    count: number;
};
type ChartRow = (string | number | { role: string })[];
const Home: React.FC = () => {
    const [totalProjects, setTotalProjects] = useState(0);
    const [newProjects, setNewProjects] = useState(0);
    const [inProgressProjects, setInProgressProjects] = useState(0);
    const [closedProjects, setClosedProjects] = useState(0);
    const [projectsPerSource, setProjectsPerSource] = useState<ChartRow[]>([["Source", "Quantity"]]);
    const [projectsPerTarget, setProjectsPerTarget] = useState<ChartRow[]>([["Target", "Quantity"]]);
    const [projectsPerYears, setProjectsPerYears] = useState<ChartRow[]>([["Year", "Quantity"]]);
    const [projectsPerFilter, setProjectsPerFilter] = useState<ChartRow[]>([["Filter", "Quantity"]]);
    const [projectsPerMonth, setProjectsPerMonth] = useState<ChartRow[]>([["Month", "Quantity"]]);
    const [projectsPerMonthAndYear, setProjectsPerMonthAndYear] = useState<(string | number)[][]>([["Month"]]);
    const getInstanceStats = useGetInstanceStatisticsQuery();

    const projectsData = [
        ["Status", "Quantity"],
        ["New", newProjects],
        ["In Progress", inProgressProjects],
        ["Completed", closedProjects],
    ];
    useEffect(() => {
        if (getInstanceStats.data) {
            getInstanceStats.refetch();
            const projectsByStatus = (getInstanceStats.data as any).projectsByStatus;

            let total = 0;
            let newProjects = 0;
            let inProgress = 0;
            let closed = 0;

            projectsByStatus.forEach((item: { name: string, count: number }) => {
                total += item.count;

                if (item.name === 'NEW') {
                    newProjects = item.count;
                } else if (item.name === 'IN_PROGRESS') {
                    inProgress = item.count;
                } else if (item.name === 'COMPLETED') {
                    closed = item.count;
                }
            });

            setTotalProjects(total);
            setNewProjects(newProjects);
            setInProgressProjects(inProgress);
            setClosedProjects(closed);
        }
    }, [getInstanceStats.data]);
    useEffect(() => {
        if (getInstanceStats.data) {
            const projectsBySourceLocale = (getInstanceStats.data as any).projectsBySourceLocale;
            const jobsByTargetLocale = (getInstanceStats.data as any).jobsByTargetLocale;
            const projectsByCreationMonth = (getInstanceStats.data as any).projectsByCreationMonth;
            const projectsByFilter = (getInstanceStats.data as any).projectsByFilter;

            getAllProjectsPerSource(projectsBySourceLocale);
            getAllProjectsPerTarget(jobsByTargetLocale);
            getAllProjectsPerYear(projectsByCreationMonth);
            getAllProjectsPerFilter(projectsByFilter);
            getAllProjectsPerMonth(projectsByCreationMonth);
            getAllProjectsPerMonthAndYear(projectsByCreationMonth);
        }
    }, [getInstanceStats.data]);
    function getAllProjectsPerSource(data: StatsCount[]) {
        let index = 0;
        const chartData = [
            ["Locale", "Count", { role: "style" }],
            ...data.map(item => {
                return [item.name, item.count, COLORS[index++ % COLORS.length]];
            }),
        ];
        setProjectsPerSource(chartData);
    }
    function getAllProjectsPerTarget(data: StatsCount[]) {
        let index = 0;
        const chartData = [
            ["Filter", "Count", { role: "style" }],
            ...data.map(item => {
                return [item.name, item.count, COLORS[index++ % COLORS.length]];
            }),
        ];
        setProjectsPerTarget(chartData);
    }
    const projectsPerYearOptions = {
        hAxis: {title: "Year"},
        vAxis: {title: "Projects", minValue: 0},
        legend: "none",
    };
    function getAllProjectsPerYear(data: StatsCount[]) {
        let projectsPerYear = new Map<number, number>();
        let currentYear = new Date().getFullYear();
        let basicYear = 2010;

        for (let i = basicYear; i <= currentYear; i++) {
            projectsPerYear.set(i, 0);
        }
        data.forEach((item: StatsCount) => {
            const year = parseInt(item.name.split("-")[0], 10);
            const count = item.count;
            if (projectsPerYear.has(year)) {
                const currentCount = projectsPerYear.get(year) || 0;
                projectsPerYear.set(year, currentCount + count);
            }
        });

        let nonEmptyYearsAtStartRemoved = removeEmptyYearsAtStart(projectsPerYear);
        let index = 0;
        const chartData = [
            ["Year", "Total", {role: "style"}, {role: "tooltip"}],
            ...Array.from(nonEmptyYearsAtStartRemoved.entries()).map((entry) => {
                return [String(entry[0]), entry[1], COLORS[index++ % COLORS.length], `Year: ${entry[0]}, Total: ${entry[1]}`];
            }),
        ];
        setProjectsPerYears(chartData);
    }
    function getAllProjectsPerMonth(data: StatsCount[]) {
        let projectsPerMonth = new Array(12).fill(0);
        data.forEach((item) => {
            const month = parseInt(item.name.split("-")[1], 10) - 1;
            const count = item.count;
            projectsPerMonth[month] += count;
        });
        const chartData = [
            ["Month", "Total", {role: "style"}],
            ...projectsPerMonth.map((value, index) => {
                return [MONTHS[index], value, COLORS[index % COLORS.length]];
            }),
        ];
        setProjectsPerMonth(chartData);
    }
    function getAllProjectsPerMonthAndYear(data: StatsCount[]) {
        let projectsPerMonthAndYear: { [year: string]: number[] } = {};

        data.forEach((item) => {
            const [year, month] = item.name.split("-");
            const monthIndex = parseInt(month, 10) - 1;
            const count = item.count;

            if (!projectsPerMonthAndYear.hasOwnProperty(year)) {
                projectsPerMonthAndYear[year] = Array(12).fill(0);
            }

            projectsPerMonthAndYear[year][monthIndex] += count;
        });

        const years = Object.keys(projectsPerMonthAndYear).sort();
        const chartData: (string | number)[][] = [
            ["Month", ...years],
            ...MONTHS.map((month, monthIndex) => [
                month,
                ...years.map((year) => projectsPerMonthAndYear[year][monthIndex]),
            ]),
        ];
        setProjectsPerMonthAndYear(chartData);
    }
    function getAllProjectsPerFilter(data: StatsCount[]) {
        let index = 0;
        const chartData = [
            ["Locale", "Count", { role: "style" }],
            ...data.map(item => {
                return [item.name, item.count, COLORS[index++ % COLORS.length]];
            }),
        ];
        setProjectsPerFilter(chartData);
    }
    const ProjectChart: React.FC<ChartProps> = ({ totalProjects, data, title, type, options, color }) => {
        const ChartComponent = type === 'Pie' ? PieChart : type === 'Bar' ? BarChart : LineChart;
        return (
            totalProjects > 0 ? (
                <ChartContainer color={color}>
                    <ChartComponent data={data} title={title} options={options} />
                </ChartContainer>
            ) : (
                <ChartContainerFlex color={color}>
                    <EmptyMessage>No projects created yet.</EmptyMessage>
                </ChartContainerFlex>
            )
        )
    };
    return (
        <HomeContainer>
            <Breadcrumb/>
            <PageTitle title="Dashboards"/>
            <AllChartsContainer>
                <ProjectChart totalProjects={totalProjects} data={projectsData} title={"Projects per Status"} type='Pie' color={getNextColor()} />
                <ProjectChart totalProjects={totalProjects} data={projectsPerYears} title={"Projects per Year"} type='Line' options={projectsPerYearOptions} color={getNextColor()} />
                <ProjectChart totalProjects={totalProjects} data={projectsPerTarget} title={"Most Used Target Languages"} type='Bar' color={getNextColor()} />
                <ProjectChart totalProjects={totalProjects} data={projectsPerSource} title={"Most Used Source Languages"} type='Bar' color={getNextColor()} />
                <ProjectChart totalProjects={totalProjects} data={projectsPerFilter} title={"Projects per Filter"} type='Pie' color={getNextColor()} />
                <ProjectChart totalProjects={totalProjects} data={projectsPerMonth} title={"Projects per Creation Month"} type='Bar' color={getNextColor()} />
                <ProjectChart totalProjects={totalProjects} data={projectsPerMonthAndYear} title={"Projects per Creation Month and Year"} type='Line' color={getNextColor()} />
            </AllChartsContainer>
        </HomeContainer>
    );
};
export default Home;