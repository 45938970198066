import React from 'react'
import {FaAngleDoubleRight} from 'react-icons/fa'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const BreadcrumbList = styled.ol`
    display: flex;
    flex-wrap: wrap;
    padding: 0 1rem;
    list-style: none;
    align-items: center;
`;

const BreadcrumbItem = styled.li`
  margin: 0 0.2rem;
  color: #6c757d;
  text-decoration: none;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: #551A8B;
`;

const IconStyle = {
    color: '#EAC113',
}
interface BreadcrumbProps{
    link?: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({link})=> {
    const navigate = useNavigate();

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        navigate('/home');
    }

    return (
        <BreadcrumbList>
            <FaAngleDoubleRight style={IconStyle}/>
            <BreadcrumbItem>
                {link ? (
                    <StyledLink href="/" onClick={handleClick}>Home</StyledLink>
                ) : (
                    'Home'
                )}
            </BreadcrumbItem>
            {link && (
                <>
                    <BreadcrumbItem>/</BreadcrumbItem>
                    <BreadcrumbItem>{link}</BreadcrumbItem>
                </>
            )}
        </BreadcrumbList>
    )
}

export default Breadcrumb;