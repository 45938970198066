import React from "react";
import styled from 'styled-components';
import landingImageCorner from "../../assets/img/landing-corner.svg";

const LandingVideoContainer = styled.div`
  margin-left: 100px;

  @media (max-width: 1852px) {
    margin-left: 50px;
  }
  @media (max-width: 377px) {
    margin-left: 35px;
  }
  @media (max-width: 285px) {
    margin-left: -7px;
  }
`;
const LandingVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const YoutubeEmbed = styled.iframe`
  width: 575px;
  height: 325px;
  border-radius: 14px;
  box-shadow: 0 0 50px 2px rgba(234, 193, 19, 0.5);

  @media (max-width: 1590px) {
    width: 380px;
    height: 226px;
  }
  @media (max-width: 514px) {
    width: 260px;
    height: 147px;
  }
`;
const CornerContainer = styled.img`
  width: 185px;
  height: 65px;

  @media (max-width: 1590px) {
    width: 80px;
  }
`;
const LandingVideo: React.FC= () => {
    return(
        <LandingVideoContainer>
            <LandingVideoWrapper>
                <CornerContainer style={{ transform: 'rotate(-180deg)'}}  src={landingImageCorner} alt={"Underline decoration lines"} title={"underline-decoration-lines"}/>
                <YoutubeEmbed
                    src="https://www.youtube.com/embed/owxlUm9LgH4?enablejsapi=1&rel=0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
                <CornerContainer src={landingImageCorner} />
            </LandingVideoWrapper>
        </LandingVideoContainer>
    )
}
export default LandingVideo