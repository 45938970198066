import React from 'react';
import styled from 'styled-components';
import { Chart } from 'react-google-charts';
import { COLORS } from "../../utils/constants";

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60px;
  justify-content: center;
`;
export const ChartTitle = styled.h2`
  text-align: center;
  font-weight: 600;
  font-size: 23px;
`;
const Subtitle = styled.h5`
  font-size: 16px;
  font-weight: 600;
`;
interface PieChartProps {
    data: (string | number)[][];
    title: string;
    subtitle?: string;
}

const PieChart: React.FC<PieChartProps> = ({ data, title, subtitle }) => {
    return (
        <>
        <TitleContainer>
            <ChartTitle>{title}</ChartTitle>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </TitleContainer>
        <Chart
            width={'100%'}
            height={'250px'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={data}
            options = {
                {
                    colors: COLORS,
                    is3D: true,
                }
            }
            rootProps={{ 'data-testid': '1' }}
        />
        </>
    );
};

export default PieChart;

