import React, { useEffect, useState} from "react";
import PageTitle from "components/PageTitle";
import {SubtitlesEditor} from "../subtitles/SubtitlesEditor";
import {
    useDownloadSourceMutation,
    useUpdateSourceMutation
} from "../../services/projectsApiSlice";
import {
    parseSRT,
    Subtitle,
    convertToSrt,
    secondsToTimestamp,
    Timestamp,
    getSecondsFromTimestamp, areSubtitlesEquivalent
} from "../subtitles/subtitleUtils";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import {VideoPlayer} from "../subtitles/VideoPlayer";
import SuccessPopup from "../ui/forms/SuccessPopup";

const MainContainer = styled.div`
  margin-left: -200px;

  @media (max-width: 1000px) {
    margin-left: -50px;
  }
  @media (max-width: 650px) {
    margin-left: -30px;
  }
`;
const EditorContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

const VideoPlayerContainer = styled.div`
  flex: 4;
  border-radius: 10px;
  border: 1px solid #aaa;
  margin: 52px 10px 0 10px;
  padding: 10px;
  
  @media (max-width: 1200px) {
    width: 750px;
  }
  @media (max-width: 810px) {
    width: 650px;
  }
  @media (max-width: 750px) {
    width: 500px;
  }
  @media (max-width: 580px) {
    width: 350px;
  }
`;
const SubtitlesContainer = styled.div`
  flex: 5;
  padding: 5px;
  margin: 0 5px;
`;
const blobToString = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = () => reject(new Error('Error when reading Blob'));
        reader.readAsText(blob);
    });
};
const SourceEditor: React.FC = () => {
    const { projectId, sourceId } = useParams<{ projectId: string; sourceId: string }>();
    const [editorSubtitles, setEditorSubtitles] = useState<Subtitle[]>([]);
    const [originalSubtitles,setOriginalSubtitles] = useState<Subtitle[] | null>(null);
    const [currentTimeSeconds, setCurrentTimeSeconds] = useState<number>(0);
    const [videoDurationSeconds, setVideoDurationSeconds] = useState<number>(0);
    const [downloadSource] = useDownloadSourceMutation();
    const [updateSource, {isError}] = useUpdateSourceMutation();
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [focusTime, setFocusTime] = useState<number>(0);
    const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false);
    const [videoSubtitles, setVideoSubtitles] = useState<Subtitle[]>(editorSubtitles);
    const [showUpdateBtn, setShowUpdateBtn] = useState<boolean>(false);

    useEffect(() => {
        if(originalSubtitles == null && editorSubtitles.length > 0){
            setOriginalSubtitles(editorSubtitles);
        }

        if(editorSubtitles.length > 0){

            if(editorSubtitles.length != originalSubtitles?.length){
                setShowUpdateBtn(true);
            } else {
                let areSubsDifferent: boolean = false;
                for (let i = 0; i < editorSubtitles.length; i++) {
                    let currentOriginal = originalSubtitles?.[i];
                    let currentEditor = editorSubtitles[i];
                    if(currentOriginal && !areSubtitlesEquivalent(currentOriginal, currentEditor)){
                        areSubsDifferent = true;
                        break;
                    }
                }
                setShowUpdateBtn(areSubsDifferent);
            }
        }
    }, [editorSubtitles]);

    const handleUpdateSource = async () => {
        if (originalSubtitles != editorSubtitles && sourceId && projectId) {
            try {
                const serializedSubtitles = convertToSrt(editorSubtitles);
                await updateSource({projectId: projectId, assetId: sourceId, data: serializedSubtitles }).unwrap()
                if(!isError){
                    setShowSuccessPopup(true);
                    setOriginalSubtitles(editorSubtitles);
                    setShowUpdateBtn(false);
                }

            } catch (error) {
                setShowErrorPopup(true);
            }
        }
    };
    const handleCloseConfirmationPopup = () => {
        setShowSuccessPopup(false);
        setShowErrorPopup(false);
    };

    useEffect(() => {
        const handleBeforeUnload = (e:BeforeUnloadEvent) => {
            if (editorSubtitles != originalSubtitles) {
                e.preventDefault();
            }
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [editorSubtitles, originalSubtitles]);

    useEffect(() => {
        if (sourceId) {
            downloadSource(sourceId)
                .unwrap()
                .then((response) => {
                    if (response instanceof Blob) {
                        return blobToString(response);
                    }
                    return Promise.reject(new Error("Response is not Blob"));
                })
                .then((srtString) => {
                    const parsedSubtitles = parseSRT(srtString);
                    setEditorSubtitles(parsedSubtitles);
                })
                .catch((error) => {
                    console.error("Error while processing SRT file: ", error);
                });
        }
    }, [sourceId, downloadSource]);

    const handleSubtitleFocus = (start: Timestamp): void => {
        setFocusTime(getSecondsFromTimestamp(start))
    }

    const handleReloadClick = ():void => {
        setVideoSubtitles(editorSubtitles);
    }

    return (
        <MainContainer>
            <PageTitle title="Source Editor"/>
            <EditorContainer>
                <SubtitlesContainer id="subtitles-container">
                    <SubtitlesEditor
                        subtitles={editorSubtitles}
                        updateSubtitles={setEditorSubtitles}
                        currentTime={secondsToTimestamp(currentTimeSeconds)}
                        videoDuration={secondsToTimestamp(videoDurationSeconds)}
                        onUpdate={handleUpdateSource}
                        onSubtitleFocus={handleSubtitleFocus}
                        showUpdateBtn={showUpdateBtn}
                    />
                </SubtitlesContainer>

                <VideoPlayerContainer id="video-player-container">
                    <VideoPlayer
                        subtitles={videoSubtitles}
                        onUpdateCurrent={setCurrentTimeSeconds}
                        onUpdateDuration={setVideoDurationSeconds}
                        focusTime={focusTime}
                        onReloadSubtitles={handleReloadClick}
                    />
                </VideoPlayerContainer>

                {showSuccessPopup && <SuccessPopup
                    successMessage="Success!"
                    instructions="Source has been modified."
                    confirmationMessage="Thanks!"
                    handleClosePopup={handleCloseConfirmationPopup}
                />}
                {showErrorPopup && <SuccessPopup
                    successMessage="Permission denied"
                    instructions="You don't have permissions to modify this asset or the project has already started"
                    confirmationMessage="Thanks!"
                    handleClosePopup={handleCloseConfirmationPopup}
                />}
            </EditorContainer>
        </MainContainer>
    );
}

export default SourceEditor