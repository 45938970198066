import React, {useState, useEffect, ChangeEvent, useRef} from "react";
import styled from "styled-components";
import FullTable from "../tables/FullTable";
import Badge, {formatStatus} from "./../projects/Badge";
import FileUploadPopup from "./../projects/FileUploadPopup";
import CountryFlag from "./../projects/CountryFlag";
import {FiUpload} from "react-icons/fi";
import SuccessPopup from "../ui/forms/SuccessPopup";
import SwitchButton from "../buttons/SwitchButton";
import {JobPropsForTable} from "../../pages/Project";
import { useCompleteTaskMutation, useDownloadBilingualMutation, useDownloadTargetMutation, useEditTaskMutation } from "../../services/tasksApiSlice";
import { useGetOneProjectQuery} from '../../services/projectsApiSlice';
import {useNavigate, useParams} from "react-router-dom";
import ModalPopUp from "../administration/ModalPopUp";
import ChangeAssigneeForm from "./ChangeAssigneeForm";
import {AssigneeProps, MemberOption, ProjectFilterProps, formatHeader} from "../../pages/Projects";
import {UserInfo} from "../../store/userSlice";
import {useGetWorkspaceQuery} from "../../services/workspacesApiSlice";
import {TypeOfList} from "../../services/usersApiSlice";
import SelectOption from "../ui/forms/action/SelectOption";
import {FILTER_OPTIONS} from "../../utils/constants";
import {useAppSelector} from "../../hooks/store-hooks";
import {HiDotsVertical} from "react-icons/hi";
import Translate from '../../assets/img/translate.webp';
import {PencilEditIcon} from "../icons/PencilEditIcon";

const UploadButton = styled.button`
  cursor: pointer;
  border: none;
  font-size: 18px;
  background-color: transparent;
`;
const DownloadDropdown = styled.select`
  border-radius: 4px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 4px 8px;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #ccc;
  }
`;
const CenteredIconCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TranslateIcon = styled.div<{imgUrl: string, disabled?: boolean}>`
  width: 35px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.imgUrl});
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
`;
const DownloadOptions = [
    {value: "none", label: "Select"},
    {value: "bilingual", label: "Download Bilingual"},
    {value: "target", label: "Download Target"},
];
const taskTableHeaders = [
    "id",
    "file_name",
    "target_language",
    "assignee",
    "filter",
    "segments",
    "wordcount",
    "status",
    "upload_bilingual",
    "download",
    "translator",
    "complete_task",
];
export const shortenFileName = (fileName: string) => {
    if (fileName.length > 20) {
        const start = fileName.slice(0, 20 / 2);
        const end = fileName.slice(-20 / 2);
        return `${start}...${end}`;
    }
    return fileName;
};
export interface TaskProps {
    id: number;
    file_name: JSX.Element;
    file_name_str: string;
    target_language: string;
    assignee: AssigneeProps;
    filter: ProjectFilterProps;
    segments: number;
    wordcount: number;
    status: JSX.Element;
    upload_bilingual: string;
    download: string;
    complete_task: string;
}
interface TaskTableProps {
    selectedJob: JobPropsForTable | null;
    reloadProject?: () => void;
    style?: React.CSSProperties;
    projectId?: string;
    workspaceId: string;
}
const TaskTable: React.FC<TaskTableProps> = ({selectedJob, reloadProject, workspaceId}) => {
    const [allTasks, setAllTasks] = useState<TaskProps[]>([]);
    const [showUploadPopup, setShowUploadPopup] = useState<number | null>(null);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState<number | null>(null);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [downloadBilingual, downloadBilingualResult] = useDownloadBilingualMutation();
    const [downloadTarget, downloadTargetResult] = useDownloadTargetMutation();
    const [completeTask, completeTaskResult] = useCompleteTaskMutation();
    const {projectId} = useParams<{ projectId: string }>();
    const {refetch} = useGetOneProjectQuery(projectId || "0");
    const [allMembersFromWorkspace, setAllMembersFromWorkspace] = useState<MemberOption[]>([]);
    const { data: workspaceUsers, refetch: refetchWorkspaceUsers } = useGetWorkspaceQuery(workspaceId);
    const [selectedWorkspace, setSelectedWorkspace] = useState('0');
    const [selectedTask, setSelectedTask] = useState(0);
    const [showMembersOptionsModal, setShowMembersOptionsModal] = useState(false);
    const [editTask] = useEditTaskMutation({});
    const [newTaskFilter, setNewTaskFilter] = useState("");
    const [editingFilterTasktId, setEditingFilterTaskId] = useState<number | null>(null);
    const showFilterOptions = useRef<HTMLDivElement | null>(null);
    const [tempTaskId, setTempTaskId] = useState<number | null>(null);
    const userInfoFromState = useAppSelector(state => state.userInfo) as UserInfo;
    const [columnOrder, setColumnOrder] = useState<string[]>(taskTableHeaders);
    const [loadingColumnOrder, setLoadingColumnOrder] = useState(true);
    const navigate = useNavigate();
    const [showFilterConfirmationPopup, setShowFilterConfirmationPopup] = useState(false);
    const [currentAssignee, setCurrentAssignee] = useState("");

    useEffect(() => {
        if(completeTaskResult.isSuccess){
            refetch()
        }
        if(completeTaskResult.isError){

        }
    }, [completeTaskResult]);

    useEffect(() => {
        if(downloadBilingualResult.isSuccess){
            refetch()
        }
        if(downloadBilingualResult.isError){
        }
    }, [downloadBilingualResult, reloadProject]);
    useEffect(() => {
        if (workspaceUsers) {
            let usersForDropdown = workspaceUsers.members
                .map((member:UserInfo) => {
                    return {
                        value: member.username,
                        label: `${member.firstName} ${member.lastName}`,
                    };
                })
                .filter((user:MemberOption) => user.value !== "Underline-Support" && user.value !== currentAssignee);

            setAllMembersFromWorkspace(usersForDropdown);
        }else {
            refetchWorkspaceUsers();
        }
    }, [selectedWorkspace, workspaceUsers, currentAssignee]);
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (showFilterOptions.current && !showFilterOptions.current.contains(event.target as Node)) {
                stopEditingFilter();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showFilterOptions]);
    const ShowWarningPopup = () => {
        return (
            <SuccessPopup
                successMessage="Task cannot be completed."
                instructions="A task can only be completed if its status is 'In Progress' or 'Translation Ready'."
                confirmationMessage="Understood!"
                handleClosePopup={() => setShowWarning(false)}
            />
        );
    };
    const handleOpenUploadPopup = (taskId: number) => {
        setShowUploadPopup(taskId);
    };
    const handleCloseUploadPopup = () => {
        setShowUploadPopup(null);
    };
    const ShowConfirmationPopup = (taskId: number) => {
        return (
            <SuccessPopup
                successMessage={`Task ID ${taskId} will be completed.`}
                instructions="Are you sure?"
                confirmationMessage="Yes!"
                handleClosePopup={() => handleCompleteTask(taskId)}
                handleCancel={handleCloseConfirmationPopup}
                cancelMessage="No!"
            />
        );
    };
    const handleCloseConfirmationPopup = () => {
        setShowConfirmationPopup(null);
    };
    const renderHeader = (header: string, index: number): React.ReactNode => {
        const formattedHeader = formatHeader(header);
        return (
            <React.Fragment>
                <HiDotsVertical style={{ marginRight: "5px" }} />
                {formattedHeader}
            </React.Fragment>
        );
    };
    const renderUploadButton = (taskId: number, isTaskCompleted: boolean, task: any) => (
        <UploadButton
            id={`task_${taskId}_upload_icon`}
            disabled={isTaskCompleted || !canEditTask(task, selectedJob)}
            onClick={() => handleOpenUploadPopup(taskId)}
        >
            <FiUpload/>
        </UploadButton>
    );

    const renderCell = (id: number, key: string, value: any, rowIndex: number): React.ReactNode => {
        const task = allTasks[rowIndex];
        const taskId = task.id;
        const isTaskCompleted = task.status.props.children === "Completed";
        const canCompleteTask = task.status.props.children === "In Progress" || task.status.props.children === "Translation Ready";

        if (key === "target_language") {
            return <CountryFlag languageCode={value}/>;
        } else if (key === "upload_bilingual") {
            return renderUploadButton(taskId, isTaskCompleted, task);
        } else if (key === "download") {
            return (
                <DownloadDropdown
                    id={`task_${taskId}_download_dropdown`}
                    onChange={(event) => handleDownloadSelect(event, taskId,task.file_name_str)}
                    disabled={!canEditTask(task, selectedJob)}
                >
                    {DownloadOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </DownloadDropdown>
            );
        } else if (key === "complete_task") {
            return (
                <SwitchButton
                    id={`task_${taskId}_complete_switch`}
                    checked={!isTaskCompleted}
                    disabled={isTaskCompleted || !canEditTask(task, selectedJob)}
                    onChange={() =>
                        canCompleteTask
                            ? setShowConfirmationPopup(taskId)
                            : setShowWarning(true)
                    }
                />
            );
        } else if (key === "assignee") {
            if (typeof value === "object" && value !== null) {
                return (
                    <>
                        {value.username}
                        {value.icon}
                    </>
                );
            }
        } else if (key === "filter") {
            if (typeof value === "object" && value !== null) {
                if (value.taskId === editingFilterTasktId) {
                    return (
                        <div ref={showFilterOptions}>
                            <SelectOption
                                id={`task_${taskId}_filter_select`}
                                onChange={handleChangeTaskFilter}
                                value={newTaskFilter}
                                options={FILTER_OPTIONS.filter(option => option.value !== value.defaultFilter.toLowerCase())}
                                width={110}
                            />
                        </div>
                    );
                } else {
                    return (
                        <>
                            {value.defaultFilter}
                            {value.icon}
                        </>
                    );
                }
            }
        } else if(key === "translator"){
            return (
                <CenteredIconCell>
                    <TranslateIcon
                        id={`task_${taskId}_translator_icon`}
                        onClick={() => handleTranslateClick(taskId)}
                        imgUrl={Translate}
                        title={"Open Translator"}
                        disabled={!canEditTask(task, selectedJob)}
                    />
                </CenteredIconCell>
            )

        } else {
            return value;
        }
    };
    const handleTranslateClick = (taskId: number) => {
        navigate(`/translator/${projectId}/${taskId}`);
    };

    const handleAssigneeIconClick = (taskId: number, workspaceId: string, assignee: string) => {
        setSelectedWorkspace(workspaceId);
        refetchWorkspaceUsers();
        setShowMembersOptionsModal(true);
        setSelectedTask(taskId);
        setCurrentAssignee(assignee);
    };
    const handleDownloadSelect = async (event: React.ChangeEvent<HTMLSelectElement>, taskId: number, fileName: string) => {
        switch (event.target.value) {
            case "bilingual":
                downloadBilingual(taskId).unwrap()
                    .then((result: { blob: Blob, status: number }) => {
                        handleBlob(result.blob, fileName.replace(".srt", ".xlf"));
                    })
                    .then(() => {
                        refetch();
                    })
                    .catch((error: any) => {
                        console.log("Error in downloadBilingual:", error);
                    });

                break;
            case "target":
                downloadTarget(taskId).unwrap()
                    .then((blob: Blob | Promise<Blob>) => {
                        handleBlob(blob, fileName);
                    });
                break;
        }
    };

    const handleBlob = (blob: any, fileName: string) => {
        const element = document.createElement("a");
        element.href = URL.createObjectURL(blob);
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };
    const handleCompleteTask = async (taskId: number) => {
        await completeTask(taskId);
        setShowConfirmationPopup(null)
    };
    const handleTaskFilterIconClick = (taskId: number) => {
        setEditingFilterTaskId(taskId);
        setTempTaskId(taskId);
    };
    const handleConfirmFilterChange = async () => {
        if (newTaskFilter !== "") {
            try {
                await editTask({ taskId: tempTaskId, updates: { filter: newTaskFilter}});
                setEditingFilterTaskId(null);
                setTempTaskId(null);
                setNewTaskFilter("")
                refetch();
            } catch (err) {
                console.error("Changing filter error: ", err);
            }
        }
        setShowFilterConfirmationPopup(false);
        setEditingFilterTaskId(null);
        setNewTaskFilter("");
        setTempTaskId(null);
    };
    const handleCloseFilterConfirmationPopup = () =>{
        setShowFilterConfirmationPopup(false);
        setEditingFilterTaskId(null);
        setTempTaskId(null);
    }
    const stopEditingFilter = () => {
        setEditingFilterTaskId(null);
    };
    const handleChangeTaskFilter = async (event: ChangeEvent<HTMLSelectElement>) => {
        setNewTaskFilter(event.target.value);
        setShowFilterConfirmationPopup(true);
    };

    function canEditTask(task: any, selectedJob: JobPropsForTable | null) {

        if(userInfoFromState.roles?.includes("ROLE_ADMIN") || userInfoFromState.roles?.includes("ROLE_OWNER")){
            return true;
        }
        if(task.assignee.username === `${userInfoFromState.firstName} ${userInfoFromState.lastName}` || task.assignee.username === userInfoFromState.username){
            return true;
        }
        if(selectedJob?.projectCreator === userInfoFromState.username){
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (userInfoFromState.views && userInfoFromState.views.length > 0) {
            const jobsListView = userInfoFromState.views.find(view => view.name === "TASKS_LIST");
            if (jobsListView) {
                setColumnOrder(jobsListView.columns.split(';'));
                setLoadingColumnOrder(false);
            } else {
                setColumnOrder(taskTableHeaders);
                setLoadingColumnOrder(false);
            }
        } else {
            setColumnOrder(taskTableHeaders);
            setLoadingColumnOrder(false);
        }

        if (selectedJob) {
            const newTasks: TaskProps[] = selectedJob.tasks.map((element: any) => {
                const statusInfo = formatStatus(element.status);
                const statusBadge = (
                    <Badge backgroundColor={statusInfo.color} textColor="#fff">
                        {statusInfo.label}
                    </Badge>
                );
                return {
                    id: element.id,
                    file_name: (
                        <span title={element.file}>
                            {shortenFileName(element.file)}
                        </span>
                    ),
                    file_name_str: element.file,
                    target_language: element.targetLocale,
                    assignee: {
                        username: `${element.assignee.firstName} ${element.assignee.lastName}`,
                        icon: canEditTask(element, selectedJob) ?
                            <PencilEditIcon
                                id={`task_${element.id}_assignee_icon`}
                                onClick={() => handleAssigneeIconClick(element.id, workspaceId, element.assignee.username)}
                                style={{cursor: "pointer"}}
                            />:null
                    },
                    filter:{
                        defaultFilter: element.filter.charAt(0).toUpperCase() + element.filter.slice(1),
                        icon: canEditTask(element, selectedJob) ?
                            <PencilEditIcon
                                id={`task_${element.id}_filter_icon`}
                                onClick={() => handleTaskFilterIconClick(element.id)}
                                style={{cursor: "pointer"}}
                            />: null,
                        taskId: element.id
                    },
                    segments: element.segments,
                    wordcount: element.wordCount,
                    status: statusBadge,
                    upload_bilingual: "Upload",
                    download: "Download",
                    complete_task: "Complete",
                };
            });
            setAllTasks(newTasks);
        }
    }, [selectedJob, userInfoFromState]);

    return (
        <>
            {!loadingColumnOrder && (
                <FullTable
                    typeOfTable={"task"}
                    headers={columnOrder}
                    content={allTasks}
                    renderCell={renderCell}
                    renderHeader={renderHeader}
                    typeOfList={TypeOfList.TASKS_LIST}
                    style={{border: "1px solid #e0e0e0"}}
                    headerBackgroundColor="#fcfadc"
                    isDraggable={true}
                />
            )}
            {showUploadPopup && <FileUploadPopup taskId={showUploadPopup} onClose={handleCloseUploadPopup}/>}
            {showConfirmationPopup && ShowConfirmationPopup(showConfirmationPopup)}
            {showWarning && ShowWarningPopup()}
            {showFilterConfirmationPopup &&
                <SuccessPopup
                    successMessage="By changing this filter, you will lose the record of translated segments, history, and comments."
                    instructions="This action cannot be undone. Are you sure you want to proceed?"
                    confirmationMessage="Yes, proceed"
                    handleClosePopup={handleConfirmFilterChange}
                    handleCancel={handleCloseFilterConfirmationPopup}
                    cancelMessage="Cancel"
                />
            }
            {showMembersOptionsModal  && (
                <ModalPopUp>
                    <ChangeAssigneeForm
                        handleClose={()=> setShowMembersOptionsModal(false)}
                        memberOptions={allMembersFromWorkspace}
                        id={selectedTask}
                        type={'task'}
                        onAssigneeChanged={refetch}
                    />
                </ModalPopUp>
            )}
        </>
    );
};

export default TaskTable;