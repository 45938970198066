import React from 'react';
import { IconType } from 'react-icons';
import styled from 'styled-components';
import { FaAsterisk } from "react-icons/fa";

const FormLabel = styled.label<{ marginLeft: number}>`
  margin: 10px 10px 0 10px;
  margin-left: ${({ marginLeft }) => marginLeft}px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  svg {
    margin-right: 8px;
  }
`;
const RedAsterisk = styled(FaAsterisk)`
  color: red;
  margin-left: 2px;
  font-size: 8px;
  position: relative;
  top: -5px;
`;

const ExtraMessage = styled.span`
  font-size: 12px;
  color: #969696;
`;
interface ActionFormLabelProps{
  icon?: IconType;
  text: string;
  isRequired?: boolean;
  marginLeft?: number;
  extraMessage?: string;
}
const ActionFormLabel: React.FC<ActionFormLabelProps> = ({icon: IconComponent, text, isRequired, marginLeft= 10, extraMessage}) => {
    return (
        <>
            <FormLabel marginLeft={marginLeft}>
                {IconComponent && <IconComponent/>}
                {text}
                {isRequired && <RedAsterisk/>}
                <ExtraMessage>{extraMessage}</ExtraMessage>
            </FormLabel>
        </>
    );
}

export default ActionFormLabel;