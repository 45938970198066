import React from 'react'
import logo from "../../../../assets/img/logo-gray-mustard-transparent.webp";
import styled from 'styled-components';

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    z-index: 1;
`;
const LogoImage = styled.img`
    height: 170px;
    width: auto;

  @media screen and (min-resolution: 140dpi) {
    height: 130px;
  }
`;
const AuthFormLogo: React.FC = () => {
    return (
        <ImageContainer>
            <LogoImage src={logo} alt="Underline logo" title={"underline-logo"} />
        </ImageContainer>
    );
};

export default AuthFormLogo