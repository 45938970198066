import React from 'react';
import styled from 'styled-components';
import profilePic from '../../../../assets/img/profile-pic.webp'

const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
`;

const ProfileImage = styled.img`
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50%;
`;
const UserDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 20px;
`;

const UserInfo = styled.p`
    margin: 0;
`;
const UserName = styled.p`
    margin: 0;
    font-size: 20px;
    font-weight: bold;
`;
const UserRole = styled.p`
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
`;
interface ProfileCardProps {    
    firstName: string;
    lastName: string;
    role: string;
    id: string;
    instanceId: number
}
const UserBasicInfo: React.FC<ProfileCardProps> = ({firstName, lastName, role, id, instanceId}) => {
    
    return (
    <CardContainer>
        <ProfileImage src={profilePic} alt={"Underline user avatar"} title={"underline-user-avatar"} />
        <UserDetails>
            <UserName id={"user_full_name"}>{`${firstName} ${lastName}`}</UserName>
            <UserRole id={"user_role"}>{role}</UserRole>
            <UserInfo id={"user_id"}>{`User ID: ${id}`}</UserInfo>
            <UserInfo id={"user_instance_id"}>{`Instance Id: ${instanceId}`}</UserInfo>
        </UserDetails>
    </CardContainer>
    )
}
export default UserBasicInfo