import React, { useState} from 'react';
import styled from 'styled-components';
import { VscSettingsGear } from "react-icons/vsc";
import SettingsModal from "./CookieSettingModal";
import {useAppDispatch} from "../../hooks/store-hooks";
import {COOKIES_CONSENT_EXPIRATION_HOURS} from "../../utils/constants";
import {setCookieConsent} from "../../store/cookieConsentSlice";

const Area = styled.div<{ isSidebarCollapsed: boolean }>`
  position: fixed;
  bottom: 15px;
  left: ${({ isSidebarCollapsed }) => (isSidebarCollapsed ? '-9px' : '170px')};
  width: 50px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  z-index: 1041;
`;
const GearButton = styled.div<{ isSidebarCollapsed: boolean }>`
  position: fixed;
  bottom: 70px;
  left: ${({ isSidebarCollapsed }) => (isSidebarCollapsed ? '1px' : '180px')};
  cursor: pointer;
  z-index: 1040;
`;
const StyledGearIcon = styled(VscSettingsGear)<{ showTooltip: boolean }>`
  font-size: 24px;
  color: ${({ showTooltip }) => (showTooltip ? '#EAC113' : '#fff')};
  cursor: pointer;
  transition: color 0.3s;

  @media screen and (min-resolution: 140dpi) {
    font-size: 18px;
  }
`;
const IconWrapper = styled.div<{ rotationDegree: number,  showTooltip: boolean }>`
  background-color: ${({ showTooltip }) => (showTooltip ? '#111111' : '#1a1a1a')};
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.15s;
  transform: ${({rotationDegree}) => `rotate(${rotationDegree}deg)`};
  z-index: 1041;

  @media screen and (min-resolution: 140dpi) {
    width: 40px;
    height: 40px;
  }
`;
const Tooltip = styled.div`
  background-color: #111111;
  color: white;
  padding: 16px;
  font-size: 16px;
  width: 215px;
  border-radius: 10px 30px 30px 10px;
  position: absolute;
  bottom: 55px;
  left: 28px;
  white-space: nowrap;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1030;  
  transition: color 0.3s;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: #EAC113;
  }

  @media screen and (min-resolution: 140dpi) {
    font-size: 12px;
    width: 170px;
    padding: 11px;
  }
`;
interface CookieGearProps {
    isSidebarCollapsed: boolean;
}
const CookieGear: React.FC<CookieGearProps> = ({isSidebarCollapsed}) => {
    const [rotationDegree, setRotationDegree] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const handleMainIconMouseEnter = () => {
        setRotationDegree(180);
        setShowTooltip(true);
    };
    const handleMainIconMouseLeave = () => {
        setRotationDegree(0);
        setShowTooltip(false);
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleAcceptCookies = () => {
        let expirationDate: number = new Date().getTime() + COOKIES_CONSENT_EXPIRATION_HOURS * 60 * 60 * 1000;
        dispatch(setCookieConsent({consent: true, expirationDate, necessaryCookies: true, thirdPartyCookies: true}));
        setIsModalOpen(false)
    };

    return(
        <Area isSidebarCollapsed={isSidebarCollapsed}>
            <GearButton isSidebarCollapsed={isSidebarCollapsed}>
                <IconWrapper
                    rotationDegree={rotationDegree}
                    showTooltip={showTooltip}
                    onMouseEnter={handleMainIconMouseEnter}
                    onMouseLeave={handleMainIconMouseLeave}
                >
                    <StyledGearIcon showTooltip={showTooltip} />
                </IconWrapper>
            </GearButton>
            {showTooltip && (
                <Tooltip
                    onMouseEnter={handleMainIconMouseEnter}
                    onMouseLeave={handleMainIconMouseLeave}
                    onClick={handleOpenModal}
                >
                    Change Cookies Settings
                </Tooltip>
            )}
            {isModalOpen && (
                <SettingsModal
                    handleClose={handleCloseModal}
                    handleAcceptCookies={handleAcceptCookies}
                    setIsModalOpen={setIsModalOpen}
                />
            )}
        </Area>
    )
}

export default CookieGear