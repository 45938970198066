import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

interface ItemLinkProps {
    isCollapsed: boolean;
}

const ItemLink = styled.button<ItemLinkProps>`
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 18px;
  line-height: 0.5;
  width: 100%;
  text-align: left;
  background-color: #3b3b3b;
  border: 0;
  cursor: pointer;
  margin-bottom: ${(props) => (props.isCollapsed ? "8px" : "0")};
  &:hover {
    background-color: gray;
  }
  &:focus {
    outline: none;
  }
`;

const ItemText = styled.p`
  font-size: 15px;
  color: #ffff;
  vertical-align: middle;
  margin-left: 8px;

  @media screen and (min-resolution: 140dpi) {
    font-size: 12px;
  }  
`;

const IconWrapper = styled.span`
  margin-left: 10px;
  color: #fff;
  
  @media screen and (min-resolution: 140dpi) {
    font-size: 15px;
  }
`;

interface NavbarItemProps {
    icon: JSX.Element;
    text: string;
    link: string;
    isCollapsed: boolean;
}

const NavbarItem: React.FC<NavbarItemProps> = ({ icon, text, link, isCollapsed }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(link);
    };
    return (
        <li>
            <ItemLink title={text} isCollapsed={isCollapsed} onClick={handleClick}>
                <IconWrapper >{React.cloneElement(icon)}</IconWrapper>
                <ItemText style={{ display: isCollapsed ? "none" : "inline" }}>
                    {text}
                </ItemText>
            </ItemLink>
        </li>
    );
};

export default NavbarItem;

