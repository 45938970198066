import React, { ChangeEvent, ReactNode } from 'react'
import styled from 'styled-components';

const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top:16px;
  margin-bottom: 16px;
  flex-wrap: nowrap;
  height: 34px;
  max-width: 100%;
  padding-left:20px;
  padding-right:20px;

  @media (max-width: 680px) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;
const InputIconContainer = styled.div`
  display: flex;
  margin-right: -1px;
  display: -ms-flexbox;
  display: flex;
`;
const InputIcon = styled.span`
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #2d292a;
  text-align: center;
  white-space: nowrap;
  background-color: #eac113;
  border: 1px solid #eac113;  
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  height:20px;
`;
const TextInput = styled.input<{ inputWidth?: string, inputHeight?: string }>`
  display: block;
  width: ${({ inputWidth }) => inputWidth || '300px'};
  height: ${({ inputHeight }) => inputHeight || '20px'};
  padding: 6px 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #2d292a;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border:  1px solid #aaa;
    outline: none;
  }
  @media (max-width: 395px) {
    width: 170px;
  }
`;
interface RegistrationInputProps {
  prependIcon: ReactNode;
  placeholder: string;
  type: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  inputWidth?: string;
  inputHeight?: string;
}

const AuthFormInput: React.FC<RegistrationInputProps> = ({ prependIcon, placeholder,type,value,onChange,name, inputWidth, inputHeight}) => {
  return (
    <InputContainer>
      <InputIconContainer>
        <InputIcon>{prependIcon}</InputIcon>
      </InputIconContainer>
      <TextInput
          name={name}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={onChange}
          inputWidth={inputWidth}
          inputHeight={inputHeight}
      />
    </InputContainer>
  );
};

export default AuthFormInput;