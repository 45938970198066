import React from 'react';
import logoWhite from "../../../assets/img/logo-white-transparent.webp";
import styled from 'styled-components';

const MessageContainer = styled.div<{ workspacesMode?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;

  @media screen and (max-width: 940px) {
    left: ${({ workspacesMode }) => (workspacesMode ? '20%' : '0')};
  }
`;
const PopupContent = styled.div`
    background-color: #fff;
    padding: 20px 60px;
    border-radius: 5px;
    text-align: center;
    max-width: 600px;
    width: 90%;
    box-sizing: border-box;

    @media (max-width: 1100px) {
        margin-left: 10%; 
        margin-right: 10%;
    }
`;
const MessageContent = styled.h3`
    word-wrap: break-word;
    font-weight: 600;
    font-size: 18px;
    white-space: normal;
`;
const ConfirmationButton = styled.button`
  background-color: #34d639;
  color: #fff;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  &:focus {
    border:  1px solid #ccc;
    outline: none;
  }
`;
const CancelButton = styled.button`
  background-color: #f44336;
  color: #fff;
  padding: 5px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  margin-right: 20px;
  &:focus {
    border: 1px solid #ccc;
    outline: none;
  }
`;
interface SuccessPopupProps {
    successMessage: string;
    instructions: string;
    confirmationMessage: string;
    handleClosePopup: () => void;
    handleCancel?: () => void;
    cancelMessage?: string;
    handleConfirm?: () => void;
    workspacesMode?: boolean;
}

const SuccessPopup: React.FC<SuccessPopupProps> = ({
                                                       successMessage,
                                                       instructions,
                                                       confirmationMessage,
                                                       handleClosePopup,
                                                       handleCancel,
                                                       handleConfirm,
                                                       cancelMessage,
                                                       workspacesMode,
                                                   }) => {
    return (
        <MessageContainer workspacesMode={workspacesMode}>
            <PopupContent>
                <img src={logoWhite} alt="Underline logo" title={"underline-logo"} height="90px" />
                <MessageContent>{successMessage}</MessageContent>
                <p>{instructions}</p>
                {handleCancel && (
                    <CancelButton
                        id={"popup_cancel_button"}
                        onClick={handleCancel}
                    >
                        {cancelMessage || "Cancel"}
                    </CancelButton>
                )}
                <ConfirmationButton
                    id={"popup_confirmation_button"}
                    onClick={handleClosePopup}
                >
                    {confirmationMessage}
                </ConfirmationButton>
            </PopupContent>
        </MessageContainer>
    );
};


export default SuccessPopup;