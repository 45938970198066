import React, {ReactNode} from 'react';
import { useAppSelector } from "../hooks/store-hooks";
import { useNavigate } from "react-router-dom";

interface RequireRoleProps {
    allowedRoles: UserRole[];
    children: ReactNode;
}
export enum UserRole {
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_OWNER = "ROLE_OWNER",
    ROLE_USER = "ROLE_USER",
}
const RequireRole: React.FC<RequireRoleProps> = ({ allowedRoles, children }) => {
    const userRole = useAppSelector(state => state.userInfo.roles[0]);
    const navigate = useNavigate();

    if (!allowedRoles.includes(userRole as UserRole)) {
        navigate('/home');
        return null;
    }
    return <>{children}</>;
};
export default RequireRole;