import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
    useDownloadBilingualMutation,
    useGetTaskSegmentsQuery,
    usePreviewTargetMutation,
    useUpdateTaskSegmentsMutation
} from "../services/tasksApiSlice";
import {parseSRT, parseSubtitleTimestampString, Subtitle} from "../components/subtitles/subtitleUtils";
import styled from "styled-components";
import PageTitle from "../components/PageTitle";
import CountryFlag from "../components/projects/CountryFlag";
import {MdDoubleArrow, MdOutlineCloudOff} from "react-icons/md";
import SlimButton from "../components/ui/forms/action/SlimButton";
import SuccessPopup from "../components/ui/forms/SuccessPopup";
import {VideoPlayer} from "../components/subtitles/VideoPlayer";
import TargetEditor from "../components/translator/TargetEditor";
import PlaceholderBadge from "../components/translator/PlaceholderBadge";
import SwitchButton from "../components/buttons/SwitchButton";
import {BsCloudCheck} from "react-icons/bs";
import CloudSyncing from "../assets/img/cloud-sync.gif";
import {useGetOneProjectQuery} from "../services/projectsApiSlice";
import Validations, {Validation, ValidationItem, ValidationType} from "../components/validations/Validations";
import {DetailWrapper} from "../components/projects/InfoCard";
import {Segment, SegmentStatus, SegmentType} from "../services/segmentsApiSlice";
import {FaCheckCircle, FaSearch} from "react-icons/fa";
import {IoLockClosedSharp} from "react-icons/io5";
import {RiPencilFill} from "react-icons/ri";
import {FcCancel} from "react-icons/fc";
import {Popover} from "react-tiny-popover";
import {BiFileBlank} from "react-icons/bi";
import {TbSquareRoundedLetterR} from "react-icons/tb";

const SegmentIconTooltip = styled.div`
  background: white;
  border: 1px solid black;
  border-radius: 4px;
  padding: 4px 10px;
  font-size: 14px;
`

const SegmentHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1.2fr;
  background-color: #f3f6f4;
  padding: 10px;
  font-weight: bold;
`;

const TranslatorHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
`;

const TranslatorControlsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  gap: 20px;
`;

const SingleControlContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SegmentsScrollContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

const DetailsBox = styled.div`
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: #ffffff;
  padding: 20px;
  width: fit-content;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  align-items: flex-start;
  grid-auto-rows: min-content;
  grid-gap: 10px;

  @media (max-width: 1115px) {
    grid-template-columns: 1fr;
  }
`;
const SegmentRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto 1fr;
  align-items: stretch;
  gap: 10px;
  padding: 0;
`;

const GridContainer = styled.div`
    display: grid;
    height: 105vh;
    grid-template-columns: auto 45%;
    grid-template-rows: 1fr 4fr 2fr;
    grid-template-areas: 
        "details validations"
        "translator video"
        "footer footer";
    grid-column-gap: 5px;
    grid-row-gap: 10px;

  @media screen and (min-resolution: 140dpi) {
    height: 150vh;
  }
  @media (max-width: 300px) {
    height: 130vh;
  }
`;

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-area: translator;
  padding: 0 10px;
`;

const TranslatorContainer = styled.div`
`

const ValidationsContainer = styled.div`
  grid-area: validations;
  border-radius: 10px;
  border: 1px solid #aaa;
  overflow: hidden;
`;
const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  grid-area: details;

  @media (max-width: 1115px) {
    width: 400px;
  }
`;

const SegmentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8px;
`;
const SegmentId = styled.div`
  font-weight: bold;
`

const SegmentContainer = styled.div`
  min-height: auto;
  max-height: 410px;
  overflow-y: auto;
  margin-top: 5px;
`;

const SourceContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #6d7f92;
  padding: 8px;
  font-size: 14px;
  background-color: #f3f6f4;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
`;

const Detail = styled.div`
  margin: 5px;
  font-size: 16px;
`;

const FlagDetail = styled(Detail)`
  margin: 5px 0;
`;

const DetailLabel = styled.span`
  font-weight: bold;
  margin-right: 2px;
`;

const ButtonsContainer = styled.div`
  margin-top: 15px;
`;

const VideoPlayerContainer = styled.div`
  border-radius: 10px;
  border: 1px solid #aaa;
`;

const GifImage = styled.img`
  width: 30px;
  height: auto;
`;

const ContextSegmentContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 25px;
`;

const ContextInfoContainer = styled.div`
  margin: 0 10px;
`;

const TextContainer = styled.span`
  white-space: pre-wrap;
`;

interface Detail {
    label: string;
    value: React.ReactNode;
    CustomDetail?: React.ReactNode
}

interface LastConfirmedContent {
    segId: string,
    lastConfirmedStatus: SegmentStatus,
    lastConfirmedContent: string
}

export interface EditorSegment extends Segment {
    repetitionData: RepetitionData;
}

interface RepetitionData {
    isRepetition: boolean;
    isFirstOccurrence: boolean;
    repeatsSegment: number;
}

export const Translator: React.FC = () => {
    const navigate = useNavigate();
    const {projectId, taskId} = useParams<{ projectId: string; taskId: string }>();
    const taskIdNumber = taskId ? parseInt(taskId, 10) : null;
    const [updateTaskSegments] = useUpdateTaskSegmentsMutation();
    const {data: savedSegmentsData} = useGetTaskSegmentsQuery(taskIdNumber || 0);
    const {data: projectData} = useGetOneProjectQuery(projectId || '0');
    const [segments, setSegments] = useState<EditorSegment[]>([]);
    const [details, setDetails] = useState<Detail[]>([]);
    const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false);
    const [showWarningMessage, setShowWarningMessage] = useState<boolean>(false);
    const [currentTimeSeconds, setCurrentTimeSeconds] = useState<number>(0);
    const [focusTime, setFocusTime] = useState<number>(0);
    const [videoDurationSeconds, setVideoDurationSeconds] = useState<number>(0);
    const [videoSubtitles, setVideoSubtitles] = useState<Subtitle[]>([]);
    const [previewTarget] = usePreviewTargetMutation();
    const [downloadBilingual] = useDownloadBilingualMutation();
    const [showContext, setShowContext] = useState<boolean>(false);
    const [clearAllCounter, setClearAllCounter] = useState<number>(0);
    const [areSegmentsSync, setAreSegmentsSync] = useState(true);
    const [disableShowContext, setDisableShowContext] = useState<boolean>(false);
    const [validations, setValidations] = useState<Validation[]>([]);
    const [lastConfirmedValues, setLastConfirmedValues] = useState<LastConfirmedContent[]>([])
    const [preTranslate, setPretranslate] = useState<boolean>(true);
    const [validationFocus, setValidationFocus] = useState<{id: number, time: Date} | null>(null);

    useEffect(() => {
        if (segments.findIndex(s => s.type === SegmentType.NON_TRANSLATABLE) >= 0) {
            setDisableShowContext(false);
        } else {
            setDisableShowContext(true);
        }
        if(lastConfirmedValues.length === 0){
            setLastConfirmedValues(segments.map(s => ({segId: s.id, lastConfirmedContent: s.target, lastConfirmedStatus: s.status})));
        }
    }, [segments]);

    const formatSourceText = (segment: Segment) => {
        const sourceText = segment.source;
        const regex = /(\{\d+\})/g;
        const parts = sourceText.split(regex);
        return (
            <span>
                {parts.map((part, index) => {
                    if (regex.test(part)) {
                        const placeholderId = part.replace(/[{}]/g, '');
                        const currentPh = segment.placeholders.find(ph => ph.tagId.toString() === placeholderId)
                        return (
                            <PlaceholderBadge key={index} placeholderObj={currentPh}>
                                {part}
                            </PlaceholderBadge>
                        );
                    } else {
                        return <TextContainer key={index} style={{display: 'inline'}}>{part}</TextContainer>;
                    }
                })}
            </span>
        );
    };

    const compareSegments = (a: Segment, b: Segment): number => {
        if (a.taskSegmentId === b.taskSegmentId) {
            //Sort non-translatable segments first
            if (a.type == SegmentType.NON_TRANSLATABLE) {
                return -1;
            } else {
                return 1;
            }
        }
        return a.taskSegmentId - b.taskSegmentId;
    }

    function normalizeTags(segmentTextContent: string) : String {
        if (segmentTextContent == undefined) {
            return segmentTextContent;
        }
        // Replace numbers within curly braces with a placeholder
        return segmentTextContent.replace(/\{\d+\}/g, '{$}');
    }

    useEffect(() => {
        if (savedSegmentsData && Array.isArray(savedSegmentsData)) {
            const sortedSegments = [...savedSegmentsData].sort(compareSegments);
            //Adds repetition data
            const segmentsWithRepetitions = sortedSegments.map((s,i) => {
                let currentRepData: RepetitionData = {repeatsSegment: 0, isRepetition: false, isFirstOccurrence: false};

                let segmentsWithSameSource = sortedSegments.filter(compareSegment => normalizeTags(compareSegment.source) === normalizeTags(s.source));
                //If more than 1 segment with same source mark this one as repetition
                if(segmentsWithSameSource.length > 1){
                    currentRepData = {...currentRepData, isRepetition: true}
                    let firstMatchIndex = sortedSegments.findIndex(compareSegment => normalizeTags(compareSegment.source) === normalizeTags(s.source));
                    if(firstMatchIndex === i){
                        currentRepData = {...currentRepData, isFirstOccurrence: true}
                    } else {
                        //If is not the first occurrence
                        currentRepData = {...currentRepData, repeatsSegment: sortedSegments[firstMatchIndex].taskSegmentId}
                    }
                }

                return {...s, repetitionData: currentRepData};
            })
            setSegments(segmentsWithRepetitions);

            let firstTranslatableSeg = savedSegmentsData.filter(s => s.type === SegmentType.TRANSLATABLE)[0];
            const newDetails: Detail[] = [
                {
                    label: 'Source Locale',
                    value: <CountryFlag languageCode={firstTranslatableSeg.sourceLocale} fontSize="13px"/>,
                    CustomDetail: FlagDetail
                },
                {
                    label: 'Target Locale',
                    value: <CountryFlag languageCode={firstTranslatableSeg.targetLocale} fontSize="13px"/>,
                    CustomDetail: FlagDetail
                }
            ];
            if (projectData) {
                newDetails.push(
                    {
                        label: 'Max lines per subtitle',
                        value: projectData.maxLines.toString()
                    },
                    {
                        label: 'Max chars per line',
                        value: projectData.maxCharactersPerLine.toString()
                    }
                )
            }
            newDetails.push({
                label: 'Total Segments',
                value: savedSegmentsData.filter(s => s.type === SegmentType.TRANSLATABLE).length.toString()
            });

            setDetails(newDetails);
        }
    }, [savedSegmentsData, projectData]);

    const handleTargetChange = (newText: string, segmentId: string) => {
        setSegments((prevSegments) => {
            let foundSegmentIndex: number = prevSegments.findIndex(s => s.id === segmentId);
            if(foundSegmentIndex < 0) return prevSegments;

            let foundLastConfirmedValue: LastConfirmedContent | undefined = lastConfirmedValues.find(value => value.segId === segmentId);

            //The new status will be Draft or the last confirmed value depending on the changes in the target content
            let newStatus: SegmentStatus = prevSegments[foundSegmentIndex].status;
            if(foundLastConfirmedValue){
                if(newText !== foundLastConfirmedValue.lastConfirmedContent){
                    newStatus = SegmentStatus.DRAFT;
                }else{
                    newStatus = foundLastConfirmedValue.lastConfirmedStatus;
                }
            }

            //Change the status and target content of the relevant segment
            return prevSegments.map((s, i) => {
                if(s.id === segmentId &&  i === foundSegmentIndex){
                    return {...s, target: newText, status: newStatus}
                }else{
                    return s;
                }
            });
        });
    };

    const handleLoadSubtitles = async () => {
        if (taskIdNumber === null) {
            return;
        }

        const updatedSegments = segments.map(segment => ({
            id: segment.id,
            target: segment.target
        }));

        const requestData = {
            taskId: taskIdNumber,
            segments: updatedSegments
        };
        try {
            const result = await previewTarget(requestData).unwrap();
            const srtData = parseSRT(result.toString());
            setVideoSubtitles(srtData)
        } catch (error) {
            console.error("Error when loading subs: ", error);
        }
    };

    const handleBlob = (blob: any) => {
        const element = document.createElement("a");
        element.href = URL.createObjectURL(blob);
        element.download = `udl_file_${taskId}.xlf`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };
    const handleDownloadXliff = () => {
        if (taskIdNumber === null) {
            return;
        }
        if (!areSegmentsSync) {
            setShowWarningMessage(true);
            return;
        }

        downloadBilingual(taskIdNumber).unwrap()
            .then((result: { blob: Blob, status: number }) => {
                handleBlob(result.blob);
            })
    };

    const handleClearAll = (): void => {
        // This will trigger the useEffect hook on all TargetEditor components.
        setClearAllCounter(counter => counter + 1);
    }


    const handleContextControlChange = (isChecked: boolean): void => {
        setShowContext(isChecked);
    }

    const handlePretranslateControlChange = (isChecked: boolean): void => {
        setPretranslate(isChecked);
    }

    const handleSegmentValidationUpdate = (newSegmentValidations: Validation[], segmentNumber: number): void => {
        setValidations(prevValidations => {
            return prevValidations
                .filter(v => v.inputId !== segmentNumber)
                .concat(newSegmentValidations);
        })
    }

    const handleSegmentStatusConfirmation = (newStatus: SegmentStatus, segmentId: string, newContent: string): void => {
        setLastConfirmedValues(prevValues => {
            return prevValues.map(value => {
                if(value.segId === segmentId){
                    return {...value, lastConfirmedStatus: newStatus, lastConfirmedContent: newContent}
                }else {
                    return value;
                }
            })
        })
    }

    function replacePlaceholders(targetFrom1stOccurrence: string, currentSegmentSource: string): string {
        const tagRegex = /\{\d+\}/g;
        let phsFromCurrentSource: RegExpMatchArray | null = currentSegmentSource.match(tagRegex);
        let phsFromNewTarget: RegExpMatchArray | null = targetFrom1stOccurrence.match(tagRegex);
        if (!phsFromCurrentSource) return targetFrom1stOccurrence;
        if (phsFromCurrentSource.length !== phsFromNewTarget?.length) return targetFrom1stOccurrence;


        let index: number = 0
        return targetFrom1stOccurrence.replace(tagRegex, (match, i) => {
            if (phsFromCurrentSource && index >= 0 && index < phsFromCurrentSource.length) {
                return phsFromCurrentSource[index++];
            }
            return match;
        });
    }

    const handleUpdateFirstOccurrence = (taskSegmentId: number, previousFirstOccurrenceTarget: string, newFirstOccurrenceTarget: string): void => {
        if (!preTranslate) return;
        if (!taskIdNumber) return;

        let repetitionsInSync = segments
            //If segments are in sync the previous first occurrence target should be the same as the current target of the repetition
            .filter(s => normalizeTags(previousFirstOccurrenceTarget) === normalizeTags(s.target))
            //If the status of the segment was modified manually should not be in sync
            .filter(s => (s.status === SegmentStatus.DRAFT || (s.status === SegmentStatus.NON_TRANSLATED && (s.target === "" || s.target.match(/\{\d+}+/g)))))
            .filter(s => s.repetitionData.isRepetition && s.repetitionData.repeatsSegment === taskSegmentId && !s.repetitionData.isFirstOccurrence);

        let newStatus = newFirstOccurrenceTarget === "" ? SegmentStatus.NON_TRANSLATED : SegmentStatus.DRAFT;

        let segmentsRequestData: { id: string, target: string, status: SegmentStatus }[] = repetitionsInSync.map(s => {
            let newTargetText = replacePlaceholders(newFirstOccurrenceTarget, s.source);
            return { id: s.id, target: newTargetText, status: newStatus };
        });

        let requestBody = { taskId: taskIdNumber, segments: segmentsRequestData };
        updateTaskSegments(requestBody);
    }

    return (
        <>
            <PageTitle title="Online Editor"/>
            <GridContainer>
                <DetailsContainer>
                    <DetailsBox>
                        {details.map((detail) => {
                            return (
                                <DetailWrapper key={detail.label}>
                                    <MdDoubleArrow/>
                                    <DetailLabel>{detail.label}: </DetailLabel>
                                    <span id={`segments_${detail.label.toLowerCase()}`}>{detail.value}</span>
                                </DetailWrapper>
                            );
                        })}
                    </DetailsBox>
                </DetailsContainer>
                <ValidationsContainer>
                    <Validations>
                        {validations
                            .sort((v1, v2) => v1.inputId - v2.inputId)
                            .map(((validation, i) => {
                            return (
                                <ValidationItem key={`validation-${i}`} validation={validation} onValidationClick={setValidationFocus}/>
                            );
                        }))}
                    </Validations>
                </ValidationsContainer>
                <EditorContainer>
                    <TranslatorContainer>
                        <TranslatorHeaderContainer>
                            <TranslatorControlsContainer>
                                <SwitchControl onChange={handleContextControlChange} isDisabled={disableShowContext} label={"Show Context"} initState={showContext}/>
                                <SwitchControl onChange={handlePretranslateControlChange} isDisabled={false} label={"Pre-translate repetitions"} initState={preTranslate}/>
                            </TranslatorControlsContainer>
                            {validations.find(v => v.type === ValidationType.ERROR)
                                ? <MdOutlineCloudOff size={'25px'} title={"Error Syncing Data"} className="error"/>
                                : areSegmentsSync
                                    ? <BsCloudCheck size={'30px'} title={"Data Saved"} className="loaded"/>
                                    : <GifImage src={CloudSyncing} alt="Syncing data" title={"Saving Data"}
                                                className="loading"/>
                            }
                        </TranslatorHeaderContainer>
                        <SegmentHeader>
                            <div>#</div>
                            <div>Source</div>
                            <div>Target</div>
                        </SegmentHeader>
                        <SegmentsScrollContainer>
                            {segments
                                .map((segment, index) => (
                                    <SegmentContainer key={`segment-container-${index}-${segment.taskSegmentId}`}>
                                        {showContext && segment.type === SegmentType.NON_TRANSLATABLE &&
                                            <ContextSegment segment={segment}/>
                                        }
                                        {segment.type === SegmentType.TRANSLATABLE &&
                                            <SegmentRow>
                                                <SegmentInfoContainer>
                                                    <SegmentId>{segment.taskSegmentId}</SegmentId>
                                                </SegmentInfoContainer>
                                                <SourceContainer id={`source-input-${segment.taskSegmentId}`}>
                                                    {formatSourceText(segment)}
                                                </SourceContainer>
                                                <SegmentInfoContainer>
                                                    <SegmentStatusIcon status={segment.status}/>
                                                    {segment.repetitionData.isRepetition && <RepetitionIcon repetitionData={segment.repetitionData}/>}
                                                </SegmentInfoContainer>
                                                <TargetEditor
                                                    segment={segment}
                                                    onTextChange={handleTargetChange}
                                                    clearOnChange={clearAllCounter}
                                                    setAreSegmentsSync={setAreSegmentsSync}
                                                    onUpdateError={handleSegmentValidationUpdate}
                                                    maxLines={projectData?.maxLines ? projectData.maxLines : 0}
                                                    maxCharsPerLine={projectData?.maxCharactersPerLine ? projectData.maxCharactersPerLine : 0}
                                                    onChangeStatus={handleSegmentStatusConfirmation}
                                                    onUpdateFirstOccurrence={handleUpdateFirstOccurrence}
                                                    focus={validationFocus}
                                                />
                                            </SegmentRow>
                                        }
                                    </SegmentContainer>
                                ))}
                        </SegmentsScrollContainer>
                    </TranslatorContainer>
                    <ButtonsContainer>
                        <SlimButton id={"clear-all-button"} text={"Clear All"} onClick={handleClearAll}
                                    marginTop={'5px'}
                                    textColor="#2d292a" bgColor="#eac113"
                                    bgGradient="linear-gradient(to right, #eac113, #ffeb3b, #eac113)"/>
                        <SlimButton id={"download-xliff-button"} text={"Download Xliff"} onClick={handleDownloadXliff}
                                    marginTop={'5px'}
                                    textColor="#2d292a" bgColor="#eac113"
                                    bgGradient="linear-gradient(to right, #eac113, #ffeb3b, #eac113)"/>
                        <SlimButton id={"back_to_project"} text="Back" onClick={() => navigate(`/project/${projectId}`)}
                                    marginTop={"5px"}
                                    textColor="#2d292a" bgColor="#eac113"
                                    bgGradient="linear-gradient(to right, #eac113, #ffeb3b, #eac113)"/>
                    </ButtonsContainer>
                </EditorContainer>
                <VideoPlayerContainer>
                    <VideoPlayer
                        subtitles={videoSubtitles}
                        onUpdateCurrent={setCurrentTimeSeconds}
                        onUpdateDuration={setVideoDurationSeconds}
                        focusTime={focusTime}
                        onReloadSubtitles={handleLoadSubtitles}
                        reloadText={"Apply Subs to Video"}
                    />
                </VideoPlayerContainer>
                {showSuccessPopup && <SuccessPopup
                    successMessage="Success!"
                    instructions="Target has been saved."
                    confirmationMessage="Thanks!"
                    handleClosePopup={() => setShowSuccessPopup(false)}
                />}
                {showWarningMessage && <SuccessPopup
                    successMessage="It looks like you have unsaved changes"
                    instructions="Please save your segment changes to download file."
                    confirmationMessage="Understood"
                    handleClosePopup={() => setShowWarningMessage(false)}
                />}
            </GridContainer>
        </>
    )
}
const SwitchControl: React.FC<{ onChange: (value: boolean) => void, isDisabled?: boolean, label: string, initState: boolean}> = (props) => {
    const [isChecked, setIsChecked] = useState<boolean>(props.initState)
    useEffect(() => {
        props.onChange(isChecked);
    }, [isChecked]);

    const handleChange = () => {
        setIsChecked(prevState => !prevState)
    }

    let id = props.label.toLowerCase().replace(" ","-");

    return (
        <SingleControlContainer>
            <label htmlFor={id} style={{marginRight: '5px'}}>{props.label}</label>
            <SwitchButton id={id} checked={isChecked} onChange={handleChange}
                          disabled={props.isDisabled}/>
        </SingleControlContainer>
    )
}

const ContextSegment: React.FC<{ segment: Segment }> = ({segment}) => {
    let {start, end} = parseSubtitleTimestampString(segment.source);
    return (
        <ContextSegmentContainer>
            <ContextInfoContainer><strong>Start: </strong>{start}</ContextInfoContainer>
            <ContextInfoContainer><strong>End: </strong>{end}</ContextInfoContainer>
        </ContextSegmentContainer>
    );
}

export const getSegmentStatusIcon = (status: SegmentStatus) : any => {
    switch (status){
        case SegmentStatus.TRANSLATED: return <FaCheckCircle color={"green"}/>
        case SegmentStatus.LOCKED: return <IoLockClosedSharp color={"orange"}/>
        case SegmentStatus.DRAFT: return <RiPencilFill color={"gray"}/>
        case SegmentStatus.REVIEWED: return <FaSearch color={"darkgreen"}/>
        case SegmentStatus.REJECTED: return <FcCancel/>
        case SegmentStatus.NON_TRANSLATED: return <BiFileBlank/>
        default: return <BiFileBlank/>;
    }
}

const SegmentStatusIcon: React.FC<{status: SegmentStatus}> = ({status}) => {
    const [showPopOver, setShowPopOver] = useState<boolean>(false);
    let displayStatus = `${status.toString().charAt(0).toUpperCase()}${status.toString().substring(1).toLowerCase().replace('_'," ")}`
    return (
        <Popover
            containerStyle={{zIndex: '9999'}}
            isOpen={showPopOver}
            padding={5}
            positions={['right']}
            content={() => (
                    <SegmentIconTooltip>
                        Status: {displayStatus}
                    </SegmentIconTooltip>

            )}
        >
            <div onMouseEnter={() => setShowPopOver(true)} onMouseLeave={() => setShowPopOver(false)}>
                {getSegmentStatusIcon(status)}
            </div>
        </Popover>
    )
}

const RepetitionIcon: React.FC<{repetitionData: RepetitionData}> = ({repetitionData}) => {
    const [showPopOver, setShowPopOver] = useState<boolean>(false);

    let tooltipContent: string = repetitionData.isFirstOccurrence ? 'First occurrence' : `Segment repetition: ${repetitionData.repeatsSegment}`;
    
    return (
        <Popover
            containerStyle={{zIndex: '9999'}}
            isOpen={showPopOver}
            padding={5}
            positions={['right']}
            content={() => (
                <SegmentIconTooltip>
                   {tooltipContent}
                </SegmentIconTooltip>

            )}
        >
            <div onMouseEnter={() => setShowPopOver(true)} onMouseLeave={() => setShowPopOver(false)}>
                <TbSquareRoundedLetterR/>
            </div>
        </Popover>
    )
}