import {Routes, Route, useLocation} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Administration from "./pages/Administration";
import Converter from "./pages/Converter";
import ForgotPassword from "./pages/ForgotPassword";
import Help from "./pages/Help";
import Login from "./pages/Login";
import NewProject from "./pages/NewProject";
import Profile from "./pages/Profile";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import Register from "./pages/Register";
import TermsOfUse from "./pages/TermsConditions";
import Layout from "./components/ui/Layout";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RequireAuth from "./components/RequireAuth";
import Logout from "./components/Logout";
import AccountConfirmation from "./pages/AccountConfirmation";
import ResetPassword from "./pages/ResetPassword";
import {createGlobalStyle} from 'styled-components';
import 'typeface-source-sans-pro';
import Workspaces from "./pages/Workspaces";
import ConfirmInvitation from "./pages/ConfirmInvitation";
import ContactForm from "./pages/ContactForm";
import Subscription from "./pages/Subscription";
import Landing from "./pages/Landing";
import {CookieConsent} from "./components/cookies/CookieConsent";
import {SubtitlesCreator} from "./pages/SubtitlesCreator";
import SourceEditor from "./components/projects/SourceEditor";
import {Translator} from "./pages/Translator";
import Page404 from "./pages/Page404";
import {DEFINED_ROUTES} from "./utils/constants";
import {useState} from "react";

const GlobalStyle = createGlobalStyle<{ bgColor: boolean }>`
  body, html {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: ${props => props.bgColor ? '#2d292a' : '#fff'};
    max-height: 100%;
  }
`;

function App() {
    const {pathname} = useLocation();
    const matchRoute = (path: string, route: string) => {
        const pathSegments = path.split('/').filter(p => p);
        const routeSegments = route.split('/').filter(r => r);

        if (pathSegments.length !== routeSegments.length) {
            return false;
        }

        return routeSegments.every((segment, index) => {
            return segment.startsWith(':') || segment === pathSegments[index];
        });
    };

    const isRouteDefined = DEFINED_ROUTES.some(route => matchRoute(pathname, route));
    const isPageNotFound = !isRouteDefined;
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    return (
        <>
            <GlobalStyle bgColor={isPageNotFound}/>
            <Routes>
                <Route element={<CookieConsent isSidebarCollapsed={isSidebarCollapsed}/>}>
                    {/* Public routes */}
                    <Route path="/" element={<Landing/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/forgot-password" element={<ForgotPassword/>}/>
                    <Route path="/terms-and-conditions" element={<TermsOfUse/>}/>
                    <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    <Route path="/account-confirmation" element={<AccountConfirmation/>}/>
                    <Route path="/reset-password" element={<ResetPassword/>}/>
                    <Route path="/invite/confirm" element={<ConfirmInvitation/>}/>

                    {/* Protected routes */}
                    <Route element={<RequireAuth/>}>
                        <Route path="/" element={<Layout isSidebarCollapsed={isSidebarCollapsed} setIsSidebarCollapsed={setIsSidebarCollapsed}/>}>
                            <Route path="/subtitles" element={<SubtitlesCreator/>}/>
                            <Route path="/home" element={<Home/>}/>
                            <Route path="about" element={<About/>}/>
                            <Route path="administration" element={<Administration/>}/>
                            <Route path="converter" element={<Converter/>}/>
                            <Route path="subtitles-editor" element={<SubtitlesCreator/>}/>
                            <Route path="source-editor/:projectId/:sourceId" element={<SourceEditor/>}/>
                            <Route path="translator/:projectId/:taskId" element={<Translator/>}/>
                            <Route path="help" element={<Help/>}/>
                            <Route path="new-project" element={<NewProject/>}/>
                            <Route path="profile" element={<Profile/>}/>
                            <Route path="projects" element={<Projects/>}/>
                            <Route path="project/:projectId" element={<Project/>}/>
                            <Route path="workspaces" element={<Workspaces/>}/>
                            <Route path="subscription" element={<Subscription/>}/>
                            <Route path="logout" element={<Logout/>}/>
                            <Route path="contact-us" element={<ContactForm/>}/>
                        </Route>
                    </Route>
                </Route>
                <Route path="*" element={<Page404/>}/>
            </Routes>
        </>
    );
}

export default App;