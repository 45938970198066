import React, {useState} from 'react';
import {EditorButton} from "../translator/TargetEditorButtons";
import {TbExchange} from "react-icons/tb";
import {ArrowContainer, Popover} from "react-tiny-popover";
import styled from "styled-components";
import {SegmentStatus} from "../../services/segmentsApiSlice";
import Select, {SingleValue, StylesConfig} from "react-select";

const PopOverContainer = styled.div`
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-width: 300px;
    height: 200px;
`;

const OptionalCommentInput = styled.textarea`
    resize: none;
    width: 90%;
    min-height: 50px;
`;

const SaveActionBtn = styled.button`
    padding: 5px 10px;
    background-color: rgba(255, 193, 7, 0.5);;
    border: 1px solid black;
    border-radius: 4px;
`

interface Props {
    onChangeStatusAction: (newStatus: SegmentStatus, comment?: string) => void;
    currentStatus: SegmentStatus;
}

const ChangeSegmentStatusBtn = ({onChangeStatusAction, currentStatus}: Props) => {
    const [showPopOver, setShowPopOver] = useState(false);

    const handleClick = (event: any): void => {
        event.preventDefault();
        setShowPopOver(prevState => !prevState);
    }

    return (
        <Popover
            containerStyle={{zIndex: '9999'}}
            isOpen={showPopOver}
            onClickOutside={() => setShowPopOver(false)}
            content={({position, childRect, popoverRect}) => (
                <ArrowContainer childRect={childRect} popoverRect={popoverRect} arrowSize={8} arrowColor={"black"}
                                position={position}>
                    <ChangeStatusPopOver changeStatus={onChangeStatusAction} closePopOver={() => setShowPopOver(false)} currentStatus={currentStatus}/>
                </ArrowContainer>
            )}
        >
            <EditorButton onMouseDown={handleClick} title="Change Segment Status">
                <TbExchange title="Change Segment Status"/>
            </EditorButton>
        </Popover>
    );
};
export default ChangeSegmentStatusBtn;

const ChangeStatusPopOver = ({changeStatus, closePopOver, currentStatus}: {
    changeStatus: (newStatus: SegmentStatus, comment?: string) => void,
    closePopOver: () => void,
    currentStatus: SegmentStatus }) => {
    const statusOptions: { label: string, value: string }[] = Object.values(SegmentStatus)
        .filter(s => s !== currentStatus)
        .map(value => ({
        value,
        label: `${value.toString().toUpperCase().charAt(0)}${value.substring(1).toLowerCase().replace("_", " ")}`
    }));

    const [selected, setSelected] = useState<{ label: string, value: string } | null>(null);
    const [optionalComment, setOptionalComment] = useState<string>('');

    const handleSaveAction = () => {
        if (selected) {
            if (optionalComment !== '') {
                changeStatus(selected.value as SegmentStatus, optionalComment)
            } else {
                changeStatus(selected.value as SegmentStatus)
            }
        }
        closePopOver();
    }

    const handleSelectChange = (newSelected: SingleValue<{ label: string, value: string }>) => {
        if (newSelected) {
            setSelected({value: newSelected.value, label: newSelected.label});
        } else {
            setSelected(null);
        }
    }

    const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
        const isSelectElementClicked = event.target instanceof Element && event.target.closest('.react-select__control') !== null;
        if (!isSelectElementClicked) {
            event.stopPropagation();
        }
    };

    return (
        <PopOverContainer onClick={handleMenuClose}>
            <div><strong>Select new segment status:</strong></div>
            <Select options={statusOptions} onChange={handleSelectChange} autoFocus={true} placeholder={"Status"}/>
            <OptionalCommentInput placeholder={"Insert a comment (Optional)"}
                                  onChange={(e) => setOptionalComment(e.target.value)}
                                  value={optionalComment}/>
            <SaveActionBtn onClick={handleSaveAction} disabled={!selected}>Save</SaveActionBtn>
        </PopOverContainer>
    )
}