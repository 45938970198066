import React, {useState, useEffect, useLayoutEffect, ReactElement} from 'react';
import Sidebar from "../sidebar/Sidebar";
import Banner from "../Banner";
import Footer from "../Footer";
import FloatingIcon from "../FloatingIcon";
import styled from 'styled-components';
import { Outlet, useLocation } from 'react-router-dom';
import {useGetInstanceInfoQuery, useGetUserInfoQuery} from "../../services/usersApiSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/store-hooks";
import { TokenInfo, tokenInfoSelector } from "../../store/tokenSlice";
import Spinner from "../spinner/Spinner";
import { updateUserInfo } from "../../store/userSlice";
import {setInstanceInfo} from "../../store/instanceSlice"
import {DEFINED_ROUTES} from '../../utils/constants'

const PageContent = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 79vh;
`;
function useMediaQuery(query: string) {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useLayoutEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        media.addEventListener('change', listener);
        return () => media.removeEventListener('change', listener);
    }, [matches, query]);

    return matches;
}
interface LayoutProps {
    isSidebarCollapsed: boolean;
    setIsSidebarCollapsed: Function;
}
const Layout: React.FC<LayoutProps> = ({isSidebarCollapsed, setIsSidebarCollapsed}) => {
    const { pathname } = useLocation();
    const isFloatingIconVisible = !(pathname === '/administration' || pathname === '/new-project');
    const { data: sessionInfo, refetch: reloadUserInfo, isFetching  } = useGetUserInfoQuery();
    const tokenInfo: TokenInfo = useAppSelector(tokenInfoSelector);
    const dispatch = useAppDispatch();
    const { data: instanceData } = useGetInstanceInfoQuery();
    const contentWrapperStyle = isSidebarCollapsed ? { marginLeft: "60px" } : { marginLeft: "250px" };
    const isSmallScreen = useMediaQuery('(max-width: 1000px)');


    useEffect(() => {
        if(isSmallScreen){
            setIsSidebarCollapsed(true);
        } else {
            setIsSidebarCollapsed(false);
        }
    }, [isSmallScreen]);


    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };
    useEffect(() => {
        reloadUserInfo();
    }, [tokenInfo, reloadUserInfo]);

    useEffect(() => {
        dispatch(updateUserInfo(sessionInfo));
    }, [sessionInfo, dispatch]);

    useEffect(() => {
        dispatch(setInstanceInfo(instanceData));
    }, [instanceData, dispatch]);

    let content: ReactElement;
    if (isFetching && pathname !== '/projects' && pathname !== '/profile' && pathname !== '/administration' && !pathname.startsWith('/project')) {
        content = <Spinner />;
    } else {
        content = (
            <>
                { !pathname.startsWith('/source-editor') && <Sidebar isCollapsed={isSidebarCollapsed} /> }
                <div style={contentWrapperStyle}>
                    { !pathname.startsWith('/source-editor') && <Banner toggleSidebar={toggleSidebar} /> }
                    <PageContent>
                        <Outlet />
                    </PageContent>
                    { !pathname.startsWith('/source-editor') && <Footer isSidebarCollapsed={isSidebarCollapsed} /> }
                </div>
                {isFloatingIconVisible && <FloatingIcon />}
            </>
        );
    }
    return content;
};

export default Layout;