import React, {SyntheticEvent} from 'react';
import {EditorButton} from "../translator/TargetEditorButtons";
import {HiOutlineClipboardCopy} from "react-icons/hi";

function CopySourceToTargetBtn({onCopySourceAction}: {onCopySourceAction: (e:SyntheticEvent)=>void}) {
    return (
        <EditorButton onMouseDown={onCopySourceAction} title="Copy Source Content">
            <HiOutlineClipboardCopy title="Copy Source Content"/>
        </EditorButton>
    );
}

export default CopySourceToTargetBtn;