import React, {useState, useEffect} from "react";
import { useDispatch } from 'react-redux';
import styled from "styled-components";
import {FaUserAlt, FaUserFriends, FaUserCheck, FaUserAstronaut} from "react-icons/fa";
import {MdAlternateEmail} from "react-icons/md";
import {BsFillPersonVcardFill} from "react-icons/bs";
import ActionFormTitle from "../ui/forms/action/ActionFormTitle";
import FormLabel from "../ui/forms/action/ActionFormLabel";
import FormInput from "../ui/forms/action/ActionFormInput";
import SlimButton from "../ui/forms/action/SlimButton";
import InvalidFeedback from "../ui/forms/InvalidFeedback";
import SuccessPopup from "../ui/forms/SuccessPopup";
import SelectOption from "../ui/forms/action/SelectOption";
import {useGetOneUserQuery, useGetUsersQuery, useUpdateUserMutation} from "../../services/usersApiSlice";
import {setTokens} from "../../store/tokenSlice";
import {useRefreshTokenMutation} from "../../services/tokensApiSlice";
import {CookieConsentState} from "../../store/cookieConsentSlice";
import {useAppSelector} from "../../hooks/store-hooks";

const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;
const ProfileFormContainer = styled.div`
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 470px;
`;
const FormBody = styled.div`
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
`;
const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;
const ButtonsContainer = styled.div`
  margin-top: 15px;
  margin-right: 10px;
  display: flex;
  justify-content: flex-end;
`;

interface FormField {
    labelIcon: any;
    labelText: string;
    inputId: string;
    inputType: string;
    inputName: string;
    inputValue: string;
    onChange: string;
}

const formFields = [
    {
        labelIcon: FaUserAlt,
        labelText: "First Name",
        inputId: "user-first-name",
        inputType: "text",
        inputName: "firstName",
        inputValue: "",
        onChange: "handleChangeFirstName",
    },
    {
        labelIcon: FaUserFriends,
        labelText: "Last Name",
        inputId: "user-last-name",
        inputType: "text",
        inputName: "lastName",
        inputValue: "",
        onChange: "handleChangeLastName",
    },
    {
        labelIcon: FaUserCheck,
        labelText: "Username",
        inputId: "user-username",
        inputType: "text",
        inputName: "username",
        inputValue: "",
        onChange: "handleChangeUsername",
    },
    {
        labelIcon: BsFillPersonVcardFill,
        labelText: "Company",
        inputId: "user-company",
        inputType: "text",
        inputName: "company",
        inputValue: "",
        onChange: "handleChangeCompany",
    },
    {
        labelIcon: MdAlternateEmail,
        labelText: "Email",
        inputId: "user-email",
        inputType: "email",
        inputName: "email",
        inputValue: "",
        onChange: "handleChangeEmail",
    },
    {
        labelIcon: FaUserAstronaut,
        labelText: "Role",
        inputId: "user-role",
        inputType: "select",
        inputName: "role",
        inputValue: "",
        onChange: "handleChangeRole",
    },
];

function fieldsArray(array: FormField[], columns: number) {
    const results = [];
    while (array.length) {
        results.push(array.splice(0, columns));
    }
    return results;
}

const validateEmail = (email: string): boolean => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
};

interface FieldStateAndHandler {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface UpdatedUserInfoProps {
    id: number;
    firstName?: string;
    lastName?: string;
    username?: string;
    email?: string;
    company?: string;
    roles?: string[];
}
interface EditUserFormProps {
    id: number;
    handleClose: () => void;
    roleOptions: Array<{ value: string; label: string }>;
    setShowEditUserPopup: (show: boolean) => void;
    onSuccessfulUpdate: () => void;
    onError: (message: string) => void;
    showModalSuccessPopup: boolean;
    errorModalMessage: string;
    closeSuccessPopup: () => void;
}
const EditUserForm: React.FC<EditUserFormProps> = ({id, handleClose, roleOptions, setShowEditUserPopup,onSuccessfulUpdate, closeSuccessPopup, onError,showModalSuccessPopup = false,errorModalMessage = "",}) => {
    const {data, refetch: refetchUser} = useGetOneUserQuery(id);
    const groupedFormFields = fieldsArray([...formFields], 2);
    const [errorMessage, setErrorMessage] = useState(errorModalMessage);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [role, setRole] = useState<string[]>([]);
    const [showSuccessPopup, setShowSuccessPopup] = useState(showModalSuccessPopup);
    const [updateUser] = useUpdateUserMutation();
    const {refetch} = useGetUsersQuery({});
    const dispatch = useDispatch();
    const [refreshToken] = useRefreshTokenMutation();
    const cookieState: CookieConsentState = useAppSelector(state => state.cookieConsent);


   useEffect(() => {
        if (data) {
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setUsername(data.username);
            setEmail(data.email);
            setCompany(data.company);
            setRole(data.roles);
        }
    }, [data]);

    const handleChange = (
        setter: React.Dispatch<React.SetStateAction<string>>
    ) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setter(event.target.value);
    };

    const handleChangeRole = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        if (event.target instanceof HTMLSelectElement) {
            setRole(Array.from(event.target.selectedOptions, (option) => option.value));
        }
    };
    const fieldStatesAndHandlers: { [key: string]: FieldStateAndHandler } = {
        firstName: {value: firstName, onChange: handleChange(setFirstName)},
        lastName: {value: lastName, onChange: handleChange(setLastName)},
        username: {value: username, onChange: handleChange(setUsername)},
        email: {value: email, onChange: handleChange(setEmail)},
        company: {value: company, onChange: handleChange(setCompany)},
        role: {value: role[0], onChange: handleChangeRole},
    };

    const ShowSuccessPopup = () => {
        return (
            <SuccessPopup
                successMessage="Success!"
                instructions="User has been updated"
                confirmationMessage="THANKS!"
                handleClosePopup={handleClosePopup}
            />
        );
    };
    const handleClosePopup = () => {
        setShowSuccessPopup(false);
        setShowEditUserPopup(false);
        refetch();
        refetchUser();
        closeSuccessPopup();
    };
    const validateEmptyFields = () => {
        const fieldsToValidate = [
            {value: firstName, name: "First Name"},
            {value: lastName, name: "Last Name"},
            {value: username, name: "Username"},
            {value: email, name: "Email"},
            {value: company, name: "Company"},
            {value: role, name: "Role", isArray: true},
        ];

        for (const field of fieldsToValidate) {
            if (field.isArray) {
                if ((field.value as string[]).length === 0) {
                    return {isValid: false};
                }
            } else {
                if (!(field.value as string).trim()) {
                    return {isValid: false};
                }
            }
        }
        return {isValid: true};
    };


    const userInfoUpdated = (): UpdatedUserInfoProps => {
        if (!data) {
            return {id: id};
        }
        let updatedData: UpdatedUserInfoProps = {id: id};
        if (data.firstName !== firstName) {
            updatedData.firstName = firstName;
        }
        if (data.lastName !== lastName) {
            updatedData.lastName = lastName;
        }
        if (data.username !== username) {
            updatedData.username = username;
        }
        if (data.email !== email) {
            updatedData.email = email;
        }
        if (data.company !== company) {
            updatedData.company = company;
        }
        if (data.roles !== role) {
            updatedData.roles = role;
        }

        return updatedData;
    };

    const handleEditProfile = async (event: React.MouseEvent) => {
        event.preventDefault();
        const updatedData = userInfoUpdated();
        const validationResult = validateEmptyFields();
        const isValidEmail = validateEmail(email);

        if (!validationResult.isValid) {
            setErrorMessage("Fields cannot be empty");
            return;
        }
        if (!isValidEmail) {
            setErrorMessage("Email format must be valid.");
            return;
        }
        if (Object.keys(updatedData).length <= 1) {
            setErrorMessage("No information updated");
            return;
        }

        try {
            await updateUser(updatedData).unwrap();
            const refreshedTokens = await refreshToken().unwrap();
            dispatch(setTokens(refreshedTokens));
            onSuccessfulUpdate();
        } catch (error: any) {
            if(error.status === 400) {
                let errorMessage: string = '';
                for (const value of Object.values(error.data)) {
                    errorMessage += value as string + ". ";
                }
                onError(errorMessage);
            } else{
                onError("Error when updating user")
            }
        }
    };

    return (
        <>
            <ProfileContainer>
                <ProfileFormContainer>
                    <ActionFormTitle title="Edit User"/>
                    <FormBody>
                        {groupedFormFields.map((group, groupIndex) => (
                            <FormRow key={groupIndex}>
                                {group.map((field, fieldIndex) => {
                                    const fieldStateAndHandler =
                                        fieldStatesAndHandlers[field.inputName];
                                    if (!fieldStateAndHandler) {
                                        return null;
                                    }
                                    return (
                                        <FormColumn key={`${groupIndex}-${fieldIndex}`}>
                                            <FormLabel
                                                icon={field.labelIcon}
                                                text={field.labelText}
                                            />
                                            {field.inputType === "select" ? (
                                                <SelectOption
                                                    id={"edit_user_role_select"}
                                                    value={fieldStateAndHandler.value}
                                                    onChange={handleChangeRole}
                                                    options={roleOptions}
                                                    width={200}
                                                    disabled={role.includes('ROLE_ADMIN') || role.includes('ROLE_OWNER')}
                                                />
                                            ) : (
                                                <FormInput
                                                    id={`edit_user_${field.labelText.toLowerCase()}`}
                                                    type={field.inputType}
                                                    value={fieldStateAndHandler.value as string}
                                                    onChange={fieldStateAndHandler.onChange}
                                                />
                                            )}
                                        </FormColumn>
                                    );
                                })}
                            </FormRow>
                        ))}
                        {errorMessage && <InvalidFeedback errorMessage={errorMessage}/>}
                        {showSuccessPopup && <ShowSuccessPopup/>}
                        <ButtonsContainer>
                            <SlimButton
                                id={"edit_user_cancel_button"}
                                type="reset"
                                bgColor="#c71313"
                                text="Cancel"
                                textColor="#fff"
                                onClick={handleClose}
                                bgGradient="linear-gradient(to right, #a11212, #c71313, #a11212)"
                            />
                            <SlimButton
                                id={"edit_user_submit_button"}
                                type="button"
                                bgColor="#eac113"
                                text="Save changes"
                                textColor="#2d292a"
                                onClick={handleEditProfile}
                                bgGradient="linear-gradient(to right, #eac113, #ffeb3b, #eac113)"
                            />
                        </ButtonsContainer>
                    </FormBody>
                </ProfileFormContainer>
            </ProfileContainer>
        </>
    );
};

export default EditUserForm;