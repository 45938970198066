import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {ToolkitStore} from "@reduxjs/toolkit/dist/configureStore";
import userInfoReducer from "./userSlice"
import tokenInfoReducer from "./tokenSlice"
import {underlineApi} from "../services/underlineApi";
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'// defaults to localStorage for web
import thunk from 'redux-thunk';
import cookieConsentReducer from './cookieConsentSlice';
import instanceSlice from './instanceSlice';
import {tokenMiddleware} from './tokenMiddleware';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['tokenInfo', 'cookieConsent'],
}

const storeReducer = combineReducers({
    [underlineApi.reducerPath]: underlineApi.reducer,
    userInfo: userInfoReducer,
    tokenInfo: tokenInfoReducer,
    cookieConsent: cookieConsentReducer,
    instanceInfo: instanceSlice
});

const persistedReducer = persistReducer(persistConfig, storeReducer)

const store: ToolkitStore = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({serializableCheck: false})
            .concat(underlineApi.middleware)
            .concat(thunk)
            .concat(tokenMiddleware)
    },
    devTools: false
});

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;