import {underlineApi} from "./underlineApi";

interface ConversionRequest {
    files: File[],
    conversionMethod: string
}

export const converterApiSlice = underlineApi.injectEndpoints({
    endpoints: (builder) => ({
        convert: builder.mutation<any, ConversionRequest>({
            query: (request) => {
                let formData= new FormData();
                request.files.forEach(file => {
                    formData.append('files', file);
                });
                formData.append('conversionMethod', request.conversionMethod);
                return {
                    url: '/converter',
                    method: 'POST',
                    formData: true,
                    body: formData,
                    responseHandler: (response) => response.blob()
                }
            }
        })
    })
})

export const {useConvertMutation} = converterApiSlice;