import React, {useEffect, useState} from "react";
import styled from 'styled-components';
import {useConfirmInvitationMutation,useGetUserFromTokenQuery} from "../services/usersApiSlice";
import {useLocation, useNavigate} from "react-router-dom";
import  {ButtonType} from "../components/ui/forms/auth/AuthFormButton";
import {useAppSelector} from "../hooks/store-hooks";
import AuthFormInput from "../components/ui/forms/auth/AuthFormInput";
import {FaUserAlt, FaUserCheck, FaUserFriends} from "react-icons/fa";
import {MdAlternateEmail} from "react-icons/md";
import {RiLock2Fill, RiLockPasswordFill} from "react-icons/ri";
import {BsFillPersonVcardFill} from "react-icons/bs";
import AuthFormButton from "../components/ui/forms/auth/AuthFormButton";
import InvalidFeedback from "../components/ui/forms/InvalidFeedback";
import AuthForm from "../components/ui/forms/auth/AuthForm";
import SuccessPopup from "../components/ui/forms/SuccessPopup";

const InputGroup = styled.div<{ width?: string }>`
  margin-bottom: -20px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: ${(props) => props.width || 'auto'};
`;
const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
};
const isValidPassword = (password: string): boolean => {
    const lengthCheck = /.{9,}/.test(password);
    const upperCaseCheck = /[A-Z]/.test(password);
    const symbolOrNumberCheck = /[\d\W]/.test(password);

    return lengthCheck && upperCaseCheck && symbolOrNumberCheck;
}
const ConfirmInvitation: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const InputWidth = '230px';
    const searchParams = new URLSearchParams(location.search);
    const registrationToken = searchParams.get("token") || "";
    const instanceId = searchParams.get("instanceId") || "";
    const initialUserInfo = useAppSelector(state => state.userInfo);
    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [userData, setUserData] = useState(initialUserInfo);
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const { data: userInitialData,} = useGetUserFromTokenQuery({ token: registrationToken, instanceId: instanceId }, { skip: registrationToken === "" });
    const [confirmInvitation] = useConfirmInvitationMutation();

    useEffect(() => {
        if (userInitialData) {
            setUserData(userInitialData);
        }
    }, [userInitialData]);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        switch (name) {
            case 'password':
                setNewPassword(value);
                break;
            case 'repeatPassword':
                setRepeatPassword(value);
                break;
            default:
                setUserData({
                    ...userData,
                    [name]: value
                });
                break;
        }
    };
    const hasEmptyFields = () => {
        const fieldsToValidate = [
            {value: userData.firstName, name: "First Name"},
            {value: userData.lastName, name: "Last Name"},
            {value: userData.username, name: "Username"},
            {value: userData.email, name: "Email"},
            {value: userData.company, name: "Company"},
        ];
        for (const field of fieldsToValidate) {
            if (!field.value.trim()) {
                return {isValid: false};
            }
        }
        return {isValid: true};
    }
    const validateForm = (): boolean => {
        if (!hasEmptyFields().isValid) {
            setErrorMessage("Fields cannot be empty");
            return false;
        }
        if (userData.email && !isValidEmail(userData.email)) {
            setErrorMessage("Invalid email format.");
            return false;
        }
        if (!newPassword || !isValidPassword(newPassword)) {
            setErrorMessage("Password must be at least 9 characters long, contain at least one uppercase letter and at least one number or symbol.");
            return false;
        }
        if (!repeatPassword || newPassword !== repeatPassword) {
            setErrorMessage("Passwords do not match.");
            return false;
        }
        return true;
    };

    const handleConfirmInvitation = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        setErrorMessage('');
        try {
            const response = await confirmInvitation({
                registrationToken,
                instanceId,
                updateInfo: {
                    ...userData,
                    newPassword: newPassword,
                },
            });
            if ('error' in response && response.error) {
                if ('data' in response.error) {
                    const errorData = response.error.data as { error: string };
                    if (errorData && errorData.error) {
                        setErrorMessage(errorData.error);
                    }
                } else {
                    setErrorMessage('An unknown error occurred.');
                }
            } else {
                setShowSuccessPopup(true);
            }
        } catch (error) {
            console.error(error);
            setErrorMessage('An unknown error occurred.');
        }
    };
    const handleClosePopup = () => {
        setShowSuccessPopup(false);
        navigate(`/login?&instanceId=${instanceId}`);
    };
    return(
        <>
            <AuthForm title="Confirm Invitation" width={670} height={400} footerLinks={[]}
                      hasError={!!errorMessage} submitHandler={handleConfirmInvitation} extraMargin={'50px'}>
                <InputGroup>
                    <AuthFormInput
                        name="firstName"
                        prependIcon={<FaUserAlt/>}
                        placeholder="First Name"
                        type="text"
                        value={userData.firstName}
                        onChange={handleInputChange}
                        inputWidth={InputWidth}
                    />

                    <AuthFormInput
                        name="lastName"
                        prependIcon={<FaUserFriends/>}
                        placeholder="Last Name"
                        type="text"
                        value={userData.lastName}
                        onChange={handleInputChange}
                        inputWidth={InputWidth}
                    />
                </InputGroup>

                <InputGroup>
                    <AuthFormInput
                        name="username"
                        prependIcon={<FaUserCheck/>}
                        placeholder="Username"
                        type="text"
                        value={userData.username}
                        onChange={handleInputChange}
                        inputWidth={InputWidth}
                    />

                    <AuthFormInput
                        name="email"
                        prependIcon={<MdAlternateEmail/>}
                        placeholder="Email"
                        type="email"
                        value={userData.email}
                        onChange={handleInputChange}
                        inputWidth={InputWidth}
                    />
                </InputGroup>

                <InputGroup>
                    <AuthFormInput
                        name="password"
                        prependIcon={<RiLock2Fill/>}
                        placeholder="Password"
                        type="password"
                        value={newPassword}
                        onChange={handleInputChange}
                        inputWidth={InputWidth}
                    />

                    <AuthFormInput
                        name="repeatPassword"
                        prependIcon={<RiLockPasswordFill/>}
                        placeholder="Repeat Password"
                        type="password"
                        value={repeatPassword}
                        onChange={handleInputChange}
                        inputWidth={InputWidth}
                    />
                </InputGroup>

                <InputGroup>
                    <AuthFormInput
                        name="company"
                        prependIcon={<BsFillPersonVcardFill/>}
                        placeholder="Company"
                        type="text"
                        value={userData.company}
                        onChange={handleInputChange}
                        inputWidth={InputWidth}
                    />
                </InputGroup>
                <AuthFormButton type={ButtonType.submit} text='Confirm'/>
                <InputGroup>
                    {errorMessage && <InvalidFeedback errorMessage={errorMessage}/>}
                </InputGroup>
            </AuthForm>
            {showSuccessPopup && <SuccessPopup
                successMessage="Success!"
                instructions="Your profile has been updated"
                confirmationMessage="THANKS!"
                handleClosePopup={handleClosePopup}
            />}
        </>
    );
}
export default ConfirmInvitation;