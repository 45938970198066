import React from 'react';
import styled from 'styled-components';

const Button = styled.button<{ bgColor: string, txtColor: string, marginLeft: string, marginTop: string, bgGradient?: string }>`
  color: ${({txtColor}) => txtColor};
  background-color: ${({bgColor}) => bgColor};
  border: 1px solid ${({bgColor}) => bgColor};
  padding: 5px 20px;
  font-size: 14px;
  display: inline-block;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  line-height: 1.5;
  border-radius: 4px;
  text-transform: uppercase;
  margin-left: ${({marginLeft}) => marginLeft};
  margin-top: ${({marginTop}) => marginTop};
  background: ${({bgGradient}) => bgGradient ? bgGradient : undefined};

  &:hover {
    background-color: #2d292a;
    border: 1px solid ${({bgColor}) => bgColor};;
    color: #fff;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #A9A9A9;
    border: 1px solid #A9A9A9;
    color: #9a9a9a;
    cursor: default;
  }

  @media (max-width: 750px) {
    font-size: 14px;
  }
  @media (max-width: 285px) {
    width: 180px;
  }
  @media screen and (min-resolution: 140dpi) {
    font-size: 9px;
  }
`;

interface SlimButtonProps{
    id?: string;
    text: string;
    bgColor: string;
    type?: "button" | "submit" | "reset";
    textColor: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    marginLeft?: string;
    bgGradient?: string;
    marginTop?: string;
    disabled?: boolean;
}

const SlimButton: React.FC<SlimButtonProps> = ({id, text,bgColor,type,textColor,onClick, marginLeft = '5px', marginTop='1px', bgGradient, disabled= false}) => {
    return (
        <Button id={id} type={type} bgColor={bgColor} txtColor={textColor} onClick={onClick} marginLeft={marginLeft} marginTop={marginTop} bgGradient={bgGradient} disabled={disabled}> {text} </Button>
    );
};

export default SlimButton