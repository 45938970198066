import React from 'react';
import styled from 'styled-components';

const BadgeContainer = styled.span`
  display: inline-block;
  padding: 4px 8px;
  line-height: 1;
  white-space: nowrap;
  border-radius: 8px;
  background-color: #97C1F1;
  font-size: 13px;
  margin-right: 3px;
  font-weight: 500;
`;
const statusOptions = [
    { value: "NEW", label: "New", color: "#28a745" },
    { value: "IN_PROGRESS", label: "In Progress", color: "#fd7e14" },
    { value: "COMPLETED", label: "Completed", color: "#007bff" },
    { value: "TRANSLATION_READY", label: "Translation Ready", color: "#6f42c1" },
];
export function formatStatus(role: string): { label: string, color: string } {
    const option = statusOptions.find(option => option.value === role);
    return {
        label: option?.label || role,
        color: option?.color || "#c9c9c9"
    };
}

interface BadgeProps {
    id?: string;
    text?: string;
    backgroundColor?: string;
    textColor?: string;
    data?: string;
    children?: React.ReactNode;
}
const Badge: React.FC<BadgeProps> = ({ id, text, backgroundColor, textColor, data, children }) => {
    return (
        <BadgeContainer id={id} data-badge={data} style={{ backgroundColor, color: textColor }}>
            {text || children}
        </BadgeContainer>
    );
};

export default Badge;
