import {createSlice, PayloadAction, Slice} from "@reduxjs/toolkit";
import {View} from "../services/usersApiSlice";

export interface UserInfo {
    id: number,
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    roles: string[]
    company: string,
    enabled: boolean,
    locked: boolean,
    lastLogin: string,
     views?:View[]
}

const initialState: UserInfo = {
    id: 0,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    company: '',
    enabled: true,
    locked: false,
    lastLogin: '',
    views: []
}

const userSlice: Slice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        updateUserInfo(state: UserInfo, action: PayloadAction<Partial<UserInfo>>) {
            Object.assign(state, action.payload);
        },
        clearUserInfo(): UserInfo{
            return initialState;
        }
    }
});
export const {updateUserInfo, clearUserInfo} = userSlice.actions;
export default userSlice.reducer;