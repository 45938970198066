import React, {useState, ChangeEvent, useEffect, useLayoutEffect} from 'react'
import { skipToken } from '@reduxjs/toolkit/query/react';
import styled from 'styled-components';
import Breadcrumb from "components/Breadcrumb";
import PageTitle from "components/PageTitle";
import ActionForm from '../components/ui/forms/action/ActionForm';
import FormLabel from '../components/ui/forms/action/ActionFormLabel';
import FormInput from '../components/ui/forms/action/ActionFormInput';
import SelectOption from '../components/ui/forms/action/SelectOption';
import MultipleSelect, {Option} from '../components/ui/forms/action/MultipleSelect';
import UploadFileButton from '../components/ui/forms/action/UploadFileButton';
import {FILTER_OPTIONS, LANGUAGE_OPTIONS} from '../utils/constants';
import SuccessPopup from '../components/ui/forms/SuccessPopup';
import InvalidFeedback from 'components/ui/forms/InvalidFeedback';
import {useNavigate} from "react-router-dom";
import {NewProjectData, useCreateProjectMutation, useGetProjectsQuery} from "../services/projectsApiSlice";
import Spinner from "../components/spinner/Spinner";
import {useAppSelector} from "../hooks/store-hooks";
import {UserInfo} from "../store/userSlice";
import {useGetWorkspacesQuery, useGetWorkspaceQuery, Workspace} from '../services/workspacesApiSlice';

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  flex-wrap: wrap;

  @media (max-width: 875px) {
    flex-direction: column;
  }
`;
const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  align-items: flex-start;
`;
const FormTextArea = styled.textarea`
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 550px;
  height: 100px;

  &:focus {
    border: 1px solid #aaa;
    outline: none;
  }

  @media (max-width: 875px) {
    width: 320px;
  }
` as React.ComponentType<{
    id: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}>;

const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear().toString();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:00`;
};
function useMediaQuery(query: string) {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useLayoutEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        media.addEventListener('change', listener);
        return () => media.removeEventListener('change', listener);
    }, [matches, query]);

    return matches;
}
interface UserOption {
    value: string;
    label: string;
}

interface WorkspaceOption {
    value: string;
    label: string;
}

const NewProject: React.FC = () => {
    const [projectName, setProjectName] = useState('');
    const [assignee, setAssignee] = useState('');
    const [source, setSource] = useState('');
    const [selectedTargets, setSelectedTargets] = useState<string[]>([]);
    const [filter, setFilter] = useState('');
    const [dueDate, setDueDate] = useState(getCurrentDateTime());
    const [description, setDescription] = useState('');
    const [file, setFile] = useState<File | null>(null);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [resetFile, setResetFile] = useState(false);
    const navigate = useNavigate();
    const [createProject, {isLoading}] = useCreateProjectMutation();
    const [fileErrorMessage, setFileErrorMessage] = useState('');
    const {username} = useAppSelector(state => state.userInfo);
    const [createdProjectId, setCreatedProjectId] = useState<string | null>(null);
    const projectsPerMonthLimit = useAppSelector((state) => state.instanceInfo.projectsPerMonth);
    const jobsPerProjectLimit = useAppSelector((state) => state.instanceInfo.jobsPerProject);
    const currentMonthCreatedProjects = useAppSelector((state) => state.instanceInfo.currentMonthProjects);
    const getProjects = useGetProjectsQuery({page: 0, size: 20, sortBy: "creationDate", sortDir: "desc"});
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const [showProjectCreationLimitPopup, setShowProjectCreationLimitPopup] = useState(false);
    const [showJobsLimitPopup, setShowJobsLimitPopup] = useState(false);
    const [allUsers, setAllUsers] = useState<UserOption[]>([]);
    const {data: workspacesInfo} = useGetWorkspacesQuery({});
    const [workspacesNames, setWorkspacesNames] = useState<WorkspaceOption[]>([]);
    const [selectedWorkspace, setSelectedWorkspace] = useState<WorkspaceOption | null>(null);
    const {data: workspaceUsers} = useGetWorkspaceQuery(selectedWorkspace ? selectedWorkspace.value : skipToken);
    const isSmallScreen = useMediaQuery('(max-width: 875px)');
    const [maxLines, setMaxLines] = useState<number | null>( null);
    const [maxChars, setMaxChars] = useState<number | null>( null);

    useEffect(() => {
        if (getProjects.data) {
            if (getProjects.data.content) {
                if (currentMonthCreatedProjects >= projectsPerMonthLimit) {
                    setShowProjectCreationLimitPopup(true);
                }
            }
        }
    }, [getProjects.data, currentMonth, currentYear]);


    useEffect(() => {
        setAssignee('');
        if (workspaceUsers) {
            let usersForDropdown = workspaceUsers.members
                .map((member: UserInfo) => {
                    return {
                        value: member.username,
                        label: `${member.firstName} ${member.lastName}`,
                    };
                })
                .filter((user: UserOption) => user.value !== "Underline-Support");

            setAllUsers(usersForDropdown);
        }
    }, [selectedWorkspace, workspaceUsers]);

    const handleWorkspaceChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedWorkspaceId = event.target.value;
        const foundWorkspace = workspacesNames.find(workspace => workspace.value === selectedWorkspaceId);
        if (foundWorkspace) {
            setSelectedWorkspace(foundWorkspace);
        }
    };

    useEffect(() => {
        if (workspacesInfo) {
            let workspacesForDropdown: WorkspaceOption[] = workspacesInfo
                .map(getWorkspacesForDropdown)
            setWorkspacesNames(workspacesForDropdown);
        }
    }, [workspacesInfo]);

    const getWorkspacesForDropdown = (workspace: Workspace): WorkspaceOption => {
        return {
            value: workspace.id,
            label: workspace.name,
        };
    };

    const handleChangeProjectName = (event: ChangeEvent<HTMLInputElement>) => {
        const newProjectName = event.target.value;
        if (newProjectName.length > 60) {
            setErrorMessage('Project name cannot be more than 60 characters long');
        } else {
            setErrorMessage('');
        }
        setProjectName(newProjectName);
    };
    const handleChangeAssignee = (event: ChangeEvent<HTMLSelectElement>) => {
        setAssignee(event.target.value);
    };

    const handleMultipleTargetChange = (newValues: Option[]) => {
        if (newValues.length > jobsPerProjectLimit) {
            setShowJobsLimitPopup(true);
        } else {
            setSelectedTargets(newValues.map(option => option.value));
        }
    };
    const handleChangeDueDate = (event: ChangeEvent<HTMLInputElement>) => {
        setDueDate(event.target.value);
    };
    const handleChangeDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    };
    const handleChangeFile = (uploadedFile: File | null, errorMessage?: string) => {
        setErrorMessage("");
        setFileErrorMessage("");
        if (errorMessage) {
            setFileErrorMessage(errorMessage);
        } else {
            setFileErrorMessage('');
        }
        setFile(uploadedFile);
    };
    const handleCloseProjectLimitPopup = () => {
        if (createdProjectId) {
            navigate(`/project/${createdProjectId}`);
        } else {
            setShowProjectCreationLimitPopup(false);
            navigate('/projects/');
        }
    };
    const handleCloseJobLimitPopup = () => {
        setShowJobsLimitPopup(false);
    };

    interface FieldStatesAndHandlers {
        [key: string]: FieldStateAndHandler;
    }

    interface FieldStateAndHandler {
        value: string;
        onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    }

    const handleChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setter(event.target.value);
    };
    const fieldStatesAndHandlers: FieldStatesAndHandlers = {
        source: {value: source, onChange: handleChange(setSource)},
        filter: {value: filter, onChange: handleChange(setFilter)},
    };
    const resetInfo = () => {
        setResetFile(false);
        setProjectName('');
        setAssignee('');
        setSource('');
        setSelectedTargets([]);
        setFilter('');
        setDueDate(getCurrentDateTime());
        setDescription('');
        setErrorMessage('');
        setSelectedWorkspace(null);
        setFileErrorMessage('');

        setTimeout(() => {
            setResetFile(true);
        }, 0);
    }

    const handleClosePopup = () => {
        setShowSuccessPopup(false);
        if (createdProjectId) {
            navigate(`/project/${createdProjectId}`);
        } else {
            navigate('/projects/');
        }
    };

    const hasEmptyFields = () => {
        if (projectName.trim().length === 0) {
            return true;
        }
        if (source.trim().length === 0) {
            return true;
        }
        if (selectedTargets.length === 0) {
            return true;
        }
        if (filter.trim().length === 0) {
            return true;
        }
        if (!dueDate) {
            return true;
        }
        if (!file) {
            return true;
        }
        return !selectedWorkspace;

    };
    const isValidDueDate = () => {
        const now = new Date();
        const limit_time = new Date(now.getTime() - 2 * 60 * 60 * 1000);
        return dueDate >= limit_time.toISOString().substring(0, 16);
    }
    const hasValidLanguages = () => {
        return source.trim() !== '' && !selectedTargets.includes(source);
    };
    const isFormValid = (): boolean => {
        if (hasEmptyFields()) {
            setErrorMessage('Mandatory fields cannot be empty');
            return false;
        }
        if (projectName.length > 60) {
            setErrorMessage('Project name cannot be more than 60 characters long');
            return false;
        }
        if (!isValidDueDate()) {
            setErrorMessage('Due date must be at least 1 hour later.');
            return false;
        }
        if (!hasValidLanguages()) {
            setErrorMessage('Source and target languages cannot be the same.');
            return false;
        }
        if(description.length > 250){
            setErrorMessage("The project description must not exceed 250 characters. Please revise and shorten your text.");
            return false;
        }
        return true;
    }
    const handleNewProject = (event: React.FormEvent) => {
        setErrorMessage('');
        event.preventDefault();

        if (!isFormValid()) {
            return;
        }

        if (!selectedWorkspace) {
            setErrorMessage('A workspace must be selected.');
            return;
        }

        const projectData: NewProjectData = {
            name: projectName,
            filter,
            sourceLocale: source,
            targetLocales: selectedTargets,
            dueDate,
            description,
            file,
            assignee: assignee || username,
            workspace: selectedWorkspace.value,
            maxLines: maxLines,
            maxCharactersPerLine: maxChars
        };

        setErrorMessage('');
        createProject(projectData).unwrap()
            .then((data: any) => {
                setCreatedProjectId(data.id);
                setShowSuccessPopup(true);
            })
            .catch((error) => {
                console.log("Error: ", error)
                if (error.status === 'FETCH_ERROR') {
                    setErrorMessage("The file is too large. Please upload a file smaller than 50 MB.");
                } else if (error.status === 400) {
                    let errorMessage: string = '';
                    for (const key in error.data) {
                        errorMessage += error.data[key] + ". ";
                    }
                    setErrorMessage(errorMessage);
                } else {
                    setErrorMessage("An unexpected error occurred.")
                }
            });
    }

    const getPositiveNumberOrNull = (value: string): number | null => {
        if(parseInt(value) > 0){
            return parseInt(value);
        }else {
            return null;
        }
    }

    return (
        <>
            {isLoading && <Spinner/>}
            <Breadcrumb link="New Project"/>
            <PageTitle title="New Project"/>
            <ActionForm title="Create a New Project" width={isSmallScreen ? 360 : 600} cancelMessage='cancel' acceptMessage='Save changes'
                        cancelAction={resetInfo} acceptAction={handleNewProject}
                        columns={2}>
                <FormRow>
                    <FormColumn>
                        <FormLabel text="Project Name" isRequired extraMessage={'(60 characters limit)'} marginLeft={1}/>
                        <FormInput
                            id={"new_project_name"}
                            value={projectName}
                            type="text"
                            onChange={handleChangeProjectName}
                            width={240}/>

                    </FormColumn>
                    <FormColumn>
                        <FormLabel text={"Workspace"} isRequired marginLeft={1}/>
                        <SelectOption
                            id={"new_project_workspace"}
                            value={selectedWorkspace ? selectedWorkspace.value : ''}
                            onChange={handleWorkspaceChange}
                            options={workspacesNames}
                        />
                    </FormColumn>
                </FormRow>

                <FormRow>
                    <FormColumn>
                        <FormLabel text="Assignee" marginLeft={1}/>
                        <SelectOption
                            id={"new_project_assignee"}
                            value={assignee}
                            onChange={handleChangeAssignee}
                            options={allUsers}
                        />
                    </FormColumn>
                    <FormColumn>
                        <FormLabel text="Filter" isRequired marginLeft={1}/>
                        <SelectOption
                            id={"new_project_filter"}
                            value={fieldStatesAndHandlers.filter.value}
                            onChange={fieldStatesAndHandlers.filter.onChange}
                            options={FILTER_OPTIONS}
                        />
                    </FormColumn>
                </FormRow>

                <FormRow>
                    <FormColumn>
                        <FormLabel text="Source Language" isRequired marginLeft={1}/>
                        <SelectOption
                            id={"new_project_source"}
                            value={fieldStatesAndHandlers.source.value}
                            onChange={fieldStatesAndHandlers.source.onChange}
                            options={LANGUAGE_OPTIONS}
                        />

                    </FormColumn>

                    <FormColumn>
                        <FormLabel text="Target Language" isRequired marginLeft={1}/>
                        <MultipleSelect
                            id={"new_project_target"}
                            value={selectedTargets.map(target => ({value: target, label: target}))}
                            onChange={handleMultipleTargetChange}
                            options={LANGUAGE_OPTIONS}
                            multiple
                            selectedSource={source}
                        />
                    </FormColumn>
                </FormRow>

                <FormRow>
                    <FormColumn>
                        <FormLabel text="Max lines per subtitle (0 = no limit)" marginLeft={1}/>
                        <FormInput
                            id={"max_lines_input"}
                            value={maxLines?.toString()}
                            type="number"
                            min={0}
                            onChange={event => setMaxLines(getPositiveNumberOrNull(event.target.value))}
                            width={240}
                        />
                    </FormColumn>

                    <FormColumn>
                        <FormLabel text="Max characters per line (0 = no limit)" marginLeft={1}/>
                        <FormInput
                            id={"max_chars_input"}
                            value={maxChars?.toString()}
                            type="number"
                            min={0}
                            onChange={event => setMaxChars(getPositiveNumberOrNull(event.target.value))}
                            width={240}
                        />
                    </FormColumn>
                </FormRow>

                <FormRow>
                    <FormColumn>
                        <FormLabel text="Due Date" isRequired marginLeft={1}/>
                        <FormInput
                            id={"new_project_due_date"}
                            value={dueDate}
                            type="datetime-local"
                            onChange={handleChangeDueDate}
                            width={240}
                            min={getCurrentDateTime()}/>
                    </FormColumn>
                </FormRow>

                <FormRow>
                    <FormColumn>
                        <FormLabel text="Project Description" marginLeft={1}/>
                        <FormTextArea
                            id={"new_project_description"}
                            value={description}
                            onChange={handleChangeDescription}/>
                    </FormColumn>
                </FormRow>
                <FormRow>
                    <UploadFileButton
                        id={"new_project_upload_file_button"}
                        onChange={handleChangeFile}
                        reset={resetFile}
                        validFiles=".srt, .zip"
                        fileErrorMessage={fileErrorMessage}
                    />
                </FormRow>
                {errorMessage && <InvalidFeedback errorMessage={errorMessage}/>}
                {showSuccessPopup && <SuccessPopup
                    successMessage="Success!"
                    instructions="Your project has been created."
                    confirmationMessage="THANKS!"
                    handleClosePopup={handleClosePopup}
                />}
                {showProjectCreationLimitPopup && <SuccessPopup
                    successMessage="You have reach your limit!"
                    instructions="Cannot create more projects this month."
                    confirmationMessage="Understood!"
                    handleClosePopup={handleCloseProjectLimitPopup}
                />}
                {showJobsLimitPopup && <SuccessPopup
                    successMessage="Too many target languages!"
                    instructions={`Cannot select more than ${jobsPerProjectLimit} targets.`}
                    confirmationMessage="Understood!"
                    handleClosePopup={handleCloseJobLimitPopup}
                />}
            </ActionForm>
        </>
    );
}
export default NewProject