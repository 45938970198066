import {underlineApi} from "./underlineApi";
import {TokenInfo} from "../store/tokenSlice";
import {INSTANCE_HEADER} from "../utils/constants";

interface Credentials {
    username: string,
    password: string
    instanceId: string
}
export const tokensApiSlice = underlineApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<TokenInfo, Credentials>({
            query: (credentials) => ({
                url: '/auth/login',
                method: 'POST',
                headers: {
                    [INSTANCE_HEADER]: credentials.instanceId
                },
                body: {...credentials}
            })
        }),
        refreshToken: builder.mutation<TokenInfo, void>({
            query: () => ({
                url: '/auth/refresh',
                method: 'POST',
            })
        })
    })
});

export const {useLoginMutation, useRefreshTokenMutation} = tokensApiSlice;