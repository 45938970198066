import React from 'react';
import styled from 'styled-components';
import {FaAngleDown} from 'react-icons/fa'

const Select = styled.select<{width: number, height:number, marginLeft: number}>`
    appearance: none;
    border: 1px solid rgb(206, 212, 218);
    border-radius:4px;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    margin-left: ${({ marginLeft }) => marginLeft}px;
    position: relative;
    padding-left: 10px;
    color: #444;
    font-size: 12px;

    &:focus {
    border:  1px solid #ced4da;
    outline: none;
    }
    &::-ms-expand {
    display: none;
    }
`; 
const SelectWrapper = styled.div`
    position: relative;
    display: inline-block;
`;
const IconWrapper = styled.div`
    position: absolute;
    right: 10px;
    top: 60%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #EAC113;
    font-size: 18px;    
`;
interface Option {
    value: string | number;
    label: string;
}

interface SelectOptionProps {
    id?: string;
    value:  string | string[];
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    options: Option[];
    width?: number;
    height?: number;
    marginLeft?: number;
    disabled?: boolean;
}

const SelectOption: React.FC<SelectOptionProps> = ({ id, value, onChange, options, width=250, height=35, marginLeft = 0 , disabled=false}) => {
    return (
        <SelectWrapper>
            <Select id={id} value={value} onChange={onChange} width={width} height={height} marginLeft={marginLeft} disabled={disabled}>
                <option value="" hidden={value !== ""}>Select One</option>
                {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
                ))}
            </Select>
            <IconWrapper>
                <FaAngleDown />
            </IconWrapper>
        </SelectWrapper>
    );
};

export default SelectOption;