import {getUrlWithPaginationProps, PageProps, PaginationParams, underlineApi} from "./underlineApi";
import {INSTANCE_HEADER} from "../utils/constants";
import {IntanceInfo} from "../store/instanceSlice";

export interface UserInfo {
    id: number,
    username: string,
    firstName: string,
    lastName: string,
    email: string,
    roles: string[],
    company: string,
    enabled: boolean,
    locked: boolean,
    lastLogin: string
}

export interface UserOwnerInfo {
    username: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface UpdateUserInfoProps {
    id: number,
    username?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    roles?: string[],
    company?: string,
    enabled?: boolean,
    locked?: boolean,
    oldPassword?: string,
    newPassword?: string,
    views?: View[],
}
export interface View {
    name: TypeOfList;
    columns: string;
}
export enum TypeOfList {
    PROJECTS_LIST = "PROJECTS_LIST",
    JOBS_LIST = "JOBS_LIST",
    TASKS_LIST = "TASKS_LIST",
    USERS_LIST = "USERS_LIST"
}
interface UsersPage extends PageProps{
    content: UserInfo[]
}

export interface RegistrationInfo extends NewUserInfo {
    password: string;
    repeatPassword: string;
}

export interface NewUserInfo {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    company: string;
    roles?: string[];
    workspaces?: number[];
}



export const usersApiSlice = underlineApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserInfo: builder.query<UserInfo, void>({
            query: () => ({
                url: '/users/session',
                method: 'GET',
            }),
            providesTags: ['Users', 'Views']
        }),
        getInstanceInfo: builder.query<IntanceInfo, void>({
            query: () => ({
                url: '/instance',
                method: 'GET',
            }),
            providesTags: ['Instance'],
        }),
        getInstanceStatistics: builder.query<IntanceInfo, void>({
            query: () => ({
                url: '/instance/statistics',
                method: 'GET',
            }),
            providesTags: ['Instance'],
        }),
        sendForgotPasswordEmail: builder.mutation<void, {email: string, instanceId: string, ownerEmail: string}>({
            query: (query) => {
                let requestBody: any = {
                    email: query.email
                }
                if(query.instanceId) {
                    requestBody.instanceId = query.instanceId;
                }else{
                    requestBody.ownerEmail = query.ownerEmail;
                }

                return {
                    url: '/users/forgot-password',
                    method: 'POST',
                    body: requestBody
                }
            }
        }),
        register: builder.mutation({
            query: (formData: RegistrationInfo) => ({
                url: '/users/registration',
                method: 'POST',
                body: formData,
                responseHandler: (response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.text();
                    } else {
                        return response.json();
                    }
                },
            })
        }),
        updateUser: builder.mutation({
            query: (updateInfo: UpdateUserInfoProps) => ({
                url: `/users/${updateInfo.id}`,
                method: 'PATCH',
                body: updateInfo
            }),
            invalidatesTags:['Users']
        }),
        updateUserViews: builder.mutation({
            query: (updateInfo: UpdateUserInfoProps) => ({
                url: `/users/${updateInfo.id}`,
                method: 'PATCH',
                body: updateInfo
            }),
            invalidatesTags: ['Views']
        }),
        countUsers: builder.query<{ ENABLED: number, DISABLED: number, TOTAL: number }, void>({
            query: () => ({
                url: '/users/count',
                method: 'GET'
            }),
            providesTags: ['Users']
        }),
        getUsers: builder.query<UsersPage, PaginationParams>({
            query: paginationParams => {
                return {
                    url: getUrlWithPaginationProps('/users', paginationParams),
                    method: 'GET'
                }
            },
            providesTags: ['Users']
        }),
        activateUser: builder.mutation<void, number>({
            query: (userId) => {
                return {
                    url: `/users/enable/${userId}`,
                    method: 'PATCH'
                }
            },
            invalidatesTags: ['Instance', 'Workspaces','Users'],
        }),
        disableUser: builder.mutation<void, number>({
            query: (userId) => {
                return {
                    url: `/users/disable/${userId}`,
                    method: 'PATCH'
                }
            },
            invalidatesTags: ['Instance', 'Workspaces','Users'],
        }),
        inviteUser: builder.mutation({
            query: (formData: NewUserInfo) => {
                return {
                    url: '/users/invite',
                    method: 'POST',
                    body: formData,
                    responseHandler: (response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.text();
                        } else {
                            return response.json();
                        }
                    },
                }
            },
            invalidatesTags: ['Instance', 'Workspaces','Users'],
        }),
        getOneUser: builder.query<UserInfo, number>({
            query: (userId) => {
                return {
                    url: `/users/${userId}`,
                    method: 'GET'
                }
            },
            providesTags: ['Users']
        }),
        changePassword: builder.mutation<void, { token: string, instanceId: string, newPassword: string }>({
            query: (query) => ({
                url: '/users/reset-password',
                method: 'POST',
                headers: {
                    [INSTANCE_HEADER]: query.instanceId},
                body:{
                    "token": query.token,
                    "newPassword": query.newPassword,
                },
                responseHandler: (response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.text();
                    } else {
                        return response.json();
                    }
                },
            })
        }),
        accountConfirmation: builder.query<void, { registrationToken: string, instanceId: string }>({
            query: (query) => ({
                    url: `/users/registration/confirm?token=${query.registrationToken}`,
                    method: 'GET',
                    headers: {
                        [INSTANCE_HEADER]: query.instanceId},
                    responseHandler: (response) => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.text();
                        } else {
                            return response.json();
                        }
                    },
                    }),
                }),
        getUserFromToken: builder.query<UserInfo, { token: string; instanceId: string }>({
            query: ({ token, instanceId }) => ({
                url: `/users/from-token?token=${token}`,
                method: 'GET',
                headers: {
                    'INSTANCE_ID': instanceId,
                },
            }),
        }),
        confirmInvitation: builder.mutation<void, { registrationToken: string, instanceId: string, updateInfo: UpdateUserInfoProps }>({
            query: (mutation) => ({
                url: `/users/invite/confirm?token=${mutation.registrationToken}`,
                method: 'PATCH',
                headers: {
                    [INSTANCE_HEADER]: mutation.instanceId},
                body: mutation.updateInfo,
                responseHandler: (response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.text();
                    } else {
                        return response.json();
                    }
                },
            }),
        }),
    })
})
export const {
    useGetUserInfoQuery,
    useGetInstanceInfoQuery,
    useGetInstanceStatisticsQuery,
    useSendForgotPasswordEmailMutation,
    useRegisterMutation,
    useUpdateUserMutation,
    useCountUsersQuery,
    useGetUsersQuery,
    useActivateUserMutation,
    useDisableUserMutation,
    useInviteUserMutation,
    useGetOneUserQuery,
    useAccountConfirmationQuery,
    useChangePasswordMutation,
    useConfirmInvitationMutation,
    useGetUserFromTokenQuery,
    useUpdateUserViewsMutation
} = usersApiSlice;