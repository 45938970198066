import React from "react";
import styled from 'styled-components';
import landingAnimation from "../../assets/img/landing-animation.gif";

const GifContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 204px;
  padding-top: 20px;
  padding-bottom: 60px;

`;
const GifImage  = styled.img`
  max-width: 100%;
  height: auto;
`;
const LandingGif: React.FC= () => {
    return(
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <GifContainer>
                <GifImage src={landingAnimation} alt="Underline landing animation" title={"underline-landign-animation"}/>
            </GifContainer>
        </div>
    )
}
export default LandingGif