import React, { useState} from 'react';
import styled from 'styled-components';
import { BsFillClipboardPlusFill } from 'react-icons/bs';
import {ImPlus} from 'react-icons/im'
import { useNavigate } from "react-router-dom";

const ExpandedArea = styled.div`
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: nowrap;
    z-index: 1001;
`;
const FloatingButton = styled.div`
    position: fixed;
    bottom: 70px;
    right: 25px;
    cursor: pointer;
    z-index: 1000;

  @media (max-width: 1115px) {
    display: none;
  }
`;
const IconWrapper = styled.div<{ rotationDegree: number }>`
    background-color: #009688;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    transition: background-color 0.3s, transform 0.15s;
    transform: ${({ rotationDegree }) => `rotate(${rotationDegree}deg)`};

    &:hover {
        background-color: #00796b;
    }
`;
const Tooltip = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px 10px;
    font-size: 14px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    position: absolute;
    bottom: 190px;
    right: 40px;
    white-space: nowrap;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  @media (max-width: 1115px) {
    display: none;
  }
`;
const SecondFloatingButton = styled.div`
    position: absolute;
    bottom: 130px;
    right: 10px;
    cursor: pointer;

  @media (max-width: 1115px) {
    display: none;
  }
`;

const FloatingIcon: React.FC = () => {
    const [iconVisible, setIconVisible] = useState(false);
    const [rotationDegree, setRotationDegree] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);
    const navigate = useNavigate();
    const handleMainIconMouseEnter = () => {
        setIconVisible(true);
        setRotationDegree(180);
    };
    const handleMainIconMouseLeave = () => {
        setRotationDegree(0);
    };
    const handleMouseLeave = () => {
        setIconVisible(false);
    };

    const handleIconWrapperMouseEvents = (
        enter: () => void,
        leave: () => void
    ) => ({
        onMouseEnter: enter,
        onMouseLeave: leave
    });
    
    return (
        <ExpandedArea onMouseLeave={handleMouseLeave}>
        <FloatingButton>
            <IconWrapper
                rotationDegree={rotationDegree}
                {...handleIconWrapperMouseEvents(
                    handleMainIconMouseEnter,
                    handleMainIconMouseLeave
                )}
            >
                <ImPlus size={24} color="white" />
            </IconWrapper>
        </FloatingButton>

        {iconVisible && (
        <>
            <SecondFloatingButton onClick={() => navigate("/new-project")}>
                <IconWrapper 
                rotationDegree={0}
                {...handleIconWrapperMouseEvents(
                    () => setShowTooltip(true),
                    () => setShowTooltip(false)
                )}
                >
                    <BsFillClipboardPlusFill size={24} color="white" />
                </IconWrapper>
            </SecondFloatingButton>
            {showTooltip && <Tooltip>Create Project</Tooltip>}
        </>
        )}
        </ExpandedArea>
    );
};
export default FloatingIcon;