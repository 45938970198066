import React from 'react';
import styled from 'styled-components';

const Button = styled.label`
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
`;

const Input = styled.input`
    display: none;
    &:checked + span {
        background-color: #2196f3;
    }
    &:checked + span:before {
        transform: translateX(16px);
    }
`;

const Switch = styled.span<{ disabled?: boolean }>`
    position: absolute;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
        position: absolute;
        content: '';
        height: 14px;
        width: 14px;
        left: 5px;
        bottom: 3px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
    }
`;

interface SwitchButtonProps {

    checked: boolean;
    onChange: (checked: boolean) => void;
    id?: string;
    confirmation?: () => Promise<void>;
    disabled?: boolean;
}
const SwitchButton: React.FC<SwitchButtonProps> = ({ checked, onChange, id, confirmation, disabled }) => {
    const handleOnChange = async () => {
        try {
            if (disabled){
                return;  
            } else if (confirmation) {
                await confirmation();
            }
            if (onChange) {
                onChange(!checked);
            }
        } catch (error) {
            console.error(error);
        }
        };

        return (
            <Button id={id}>
                <Input id={id} type="checkbox" checked={checked} onChange={handleOnChange} disabled={disabled} />
                <Switch disabled={disabled} />
            </Button>
        );
};

export default SwitchButton;