import React, {useState, useCallback, useRef, useEffect} from 'react'
import styled from 'styled-components';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TaskTable from 'components/projects/TaskTable';
import {UpdateUserInfoProps, useUpdateUserMutation,TypeOfList,useUpdateUserViewsMutation,} from "../../services/usersApiSlice";
import {useAppSelector} from "../../hooks/store-hooks";

const Table = styled.table`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border-radius: 4px;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  -ms-flex: 1 1 auto;
  white-space: nowrap;
  border-collapse: collapse;
  margin-bottom: 20px;
`;
const Header = styled.th<{ headerBackgroundColor?: string }>`
  background-color: ${({ headerBackgroundColor }) =>
          headerBackgroundColor ? headerBackgroundColor : "#ecebeb"};
  font-weight: bold;
  padding: 8px;
  text-align: left;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;

  &:first-child {
    border-left: 1px solid #ccc;
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
  }
`;
const TableRow = styled.tr`
  border-bottom: 1px solid #ccc;
`;
const TableCell = styled.td`
  padding: 8px;
  text-align: left;
  font-size: 15px;
  word-wrap: break-word;
  
  &:first-child {
    border-left: 1px solid #ccc;
  }
  &:last-child {
    border-right: 1px solid #ccc;
  }
`;
interface DraggableHeaderProps {
    typeOfTable: string;
    header: string;
    index: number;
    moveHeader: (fromIndex: number, toIndex: number) => void;
    renderHeader?: (header: string, index: number) => React.ReactNode;
    headerBackgroundColor?: string;
    isDraggable?: boolean;
}
interface DragItem {
    id: number;
    type: string;
}
const DraggableHeader: React.FC<DraggableHeaderProps> = ({ typeOfTable, header, index, moveHeader, renderHeader, headerBackgroundColor, isDraggable  }) => {
    const originalIndex = index;
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'header',
        item: { id: originalIndex },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const [, drop] = useDrop(() => ({
        accept: 'header',
        drop: (item: DragItem) => {
            if (item.id !== index) {
                moveHeader(item.id, index);
            }
        },
    }));

    return (
        <Header
            id={`${typeOfTable}_header_${header}`}
            ref={isDraggable ? (node) => drag(drop(node)) : null}
            key={index}
            style={{cursor: isDraggable ? 'grab' : 'default', backgroundColor: headerBackgroundColor }}
        >
            {renderHeader ? renderHeader(header, index) : header}
        </Header>
    );
};

interface FullTableProps {
    headers: string[];
    content: any[];
    renderCell?: (
        jobId: number,
        key: string,
        value: any,
        rowIndex: number,
        cellIndex: number,
        expandableRows: boolean | undefined
    ) => React.ReactNode;
    renderHeader?: (header: string, index: number) => React.ReactNode;
    expandableRows?: boolean;
    renderSubTable?: (rowIndex: number) => React.ReactNode | null;
    expandedRow?: number | null;
    style?: React.CSSProperties;
    headerBackgroundColor?: string;
    workspaceId?:string;
    typeOfList: TypeOfList;
    isDraggable?: boolean;
    typeOfTable: string;
}
const FullTable: React.FC<FullTableProps> = ({
                                                 headers: initialHeaders,
                                                 content,
                                                 renderCell = (key, value, rowIndex, cellIndex) => { return value; },
                                                 renderHeader,
                                                 expandableRows = false,
                                                 renderSubTable,
                                                 expandedRow,
                                                 style,
                                                 headerBackgroundColor,
                                                 workspaceId,
                                                 typeOfList,
                                                 isDraggable = false,
                                                 typeOfTable
                                             }) => {
    const [headers, setHeaders] = useState<string[]>(initialHeaders);
    const [updateUser] = useUpdateUserMutation();
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const userInfoFromState = useAppSelector(state => state.userInfo);
    const [columnMoved, setColumnMoved] = useState(false);
    const [updateUserViews] = useUpdateUserViewsMutation();

    const moveHeader = useCallback((fromIndex: number, toIndex: number) => {
        setHeaders((prevHeaders) => {
            const newHeaders = [...prevHeaders];
            const [removed] = newHeaders.splice(fromIndex, 1);
            newHeaders.splice(toIndex, 0, removed);
            return newHeaders;
        });
        setColumnMoved(true);
    }, []);

    useEffect(() => {
        if (columnMoved) {
            timeoutRef.current = setTimeout(() => {
                const columns = headers.join(";");
                let updatedData: UpdateUserInfoProps = { id: userInfoFromState.id };
                let newView = { name: typeOfList, columns: columns };
                updatedData.views = [newView];
                handleEditProfile(updatedData);
                setColumnMoved(false);
            }, 1000);
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        }
    }, [columnMoved]);

    const handleEditProfile = async (updatedData: UpdateUserInfoProps) => {
        try {
            await updateUserViews(updatedData).unwrap();
        } catch (error: any) {
            console.log(error);
        }
    };
    return (
        <DndProvider backend={HTML5Backend}>
            <Table style={style} id={`${typeOfTable}_table`}>
                <thead>
                <tr>
                    {headers.map((header, index) => (
                        <DraggableHeader
                            typeOfTable={typeOfTable}
                            key={index}
                            header={header}
                            index={index}
                            moveHeader={moveHeader}
                            renderHeader={renderHeader}
                            headerBackgroundColor={headerBackgroundColor}
                            isDraggable={isDraggable}
                        />
                    ))}
                </tr>
                </thead>
                <tbody>
                {content.map((element, rowIndex) => (
                    <React.Fragment key={rowIndex}>
                        <TableRow>
                            {headers.map((key, cellIndex) => {
                                return (
                                    <TableCell  id={`${typeOfTable}_${element.id}_${key}`} key={cellIndex}>
                                        {renderCell(element.id, key, element[key], rowIndex, cellIndex, expandableRows)}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                        {expandableRows && expandedRow === rowIndex && (
                            <TableRow>
                                <TableCell colSpan={headers.length}>
                                    <TaskTable selectedJob={element} style={style} workspaceId={workspaceId || ''} />
                                </TableCell>
                            </TableRow>
                        )}
                    </React.Fragment>
                ))}
                </tbody>
            </Table>
        </DndProvider>
    );
};
export default FullTable