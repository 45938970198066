import React from "react";
import styled from 'styled-components';
import SubscriptionCard from "../components/subscription/SubscriptionCard";
import LandingFooter from "../components/landing/LandingFooter";
import LandingNavbar from "../components/landing/LandingNavbar";
import LandingVideo from "../components/landing/LandingVideo";
import LandingGif from "../components/landing/LandingGif";
import LandingTitle from "../components/landing/LandingTitle";
import LandingLeftCorner from "../components/landing/LandingLeftCorner";
import LandingSectionTitle from "../components/landing/LandingSectionTitle";
import LandingCorners from "../components/landing/LandingCorners";
import {SUBSCRIPTION_PLANS} from "../utils/constants";
import {useEffect} from "react";
import TagManager from "react-gtm-module";
import LandingBackToTop from "../components/landing/LandingBackToTop";

const LandingBackground = styled.div`
  background-color: #2D292A;
  width: auto;
  height: auto;
  flex-shrink: 0;
  color: #FFFFFF;
  
`;
const MainContainerBorder = styled.div`
  margin: 0 360px;

  @media (max-width: 1852px) {
    margin: 0 280px;
  }
  @media (max-width: 1392px) {
    margin: 0 180px;
  }
  @media (max-width: 1080px) {
    margin: 0 90px;
  }
  @media (max-width: 930px) {
    margin: 0 40px;
  }
  @media (max-width: 780px) {
    margin: 0 5px;
  }
`
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 60px 0 0 0;
  margin-top: 100px;
  @media (max-width: 620px) {
    padding: 0 0 0 10px;
  }
`;
const TitleVideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;  
  
  @media (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
  }
`;
const PageSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 60px;
`;
const SubtitleContainer = styled.div`
  margin-left: -400px;

  @media (max-width: 1616px) {
    margin-left: -350px;
  }
  @media (max-width: 1530px) {
    margin-left: -150px;
  }
  @media (max-width: 1150px) {
    margin-left: 0;
  }
`;
const Subtitle = styled.h6`
  align-self: flex-start;
  font-size: 28px;
  font-weight: 300;
  margin-top: 60px;
  margin-bottom: 0;
  
  @media (max-width: 1390px) {
    font-size: 20px;
  }
`;
const ButtonContainer = styled.div`
  align-self: flex-start;
  margin-top: 30px;
`;
const DownloadButton = styled.a`
  border: 0.50px #EAC113 solid;
  border-radius: 4px;
  width: 135px;
  height: 39px;
  font-size: 16px;
  color: #23201C;
  background-color: #EAC113;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  padding: 5px 20px;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  line-height: 1.5;

  &:hover {
    background-color: #2d292a;
    border: 1px solid #EAC113;
    color: #fff;
  }

  &:focus {
    outline: none;
  }
`;
const SubscriptionContainer = styled.div`
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  & > div {
    margin: 30px;
  }

  @media (max-width: 1700px) {
    & > div {
      margin: 5px 5px 0 0;
    }
  }
  @media (max-width: 1180px) {
    & > div {
      margin-right: 10px;
    }
  }
  @media (max-width: 565px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > div {
      margin-right: 0;
    }
  }
`;
const ExtraGap = styled.div`
  height: 195px;
  
  @media (max-width: 600px) {
    height: 50px;
  }
`;
const Landing: React.FC = () => {
    useEffect(() => {
        TagManager.initialize({
            gtmId: 'GTM-W3XX9PQ'
        })
    }, []);
    return (
        <>
            <LandingBackground>
                <LandingNavbar/>
                <MainContainerBorder>
                    <MainContainer>
                        <TitleVideoContainer>
                            <LandingTitle/>
                            <LandingVideo/>
                        </TitleVideoContainer>
                        <LandingGif/>
                        <LandingLeftCorner/>
                        <PageSection>
                            <LandingSectionTitle
                                id={"case-study"}
                                title={"Download the Case Study"}
                            />
                            <SubtitleContainer>
                                <Subtitle>
                                    Download the case study PDF and see<br/>
                                    how our application can help you achieve your goals.
                                </Subtitle>
                                <ButtonContainer>
                                    <DownloadButton
                                        href='./underline-case-study.pdf'
                                        download='Underline Case Study.pdf'
                                        target='_blank' rel="noreferrer">Download
                                    </DownloadButton>
                                </ButtonContainer>
                            </SubtitleContainer>
                        </PageSection>

                        <PageSection>
                            <LandingSectionTitle
                                id={"pricing"}
                                title={"Plan and Pricing Options"}
                            />
                            <SubscriptionContainer>
                                {SUBSCRIPTION_PLANS.map((plan, index) => (
                                    <SubscriptionCard plan={plan} isOnLandingPage={true}/>
                                ))}
                            </SubscriptionContainer>
                        </PageSection>
                        <LandingCorners/>
                    </MainContainer>
                    <LandingBackToTop />
                    <LandingFooter/>
                    <ExtraGap/>
                </MainContainerBorder>
            </LandingBackground>
        </>
    )
}
export default Landing;