import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {SourceFile, useGetSourceFilesQuery} from "../../services/projectsApiSlice";
import SlimButton from "../ui/forms/action/SlimButton";
import {RiEdit2Fill} from "react-icons/ri";
import Spinner from "../spinner/Spinner";
import {shortenFileName} from "./TaskTable";

const ModalBackground = styled.div<{ showModal: boolean }>`
  display: ${(props) => (props.showModal ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
`;

const SourceTable = styled.div`
  width: 30vw;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border-radius: 4px;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  -ms-flex: 1 1 auto;
  white-space: nowrap;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const TableHeader = styled.th<{ headerBackgroundColor?: string }>`
  width: 25vw;
  background-color: #ecebeb;
  font-weight: bold;
  padding: 8px;
  text-align: left;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;

  &:first-child {
    border-left: 1px solid #ccc;
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
  }
`;

const TableBody = styled.div`
  width: 30vw;
  max-height: 50vh;
  overflow-y: auto;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ccc;
`;
const TableCell = styled.td`
  width: 25vw;
  padding: 8px;
  text-align: left;
  font-size: 15px;
  &:first-child {
    border-left: 1px solid #ccc;
  }
  &:last-child {
    border-right: 1px solid #ccc;
  }
`;
const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
`;


const headers = ["File Name", "Last Date Modify", "Modify By", "Edit Source"];
const formatDateTime = (dateTimeString: string) => {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}`;
}
interface SourceModalProps{
    showModal: boolean;
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
    projectId: string;
    updateEnabled: boolean;
    reloadProject: () => void;
}
const SourceModal: React.FC<SourceModalProps> = ({ showModal, onClose, projectId, updateEnabled, reloadProject }) => {
    const { data, isFetching, isLoading } = useGetSourceFilesQuery(projectId);
    const [sources, setSources] = useState<SourceFile[]>([]);
    const handleEditSource = (sourceId: string) => {
        const url = `/source-editor/${projectId}/${sourceId}`;
        window.open(url, '_blank', `width=${window.screen.width},height=${window.screen.height}`);
    };

    const handleClose = () =>{
        onClose(false)
        reloadProject()
    }

    useEffect(() => {
        if(!isFetching && data) {
            // @ts-ignore
            setSources(data);
        }
    }, [data, isFetching]);

    return (
        <ModalBackground showModal={showModal}>
            {isLoading && <Spinner/>}
            <ModalContent>
                <SourceTable>
                    <thead>
                        <TableRow>
                            {headers.map((header)=>(
                                <TableHeader
                                    id={`source_table_header_${header}`}
                                    key={header}>{header}
                                </TableHeader>
                            ))}
                        </TableRow>
                    </thead>
                    <TableBody>
                        <tbody>
                        {sources?.map((source, index) => (
                            <TableRow key={source.id}>
                                <TableCell id={`source_${index}_file_name`} title={source.name}>{shortenFileName(source.name)}</TableCell>
                                <TableCell id={`source_${index}_last_modify`}>{formatDateTime(source.lastModify)}</TableCell>

                                <TableCell id={`source_${index}_modified_by`}>
                                    {source.modifiedBy && source.modifiedBy.username ? source.modifiedBy.username : "File not modified yet"}
                                </TableCell>

                                <TableCell id={`source_${index}_edit`}>
                                    {updateEnabled &&
                                        <RiEdit2Fill id={`source_${index}_edit_icon`} style={{cursor: "pointer"}} onClick={() => handleEditSource(source.id)}/>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                        </tbody>
                    </TableBody>
                </SourceTable>

                <CloseButtonContainer>
                    <SlimButton
                        id={"close_source_table_button"}
                        onClick={handleClose}
                        type="button"
                        bgColor="#a9a9a9"
                        text={"Close"}
                        textColor="#2d292a"
                        bgGradient="linear-gradient(to right, #a9a9a9, #dcdcdc, #a9a9a9)"
                    />
                </CloseButtonContainer>
            </ModalContent>
        </ModalBackground>
    );
};

export default SourceModal;