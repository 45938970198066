import {underlineApi} from "./underlineApi";
import {BaseQueryArg} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {UserOwnerInfo} from "./usersApiSlice";

export enum SegmentType {
    TRANSLATABLE = "TRANSLATABLE",
    NON_TRANSLATABLE = "NON_TRANSLATABLE"
}

export interface Segment {
    id: string;
    taskSegmentId: number;
    source: string;
    sourceLocale: string;
    targetLocale: string;
    target: string;
    placeholders: Placeholder[];
    wordCount: number;
    type: SegmentType;
    status: SegmentStatus;
}
export interface Placeholder {
    id: string,
    tagId: number,
    content: string
}

export enum SegmentStatus {
    TRANSLATED = 'TRANSLATED',
    REVIEWED = 'REVIEWED',
    LOCKED = 'LOCKED',
    REJECTED = 'REJECTED',
    DRAFT = 'DRAFT',
    NON_TRANSLATED = 'NON_TRANSLATED'
}

export interface SegmentHistoryRecord {
    id: string,
    oldStatus: SegmentStatus,
    newStatus: SegmentStatus,
    updatedAt: string,
    modifiedBy: UserOwnerInfo,
    oldTarget: string,
    newTarget: string
}

export interface SegmentComment {
    id: string,
    text: string,
    creationDate: string,
    creator: UserOwnerInfo,
    relatedHistoryRecord: SegmentHistoryRecord
}

export const segmentsApiSlice = underlineApi.injectEndpoints({
    endpoints: (builder) => ({
        updateSegment: builder.mutation({
            query: ({id, updateInfo}) => {
                return {
                    url: `/segments/${id}`,
                    method: 'PATCH',
                    body: updateInfo,
                }
            },
            invalidatesTags: ['AllProjects','Segment'],
        }),
        segmentHistory: builder.query<SegmentHistoryRecord[], string>({
            query: (segId: string) => ( {
                url: `/segments/${segId}/history`,
                method: 'GET'
            }),
            providesTags: ['Segment']
        }),
        getSegmentComments: builder.query<SegmentComment[], string>({
            query: (segId: string) => ( {
                url: `/segments/${segId}/comments`,
                method: 'GET'
            }),
            providesTags: ['Segment']
        }),
        addSegmentComment: builder.mutation<void, {id: string, comment: string}>({
            query: ({id, comment}) => {
                return {
                    url: `/segments/${id}/comments`,
                    method: 'POST',
                    body: comment,
                    headers: {
                        'Content-Type': 'text/plain'
                    }
                }
            },
            invalidatesTags: ['Segment'],
        }),
    })
});

export const {
    useUpdateSegmentMutation,
    useSegmentHistoryQuery,
    useAddSegmentCommentMutation,
    useGetSegmentCommentsQuery
} = segmentsApiSlice;