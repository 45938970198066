import React from 'react'
import NavbarItem from './NavbarItem'
import {AiFillDashboard} from 'react-icons/ai'
import {TbTransformFilled} from 'react-icons/tb'
import {RiAdminFill, RiLogoutBoxRFill} from 'react-icons/ri'
import {BsClipboardDataFill, BsFillFileEarmarkTextFill, BsPersonWorkspace, BsFillInfoCircleFill} from 'react-icons/bs'
import {MdOutlineHelp, MdMovieEdit} from 'react-icons/md'
import {BiMessageRoundedDetail} from 'react-icons/bi'
import {FaAddressCard} from 'react-icons/fa'
import styled from 'styled-components';
import {UserRole} from "../RequireRole";
import {useAppSelector} from "../../hooks/store-hooks";

const navItems = [
  {icon: <AiFillDashboard />, text: "Dashboard", link: "/home"},
  {icon: <BsClipboardDataFill />, text: "Projects", link: "/projects"},
  {icon: <BsFillFileEarmarkTextFill />, text: "New Project", link: "/new-project"},
  {icon: <TbTransformFilled />, text: "Converter", link: "/converter"},
  {icon: <MdMovieEdit />, text: "Subtitles Editor", link: "/subtitles-editor"},
  {icon: <RiAdminFill />, text: "Administration", link: "/administration"},
  {icon: <BsPersonWorkspace />, text: "Workspaces", link: "/workspaces"},
  {icon: <FaAddressCard  />, text: "Subscription", link: "/subscription"},
  {icon: <BiMessageRoundedDetail />, text: "Contact Us", link: "/contact-us"},
  {icon: <MdOutlineHelp />, text: "Help", link: "/help"},
  {icon: <BsFillInfoCircleFill />, text: "About", link: "/about"},
  {icon: <RiLogoutBoxRFill />, text: "Logout", link: "/logout"},
]

const SidebarPanel = styled.nav`
  margin-top: 10px;
`;
const SidebarList = styled.ul`
  position: relative;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  padding-left: 2px;
`;
interface NavbarProps {
  isCollapsed: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ isCollapsed }) => {
  const userRole = useAppSelector(state => state.userInfo.roles[0]);
  const filteredNavItems = userRole === UserRole.ROLE_USER
      ? navItems.filter(item => item.link !== "/administration" && item.link !== "/workspaces" && item.link !== "/subscription")
      : navItems;

  return (
    <SidebarPanel>
      <SidebarList>
        {filteredNavItems.map((item, index) => (
          <NavbarItem
            key={index}
            icon={item.icon}
            text={item.text}
            link={item.link}
            isCollapsed={isCollapsed}
          />
        ))}
      </SidebarList>
    </SidebarPanel>
  );
};

export default Navbar