import React from "react";
import styled from 'styled-components';

const CardHeader = styled.div`
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    padding: 0 20px 5px 20px;
    position: relative;
    color: #fff;
    height: 50px;
  
`;
const CardTitle = styled.h3`
    font-size: 22px;
    font-weight: 600;
    &:after {
        content: "";
        background-color: #eac113;
        height: 2px;
        width: 80px;
        margin: 10px 0 0;
        display: block;
        clear: both;
    }
`;

interface FormTitleProps {
    title: string;
};

const AuthFormTitle: React.FC<FormTitleProps> = ({ title }) => {
    return (
        <CardHeader>
            <CardTitle>{title}</CardTitle>
        </CardHeader>
    );
}

export default AuthFormTitle;