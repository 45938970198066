import React from 'react';
import PolicyTemplate from './../components/policies/PolicyTemplate';
import {SUPPORT_EMAIL} from '../utils/constants'

const privacyContent = [
    {
        heading: "Underline Privacy Policy",
        text: "At Underline, we take your privacy seriously. This Privacy Policy outlines the types of information we collect and how we use and protect that information. By using our web tool, you consent to the terms of this Privacy Policy."
    },
    {
        heading: "Information We Collect",
        text: "When you use Underline, we may collect the following information:",
        listItems: [
            "Personal information such as your name and email address",
            "Information about your usage of the web tool, including your IP address, browser type, and device information",
            "Any information you provide when communicating with us or reporting a problem"
        ]
    },
    {
        heading: "How We Use Your Information",
        text: "We use the information we collect to:",
        listItems: [
            "Provide you with access to the Underline web tool",
            "Customize and improve the user experience",
            "Respond to any inquiries or support requests you may have",
            "Provide you with information about updates or changes to the web tool",
            "Monitor and analyze usage of the web tool to improve its functionality and effectiveness"
        ]
    },
    {
        heading: "Disclosure of Your Information",
        text: 'We may disclose your information to comply with any applicable laws, regulations, or legal processes or in response to lawful requests by public authorities.'
    },
    {
        heading: "Cookies and Other Technologies",
        text: "We may use cookies or similar technologies to collect information about your usage of the web tool. Cookies are small files that are placed on your device when you access the web tool. You can set your browser to reject cookies or to alert you when cookies are being used."
    },
    {
        heading: "Links to Other Websites",
        text: "Our web tool may contain links to other websites that are not operated by us. We have no control over the content or policies of these websites, and we are not responsible for their practices."
    },
    {
        heading: "Changes to this Privacy Policy",
        text: "We may update this Privacy Policy from time to time. Any changes will be posted on this page. Your continued use of the web tool after any changes to the Privacy Policy will indicate your acceptance of the changes."
    },
    {
        heading: "Contact Us",
        text: `If you have any questions or concerns about this Privacy Policy or the use of your information, please contact us at ${SUPPORT_EMAIL}.`
    }
];
const PrivacyPolicy: React.FC = () => {
    return (
        <div>
            <PolicyTemplate policyContent={privacyContent} />
        </div>
    )
}
export default PrivacyPolicy