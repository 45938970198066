import React, {useState,useEffect} from "react";
import {FaUserCheck} from 'react-icons/fa';
import {RiLock2Fill} from 'react-icons/ri';
import {BsPersonWorkspace} from 'react-icons/bs';
import AuthForm from '../components/ui/forms/auth/AuthForm';
import AuthFormInput from "../components/ui/forms/auth/AuthFormInput";
import InvalidFeedback from '../components/ui/forms/InvalidFeedback';
import AuthFormButton, {ButtonType} from '../components/ui/forms/auth/AuthFormButton';
import {useAppDispatch, useAppSelector} from "../hooks/store-hooks";
import {useNavigate, useLocation} from "react-router-dom";
import {useLoginMutation} from "../services/tokensApiSlice";
import {setTokens} from "../store/tokenSlice";
import Spinner from "../components/spinner/Spinner";
import {INSTANCE_QUERY_PARAM} from "../utils/constants";

const footerLinks = [
    {text: "Don't have an account? ", linkText: 'Sign up here!', link: '/register'},
    {text: '', linkText: 'Forgot your password?', link: '/forgot-password'}
];

const Login = () => {
    const [login, {isLoading}] = useLoginMutation();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const instanceId = new URLSearchParams(location.search).get(INSTANCE_QUERY_PARAM);
    const [userInstanceId, setUserInstanceId] = useState(instanceId? instanceId:"");
    const [errorMessage, setErrorMessage] = useState('');
    useAppSelector(state=>state.cookieConstent);
    useEffect(() => {
    }, [instanceId]);

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            setPassword(event.target.value);
        }
    };
    const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            setUsername(event.target.value);
        }
    };
    const handleChangeInstanceId = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target) {
            setUserInstanceId(event.target.value);
        }
    };
    const handleLogin = async (event:  React.FormEvent) => {
        event.preventDefault();
        if(username.trim().length === 0 || password.trim().length === 0 || userInstanceId.trim().length === 0){
            setErrorMessage('Fields cannot be empty');
            return;
        }
        if(!userInstanceId){
            setErrorMessage('Instance ID is missing');
            return;
        }
        try {
            const tokens = await login({username: username, password: password, instanceId: userInstanceId }).unwrap();
            dispatch(setTokens(tokens));
            navigate("/home");
        } catch (e: any) {
            if(e?.status === 401 || e?.status === 404) {
                setErrorMessage('Invalid credentials');
            } else if(e?.status === 403){
                setErrorMessage(e.data.message)
                setTimeout(() => {
                    navigate("/forgot-password");
                }, 3000);
            } else{
                setErrorMessage('Something went wrong');
            }
        }
    }

    return (
        <>
            {isLoading && <Spinner/>}
            <AuthForm title="Sign in" width={400} height={420} footerLinks={footerLinks}
                      hasError={!!errorMessage} submitHandler={handleLogin}>
                <AuthFormInput
                    prependIcon={<FaUserCheck/>}
                    placeholder="Username"
                    type="text"
                    value={username}
                    onChange={handleChangeUsername}
                />
                <AuthFormInput
                    prependIcon={<RiLock2Fill/>}
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={handleChangePassword}
                />
                <AuthFormInput
                    prependIcon={<BsPersonWorkspace/>}
                    placeholder="Instance ID"
                    type="text"
                    value={userInstanceId}
                    onChange={handleChangeInstanceId}
                />
                {errorMessage && <InvalidFeedback errorMessage={errorMessage}/>}
                <AuthFormButton text='Login' type={ButtonType.submit}/>
            </AuthForm>
        </>
    );
}
export default Login;